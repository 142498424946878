/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Layout from '../../Components/Layout';
import { Pagination } from '@material-ui/lab';
import DashboardHeader from '../../Components/DashboardHeader';
import BuyerContext from '../../context/buyer/buyerContext';
import { dateFormatFront } from '../../Common/Components';
// import MessageLinks from '../../Components/Messages/MessageLinks';
import {connect} from 'react-redux';

const Transactions = (props) => {
  const buyerContext = useContext(BuyerContext);
  const { buyer_alltransaction, getAllTransaction } = buyerContext;

  const [search, setSearch] = useState({
    sh_limit: 10,
    page: 1,
  });

  const onHandlePage = (event, value) => {
    setSearch({ ...search, page: value });
  };

  useEffect(() => {
    getAllTransaction(search);
  }, [search]);

  return (
    <Layout props={props}>
      <DashboardHeader title={props.Language.trnscaps} subtitle={props.Language.detcaps} />
      <Container className="dashboard-wrapper">
        <Grid container spacing={3} className="transaction_container">
          {buyer_alltransaction.results.length ? (
            <>
              <table class="table table-hover">
                <thead>
                  <tr>
          <th>{props.Language.trid}</th>
          <th>{props.Language.ptitl}</th>
          <th>{props.Language.typee}</th>
          <th>{props.Language.amttt}</th>
          <th>{props.Language.dtecaps}</th>
                  </tr>
                </thead>
                <tbody>
                  {buyer_alltransaction.results.map((item, index) => {
                    return (
                      <tr key={index}>
                        {/* <td>
                          <span href="" className="trans_link">
                            {item.Invoice_id}
                          </span>
                        </td> */}
                        <td>{item.transid}</td>
                        <td>
                          {item.pay_type === 'membership_pay'
                            ? '-'
                            : item.pay_type === 'membership_renewal'
                            ? '-'
                            : item.title}
                        </td>
                        <td>
                          {item.pay_type === 'membership_pay'
                            ? 'Membership Payment'
                            : item.pay_type === 'membership_renewal'
                            ? 'Membership Renewal'
                            : item.pay_type === 'auction_pay'
                            ? 'Card Payment'
                            : ''}
                        </td>
                        <td>$ {item.amount}</td>
                        <td>{dateFormatFront(item.pay_date)}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </>
          ) : (
          <div className="np-pro-box"> {props.Language.no_Trans ? props.Language.no_Trans : "No Transactions" } </div>
          )}
          {buyer_alltransaction.results.length ? (
            <>
              <div className="d-flex mt-3">
                <div className="ml-auto mt-2">
                  {props.Language.owningsh ? props.Language.owningsh : "Showing" } {search.sh_limit * (search.page - 1) + 1} -{' '}
                  {search.sh_limit * search.page >
                  buyer_alltransaction.total_pagecnt
                    ? buyer_alltransaction.total_pagecnt
                    : search.sh_limit * search.page}{' '}
                  of {buyer_alltransaction.total_pagecnt}
                </div>
                <Pagination
                  count={Math.ceil(
                    buyer_alltransaction.total_pagecnt / search.sh_limit
                  )}
                  page={search.page}
                  onChange={onHandlePage}
                />
              </div>
            </>
          ) : null}
        </Grid>
      </Container>
    </Layout>
  );
};

const mapStateToProps = (state) => {
  return {
    Language : state.Language.languages
   }
 };

export default connect(mapStateToProps,undefined) (Transactions);
