import React from 'react';
import Header from '../Header';
import Subheader from '../Subheader';
import Footer from '../Footer';
import PropTypes from 'prop-types';

const Layout = ({ props, children, ...rest }) => {
  return (
    <>
      <Header props={props} />
      <Subheader />
      <div className="main-content-wrapper">{children}</div>
      <Footer />
    </>
  );
};

Layout.propTypes = {
  props: PropTypes.object
};

export default Layout;
