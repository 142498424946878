/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useContext, useEffect } from 'react';
import $ from 'jquery';
import { Redirect } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import Alerts from '../Alert/alerts';
import { onChangeFunction } from '../../Common/Components';
import AuthContext from '../../context/auth/authContext';
import AlertContext from '../../context/alert/alertContext';
import publicIp from 'public-ip';
import {connect} from 'react-redux';

const LoginForm = (props) => {
  const authContext = useContext(AuthContext);
  const alertContext = useContext(AlertContext);

  const [
    usernamecookies,
    usernamesetCookie,
    usernameremoveCookie,
  ] = useCookies(['username']);
  const [
    passwordcookies,
    passwordsetCookie,
    passwordremoveCookie,
  ] = useCookies(['password']);

  const [toHome, settoHome] = useState(false);

  const { setAlert } = alertContext;
  let { login, error, clearErrors, isAuthenticated } = authContext;

  const [user, setUser] = useState({
    username: '',
    password: '',
    ipaddress: '',
    user_data: '',
    remember: false,
  });
  const onChange = (e) => setUser(onChangeFunction(e, user));

  useEffect(() => {
    if (error) {
      if (error === 'Login successful') {
        if (user.remember) {
          usernamesetCookie('username', user.username);
          passwordsetCookie('password', user.password);
        } else {
          if (usernamecookies.username) {
            usernameremoveCookie('username');
          }
          if (passwordcookies.password) {
            passwordremoveCookie('password');
          }
        }
        let lang = localStorage.getItem('language');
        
        if(lang === 'Spanish'){
          error = 'Inicio de sesión exitoso'
        } 
        setAlert(error, 'success');
        clearErrors();
        settoHome(true);
      } else {
        let lang = localStorage.getItem('language');
        if(lang === 'Spanish'){
          if(error === 'Invalid username or password')
          error = 'Useario o contasena invalido'
        } 
        setAlert(error, 'error');
        clearErrors();
      }
    }
  }, [error]);

  useEffect(() => {
    async function fetchData() {
      if (isAuthenticated) {
        settoHome(true);
      } else {
        if (usernamecookies.username && passwordcookies.password) {
          setUser({
            ...user,
            username: usernamecookies.username,
            password: passwordcookies.password,
            ipaddress: ''// await publicIp.v4(),
          });
        } else {
          setUser({
            ...user,
            ipaddress: ''//await publicIp.v4(),
          });
        }
      }
    }

    fetchData();
  }, []);

  const onSubmit = (e) => {
    e.preventDefault();
    if ($('#LoginFrom').parsley().validate()) {
      login(user);
    } else {
      return false;
    }
  };

  return (
    <Grid className="formBox">
      {props.withoutHeader === 1 ? (
        <Grid item xs={12}>
          <Alerts />
        </Grid>
      ) : null}
      {toHome ? <Redirect to="/" /> : null}
      <Grid className="lFormHead">
        <Link to="/">
          <img src="/images/auth-logo.png" alt="" />
        </Link>
      </Grid>
      <Grid className="lFormBody">
        <h4 className="main-head">
          <span className="hbox">{props.Language.login}</span>
      <span>{props.Language.hrere}</span>
        </h4>
      <p class="auth-note">{props.Language.lhats}</p>
        <form id="LoginFrom" data-parsley-validate onSubmit={onSubmit}>
          <Grid container className="reg-inpt">
            <Grid item xs={12}>
              <TextField
                autoFocus={true}
                name="username"
                value={user.username}
                onChange={onChange}
                placeholder={props.Language.em_l}
                className="la-input"
                margin="normal"
                variant="outlined"
                inputProps={{
                  'data-parsley-required': 'true',
                  'data-parsley-error-message':props.Language.thisir ? props.Language.thisir : 'This value is required'
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="password"
                value={user.password}
                onChange={onChange}
                placeholder={props.Language.password}
                type="password"
                className="la-input"
                margin="normal"
                variant="outlined"
                inputProps={{
                  'data-parsley-required': 'true',
                  'data-parsley-error-message':props.Language.thisir ? props.Language.thisir : 'This value is required'
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={user.remember}
                      name="remember"
                      onChange={(event, value) => {
                        onChange(event);
                      }}
                      value="true"
                      color="default"
                      // inputProps={{
                      //   'data-parsley-required': 'true'
                      // }}
                    />
                  }
                  label={props.Language.remender_me}
                />
              </FormGroup>
            </Grid>
            <Grid item xs={6}>
              <Link to="/forgot-password" className="fpwd-link">
                {props.Language.f_g}
              </Link>
            </Grid>
            <Grid item xs={12}>
              <Button type="submit" className="btn btn-green">
              {props.Language.login}
              </Button>
            </Grid>
          </Grid>
        </form>

        <div class="mb-4 text-center show-xs">
          {props.Language.dyhaa} ?
          <span className="reg-links">
            <a href="/register">{props.Language.rester}</a> or{' '}
                <a href="/proregister">{props.Language.pbregis}</a>
          </span>
        </div>

        <Grid item xs={12}>
          <p className="copy-right">
            {props.Language.cpy_rght}.
          </p>
        </Grid>
      </Grid>
    </Grid>
  );
};


const mapStateToProps = (state) => {
  return {
    Language : state.Language.languages
   }
 };

export default connect(mapStateToProps,undefined)(LoginForm);
