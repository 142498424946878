import {
  ADD_PRODUCT,
  GET_EDIT_PRODUCT,
  GET_ALL_USERS,
  GET_NEW_AUCTION,
  GET_EDIT_AUCTION,
  GET_INVOICE,
  GET_INVOICE_NEW,
  RESPONSE_STATUS,
  CLEAR_RESPONSE,
  GET_SHIPPING_RATES,
  GET_ALL_SELLER,
  GET_ALL_SELLER_AUCTIONS,
  GET_ALL_SEARCH,
  GET_ALL_SIMILAR,
  GET_ALL_SEARCH_AUCTIONS,
  GET_ACTIVE_BID_PRODUCT,
  GET_WON_BID_PRODUCT,
  GET_LOST_BID_PRODUCT,
  UPDATE_PROJ_DYN,
  GET_LA_SELLERS,
  GET_ALL_PRODUCT_VIEWED
} from './productTypes';

export default (state, action) => {
  switch (action.type) {
    case ADD_PRODUCT:
      return {
        ...state,
        responseStatus: action.payload,
      };
    case GET_ACTIVE_BID_PRODUCT:
      return {
        ...state,
        buyer_activeproducts: [action.payload],
      };
    case GET_WON_BID_PRODUCT:
      return {
        ...state,
        buyer_wonproducts: [action.payload],
      };
    case GET_LOST_BID_PRODUCT:
      return {
        ...state,
        buyer_lostproducts: [action.payload],
      };
    case GET_EDIT_PRODUCT:
      return {
        ...state,
        edit_product: action.payload,
      };
    case GET_ALL_USERS:
      return {
        ...state,
        all_active_users: action.payload,
      };
    case GET_INVOICE_NEW:
      return {
        ...state,
        group_invoice_details: action.payload,
      };
    case GET_INVOICE:
      return {
        ...state,
        invoice_details: action.payload,
      };
    case GET_EDIT_AUCTION:
      return {
        ...state,
        edit_auction: action.payload.auction_details,
        auction_lots: action.payload.lot_details,
      };
    case GET_LA_SELLERS:
      return {
        ...state,
        la_sellers: action.payload.la_sellers
      };
    case GET_NEW_AUCTION:
      return {
        ...state,
        auction_lots: action.payload,
      };
    case RESPONSE_STATUS:
      return {
        ...state,
        responseStatus: action.payload,
      };
    case GET_ALL_SELLER:
      return {
        ...state,
        seller_allproducts: action.payload,
      };
    case GET_ALL_SELLER_AUCTIONS:
      console.log('in reducer ',action.payload)
      return {
        ...state,
        seller_allauctions: action.payload,
      };
    case GET_SHIPPING_RATES:
      return {
        ...state,
        shipping_rates: action.payload,
      };

    case GET_ALL_SIMILAR:
      return {
        ...state,
        search_allsimilarproducts: action.payload,
      };
    case GET_ALL_SEARCH:
      return {
        ...state,
        search_allproducts: action.payload,
      };
    case GET_ALL_SEARCH_AUCTIONS:
      return {
        ...state,
        search_allauctions: action.payload,
      };
    case UPDATE_PROJ_DYN:
      return {
        ...state,
        responseStatus: action.payload,
      };
    case CLEAR_RESPONSE:
      return {
        ...state,
        responseStatus: '',
      };
    case GET_ALL_PRODUCT_VIEWED:
      console.log('get all products in reducer',action.payload)
      return {
        ...state,
        allProductsViewed: action.payload,
      };
    default:
      return state;
  }
};
