import React from 'react';
import AuthLeft from '../../Components/Auth/AuthLeft';
import RegisterForm from '../../Components/Auth/RegisterForm';
import { withRouter } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import {compose} from 'redux';
import {connect} from 'react-redux';

const Register = (props) => {
  return (
    <Grid container className="auth-wrapper">
      <Grid item sm={6} className="auth-left-wrapper auth-xs-left">
        <AuthLeft
          pageTitle={props.Language.lghr}
          subTitle={props.Language.we_hp_ur}
          primaryButton="LOGIN"
          primaryHref="/login"
        />
      </Grid>
      <Grid item sm={6} className="Login-form">
        <RegisterForm type={'pro'} /> 
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state) => {
  return {
    Language : state.Language.languages
   }
 };

 export default compose(
  withRouter,
  connect(mapStateToProps, undefined)
)(Register);


