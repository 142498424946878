/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useContext, useEffect } from 'react';
import $ from 'jquery';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import '../Profile/profile.css';
import PayContext from '../../context/payment/payContext';
import AlertContext from '../../context/alert/alertContext';
import AuthContext from '../../context/auth/authContext';

import AddCard from './addCard';
import {connect} from 'react-redux';

const button = $('#formSubmitButton');
const CardForm = (props) => {
  const [open, setOpen] = useState(false);
  // const [editOpen, setEditOpen] = useState(false);
  // const [editCardDetails, setEditCardDetails] = useState();
  const authContext = useContext(AuthContext);
  const { user, loadUser } = authContext;
  const payContext = useContext(PayContext);
  const alertContext = useContext(AlertContext);
  const { setAlert } = alertContext;
  const {
    responseStatus,
    usercards,
    getAllUserCardsAuthorize,
    deleteUserCardAuthorize,
    clearResponse,
  } = payContext;

  const paymentSuccess = () => setOpen(!open);
  // const editCardSuccess = () => setEditOpen(!editOpen);

  const getCardDetails = () => {
    if (user.card_profileid) {
      getAllUserCardsAuthorize();
    }
  };
  useEffect(() => {
    getCardDetails();
  }, [user]);

  useEffect(() => {
    if (responseStatus && responseStatus !== '') {
      if (responseStatus === 'cardadded') {
        getCardDetails();
        if (user.card_profileid) {
        } else {
          loadUser();
        }
      } else if (responseStatus === 'carddeleted') {
        getCardDetails();
        setAlert(props.Language.crd_u_s ? props.Language.crd_u_s : 'Card successfully updated', 'success');
        clearResponse();
      } else if (responseStatus === 'cardedited') {
        getCardDetails();
      } else if (responseStatus === 'paymentsuccessful') {
        setAlert(props.Language.pay_s_c ? props.Language.pay_s_c : 'Payment successfully completed', 'success');
        clearResponse();
        button.attr('disabled', false);
      } else {
        setAlert(responseStatus, 'error');
        clearResponse();
        button.attr('disabled', false);
      }
    } else {
      clearResponse();
    }
  }, [responseStatus]);

  // const editCard = (data) => {
  //   setEditCardDetails(data);
  //   editCardSuccess();
  // };

  const deleteCard = (data) => {
    deleteUserCardAuthorize({
      profileid: data.custproi,
      payid: data.customerPaymentProfileId,
    });
  };

  return (
    <Container>
      {user.card_profileid ? (
        <>
          {usercards.length < 6 ? (
            <>
              <div className="row">
                <div className="col-sm-10">
                  <span>{props.Language.svd_crd ? props.Language.svd_crd : "SAVED CARDS"}</span>
                </div>
                <div className="col-sm-2">
                  <button className="btn btn-green" onClick={paymentSuccess}>
                  {props.Language.add_crd ? props.Language.add_crd : "ADD CARDS"}
                  </button>
                </div>
              </div>
              <br />
              <AddCard
                open={open}
                card_profileid={user.card_profileid}
                paymentSuccess={paymentSuccess}
              />
            </>
          ) : null}
          {/* {editOpen ? (
            <AddCard
              open={editOpen}
              paymentSuccess={editCardSuccess}
              editDetails={editCardDetails}
            />
          ) : null} */}
          {usercards.map((item, index) => {
            return (
              <Box className="db-pro-box scards-wrap" key={index}>
                {usercards.length > 1 ? (
                  <>
                    <div className="row">
                      <div className="col-sm-10 mb-2">
                        <div>
                        {props.Language.crd_num ? props.Language.crd_num : "Card Number "} : <b>{item.cardNumber}</b>
                        </div>
                      </div>
                      <div className="col-sm-2 mb-2">
                        <button
                          className="btn btn-green"
                          onClick={() => deleteCard(item)}
                        >
                          {props.Language.delete ? props.Language.delete : "DELETE"}
                        </button>
                      </div>
                      {/* <div className="col-sm-2 mb-2">
                        <button
                          className="btn btn-green"
                          onClick={() => editCard(item)}
                        >
                          EDIT
                        </button>
                      </div> */}
                    </div>
                  </>
                ) : (
                  <>
                    <div className="row">
                      <div className="col-sm-12 mb-2">
                        <div>
                        {props.Language.crd_num ? props.Language.crd_num : "Card Number "} : <b>{item.cardNumber}</b>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </Box>
            );
          })}
        </>
      ) : (
        <>
          <div className="row">
            <div className="col-sm-12 text-center">
              <button className="btn btn-green" onClick={paymentSuccess}>
              {props.Language.add_crd ? props.Language.add_crd : "ADD CARD"}
              </button>
            </div>
          </div>
          <br />

          <AddCard
            open={open}
            card_profileid=""
            paymentSuccess={paymentSuccess}
          />
        </>
      )}
    </Container>
  );
};

const mapStateToProps = state => {
  return {
    Language : state.Language.languages
  }
}

export default connect(mapStateToProps)(CardForm);
