import React, { useReducer } from 'react';
import ProductContext from './productContext';
import ProductReducer from './productReducer';
import apiCall from '../../Common/ApiCall';

import {
  ADD_PRODUCT,
  GET_EDIT_PRODUCT,
  GET_ALL_USERS,
  GET_EDIT_AUCTION,
  GET_INVOICE,
  GET_INVOICE_NEW,
  GET_NEW_AUCTION,
  GET_ALL_SELLER_AUCTIONS,
  GET_ALL_SELLER,
  GET_ALL_SEARCH,
  GET_ALL_SIMILAR,
  GET_ACTIVE_BID_PRODUCT,
  GET_WON_BID_PRODUCT,
  GET_LOST_BID_PRODUCT,
  GET_ALL_SEARCH_AUCTIONS,
  GET_SHIPPING_RATES,
  RESPONSE_STATUS,
  CLEAR_RESPONSE,
  UPDATE_PROJ_DYN,
  GET_LA_SELLERS,
  GET_ALL_PRODUCT_VIEWED
} from './productTypes';

const ProductState = (props) => {
  const initialState = {
    edit_product: {},
    all_active_users: [],
    edit_auction: {},
    invoice_details: {},
    group_invoice_details: {},
    auction_lots: [],
    la_sellers: [],
    shipping_rates: {},
    search_allsimilarproducts: {
      results: [],
      locate: [],
      categoriesCounts: [],
      upcomingAuction: [],
      set_disp: 0,
      total_pagecnt: 0,
      total_only: 0,
    },
    search_allproducts: {
      results: [],
      locate: [],
      state: [],
      city: [],
      categoriesCounts: [],
      subcategoriesCounts: [],
      upcomingAuction: [],
      set_disp: 0,
      total_pagecnt: 0,
      total_only: 0,
    },
    search_allauctions: {
      results: [],
      set_disp: 0,
      total_pagecnt: 0,
      total_only: 0,
    },
    // buyer_allproducts: [],
    buyer_activeproducts: [],
    buyer_wonproducts: [],
    buyer_lostproducts: [],
    seller_allproducts: {
      result: [],
      length: 0,
    },
    seller_allauctions: {
      result: [],
      length: 0,
    },
    responseStatus: null,
    allProductsViewed : {}
  };

  const [state, dispatch] = useReducer(ProductReducer, initialState);

  // Post Product
  const postProduct = async (formData) => {
    try {
      const [res] = await Promise.all([
        apiCall('post', 'sellerpostprojectlive', formData, 'formdata'),
      ]);
      if (res.data.status === 'true') {
        dispatch({
          type: ADD_PRODUCT,
          payload: 'successfully_completed',
        });
      } else {
        dispatch({
          type: RESPONSE_STATUS,
          payload: res.data.failedreason,
        });
      }
    } catch (err) {
      dispatch({
        type: RESPONSE_STATUS,
        payload: 'Something went wrong!',
      });
    }
  };

  // Post Product
  const editProduct = async (formData) => {
    try {
      const [res] = await Promise.all([
        apiCall('post', 'sellerupdateproject', formData, 'formdata'),
      ]);
      if (res.data.status === 'true') {
        dispatch({
          type: ADD_PRODUCT,
          payload: 'successfully_completed',
        });
      } else {
        dispatch({
          type: RESPONSE_STATUS,
          payload: res.data.failedreason,
        });
      }
    } catch (err) {
      dispatch({
        type: RESPONSE_STATUS,
        payload: 'Something went wrong!',
      });
    }
  };

  // Get edit Product
  const getEditProduct = async (formData) => {
    try {
      const [res] = await Promise.all([
        apiCall('post', 'getProductDetails', formData),
      ]);
      if (res.data.status === 'yes') {
        dispatch({
          type: GET_EDIT_PRODUCT,
          payload: res.data.data,
        });
      } else {
        dispatch({
          type: RESPONSE_STATUS,
          payload: res.data.failedreason,
        });
      }
    } catch (err) {
      // dispatch({
      //   type: RESPONSE_STATUS,
      //   payload: 'Something went wrong!',
      // });
    }
  };

  // Get edit Product
  const getActiveUsers = async (formData) => {
    try {
      const [res] = await Promise.all([
        apiCall('post', 'getallUsers', formData),
      ]);
      if (res.data.success === 'yes') {
        dispatch({
          type: GET_ALL_USERS,
          payload: res.data.users,
        });
      } else {
        // dispatch({
        //   type: RESPONSE_STATUS,
        //   payload: res.data.failedreason,
        // });
      }
    } catch (err) {}
  };

  // Post Product
  const postAuction = async (formData) => {
    try {
      const [res] = await Promise.all([
        apiCall('post', 'createauctionlot', formData, 'formdata'),
      ]);
      if (res.data.success) {
        dispatch({
          type: ADD_PRODUCT,
          payload: 'successfully_completed',
        });
      } else {
        dispatch({
          type: RESPONSE_STATUS,
          payload: res.data.data,
        });
      }
    } catch (err) {
      dispatch({
        type: RESPONSE_STATUS,
        payload: 'Something went wrong!',
      });
    }
  };

  const getInvoiceNew = async (formData) => {
    try {
      const [res] = await Promise.all([
        apiCall('post', 'view-invoice', formData),
      ]);
      if (res.data.success === 'yes') {
        dispatch({
          type: GET_INVOICE_NEW,
          payload: res.data.results.arr_temp,
        });
      } else {
        dispatch({
          type: RESPONSE_STATUS,
          payload: res.data.reason,
        });
      }
    } catch (err) {
      dispatch({
        type: RESPONSE_STATUS,
        payload: 'Something went wrong!',
      });
    }
  };

  const getInvoice = async (formData) => {
    try {
      const [res] = await Promise.all([
        apiCall('post', 'getinvoicedetails', formData),
      ]);
      if (res.data.success) {
        dispatch({
          type: GET_INVOICE,
          payload: res.data.data.record,
        });
      } else {
        dispatch({
          type: RESPONSE_STATUS,
          payload: res.data.data,
        });
      }
    } catch (err) {
      dispatch({
        type: RESPONSE_STATUS,
        payload: 'Something went wrong!',
      });
    }
  };
  // Post Product
  const getShippingRates = async (formData) => {
    try {
      const [res] = await Promise.all([
        apiCall('post', 'getshippingrates', formData, '', 'auctionpay'),
      ]);
      if (res.data.success) {
        dispatch({
          type: GET_SHIPPING_RATES,
          payload: res.data.data.status,
        });
      } else {
        dispatch({
          type: RESPONSE_STATUS,
          payload: res.data.data.error,
        });
      }
    } catch (err) {
      console.log('error', err);
    }
  };

  // Post Product
  const postShipping = async (formData) => {
    try {
      const [res] = await Promise.all([
        apiCall('post', 'shippingapi', formData, '', 'auctionpay'),
      ]);
      if (res.data.success) {
        dispatch({
          type: RESPONSE_STATUS,
          payload: 'successfully_shipmentapi',
        });
      } else {
        dispatch({
          type: RESPONSE_STATUS,
          payload: res.data.data,
        });
      }
    } catch (err) {
      dispatch({
        type: RESPONSE_STATUS,
        payload: 'Something went wrong!',
      });
    }
  };

  // Post Product
  const postLocalPickup = async (formData) => {
    try {
      const [res] = await Promise.all([
        apiCall('post', 'localpickupapi', formData, '', 'auctionpay'),
      ]);
      if (res.data.success) {
        dispatch({
          type: RESPONSE_STATUS,
          payload: 'successfully_localpickupapi',
        });
      } else {
        dispatch({
          type: RESPONSE_STATUS,
          payload: res.data.data,
        });
      }
    } catch (err) {
      dispatch({
        type: RESPONSE_STATUS,
        payload: 'Something went wrong!',
      });
    }
  };
  // Get edit Product
  const getEditAuction = async (formData) => {
    try {
      const [res] = await Promise.all([
        apiCall('post', 'getauctionlotdetails', formData),
      ]);
      if (res.data.success) {
        dispatch({
          type: GET_EDIT_AUCTION,
          payload: res.data.data,
        });
      } else {
        dispatch({
          type: RESPONSE_STATUS,
          payload: res.data.data,
        });
      }
    } catch (err) {
      dispatch({
        type: RESPONSE_STATUS,
        payload: 'Something went wrong!',
      });
    }
  };

  // Get edit Product
  const getNewAuction = async (formData) => {
    try {
      formData['isBiddedProducts'] = "false";
      const [res] = await Promise.all([
        apiCall('post', 'getlotdetails', formData),
      ]);
      if (res.data.success) {
        dispatch({
          type: GET_NEW_AUCTION,
          payload: res.data.data,
        });
      } else {
        dispatch({
          type: RESPONSE_STATUS,
          payload: res.data.data,
        });
      }
    } catch (err) {
      dispatch({
        type: RESPONSE_STATUS,
        payload: 'Something went wrong!',
      });
    }
  };

  // Post Product
  const getAllSellerProducts = async (formData) => {
    try {
      const [res] = await Promise.all([
        apiCall('post', 'getSellerProducts', formData),
      ]);
      if (res.data.status === 'yes') {
        dispatch({
          type: GET_ALL_SELLER,
          payload: res.data.data,
        });
      } else {
        dispatch({
          type: GET_ALL_SELLER,
          payload: {
            result: [],
            length: 0,
          },
        });
      }
    } catch (err) {
      dispatch({
        type: RESPONSE_STATUS,
        payload: 'Something went wrong!',
      });
    }
  };

  // Post Product
  const getAllSellerAuctions = async (formData) => {
    try {
      const [res] = await Promise.all([
        apiCall('post', 'getSellerAuctions', formData),
      ]);
      if (res.data.status === 'yes') {
        console.log('before dispath 1111')
        dispatch({
          type: GET_ALL_SELLER_AUCTIONS,
          payload: res.data.data,
        });
      } else {
        dispatch({
          type: GET_ALL_SELLER_AUCTIONS,
          payload: {
            result: [],
            length: 0,
          },
        });
      }
    } catch (err) {
      dispatch({
        type: RESPONSE_STATUS,
        payload: 'Something went wrong!',
      });
    }
  };

  const getAllSearchAuctions = async (formData) => {
    try {
      const [res] = await Promise.all([
        apiCall('post', 'auctionsearch', formData),
      ]);
      if (res.data.success === 'yes') {
        if (res.data) {
          dispatch({
            type: GET_ALL_SEARCH_AUCTIONS,
            payload: {
              results: res.data.results.length ? res.data.results : [],
              set_disp: res.data.set_disp,
              total_pagecnt: res.data.total_pagecnt,
              total_only: res.data.total_only,
            },
          });
        } else {
          dispatch({
            type: GET_ALL_SEARCH_AUCTIONS,
            payload: {
              results: [],
              set_disp: 0,
              total_pagecnt: 0,
              total_only: 0,
            },
          });
        }
      } else {
        dispatch({
          type: RESPONSE_STATUS,
          payload: res.data.failedreason,
        });
      }
    } catch (err) {
      dispatch({
        type: RESPONSE_STATUS,
        payload: 'Something went wrong!',
      });
    }
  };
  const getAllSimilarProducts = async (formData) => {
    try {
      const [res] = await Promise.all([
        apiCall('post', 'mobilesearch', formData),
      ]);
      if (res.data.success === 'yes') {
        if (res.data) {
          dispatch({
            type: GET_ALL_SIMILAR,
            payload: {
              results: res.data.results.length ? res.data.results : [],
              set_disp: res.data.set_disp,
              total_pagecnt: res.data.total_pagecnt,
              total_only: res.data.total_only,
              locate: res.data.locate.length ? res.data.locate : [],
              categoriesCounts: res.data.categoriesCounts.length
                ? res.data.categoriesCounts
                : [],
              upcomingAuction: res.data.upcomingAuction.length
                ? res.data.upcomingAuction
                : [],
            },
          });
        } else {
          dispatch({
            type: GET_ALL_SIMILAR,
            payload: {
              results: [],
              locate: [],
              categoriesCounts: [],
              upcomingAuction: [],
              set_disp: 0,
              total_pagecnt: 0,
              total_only: 0,
            },
          });
        }
      } else {
        dispatch({
          type: RESPONSE_STATUS,
          payload: res.data.failedreason,
        });
      }
    } catch (err) {
      dispatch({
        type: RESPONSE_STATUS,
        payload: 'Something went wrong!',
      });
    }
  };
  const getAllSearchProducts = async (formData) => {
    try {
      const [res] = await Promise.all([
        apiCall('post', 'mobilesearch', formData),
      ]);
      if (res.data.success === 'yes') {
        if (res.data) {
          dispatch({
            type: GET_ALL_SEARCH,
            payload: {
              results: res.data.results.length ? res.data.results : [],
              set_disp: res.data.set_disp,
              total_pagecnt: res.data.total_pagecnt,
              total_only: res.data.total_only,
              locate: res.data.locate.length ? res.data.locate : [],
              state: res.data.statehtm.length ? res.data.statehtm : [],
              city: res.data.cityhtm.length ? res.data.cityhtm : [],
              categoriesCounts: res.data.categoriesCounts.length
                ? res.data.categoriesCounts
                : [],
              subcategoriesCounts: res.data.subcategoriesCounts.length
                ? res.data.subcategoriesCounts
                : [],
              upcomingAuction: res.data.upcomingAuction.length
                ? res.data.upcomingAuction
                : [],
            },
          });
        } else {
          dispatch({
            type: GET_ALL_SEARCH,
            payload: {
              results: [],
              locate: [],
              state: [],
              categoriesCounts: [],
              subcategoriesCounts: [],
              upcomingAuction: [],
              set_disp: 0,
              total_pagecnt: 0,
              total_only: 0,
            },
          });
        }
      } else {
        dispatch({
          type: RESPONSE_STATUS,
          payload: res.data.failedreason,
        });
      }
    } catch (err) {
      dispatch({
        type: RESPONSE_STATUS,
        payload: 'Something went wrong!',
      });
    }
  };

  const getActiveBidProducts = async (formData) => {
    try {
      const [res] = await Promise.all([apiCall('post', 'active', formData)]);
      if (res.data.status === 'yes') {
        dispatch({
          type: GET_ACTIVE_BID_PRODUCT,
          payload: res.data.data,
        });
      } else {
        dispatch({
          type: RESPONSE_STATUS,
          payload: res.data.failedreason,
        });
      }
    } catch (err) {
      dispatch({
        type: RESPONSE_STATUS,
        payload: 'Something went wrong!',
      });
    }
  };

  const getWonBidProducts = async (formData) => {
    try {
      const [res] = await Promise.all([apiCall('post', 'my-won', formData)]);
      if (res.data.status === 'yes') {
        dispatch({
          type: GET_WON_BID_PRODUCT,
          payload: res.data.data,
        });
      } else {
        dispatch({
          type: RESPONSE_STATUS,
          payload: res.data.failedreason,
        });
      }
    } catch (err) {
      dispatch({
        type: RESPONSE_STATUS,
        payload: 'Something went wrong!',
      });
    }
  };

  const getLostBidProducts = async (formData) => {
    try {
      const [res] = await Promise.all([
        apiCall('post', 'mybids_search/lost', formData),
      ]);
      if (res.data.status === 'yes') {
        dispatch({
          type: GET_LOST_BID_PRODUCT,
          payload: res.data.data,
        });
      } else {
        dispatch({
          type: RESPONSE_STATUS,
          payload: res.data.failedreason,
        });
      }
    } catch (err) {
      dispatch({
        type: RESPONSE_STATUS,
        payload: 'Something went wrong!',
      });
    }
  };

  const updateProjectDynamic = async (formData) => {
    try {
      const [res] = await Promise.all([
        apiCall('post', 'updateProjectDetails', formData),
      ]);
      if (res.data.status === 'yes') {
        dispatch({
          type: UPDATE_PROJ_DYN,
          payload: 'proj_updated_successfull',
        });
      } else {
        dispatch({
          type: RESPONSE_STATUS,
          payload: res.data.message,
        });
      }
    } catch (err) {
      dispatch({
        type: RESPONSE_STATUS,
        payload: 'Something went wrong!',
      });
    }
  };

  const updateBuyNowDynamic = async (formData) => {
    try {
      const [res] = await Promise.all([
        apiCall('post', 'updateBuyNowDetails', formData),
      ]);
      if (res.data.status === 'yes') {
        dispatch({
          type: UPDATE_PROJ_DYN,
          payload: 'buynow_updated_successfull',
        });
      } else {
        dispatch({
          type: RESPONSE_STATUS,
          payload: res.data.message,
        });
      }
    } catch (err) {
      dispatch({
        type: RESPONSE_STATUS,
        payload: 'Something went wrong!',
      });
    }
  };

  const addWatchlist = async (formData) => {
    try {
      const [res] = await Promise.all([
        apiCall('post', 'add_watchlist', formData),
      ]);
      if (res.data.status === 'yes') {
        dispatch({
          type: RESPONSE_STATUS,
          payload: 'watchlistadded_successfully',
        });
      } else {
        dispatch({
          type: RESPONSE_STATUS,
          payload: res.data.message,
        });
      }
    } catch (err) {
      dispatch({
        type: RESPONSE_STATUS,
        payload: 'Something went wrong!',
      });
    }
  };

  const removeWatchlist = async (formData) => {
    try {
      const [res] = await Promise.all([
        apiCall('post', 'remove_watchlist', formData),
      ]);
      if (res.data.status === 'yes') {
        dispatch({
          type: RESPONSE_STATUS,
          payload: 'watchlistremoved_successfully',
        });
      } else {
        dispatch({
          type: RESPONSE_STATUS,
          payload: res.data.message,
        });
      }
    } catch (err) {
      dispatch({
        type: RESPONSE_STATUS,
        payload: 'Something went wrong!',
      });
    }
  };

  const buyNowProduct = async (formData) => {
    try {
      const [res] = await Promise.all([
        apiCall('post', 'mobileConfirmBuynow', formData),
      ]);
      if (res.data.status === 'yes') {
        dispatch({
          type: UPDATE_PROJ_DYN,
          payload: 'buynow_paid_successfull',
        });
      } else {
        dispatch({
          type: RESPONSE_STATUS,
          payload: res.data.message,
        });
      }
    } catch (err) {
      dispatch({
        type: RESPONSE_STATUS,
        payload: 'Something went wrong!',
      });
    }
  };
  const getla_sellers = async () => {
    const [res] = await Promise.all([apiCall('get', 'getlasellers')]);
    dispatch({
      type: GET_LA_SELLERS,
      payload: res.data,
    });
  };
  const clearResponse = () =>
    dispatch({
      type: CLEAR_RESPONSE,
    });

  const productViewed = async (project_id,user_id) =>{
    let data = {project_id,user_id};
    await apiCall('post','insertProductViewed',data);
  }
  const getProduct_viewed_all = async (data) => {
    const [res] = await Promise.all([apiCall('post','get_seller_products_viewed',data)]);
    dispatch({
      type: GET_ALL_PRODUCT_VIEWED,
      payload: res.data,
    });
  }
  return (
    <ProductContext.Provider
      value={{
        // buyer_allproducts: state.buyer_allproducts,
        la_sellers: state.la_sellers,
        search_allproducts: state.search_allproducts,
        search_allsimilarproducts: state.search_allsimilarproducts,
        search_allauctions: state.search_allauctions,
        edit_product: state.edit_product,
        all_active_users: state.all_active_users,
        edit_auction: state.edit_auction,
        invoice_details: state.invoice_details,
        group_invoice_details: state.group_invoice_details,
        auction_lots: state.auction_lots,
        buyer_activeproducts: state.buyer_activeproducts,
        buyer_wonproducts: state.buyer_wonproducts,
        buyer_lostproducts: state.buyer_lostproducts,
        seller_allproducts: state.seller_allproducts,
        seller_allauctions: state.seller_allauctions,
        responseStatus: state.responseStatus,
        shipping_rates: state.shipping_rates,
        allProductsViewed : state.allProductsViewed,
        clearResponse,
        getAllSellerProducts,
        getAllSellerAuctions,
        getAllSearchProducts,
        getAllSearchAuctions,
        getAllSimilarProducts,
        getActiveBidProducts,
        getWonBidProducts,
        getShippingRates,
        getLostBidProducts,
        getEditProduct,
        getActiveUsers,
        editProduct,
        postProduct,
        getEditAuction,
        postAuction,
        updateProjectDynamic,
        updateBuyNowDynamic,
        postShipping,
        postLocalPickup,
        buyNowProduct,
        getNewAuction,
        getInvoice,
        getInvoiceNew,
        addWatchlist,
        removeWatchlist,
        getla_sellers,
        productViewed,
        getProduct_viewed_all
      }}
    >
      {props.children}
    </ProductContext.Provider>
  );
};

export default ProductState;
