import React, { useState, useContext } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { Link } from 'react-router-dom';
import AuthContext from '../../context/auth/authContext';
import { onChangeFunction } from '../../Common/Components';
import {connect} from 'react-redux';



const Footer = (props) => {
  const authContext = useContext(AuthContext);
  const { user } = authContext;
  const [contact, setContact] = useState({
    EMAIL: '',
  });
  const onChange = (e) => setContact(onChangeFunction(e, contact));
  return (
    <footer className="footer">
      <Grid container className="auth-wrapper">
        <Grid item xs={4} md={3}>
  <Typography variant="h6">{props.Language.abttt}</Typography>
          <div className="footer-links">
  <Link to="/about">{props.Language.sm_abt}</Link>
  <Link to="/career">{props.Language.creeer}</Link>
  <Link to="/press">{props.Language.prss}</Link>
  <Link to="/trust">{props.Language.trst_ed_s}</Link>
  <Link to="/terms">{props.Language.trems_con}</Link>
          </div>
        </Grid>
        <Grid item xs={4} md={3}>
  <Typography variant="h6">{props.Language.hlpp}</Typography>
          <div className="footer-links">
  <Link to="/faq">{props.Language.fa_q}</Link>
            <Link to="/forgotpassword">{props.Language.login}</Link>
  <Link to="/contact-us">{props.Language.conta_ss}</Link>
  <Link to="/privacy">{props.Language.pcy}</Link>
  <Link to="/cookie-policy">{props.Language.ckply}</Link>
  <a href='/sitemap.xml' target="_blank">{props.Language.stmp}</a>
          </div>
        </Grid>
        <Grid item xs={4} md={3}>
  <Typography variant="h6">{props.Language.addr_ss}</Typography>
          <address>
            {props.Language.laad} <br />
            10940 {props.Language.brblvd}
            <br />
            {props.Language.nhca} 91601
            <br />
            818-665-3863
          </address>
        </Grid>
        <Grid item xs={12} md={3} className="footImgLink">
          <div className="mt-3">
            <a
              target="_blank"
              rel="noreferrer noopener"
              href="https://westernstatesacquirers.net/"
            >
              <img className="footImg1" src="/images/wsaa-logo.png" alt="" />
            </a>
          </div>
          <div className="mt-3">
            <a
              target="_blank"
              rel="noreferrer noopener"
              href="https://www.auctioneers.org/"
            >
              <img
                className="footImg2"
                src="/images/naa-site-logo.png"
                alt=""
              />
            </a>
          </div>
        </Grid>
        <Grid item sm={12} md={2} className="footSocial">
          <Typography variant="h6" className="follow-us">
            {props.Language.fuvi}
          </Typography>
          <div className="social-links">
            <a
              target="_blank"
              rel="noreferrer noopener"
              href="https://www.pinterest.com/laauctiondepot/pins/"
            >
              <i className="fab fa-pinterest"></i>
            </a>
            <a
              target="_blank"
              rel="noreferrer noopener"
              href="https://www.instagram.com/laauctiondepot/"
            >
              <i className="fab fa-instagram"></i>
            </a>
            <a
              target="_blank"
              rel="noreferrer noopener"
              href="https://www.linkedin.com/in/nina-tang-53b0771a1/?locale=zh_CN"
            >
              <i className="fab fa-linkedin"></i>
            </a>
            <a
              target="_blank"
              rel="noreferrer noopener"
              href="https://www.facebook.com/LAAuctionDepot"
            >
              <i className="fab fa-facebook"></i>
            </a>
            <a
              target="_blank"
              rel="noreferrer noopener"
              href="https://twitter.com/depot_la"
            >
              <i className="fab fa-twitter"></i>
            </a>
          </div>
          <div id="mc_embed_signup">
            <form
              action="https://auctionsoftware.us10.list-manage.com/subscribe/post?u=0bbd784161aa5bb5dce2db283&amp;id=fcde2df492"
              method="post"
              id="mc-embedded-subscribe-form"
              name="mc-embedded-subscribe-form"
              class="validate"
              novalidate
            >
              <div id="mc_embed_signup_scroll">
  <label for="mce-EMAIL">{props.Language.sub_cribe}</label>
                <input
                  type="email"
                  value={contact.EMAIL}
                  onChange={onChange}
                  name="EMAIL"
                  class="email"
                  id="mce-EMAIL"
                  placeholder={props.Language.email_ADD}
                  required
                />
                <div id="mc_embed_signupdiv" aria-hidden="true">
                  <input
                    type="text"
                    name="b_0bbd784161aa5bb5dce2db283_fcde2df492"
                    tabindex="-1"
                    value=""
                  />
                </div>
                <div class="clear">
                  <input
                    type="submit"
                    value="Subscribe"
                    name="subscribe"
                    id="mc-embedded-subscribe"
                    class="button"
                  />
                </div>
              </div>
            </form>
          </div>
        </Grid>
      </Grid>
      <link
        href="//cdn-images.mailchimp.com/embedcode/horizontal-slim-10_7.css"
        rel="stylesheet"
        type="text/css"
      />

      {user.role === '3' ? (
        <>
          <div className="fcopy-right">
            {props.Language.pmhncap}: 818-595-1012
          </div>
        </>
      ) : null}
      <div className="fcopy-right">
        {props.Language.adr_es}.
      </div>
    </footer>
  );
};

const mapStateToProps = (state) => {
  return {
    Language : state.Language.languages
   }
 };
  

export default connect(mapStateToProps,undefined)(Footer);
