import React , {useEffect,useState} from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const DialogComp = (props) =>{
    const [open,setOpen] = useState(false);
    const handleClickOpen = () => {
        setOpen(true)
    }
    const handleClickClose = () => {
        setOpen(false)
    }
return (<>
        <Dialog
            open = {props.open}
            onClose = {handleClickClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description" 
            className="custom-dialog"
        > 
            
            <DialogContent className="custom-dialog-content"> 

               <div className="won-alert"> 
                  <div className="won-product-heading">
                    <h2>Congratulations!</h2>
                    <h6>{props.title}</h6>
                  </div>
                  <div className="trophy">
                    <img src="/images/trophy.gif" alt="" />
                  </div>
                  <div>
                  <DialogContentText id= "alert-dialog-description">
                        {props.desc}
                  </DialogContentText>
                  </div>
               </div> 
            </DialogContent>
            <DialogActions>
                <Button onClick={props.handleClickClose}>Close</Button>
            </DialogActions>
        </Dialog>

</>)
}

export default React.memo(DialogComp);