/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useContext, useEffect } from 'react';
import $ from 'jquery';
import { Redirect, Link } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import PhoneInput from 'react-phone-number-input';

import Alerts from '../Alert/alerts';
import AuthContext from '../../context/auth/authContext';
import AlertContext from '../../context/alert/alertContext';
import { onChangeFunction } from '../../Common/Components';
import { connect } from 'react-redux';

const RegisterForm = props => {
  const button = $('#formSubmitButton');
  const authContext = useContext(AuthContext);
  const alertContext = useContext(AlertContext);

  const [toHome, settoHome] = useState(false);

  const { setAlert } = alertContext;
  const { register, error, clearErrors, isAuthenticated } = authContext;
  const [user, setUser] = useState({
    email: '',
    first_name: '',
    last_name: '',
    password: '',
    confirmpassword: '',
    role: props.type === 'pro' ? 2 : 1,
    terms: false,
    phone: '',
    alternate_phone: ''
  });

  const onChange = e => {
    let {value,name} = e.target;
    let patt = new RegExp('^[0-9+]*$');
    if(!patt.test(value) && name === 'alternate_phone'){
      return
    }
    setUser(onChangeFunction(e, user))
  };

  const onChangePhone = phoneNumber => {
    $('#rph_err').html('')
    setUser({
      ...user,
      phone: phoneNumber
    })
  }

  const onChangeTerms = () => {
    $('#terms_err').html('');
  };

  useEffect(() => {
    if (error) {
      let language = localStorage.getItem('language');
      let e = ''
      if (error === 'Register successful') {
        button.attr('disabled', false);
        if(language === 'Spanish'){
          e = 'Registrase Existosa'
        }else{
          e = error
        }
        setAlert(e, 'success');
        clearErrors();
        settoHome(true);
      } else {
        button.attr('disabled', false);
        if(language === 'Spanish'){
          e = 'El Email ya existe'
        }else{
          e = error
        }
        setAlert(e, 'error');
        clearErrors();
      }
    }
  }, [error]);

  useEffect(() => {
    if (isAuthenticated) {
      settoHome(true);
    }
  }, []);

  const errorTextMsg = text => {
    return `<ul class="parsley-errors-list filled"><li class="parsley-required">${text}</li></ul>`;
  };

  const customValidation = (data,props) => {
    return new Promise(async (resolve, reject) => {
      let error = 0;
      if (!data.terms) {
        $('#terms_err').html(errorTextMsg(props.Language.atac ? props.Language.atac : 'Accept Terms and Conditions.'));
        error = 1;
      }
      if (error === 1) {
        resolve(false);
      } else {
        resolve(true);
      }
    });
  };

  const onSubmit = async e => {
    e.preventDefault();
    console.log(user)
    if (user.phone === '') {
      $('#rph_err').html(`<ul class="parsley-errors-list filled"><li class="parsley-required">${props.Language.thisir ? props.Language.thisir : 'This value is required.'}</li></ul>`)
    } else {
      if(user.phone.length <10){
        $('#rph_err').html(`<ul class="parsley-errors-list filled"><li class="parsley-required">${props.Language.invalidPhNum ? props.Language.invalidPhNum : 'Invalid Phone.'}</li></ul>`)  
        return
      }
      $('#rph_err').html('')
    }
    button.attr('disabled', true);
    let [customValid] = await Promise.all([customValidation(user, props)]);
    if (
      $('#RegisterFrom')
        .parsley()
        .validate() &&
      customValid
    ) {
      register(user);
    } else {
      button.attr('disabled', false);
      return false;
    }
  };

  return (
    <Grid className="formBox">
      {toHome ? <Redirect to="/" /> : null}

      <Grid className="lFormHead">
        <Link to="/">
          <img src="/images/auth-logo.png" alt="" />
        </Link>
      </Grid>
      <Grid className="lFormBody">
        <h4 className="main-head">
          <span className="hbox">{props.Language.register}</span>
          <span>AS {props.type === 'pro' ? 'PRO BUYER' : 'BASIC BUYER'} </span>
        </h4>
        <p className="auth-note">{props.Language.etmbene}</p>
        <form id="RegisterFrom" data-parsley-validate onSubmit={onSubmit}>
          <Grid container className="reg-inpt">
            <Grid item xs={12}>
              <Alerts />
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="first_name"
                type="first_name"
                value={user.first_name}
                onChange={onChange}
                autoComplete="first_name"
                placeholder={props.Language.first_name}
                className="la-input js-autofocus"
                margin="normal"
                variant="outlined"
                autoFocus={true}
                inputProps={{
                  'data-parsley-required': 'true',
                  'data-parsley-pattern': "^[a-zA-Z ]*$",
                  "data-parsley-error-message": (function(){
                    if(user.first_name === ''){
                      return props.Language.thisir ? props.Language.thisir : 'This value is required.' 
                    }else{
                      if(!(/^[a-zA-Z ]*$/.test(user.first_name))){
                        return props.Language.invalidInp ? props.Language.invalidInp : 'This value is required.' 
                      }else{
                        return ''
                      }
                    }
                  })()
                }}
                autofocus

              />
            </Grid>
            <Grid item xs={12} className="">
              <TextField
                name="last_name"
                type="last_name"
                value={user.last_name}
                onChange={onChange}
                autoComplete="last_name"
                placeholder={props.Language.last_name}
                className="la-input"
                margin="normal"
                autofocus="true"
                variant="outlined"
                inputProps={{
                  'data-parsley-required': 'true',
                  'data-parsley-pattern': "^[a-zA-Z ]*$",
                  "data-parsley-error-message": (function(){
                    if(user.last_name === ''){
                      
                      return props.Language.thisir ? props.Language.thisir : 'This value is required.' 
                    }else{
                      if(!(/^[a-zA-Z ]*$/.test(user.last_name))){
                        return props.Language.invalidInp ? props.Language.invalidInp : 'This value is required.' 
                      }else{
                        return ''
                      }
                    }
                  })()
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="email"
                type="email"
                value={user.email}
                onChange={onChange}
                autoComplete="email"
                placeholder={props.Language.email}
                className="la-input"
                margin="normal"
                autofocus="true"
                variant="outlined"
                inputProps={{
                  'data-parsley-required': 'true',
                  "data-parsley-error-message": (function(){
                    if(user.email === ""){
                      return props.Language.thisir ? props.Language.thisir : 'This value is required.' 
                    }else{
                      return props.Language.tivasgbave ? props.Language.tivasgbave : 'This value should be a valid email.' 
                    }
                  })()
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="password"
                id="password"
                value={user.password}
                onChange={onChange}
                placeholder={props.Language.password}
                autoComplete="new-password"
                type="password"
                className="la-input"
                margin="normal"
                variant="outlined"
                inputProps={{
                  'data-parsley-minlength': '8',
                  'data-parsley-required': 'true',
                  "data-parsley-error-message": (function(){
                    if(user.password === ""){
                      return props.Language.thisir ? props.Language.thisir : 'This value is required.' 
                    }else{
                      if(user.password.length < 8){
                        return props.Language.tvitsishcom ? props.Language.tvitsishcom : 'This value is is too short.It should have 8 characters or more.' 
                      }else{
                        return ""
                      }
                      return
                    }
                  })()
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="confirmpassword"
                value={user.confirmpassword}
                onChange={onChange}
                placeholder={props.Language.confirm_password}
                autoComplete="new-password"
                type="password"
                className="la-input"
                margin="normal"
                variant="outlined"
                inputProps={{
                  'data-parsley-required': 'true',
                  'data-parsley-minlength': '8',
                  'data-parsley-equalto': '#password',
                  "data-parsley-error-message": (function(){
                    if(user.confirmpassword === ""){
                      return props.Language.thisir ? props.Language.thisir : 'This value is required.' 
                    }else{
                      if(user.confirmpassword.length < 8){
                        return props.Language.tvitsishcom ? props.Language.tvitsishcom : 'This value is is too short.It should have 8 characters or more.' 
                      }
                      if(user.password !== user.confirmpassword){
                        return props.Language.pacpdm ? props.Language.pacpdm :'Password and confirm password did not match' 
                      }
                    }
                    return ''
                  })()
                }}
              />
            </Grid>
            <Grid item xs={12}>
            <PhoneInput
                placeholder={props.Language.mob_num ? props.Language.mob_num : 'Mobile Number'}
                className="numberplusonly la-input"
                onChange={onChangePhone}
                name="phone"
                value={user.phone}
                
              />
              <span id="rph_err"></span>
            </Grid>
            <Grid item xs={12}>
              <TextField
                placeholder={props.Language.p_number ? props.Language.p_number : 'Phone Number'}
                className="form-control numberplusonly la-input"
                onChange={onChange}
                name="alternate_phone"
                className="la-input"
                margin="normal"
                variant="outlined"
                value={user.alternate_phone}
                inputProps={{
                  "data-parsley-required": "true",
                  "data-parsley-minlength" : "10",
                  "data-parsley-error-message": (function(){
                    if(user.alternate_phone === ''){
                      return props.Language.thisir ? props.Language.thisir : 'This value is required.'
                    }else{
                      if(user.alternate_phone.length < 10){
                        return props.Language.invalidPhNum ? props.Language.invalidPhNum : 'Invalid Phone.'
                      }
                    }
                    return ""
                  })()
                }}

              />
            </Grid>
            <Grid item xs={12}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={user.terms}
                      name="terms"
                      onChange={(event, value) => {
                        onChange(event);
                        onChangeTerms();
                      }}
                      value="true"
                      color="default"
                    // inputProps={{
                    //   'data-parsley-required': 'true'
                    // }}
                    />
                  }
                  // label="I accept the Terms&Conditions, Privacy Policy and Cookie Policy."
                  label={
                    <label>
                      {props.Language.icct}
                      <a href="/terms" target="_blank">
                        {' '}
                        {props.Language.trems_con}
                      </a>
                      ,{' '}
                      <a href="/privacy" target="_blank">
                        {props.Language.privacy}
                      </a>{' '}
                      and{' '}
                      <a href="/cookie-policy" target="_blank">
                        {props.Language.ookiepolicy}
                      </a>
                      .
                    </label>
                  }
                />
                <span id="terms_err"></span>
              </FormGroup>
            </Grid>

            <Grid item xs={12}>
              <button
                id="formSubmitButton"
                type="submit"
                className="btn btn-green"
              >
                {props.Language.register}
              </button>
            </Grid>
          </Grid>
        </form>

        <div className="mb-4 text-center show-xs">
          {props.Language.hnac} ? <a href="/login">{props.Language.login}</a>
        </div>

        <Grid item xs={12}>
          <p className="copy-right">
            {props.Language.cpy_rght}
          </p>
        </Grid>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state) => {
  return {
    Language: state.Language.languages
  }
};


export default connect(mapStateToProps, undefined)(RegisterForm);
