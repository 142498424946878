import React,{useEffect,useState} from 'react';
import { BrowserRouter } from 'react-router-dom';
import Routes from './Routes';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import './App.css';
// eslint-disable-next-line no-unused-vars
import parsleyjs from 'parsleyjs';
// eslint-disable-next-line no-unused-vars
import parsleycustom from './Common/ParsleyCustom';
import AuthState from './context/auth/authState';
import PayState from './context/payment/payState';
import UserState from './context/user/userState';
import AlertState from './context/alert/alertState';
import ProductState from './context/product/productState';
import BuyerState from './context/buyer/buyerState';
import setAuthToken from './Common/ApiHeader';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { SnackbarProvider } from 'notistack';
import ScrollToTop from './Routes/ScrollToTop';
import { CookiesProvider } from 'react-cookie';
import Intercom from 'react-intercom';
import {getLanguage} from './Actions/Language';


import {
  Categories,
  SubCategories,
  CaliforniaCity,
  Terms,
  Details,
  Conditions,
  ProBuyerTax,
  BuyerTax,
} from './Common/Categories';
import { Language } from '@material-ui/icons';
//global.googleapi_key = 'AIzaSyC9aXAz6rqDMS6VXpReTX98B72zMYmaMDE';
global.googleapi_key = 'AIzaSyDgv0jL6CUSHaxg4I8ZoD-ADuQqT6UGsP0';

const scriptApps = document.createElement('script');
scriptApps.src = 'https://apps.elfsight.com/p/platform.js';
scriptApps.defer = true;
document.body.appendChild(scriptApps);

//global.site_url = 'https://auctionsoftware.net';
 global.site_url = 'https://forwardapidev.auctionsoftware.com';
// global.site_url = 'http://localhost:8080';
global.newsapi_key = 'cc4f04f137dc462692115e5931383dda';
// global.site_url = '';
global.image_url = `${global.site_url}/uploads/product/`;
global.profile_image_url = `${global.site_url}/uploads/`;
// global.image_url = `https://forwardapi.s3-us-west-2.amazonaws.com/uploads/product/`;
// global.profile_image_url = `https://forwardapi.s3-us-west-2.amazonaws.com/uploads/`;
global.categories = Categories();
global.products_terms = Terms().data;
global.products_detail = Details().data;

global.subcategories = SubCategories();
global.californiacity = CaliforniaCity();
global.conditions = Conditions();
global.proBuyerTax = ProBuyerTax();
global.buyerTax = BuyerTax();
global.admin_bid_increment = [];
if (localStorage.token) {
  setAuthToken(localStorage.token);
}
// sdpkw82c - TEST Intercom
const App = (props)=> {

  useEffect(()=>{
props.Language();
  },[])

  return (
    <CookiesProvider>
      <AuthState>
        <AlertState>
          <UserState>
            <ProductState>
              <PayState>
                <BuyerState>
                  <MuiPickersUtilsProvider utils={MomentUtils}>
                    <SnackbarProvider maxSnack={3}>
                      <BrowserRouter>
                        <ScrollToTop />
                        <div className="main-wrapper">
                          <Routes />
                          <Intercom appID="diav9wn9" />
                        </div>
                      </BrowserRouter>
                    </SnackbarProvider>
                  </MuiPickersUtilsProvider>
                </BuyerState>
              </PayState>
            </ProductState>
          </UserState>
        </AlertState>
      </AuthState>
    </CookiesProvider>
  );
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      Language: getLanguage,
    },
    dispatch
  );


export default connect(undefined,mapDispatchToProps)(App);

// import React,{useEffect,useState} from 'react';
// import { BrowserRouter } from 'react-router-dom';
// import Routes from './Routes';
// import { connect } from 'react-redux';

// import './App.css';
// // eslint-disable-next-line no-unused-vars
// import parsleyjs from 'parsleyjs';
// // eslint-disable-next-line no-unused-vars
// import parsleycustom from './Common/ParsleyCustom';
// import AuthState from './context/auth/authState';
// import PayState from './context/payment/payState';
// import UserState from './context/user/userState';
// import AlertState from './context/alert/alertState';
// import ProductState from './context/product/productState';
// import BuyerState from './context/buyer/buyerState';
// import setAuthToken from './Common/ApiHeader';
// import { MuiPickersUtilsProvider } from '@material-ui/pickers';
// import MomentUtils from '@date-io/moment';
// import { SnackbarProvider } from 'notistack';
// import ScrollToTop from './Routes/ScrollToTop';
// import { CookiesProvider } from 'react-cookie';
// import Intercom from 'react-intercom';
// import {getLanguage} from './Actions/Language';
// import {
//   Categories,
//   SubCategories,
//   CaliforniaCity,
//   Terms,
//   Details,
//   Conditions,
//   ProBuyerTax,
//   BuyerTax,
// } from './Common/Categories';
// //global.googleapi_key = 'AIzaSyC9aXAz6rqDMS6VXpReTX98B72zMYmaMDE';
// global.googleapi_key = 'AIzaSyDgv0jL6CUSHaxg4I8ZoD-ADuQqT6UGsP0';

// const scriptApps = document.createElement('script');
// scriptApps.src = 'https://apps.elfsight.com/p/platform.js';
// scriptApps.defer = true;
// document.body.appendChild(scriptApps);

// // global.site_url = 'https://forwardapidev.auctionsoftware.com';
// global.site_url = 'https://forwardapidev.auctionsoftware.com';
// // global.site_url = 'http://localhost:8080';
// global.newsapi_key = 'cc4f04f137dc462692115e5931383dda';
// // global.site_url = '';
// global.image_url = `${global.site_url}/uploads/product/`;
// global.profile_image_url = `${global.site_url}/uploads/`;
// // global.image_url = `https://forwardapi.s3-us-west-2.amazonaws.com/uploads/product/`;
// // global.profile_image_url = `https://forwardapi.s3-us-west-2.amazonaws.com/uploads/`;
// global.categories = Categories();
// global.products_terms = Terms().data;
// global.products_detail = Details().data;

// global.subcategories = SubCategories();
// global.californiacity = CaliforniaCity();
// global.conditions = Conditions();
// global.proBuyerTax = ProBuyerTax();
// global.buyerTax = BuyerTax();
// global.admin_bid_increment = [];
// if (localStorage.token) {
//   setAuthToken(localStorage.token);
// }
// // sdpkw82c - TEST Intercom
// function App() {
//   return (
//     <CookiesProvider>
//       <AuthState>
//         <AlertState>
//           <UserState>
//             <ProductState>
//               <PayState>
//                 <BuyerState>
//                   <MuiPickersUtilsProvider utils={MomentUtils}>
//                     <SnackbarProvider maxSnack={3}>
//                       <BrowserRouter>
//                         <ScrollToTop />
//                         <div className="main-wrapper">
//                           <Routes />
//                           <Intercom appID="diav9wn9" />
//                         </div>
//                       </BrowserRouter>
//                     </SnackbarProvider>
//                   </MuiPickersUtilsProvider>
//                 </BuyerState>
//               </PayState>
//             </ProductState>
//           </UserState>
//         </AlertState>
//       </AuthState>
//     </CookiesProvider>
//   );
// }

// export default App;
