/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState, useEffect } from 'react';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import { Link } from 'react-router-dom';
import { dateFormatFront } from '../../Common/Components';
import { Redirect } from 'react-router-dom';
import ProductContext from '../../context/product/productContext';
import { Pagination } from '@material-ui/lab';
import Timer from '../../Common/Timer';
import Loading from '../../Components/Loading';
import apiCall from '../../Common/ApiCall';
import {connect} from 'react-redux';

const AllBids = (props) => {
  const productContext = useContext(ProductContext);
  const { seller_allproducts, getAllSellerProducts } = productContext;
  const [loading, setLoading] = useState(true);

  const [toInvoice, settoInvoice] = useState(false);
  const [toInvoiceID, settoInvoiceID] = useState({
    inv_id: 0,
    user_id: 0,
  });

  const [search, setSearch] = useState({
    status: props.page,
    limit: 10,
    page: 1,
  });
  useEffect(() => {
    setLoading(false);
  }, [seller_allproducts]);
  const onHandlePage = (event, value) => {
    setSearch({ ...search, page: value });
  };

  const onClickUnPaidInvoice = async (inv_id, user_id) => {
    if (inv_id) {
      settoInvoiceID({
        inv_id: inv_id,
        user_id: user_id,
      });
      let invoiceData = {
        cinvoice: inv_id,
        user_id: user_id,
      };
      const [res] = await Promise.all([
        apiCall('post', 'unpaidadminivoicealgo', invoiceData, ''),
      ]);
      if (res.data.msg === 'fail') {
      } else {
        settoInvoice(true);
      }
    }
  };

  useEffect(() => {
    setLoading(true);
    getAllSellerProducts(search);
  }, [search]);

  return (
    <Container>
      {toInvoice ? (
        <Redirect to={`invoice/${toInvoiceID.inv_id}/${toInvoiceID.user_id}`} />
      ) : null}
      <Loading loading={loading} />
      {seller_allproducts.result.length ? (
        <>
          {seller_allproducts.result.map((item, index) => {
            return (
              <Box className="db-pro-box" key={index}>
                <div className="row">
                  <div className="col-sm-4">
                    <div className="media">
                      <div className="media-left">
                        <img
                          className="pro-img"
                          src={`${global.image_url}${item.avatar}`}
                          alt={item.title}
                        />
                      </div>
                      <div className="media-body">
                        <div className="pro-title">
                          {item.title}
                          {item.auctionlottitle
                            ? ' (' + item.auctionlottitle + ')'
                            : null}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-8">
                    <div className="row">
                      <div className="col-sm-6 col-lg-3">
                        <div className="slv-timer">
                          {item.market_status === 'open' ? (
                            <>
                              <Timer
                                date_added={item.date_added}
                                date_closed={item.date_closed}
                                withText={1}
                              ></Timer>
                            </>
                          ) : (
                            <>
                              {props.Language.date_closed ? props.Language.date_closed : "Date Closed"} :{dateFormatFront(item.date_closed)}
                            </>
                          )}
                        </div>
                      </div>
                      <div className="col-sm-6 col-lg-3">
                        {item.market_status === 'sold' ? (
                          <>
                            <div>
                            {props.Language.ham_prce ? props.Language.ham_prce : 'Hammer Price'} :{' '}
                              <b>
                                $
                                {parseInt(item.content_head1) > 0
                                  ? parseFloat(
                                      parseInt(item.buynowamount) *
                                        parseInt(item.qty)
                                    ).toFixed(2)
                                  : item.buynowamount}
                              </b>
                              {item.buynowpaid === 1 ? props.Language.paid ? " (" +props.Language.paid+ ")" : ' (Paid)' : props.Language.unpaid ? props.Language.unpaid : ' (Unpaid)'}
                            </div>
                          </>
                        ) : (
                          <>
                            {item.auction === 1 ? (
                              <>
                                <div>
                                  {props.Language.start_prc ? props.Language.start_prc : 'Start Price'} : <b>{item.sprice}</b>
                                </div>
                              </>
                            ) : item.buynow === 1 ? (
                              <>
                                <div>
                                {props.Language.bnow_prc ? props.Language.bnow_prc : 'Buynow Price'} : <b>{item.bprice}</b>
                                </div>
                              </>
                            ) : null}
                          </>
                        )}

                        {item.buynow !== 0 ? (
                          <>
                            <div>
                            {props.Language.quantttt?props.Language.quantttt:"Quantity"} :<b>{item.qty}</b>
                            </div>
                          </>
                        ) : null}
                        <div>
                        {props.Language.no_od_bds ? props.Language.no_od_bds : "No of Bids"} : <b>{item.bidcount}</b>
                        </div>
                      </div>
                      <div className="col-sm-6 col-lg-4">
                        <div>
                        {props.Language.loc ? props.Language.loc : "Location"} : <b>{item.location}</b>
                        </div>

                        <div>
                        {props.Language.statuss ? props.Language.statuss : "Status"} :{' '}
                          <b style={{ textTransform: 'capitalize' }}>
                            {item.market_status}
                          </b>
                        </div>
                      </div>
                      <div className="col-sm-6 col-lg-2">
                        <Link to={`edit-project/${item.id}`}>
                          <label className="label cursorPointer label-success mb-2">
                            {item.market_status === 'open' &&
                            parseInt(item.bidcount) <= 0
                              ? props.Language.editt ? props.Language.editt : 'EDIT'
                              : props.Language.vw_cap ? props.Language.vw_cap : 'VIEW'}
                          </label>
                        </Link>
                        {item.market_status === 'sold' ? (
                          <>
                            {item.buynowpaid === 1 ? (
                              <>
                                <Link
                                  to={`invoice/${item.common_invoice}/${item.buynowuser_id}`}
                                >
                                  <label className="label cursorPointer label-success mb-2">
                                  {props.Language.inv_cap ? props.Language.inv_cap : 'INVOICE'}
                                  </label>
                                </Link>
                              </>
                            ) : (
                              <>
                                <label
                                  onClick={() =>
                                    onClickUnPaidInvoice(
                                      item.common_invoice,
                                      item.buynowuser_id
                                    )
                                  }
                                  className="label cursorPointer label-success mb-2"
                                >
                                  {props.Language.inv_cap ? props.Language.inv_cap : 'INVOICE'}
                                </label>
                              </>
                            )}
                          </>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              </Box>
            );
          })}

          <div className="d-flex mt-3">
            <div className="ml-auto mt-2">
          {props.Language.owningsh ? props.Language.owningsh : "Showing"} {search.limit * (search.page - 1) + 1} -{' '}
              {search.limit * search.page > seller_allproducts.length
                ? seller_allproducts.length
                : search.limit * search.page}{' '}
              of {seller_allproducts.length}
            </div>
            <Pagination
              count={Math.ceil(seller_allproducts.length / search.limit)}
              page={search.page}
              onChange={onHandlePage}
            />
          </div>
        </>
      ) : (
        <>
          <Box className="db-pro-box">
            <div className="row">
              <div className="col-sm-12 text-center"> {props.Language.noprod ? props.Language.noprod : 'No Products'} </div>
            </div>
          </Box>
        </>
      )}
    </Container>
  );
};
const mapStateToProps = (state) => {
  return {
    Language : state.Language.languages
   }
 };
export default connect(mapStateToProps)(AllBids);
