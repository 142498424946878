import React, { useState, useRef } from 'react';

function Accordion(props) {
  const [setActive, setActiveState] = useState('');
  const [icon, setIcon] = useState('fa fa fa-plus');

  const content = useRef(null);

  function toggleAccordion() {
    setActiveState(setActive === '' ? 'active' : '');
    setIcon(setActive === 'active' ? 'fa fa-plus' : 'fa fa-minus');
  }

  return (
    <div className="accordion">
      <div className="filter-head">
        <span
          className={`d-flex justify-content-between accordion ${setActive}`}
          onClick={toggleAccordion}
        >
          <h4>{props.title}</h4>
          <i className={icon}></i>
        </span>
      </div>
      <div
        ref={content}
        className={`accordion__content collapse ${
          setActive === 'active' ? 'show' : ''
        }`}
      >
        {props.children}
      </div>
    </div>
  );
}

export default Accordion;
