export const Categories = () => {
  return [
    { id: '1', name: 'Coins, Currency & Precious Metals' },
    { id: '2', name: 'Commercial & Industrial' },
    { id: '3', name: 'Heavy Equipment & Construction' },
    { id: '4', name: 'Household & Estate' },
    { id: '5', name: 'Real Estate' },
    { id: '6', name: 'Sporting Goods & Hobbies' },
    { id: '7', name: 'Technology' },
    { id: '8', name: 'Vehicles & Marine' },
    { id: '9', name: 'Unassigned' },
  ];
};
export const Details = () => {
  return {
    data: `
        <div class="row">
            <div class="col-md-4">
                <p>
                  <b>Inspection:</b>
                </p>
                <p>Wed, Apr 29, 2020</p>
                <p>Pro-buyers NOON to 6:00 pm</p>
                <p>Buyers 01:00 pm - 05:00 pm</p>
            </div>
            <div class="col-md-4">
                <p>
                  <b>Auction Dates and Time :</b>
                </p>
                <p>Starts:Thu, Apr 30, 2020</p>
                <p>08:00 pm</p>
            </div>
            <div class="col-md-4">
                <p>
                  <b>Removal:</b>
                </p>
                <p>Sat, May 02, 2020</p>
                <p>
                  <b>Pro-buyers</b>
                </p>
                <p>11:00 am to 6:00 pm </p>
                <p>
                  <b>Buyers</b>
                </p>
                <p>1:00 pm - 5:00 pm</p>
            </div>
        </div>`,
  };
};

export const Terms = () => {
  return {
    data: ` <p>Hammer Price  Buyer's Premium is 15%   and   Pro Buyer Premium is 12%</p>
            <p>This auction is contracted and managed by: LA Auction Depot LLC
            </p>
            <p>
            Manager: Rob Adlesh (rob@laauctiondepot.com )
            </p>
            <p>
            This auction is contracted and managed by <b>LA  Auction Depot .  Phone 818-665-3863.</b>
            </p>
            <p>
            Applicable <b>Sales Tax</b> will be collected.
            </p>
            <p>
            Affiliate accepts Visa, MasterCard, Discover, American Express, and Cash.  <b>NO CHECKS</b>.  Payments will not be accepted after designated pick up time. 
            </p>
            <p>
            <b>Pick up date and time is not flexible</b>.  If you are unable to have the item picked up during the scheduled removal date and time, please do not bid.  If you choose to pay in advance of the scheduled pick up/removal time and fail to have the item picked up during the scheduled time, you forfeit both the monies paid and the item.
            </p>
            <p>
            <b>Assistance with loading is not available.</b>  Bring the necessary equipment, vehicle, and manpower needed to load the item.
            </p>
            <p>
            <b>Everything is sold "as is, where is"</b> with no guarantees or warranties unless noted.  You are responsible for inspecting items prior to purchase.  <b>All sales final.  No Refunds.</b>
            At the close of the auction active items will remain open until no bids have been received for three minutes.
            </p>
            <p>
            <b>
            By placing a bid on this auction, you are agreeing to the auction specific terms listed in addition to LAAD user terms.
            </b>
            </p>
            <p>
            <b>Important Note:</b>  If the high bidder of an item does NOT acknowledge the purchase, LA Auction Depot reserves the right to determine how and to whom it will be resold.  If we determine the winning bid was not  bona-fide bidder, the item WILL NOT AUTOMATICALLY go to the next second-highest bidder.
            </p>
            <hr />
            <b>
            <p> 
            This auction is contracted and managed by LA Auction Depot LLC . By placing a bid on this auction, you are agreeing to the auction specific terms listed on this page in additions to the LAAD user terms and agreements.  
            </p>
            <p>
            LA Auction Depot LLC....( LAAD) is responsible for maintaining the LAAD website.  LAAuctiondepot.com serves as the venue used by independent auction companies (Affiliates) to present their online auctions to bidders. 
            </p>
            <p> 
            By placing a bid on LAAuctiondepot.com, bidders are verifying they are at least 18 years old and acknowledging they understand and will comply with both the LAAuctiondepot.com  website terms and the auction specific terms. Bidders MUST NOT share their password or account information with anyone. Bidders are responsible for bids placed on their account.
            </p>
            <p>
            Inspection dates/times are available for every auction. Bidders are strongly encouraged to inspect prior to placing bids. Removal day is not inspection day. When an auction ends, and the winning bidder invoice appears on the bidder’s dashboard, the bidder is obligated to honor their bid(s).
            Every auction item is sold “as is, where is” with no guarantees or warranties. You are responsible for inspecting items prior to purchase.
            </p>
            <p>
            Bidders are responsible for knowing and complying with auction terms. Failure to comply with removal terms may result in bidding privileges being disabled.  Once a bid is placed it cannot be retracted.
            Sellers and their agents are prohibited from bidding on their own assets.
            Items with a reserve will not be sold unless the reserve price has been met.
            At the close of the auction, active items will remain open until no bids have been received for three minutes.
            </p>
            <p>
            Applicable sales tax will be collected by LA Auction Depot. 
            </p>
            <p> 
            In the event that bidding rights are terminated due to no-show invoices, bidders MAY be reinstated at the discretion of LAAD only after a penalty fee of 20% of the unpaid invoice(s) and a reinstatement fee of $25.00 has been paid to LAAD and a scanned copy of your driver's license emailed to us. INVOICED ITEMS WILL NOT BE AWARDED. The payment is punitive and meant to deter no-shows. Duplicate bidder accounts will be deactivated when they are determined to exist. If possible and at our discretion, LAAD reserves the right to delete any bid we believe has been placed by a suspect bidder. Any registration that shows up on our fraud detection system may be placed on hold and bids removed until the user is verified. LA Auction Depot LLC, reserves the right to disallow anybody from bidding on its website. Cases of obvious bidding with no intent to redeem will be referred to appropriate legal authorities.
            LAAD may lower a lot reserve at any time before or after an auction closes.  If the reserve is lowered while the auction is in progress, the applicable reserve message will appear below the Next Required Bid information (Reserve Not Met or Reserve Has Been Met); the asset will sell to the high bidder if the lowered reserve amount is met. If the reserve is lowered after the auction closes, the highest bidder at auction close will receive a winning bidder invoice. 
            </p>
            <p>
            Once an auction begins its closing process, outbid notifications will not be sent.
            </p>
            <p>
            Once a bid is placed it cannot be retracted.
            </p> 
  </b>`,
  };
};

// { id: '1', name: 'Coins, Currency & Precious Metals' },
// { id: '2', name: 'Commercial & Industrial' },
// { id: '3', name: 'Heavy Equipment & Construction' },
// { id: '4', name: 'Household & Estate' },
// { id: '5', name: 'Real Estate' },
// { id: '6', name: 'Sporting Goods & Hobbies' },
// { id: '7', name: 'Technology' },
// { id: '8', name: 'Vehicles & Marine' },
// { id: '9', name: 'Major Estate Buyer' },
// { id: '10', name: 'Eviction of Abandon Property' },
// { id: '11', name: 'Store Closeouts' },
// { id: '12', name: 'Bankruptcy' },
// { id: '13', name: 'Liquidations' },
// { id: '14', name: 'Unassigned' },

export const SubCategories = () => {
  return [
    { id: '1', master_id: '1', name: 'Coins' },
    { id: '2', master_id: '1', name: 'Currency' },
    { id: '3', master_id: '1', name: 'Precious Metals/Other' },
    { id: '4', master_id: '2', name: 'Automotive/Mechanical Equipment' },
    { id: '5', master_id: '2', name: 'Commercial Audio/Visual Equipment' },
    { id: '6', master_id: '2', name: 'Commercial Salon/Spa' },
    { id: '7', master_id: '2', name: 'Commercial Supplies' },
    { id: '8', master_id: '2', name: 'Concrete Equipment' },
    { id: '9', master_id: '2', name: 'Electrical Equipment' },
    { id: '10', master_id: '2', name: 'Fire/Rescue/Safety Equipment' },
    { id: '11', master_id: '2', name: 'Food Production/Processing' },
    { id: '12', master_id: '2', name: 'HVAC Parts/Equipment' },
    { id: '13', master_id: '2', name: 'Janitorial Equipment' },
    { id: '14', master_id: '2', name: 'Material Handling/Storage' },
    { id: '15', master_id: '2', name: 'Medical Equipment' },
    { id: '16', master_id: '2', name: 'Metal Fabrication' },
    { id: '17', master_id: '2', name: 'Office Equipment' },
    { id: '18', master_id: '2', name: 'Office Supplies' },
    { id: '19', master_id: '2', name: 'Party/Banquet Supplies' },
    { id: '20', master_id: '2', name: 'Plumbing Equipment' },
    { id: '21', master_id: '2', name: 'Printing Equipment' },
    { id: '22', master_id: '2', name: 'Restaurant Equipment' },
    { id: '23', master_id: '2', name: 'Retail/Store Fixtures' },
    { id: '24', master_id: '2', name: 'Signage/Advertising' },
    { id: '25', master_id: '2', name: 'Storage/Buildings' },
    { id: '26', master_id: '2', name: 'Woodworking Equipment' },
    { id: '27', master_id: '3', name: 'Construction Equipment' },
    { id: '28', master_id: '3', name: 'Construction Material/Supplies' },
    { id: '29', master_id: '3', name: 'Heavy Equipment' },
    {
      id: '30',
      master_id: '3',
      name: 'Home Building Supplies/Material/Lumber',
    },
    { id: '31', master_id: '3', name: 'Landscaping Equipment' },
    { id: '32', master_id: '4', name: 'Antiques/Vintage' },
    { id: '33', master_id: '4', name: 'Artwork' },
    { id: '34', master_id: '4', name: 'Books/Magazines' },
    { id: '35', master_id: '4', name: 'Clothing, Shoes & Accessories' },
    { id: '36', master_id: '4', name: 'Collectibles' },
    { id: '37', master_id: '4', name: 'Electronics' },
    { id: '38', master_id: '4', name: 'Food Items' },
    { id: '39', master_id: '4', name: 'Furniture/Bathroom' },
    { id: '40', master_id: '4', name: 'Furniture/Bedroom' },
    { id: '41', master_id: '4', name: 'Furniture/Dining' },
    { id: '42', master_id: '4', name: 'Furniture/Kitchen' },
    { id: '43', master_id: '4', name: 'Furniture/Living Room' },
    { id: '44', master_id: '4', name: 'Furniture/Vintage & Antique' },
    { id: '45', master_id: '4', name: 'Games/Toys' },
    { id: '46', master_id: '4', name: 'Garage Items' },
    { id: '47', master_id: '4', name: 'Hand Tools/Hardware' },
    { id: '48', master_id: '4', name: 'Holiday/Seasonal' },
    { id: '49', master_id: '4', name: 'Home Decor' },
    { id: '50', master_id: '4', name: 'Home Office' },
    { id: '51', master_id: '4', name: 'Jewelry' },
    { id: '52', master_id: '4', name: 'Lamps/Lighting' },
    { id: '53', master_id: '4', name: 'Lawn Care' },
    { id: '54', master_id: '4', name: 'Major Appliances' },
    { id: '55', master_id: '4', name: 'Outdoor/Pati' },
    { id: '56', master_id: '4', name: 'Pet Supplies' },
    { id: '57', master_id: '4', name: 'Power Tools/Shop Equipment' },
    { id: '58', master_id: '4', name: 'Small Housewares' },
    { id: '59', master_id: '5', name: 'Commercial Property' },
    { id: '60', master_id: '5', name: 'Land/Lots' },
    { id: '61', master_id: '5', name: 'Residential Property' },
    { id: '62', master_id: '5', name: 'Vacation Property' },
    { id: '63', master_id: '5', name: 'Vacation Rentals' },
    { id: '64', master_id: '6', name: 'Bicycles/Cycling & Accessories' },
    { id: '65', master_id: '6', name: 'Camera Equipment' },
    { id: '66', master_id: '6', name: 'Exercise/Fitness' },
    { id: '67', master_id: '6', name: 'Firearms/Amm' },
    { id: '68', master_id: '6', name: 'Hobbies/Music' },
    { id: '69', master_id: '6', name: 'Hunting/Fishing/Camping' },
    { id: '70', master_id: '6', name: 'Memorabilia' },
    { id: '71', master_id: '6', name: 'Military Artifacts' },
    { id: '72', master_id: '6', name: 'Sewing Equipment/Supplies' },
    { id: '73', master_id: '6', name: 'Sporting Goods' },
    { id: '74', master_id: '7', name: 'Electronics' },
    { id: '75', master_id: '8', name: 'ATV/Dirtbikes/Snowmobiles' },
    { id: '76', master_id: '8', name: 'Automotive Accessories & Parts' },
    { id: '77', master_id: '8', name: 'Aviation' },
    { id: '78', master_id: '8', name: 'Boats/Boat Trailers' },
    { id: '79', master_id: '8', name: 'Cars/Trucks/Motorcycles' },
    { id: '80', master_id: '8', name: 'Collector Vehicles' },
    { id: '81', master_id: '8', name: 'Commercial Vehicles' },
    { id: '82', master_id: '8', name: 'Marine Accessories & Parts' },
    { id: '83', master_id: '8', name: 'Recreational Accessories & Parts' },
    { id: '84', master_id: '8', name: 'Recreational Marine' },
    { id: '85', master_id: '8', name: 'RV/Campers' },
    { id: '86', master_id: '8', name: 'Trailers' },
  ];
};

export const Conditions = () => {
  return [
    {
      id: '1',
      name: 'New',
      content:
        'Never been used. Fully functional. Comes in original retail packaging, which may show wear and/or contain stickers',
    },
    {
      id: '2',
      name: 'Like New',
      content:
        'Fully functional. Will show signs of light use. May come in a bag or brown box and may contain stickers. May be missing manuals.',
    },
    {
      id: '3',
      name: 'Uninspected Returns',
      content:
        'Condition is unknown. May be working or may be non functional. May not come in original packaging. Sets may not be complete.',
    },
    {
      id: '4',
      name: 'Scratch & Dent',
      content:
        'Significant cosmetic and/or functional defects. May be missing manuals, batteries or accessories. Often not in original retail packaging.',
    },
    {
      id: '5',
      name: 'Salvage',
      content:
        'Not functional and usually can only be used for parts. Missing manuals, batteries, chargers and other accessories ',
    },
    {
      id: '6',
      name: 'Used',
      content:
        'Used. Fully functional. Comes in original retail packaging, which may show wear and/or contain stickers',
    },
  ];
};

export const ProBuyerTax = () => {
  return 12;
};

export const BuyerTax = () => {
  return 15;
};

export const CaliforniaCity = () => {
  return [
    { city: 'Alameda', county: 'Alameda' },
    { city: 'Albany', county: 'Alameda' },
    { city: 'Berkeley', county: 'Alameda' },
    { city: 'Dublin', county: 'Alameda' },
    { city: 'Emeryville', county: 'Alameda' },
    { city: 'Fremont', county: 'Alameda' },
    { city: 'Hayward', county: 'Alameda' },
    { city: 'Livermore', county: 'Alameda' },
    { city: 'Newark', county: 'Alameda' },
    { city: 'Oakland', county: 'Alameda' },
    { city: 'Piedmont', county: 'Alameda' },
    { city: 'Pleasanton', county: 'Alameda' },
    { city: 'San Leandro', county: 'Alameda' },
    { city: 'Union City', county: 'Alameda' },
    { city: 'Amador City', county: 'Amador' },
    { city: 'Ione', county: 'Amador' },
    { city: 'Jackson', county: 'Amador' },
    { city: 'Plymouth', county: 'Amador' },
    { city: 'Sutter Creek', county: 'Amador' },
    { city: 'Biggs', county: 'Butte' },
    { city: 'Chico', county: 'Butte' },
    { city: 'Gridley', county: 'Butte' },
    { city: 'Oroville', county: 'Butte' },
    { city: 'Paradise', county: 'Butte' },
    { city: 'Angels Camp', county: 'Calaveras' },
    { city: 'Colusa', county: 'Colusa' },
    { city: 'Williams', county: 'Colusa' },
    { city: 'Antioch', county: 'Contra Costa' },
    { city: 'Brentwood', county: 'Contra Costa' },
    { city: 'Clayton', county: 'Contra Costa' },
    { city: 'Concord', county: 'Contra Costa' },
    { city: 'Danville', county: 'Contra Costa' },
    { city: 'El Cerrito', county: 'Contra Costa' },
    { city: 'Hercules', county: 'Contra Costa' },
    { city: 'Lafayette', county: 'Contra Costa' },
    { city: 'Martinez', county: 'Contra Costa' },
    { city: 'Moraga', county: 'Contra Costa' },
    { city: 'Oakley', county: 'Contra Costa' },
    { city: 'Orinda', county: 'Contra Costa' },
    { city: 'Pinole', county: 'Contra Costa' },
    { city: 'Pittsburg', county: 'Contra Costa' },
    { city: 'Pleasant Hill', county: 'Contra Costa' },
    { city: 'Richmond', county: 'Contra Costa' },
    { city: 'San Pablo', county: 'Contra Costa' },
    { city: 'San Ramon', county: 'Contra Costa' },
    { city: 'Walnut Creek', county: 'Contra Costa' },
    { city: 'Crescent City', county: 'Del Norte' },
    { city: 'Placerville', county: 'El Dorado' },
    { city: 'South Lake Tahoe', county: 'El Dorado' },
    { city: 'Clovis', county: 'Fresno' },
    { city: 'Coalinga', county: 'Fresno' },
    { city: 'Firebaugh', county: 'Fresno' },
    { city: 'Fowler', county: 'Fresno' },
    { city: 'Fresno', county: 'Fresno' },
    { city: 'Huron', county: 'Fresno' },
    { city: 'Kerman', county: 'Fresno' },
    { city: 'Kingsburg', county: 'Fresno' },
    { city: 'Mendota', county: 'Fresno' },
    { city: 'Orange Cove', county: 'Fresno' },
    { city: 'Parlier', county: 'Fresno' },
    { city: 'Reedley', county: 'Fresno' },
    { city: 'San Joaquin', county: 'Fresno' },
    { city: 'Sanger', county: 'Fresno' },
    { city: 'Selma', county: 'Fresno' },
    { city: 'Orland', county: 'Glenn' },
    { city: 'Willows', county: 'Glenn' },
    { city: 'Arcata', county: 'Humboldt' },
    { city: 'Blue Lake', county: 'Humboldt' },
    { city: 'Eureka', county: 'Humboldt' },
    { city: 'Ferndale', county: 'Humboldt' },
    { city: 'Fortuna', county: 'Humboldt' },
    { city: 'Rio Dell', county: 'Humboldt' },
    { city: 'Trinidad', county: 'Humboldt' },
    { city: 'Brawley', county: 'Imperial' },
    { city: 'Calexico', county: 'Imperial' },
    { city: 'Calipatria', county: 'Imperial' },
    { city: 'El Centro', county: 'Imperial' },
    { city: 'Holtville', county: 'Imperial' },
    { city: 'Imperial', county: 'Imperial' },
    { city: 'Westmorland', county: 'Imperial' },
    { city: 'Bishop', county: 'Inyo' },
    { city: 'Arvin', county: 'Kern' },
    { city: 'Bakersfield', county: 'Kern' },
    { city: 'California City', county: 'Kern' },
    { city: 'Delano', county: 'Kern' },
    { city: 'Maricopa', county: 'Kern' },
    { city: 'McFarland', county: 'Kern' },
    { city: 'Ridgecrest', county: 'Kern' },
    { city: 'Shafter', county: 'Kern' },
    { city: 'Taft', county: 'Kern' },
    { city: 'Tehachapi', county: 'Kern' },
    { city: 'Wasco', county: 'Kern' },
    { city: 'Avenal', county: 'Kings' },
    { city: 'Corcoran', county: 'Kings' },
    { city: 'Hanford', county: 'Kings' },
    { city: 'Lemoore', county: 'Kings' },
    { city: 'Clearlake', county: 'Lake' },
    { city: 'Lakeport', county: 'Lake' },
    { city: 'Susanville', county: 'Lassen' },
    { city: 'Agoura Hills', county: 'Los Angeles' },
    { city: 'Alhambra', county: 'Los Angeles' },
    { city: 'Arcadia', county: 'Los Angeles' },
    { city: 'Artesia', county: 'Los Angeles' },
    { city: 'Avalon', county: 'Los Angeles' },
    { city: 'Azusa', county: 'Los Angeles' },
    { city: 'Baldwin Park', county: 'Los Angeles' },
    { city: 'Bell', county: 'Los Angeles' },
    { city: 'Bell Gardens', county: 'Los Angeles' },
    { city: 'Bellflower', county: 'Los Angeles' },
    { city: 'Beverly Hills', county: 'Los Angeles' },
    { city: 'Bradbury', county: 'Los Angeles' },
    { city: 'Burbank', county: 'Los Angeles' },
    { city: 'Calabasas', county: 'Los Angeles' },
    { city: 'Carson', county: 'Los Angeles' },
    { city: 'Cerritos', county: 'Los Angeles' },
    { city: 'Claremont', county: 'Los Angeles' },
    { city: 'Commerce', county: 'Los Angeles' },
    { city: 'Compton', county: 'Los Angeles' },
    { city: 'Covina', county: 'Los Angeles' },
    { city: 'Cudahy', county: 'Los Angeles' },
    { city: 'Culver City', county: 'Los Angeles' },
    { city: 'Diamond Bar', county: 'Los Angeles' },
    { city: 'Downey', county: 'Los Angeles' },
    { city: 'Duarte', county: 'Los Angeles' },
    { city: 'El Monte', county: 'Los Angeles' },
    { city: 'El Segundo', county: 'Los Angeles' },
    { city: 'Gardena', county: 'Los Angeles' },
    { city: 'Glendale', county: 'Los Angeles' },
    { city: 'Glendora', county: 'Los Angeles' },
    { city: 'Hawaiian Gardens', county: 'Los Angeles' },
    { city: 'Hawthorne', county: 'Los Angeles' },
    { city: 'Hermosa Beach', county: 'Los Angeles' },
    { city: 'Hidden Hills', county: 'Los Angeles' },
    { city: 'Huntington Park', county: 'Los Angeles' },
    { city: 'Industry', county: 'Los Angeles' },
    { city: 'Inglewood', county: 'Los Angeles' },
    { city: 'Irwindale', county: 'Los Angeles' },
    { city: 'La Cañada Flintridge', county: 'Los Angeles' },
    { city: 'La Habra Heights', county: 'Los Angeles' },
    { city: 'La Mirada', county: 'Los Angeles' },
    { city: 'La Puente', county: 'Los Angeles' },
    { city: 'La Verne', county: 'Los Angeles' },
    { city: 'Lakewood', county: 'Los Angeles' },
    { city: 'Lancaster', county: 'Los Angeles' },
    { city: 'Lawndale', county: 'Los Angeles' },
    { city: 'Lomita', county: 'Los Angeles' },
    { city: 'Long Beach', county: 'Los Angeles' },
    { city: 'Los Angeles', county: 'Los Angeles' },
    { city: 'Lynwood', county: 'Los Angeles' },
    { city: 'Malibu', county: 'Los Angeles' },
    { city: 'Manhattan Beach', county: 'Los Angeles' },
    { city: 'Maywood', county: 'Los Angeles' },
    { city: 'Monrovia', county: 'Los Angeles' },
    { city: 'Montebello', county: 'Los Angeles' },
    { city: 'Monterey Park', county: 'Los Angeles' },
    { city: 'Norwalk', county: 'Los Angeles' },
    { city: 'North Hollywood', county: 'Los Angeles' },
    { city: 'Palmdale', county: 'Los Angeles' },
    { city: 'Palos Verdes Estates', county: 'Los Angeles' },
    { city: 'Paramount', county: 'Los Angeles' },
    { city: 'Pasadena', county: 'Los Angeles' },
    { city: 'Pico Rivera', county: 'Los Angeles' },
    { city: 'Pomona', county: 'Los Angeles' },
    { city: 'Rancho Palos Verdes', county: 'Los Angeles' },
    { city: 'Redondo Beach', county: 'Los Angeles' },
    { city: 'Rolling Hills', county: 'Los Angeles' },
    { city: 'Rolling Hills Estates', county: 'Los Angeles' },
    { city: 'Rosemead', county: 'Los Angeles' },
    { city: 'San Dimas', county: 'Los Angeles' },
    { city: 'San Fernando', county: 'Los Angeles' },
    { city: 'San Gabriel', county: 'Los Angeles' },
    { city: 'San Marino', county: 'Los Angeles' },
    { city: 'Santa Clarita', county: 'Los Angeles' },
    { city: 'Santa Fe Springs', county: 'Los Angeles' },
    { city: 'Santa Monica', county: 'Los Angeles' },
    { city: 'Sierra Madre', county: 'Los Angeles' },
    { city: 'Signal Hill', county: 'Los Angeles' },
    { city: 'South El Monte', county: 'Los Angeles' },
    { city: 'South Gate', county: 'Los Angeles' },
    { city: 'South Pasadena', county: 'Los Angeles' },
    { city: 'Temple City', county: 'Los Angeles' },
    { city: 'Torrance', county: 'Los Angeles' },
    { city: 'Vernon', county: 'Los Angeles' },
    { city: 'Walnut', county: 'Los Angeles' },
    { city: 'West Covina', county: 'Los Angeles' },
    { city: 'West Hollywood', county: 'Los Angeles' },
    { city: 'Westlake Village', county: 'Los Angeles' },
    { city: 'Whittier', county: 'Los Angeles' },
    { city: 'Chowchilla', county: 'Madera' },
    { city: 'Madera', county: 'Madera' },
    { city: 'Belvedere', county: 'Marin' },
    { city: 'Corte Madera', county: 'Marin' },
    { city: 'Fairfax', county: 'Marin' },
    { city: 'Larkspur', county: 'Marin' },
    { city: 'Mill Valley', county: 'Marin' },
    { city: 'Novato', county: 'Marin' },
    { city: 'Ross', county: 'Marin' },
    { city: 'San Anselmo', county: 'Marin' },
    { city: 'San Rafael', county: 'Marin' },
    { city: 'Sausalito', county: 'Marin' },
    { city: 'Tiburon', county: 'Marin' },
    { city: 'Fort Bragg', county: 'Mendocino' },
    { city: 'Point Arena', county: 'Mendocino' },
    { city: 'Ukiah', county: 'Mendocino' },
    { city: 'Willits', county: 'Mendocino' },
    { city: 'Atwater', county: 'Merced' },
    { city: 'Dos Palos', county: 'Merced' },
    { city: 'Gustine', county: 'Merced' },
    { city: 'Livingston', county: 'Merced' },
    { city: 'Los Banos', county: 'Merced' },
    { city: 'Merced', county: 'Merced' },
    { city: 'Alturas', county: 'Modoc' },
    { city: 'Mammoth Lakes', county: 'Mono' },
    { city: 'Carmel-by-the-Sea', county: 'Monterey' },
    { city: 'Del Rey Oaks', county: 'Monterey' },
    { city: 'Gonzales', county: 'Monterey' },
    { city: 'Greenfield', county: 'Monterey' },
    { city: 'King City', county: 'Monterey' },
    { city: 'Marina', county: 'Monterey' },
    { city: 'Monterey', county: 'Monterey' },
    { city: 'Pacific Grove', county: 'Monterey' },
    { city: 'Salinas', county: 'Monterey' },
    { city: 'Sand City', county: 'Monterey' },
    { city: 'Seaside', county: 'Monterey' },
    { city: 'Soledad', county: 'Monterey' },
    { city: 'American Canyon', county: 'Napa' },
    { city: 'Calistoga', county: 'Napa' },
    { city: 'Napa', county: 'Napa' },
    { city: 'St. Helena', county: 'Napa' },
    { city: 'Yountville', county: 'Napa' },
    { city: 'Grass Valley', county: 'Nevada' },
    { city: 'Nevada City', county: 'Nevada' },
    { city: 'Truckee', county: 'Nevada' },
    { city: 'Aliso Viejo', county: 'Orange' },
    { city: 'Anaheim', county: 'Orange' },
    { city: 'Brea', county: 'Orange' },
    { city: 'Buena Park', county: 'Orange' },
    { city: 'Costa Mesa', county: 'Orange' },
    { city: 'Cypress', county: 'Orange' },
    { city: 'Dana Point', county: 'Orange' },
    { city: 'Fountain Valley', county: 'Orange' },
    { city: 'Fullerton', county: 'Orange' },
    { city: 'Garden Grove', county: 'Orange' },
    { city: 'Huntington Beach', county: 'Orange' },
    { city: 'Irvine', county: 'Orange' },
    { city: 'La Habra', county: 'Orange' },
    { city: 'La Palma', county: 'Orange' },
    { city: 'Laguna Beach', county: 'Orange' },
    { city: 'Laguna Hills', county: 'Orange' },
    { city: 'Laguna Niguel', county: 'Orange' },
    { city: 'Laguna Woods', county: 'Orange' },
    { city: 'Lake Forest', county: 'Orange' },
    { city: 'Los Alamitos', county: 'Orange' },
    { city: 'Mission Viejo', county: 'Orange' },
    { city: 'Newport Beach', county: 'Orange' },
    { city: 'Orange', county: 'Orange' },
    { city: 'Placentia', county: 'Orange' },
    { city: 'Rancho Santa Margarita', county: 'Orange' },
    { city: 'San Clemente', county: 'Orange' },
    { city: 'San Juan Capistrano', county: 'Orange' },
    { city: 'Santa Ana', county: 'Orange' },
    { city: 'Seal Beach', county: 'Orange' },
    { city: 'Stanton', county: 'Orange' },
    { city: 'Tustin', county: 'Orange' },
    { city: 'Villa Park', county: 'Orange' },
    { city: 'Westminster', county: 'Orange' },
    { city: 'Yorba Linda', county: 'Orange' },
    { city: 'Auburn', county: 'Placer' },
    { city: 'Colfax', county: 'Placer' },
    { city: 'Lincoln', county: 'Placer' },
    { city: 'Loomis', county: 'Placer' },
    { city: 'Rocklin', county: 'Placer' },
    { city: 'Roseville', county: 'Placer' },
    { city: 'Portola', county: 'Plumas' },
    { city: 'Banning', county: 'Riverside' },
    { city: 'Beaumont', county: 'Riverside' },
    { city: 'Blythe', county: 'Riverside' },
    { city: 'Calimesa', county: 'Riverside' },
    { city: 'Canyon Lake', county: 'Riverside' },
    { city: 'Cathedral City', county: 'Riverside' },
    { city: 'Coachella', county: 'Riverside' },
    { city: 'Corona', county: 'Riverside' },
    { city: 'Desert Hot Springs', county: 'Riverside' },
    { city: 'Eastvale', county: 'Riverside' },
    { city: 'Hemet', county: 'Riverside' },
    { city: 'Indian Wells', county: 'Riverside' },
    { city: 'Indio', county: 'Riverside' },
    { city: 'Jurupa Valley', county: 'Riverside' },
    { city: 'La Quinta', county: 'Riverside' },
    { city: 'Lake Elsinore', county: 'Riverside' },
    { city: 'Menifee', county: 'Riverside' },
    { city: 'Moreno Valley', county: 'Riverside' },
    { city: 'Murrieta', county: 'Riverside' },
    { city: 'Norco', county: 'Riverside' },
    { city: 'Palm Desert', county: 'Riverside' },
    { city: 'Palm Springs', county: 'Riverside' },
    { city: 'Perris', county: 'Riverside' },
    { city: 'Rancho Mirage', county: 'Riverside' },
    { city: 'Riverside', county: 'Riverside' },
    { city: 'San Jacinto', county: 'Riverside' },
    { city: 'Temecula', county: 'Riverside' },
    { city: 'Wildomar', county: 'Riverside' },
    { city: 'Citrus Heights', county: 'Sacramento' },
    { city: 'Elk Grove', county: 'Sacramento' },
    { city: 'Folsom', county: 'Sacramento' },
    { city: 'Galt', county: 'Sacramento' },
    { city: 'Isleton', county: 'Sacramento' },
    { city: 'Rancho Cordova', county: 'Sacramento' },
    { city: 'Sacramento', county: 'Sacramento' },
    { city: 'Hollister', county: 'San Benito' },
    { city: 'San Juan Bautista', county: 'San Benito' },
    { city: 'Adelanto', county: 'San Bernardino' },
    { city: 'Apple Valley', county: 'San Bernardino' },
    { city: 'Barstow', county: 'San Bernardino' },
    { city: 'Big Bear Lake', county: 'San Bernardino' },
    { city: 'Chino', county: 'San Bernardino' },
    { city: 'Chino Hills', county: 'San Bernardino' },
    { city: 'Colton', county: 'San Bernardino' },
    { city: 'Fontana', county: 'San Bernardino' },
    { city: 'Grand Terrace', county: 'San Bernardino' },
    { city: 'Hesperia', county: 'San Bernardino' },
    { city: 'Highland', county: 'San Bernardino' },
    { city: 'Loma Linda', county: 'San Bernardino' },
    { city: 'Montclair', county: 'San Bernardino' },
    { city: 'Needles', county: 'San Bernardino' },
    { city: 'Ontario', county: 'San Bernardino' },
    { city: 'Rancho Cucamonga', county: 'San Bernardino' },
    { city: 'Redlands', county: 'San Bernardino' },
    { city: 'Rialto', county: 'San Bernardino' },
    { city: 'San Bernardino', county: 'San Bernardino' },
    { city: 'Twentynine Palms', county: 'San Bernardino' },
    { city: 'Upland', county: 'San Bernardino' },
    { city: 'Victorville', county: 'San Bernardino' },
    { city: 'Yucaipa', county: 'San Bernardino' },
    { city: 'Yucca Valley', county: 'San Bernardino' },
    { city: 'Carlsbad', county: 'San Diego' },
    { city: 'Chula Vista', county: 'San Diego' },
    { city: 'Coronado', county: 'San Diego' },
    { city: 'Del Mar', county: 'San Diego' },
    { city: 'El Cajon', county: 'San Diego' },
    { city: 'Encinitas', county: 'San Diego' },
    { city: 'Escondido', county: 'San Diego' },
    { city: 'Imperial Beach', county: 'San Diego' },
    { city: 'La Mesa', county: 'San Diego' },
    { city: 'Lemon Grove', county: 'San Diego' },
    { city: 'National City', county: 'San Diego' },
    { city: 'Oceanside', county: 'San Diego' },
    { city: 'Poway', county: 'San Diego' },
    { city: 'San Diego', county: 'San Diego' },
    { city: 'San Marcos', county: 'San Diego' },
    { city: 'Santee', county: 'San Diego' },
    { city: 'Solana Beach', county: 'San Diego' },
    { city: 'Vista', county: 'San Diego' },
    { city: 'San Francisco', county: 'San Francisco' },
    { city: 'Escalon', county: 'San Joaquin' },
    { city: 'Lathrop', county: 'San Joaquin' },
    { city: 'Lodi', county: 'San Joaquin' },
    { city: 'Manteca', county: 'San Joaquin' },
    { city: 'Ripon', county: 'San Joaquin' },
    { city: 'Stockton', county: 'San Joaquin' },
    { city: 'Tracy', county: 'San Joaquin' },
    { city: 'Arroyo Grande', county: 'San Luis Obispo' },
    { city: 'Atascadero', county: 'San Luis Obispo' },
    { city: 'Grover Beach', county: 'San Luis Obispo' },
    { city: 'Morro Bay', county: 'San Luis Obispo' },
    { city: 'Paso Robles', county: 'San Luis Obispo' },
    { city: 'Pismo Beach', county: 'San Luis Obispo' },
    { city: 'San Luis Obispo', county: 'San Luis Obispo' },
    { city: 'Atherton', county: 'San Mateo' },
    { city: 'Belmont', county: 'San Mateo' },
    { city: 'Brisbane', county: 'San Mateo' },
    { city: 'Burlingame', county: 'San Mateo' },
    { city: 'Colma', county: 'San Mateo' },
    { city: 'Daly City', county: 'San Mateo' },
    { city: 'East Palo Alto', county: 'San Mateo' },
    { city: 'Foster City', county: 'San Mateo' },
    { city: 'Half Moon Bay', county: 'San Mateo' },
    { city: 'Hillsborough', county: 'San Mateo' },
    { city: 'Menlo Park', county: 'San Mateo' },
    { city: 'Millbrae', county: 'San Mateo' },
    { city: 'Pacifica', county: 'San Mateo' },
    { city: 'Portola Valley', county: 'San Mateo' },
    { city: 'Redwood City', county: 'San Mateo' },
    { city: 'San Bruno', county: 'San Mateo' },
    { city: 'San Carlos', county: 'San Mateo' },
    { city: 'San Mateo', county: 'San Mateo' },
    { city: 'South San Francisco', county: 'San Mateo' },
    { city: 'Woodside', county: 'San Mateo' },
    { city: 'Buellton', county: 'Santa Barbara' },
    { city: 'Carpinteria', county: 'Santa Barbara' },
    { city: 'Goleta', county: 'Santa Barbara' },
    { city: 'Guadalupe', county: 'Santa Barbara' },
    { city: 'Lompoc', county: 'Santa Barbara' },
    { city: 'Santa Barbara', county: 'Santa Barbara' },
    { city: 'Santa Maria', county: 'Santa Barbara' },
    { city: 'Solvang', county: 'Santa Barbara' },
    { city: 'Campbell', county: 'Santa Clara' },
    { city: 'Cupertino', county: 'Santa Clara' },
    { city: 'Gilroy', county: 'Santa Clara' },
    { city: 'Los Altos', county: 'Santa Clara' },
    { city: 'Los Altos Hills', county: 'Santa Clara' },
    { city: 'Los Gatos', county: 'Santa Clara' },
    { city: 'Milpitas', county: 'Santa Clara' },
    { city: 'Monte Sereno', county: 'Santa Clara' },
    { city: 'Morgan Hill', county: 'Santa Clara' },
    { city: 'Mountain View', county: 'Santa Clara' },
    { city: 'Palo Alto', county: 'Santa Clara' },
    { city: 'San Jose', county: 'Santa Clara' },
    { city: 'Santa Clara', county: 'Santa Clara' },
    { city: 'Saratoga', county: 'Santa Clara' },
    { city: 'Sunnyvale', county: 'Santa Clara' },
    { city: 'Capitola', county: 'Santa Cruz' },
    { city: 'Santa Cruz', county: 'Santa Cruz' },
    { city: 'Scotts Valley', county: 'Santa Cruz' },
    { city: 'Watsonville', county: 'Santa Cruz' },
    { city: 'Anderson', county: 'Shasta' },
    { city: 'Redding', county: 'Shasta' },
    { city: 'Shasta Lake', county: 'Shasta' },
    { city: 'Loyalton', county: 'Sierra' },
    { city: 'Dorris', county: 'Siskiyou' },
    { city: 'Dunsmuir', county: 'Siskiyou' },
    { city: 'Etna', county: 'Siskiyou' },
    { city: 'Fort Jones', county: 'Siskiyou' },
    { city: 'Montague', county: 'Siskiyou' },
    { city: 'Mount Shasta', county: 'Siskiyou' },
    { city: 'Tulelake', county: 'Siskiyou' },
    { city: 'Weed', county: 'Siskiyou' },
    { city: 'Yreka', county: 'Siskiyou' },
    { city: 'Benicia', county: 'Solano' },
    { city: 'Dixon', county: 'Solano' },
    { city: 'Fairfield', county: 'Solano' },
    { city: 'Rio Vista', county: 'Solano' },
    { city: 'Suisun City', county: 'Solano' },
    { city: 'Vacaville', county: 'Solano' },
    { city: 'Vallejo', county: 'Solano' },
    { city: 'Cloverdale', county: 'Sonoma' },
    { city: 'Cotati', county: 'Sonoma' },
    { city: 'Healdsburg', county: 'Sonoma' },
    { city: 'Petaluma', county: 'Sonoma' },
    { city: 'Rohnert Park', county: 'Sonoma' },
    { city: 'Santa Rosa', county: 'Sonoma' },
    { city: 'Sebastopol', county: 'Sonoma' },
    { city: 'Sonoma', county: 'Sonoma' },
    { city: 'Windsor', county: 'Sonoma' },
    { city: 'Ceres', county: 'Stanislaus' },
    { city: 'Hughson', county: 'Stanislaus' },
    { city: 'Modesto', county: 'Stanislaus' },
    { city: 'Newman', county: 'Stanislaus' },
    { city: 'Oakdale', county: 'Stanislaus' },
    { city: 'Patterson', county: 'Stanislaus' },
    { city: 'Riverbank', county: 'Stanislaus' },
    { city: 'Turlock', county: 'Stanislaus' },
    { city: 'Waterford', county: 'Stanislaus' },
    { city: 'Live Oak', county: 'Sutter' },
    { city: 'Yuba City', county: 'Sutter' },
    { city: 'Corning', county: 'Tehama' },
    { city: 'Red Bluff', county: 'Tehama' },
    { city: 'Tehama', county: 'Tehama' },
    { city: 'Dinuba', county: 'Tulare' },
    { city: 'Exeter', county: 'Tulare' },
    { city: 'Farmersville', county: 'Tulare' },
    { city: 'Lindsay', county: 'Tulare' },
    { city: 'Porterville', county: 'Tulare' },
    { city: 'Tulare', county: 'Tulare' },
    { city: 'Visalia', county: 'Tulare' },
    { city: 'Woodlake', county: 'Tulare' },
    { city: 'Sonora', county: 'Tuolumne' },
    { city: 'Camarillo', county: 'Ventura' },
    { city: 'Fillmore', county: 'Ventura' },
    { city: 'Moorpark', county: 'Ventura' },
    { city: 'Ojai', county: 'Ventura' },
    { city: 'Oxnard', county: 'Ventura' },
    { city: 'Port Hueneme', county: 'Ventura' },
    { city: 'Santa Paula', county: 'Ventura' },
    { city: 'Simi Valley', county: 'Ventura' },
    { city: 'Thousand Oaks', county: 'Ventura' },
    { city: 'Ventura', county: 'Ventura' },
    { city: 'Davis', county: 'Yolo' },
    { city: 'West Sacramento', county: 'Yolo' },
    { city: 'Winters', county: 'Yolo' },
    { city: 'Woodland', county: 'Yolo' },
    { city: 'Marysville', county: 'Yuba' },
    { city: 'Wheatland', county: 'Yuba' },
  ];
};
