import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  LOGIN_FAIL,
  CLEAR_ERROR,
  LOGIN_SUCCESS,
  USER_LOADED,
  RECENT_SEARCH,
  ADD_RECENT_SEARCH,
  USER_LOADED_INITAL,
  CLEAR_INITIAL,
  AUTH_ERROR,
  RESPONSE_STATUS,
  CLEAR_RESPONSE,
  ONLY_ONCE,
  LOGOUT,
} from '../types';

export default (state, action) => {
  switch (action.type) {
    case USER_LOADED:
      return {
        ...state,
        isAuthenticated: true,
        isAdmin:
          action.payload.data.email === 'seller@laauction.com' ||
          action.payload.data.email === 'seller@laauctiondepot.com' ||
          action.payload.data.email === 'seller_test@laauctiondepot.com' 
            ? true
            : false,
        loading: false,
        user: action.payload.data,
        membership: action.payload.membership,
      };

    case ONLY_ONCE:
      return {
        ...state,
        onlyOnce: true,
      };

    case USER_LOADED_INITAL:
      return {
        ...state,
        isAuthenticated: true,
        isAdmin:
          action.payload.data.email === 'seller@laauction.com' ||
          action.payload.data.email === 'seller@laauctiondepot.com'||
          action.payload.data.email === 'seller_test@laauctiondepot.com' 
            ? true
            : false,
        initialLogin: true,
        loading: false,
        user: action.payload.data,
        membership: action.payload.membership,
      };
    case CLEAR_INITIAL:
      return {
        ...state,
        initialLogin: false,
      };
    case LOGIN_SUCCESS:
      localStorage.setItem('token', action.payload.data);
      return {
        ...state,
        token: action.payload.data,
        isAuthenticated: true,
        loading: false,
        error: action.payload.message,
      };
    case RECENT_SEARCH:
      return {
        ...state,
        recent_search: action.payload,
      };
    case ADD_RECENT_SEARCH:
      return {
        ...state,
        recent_search: [action.payload, ...state.recent_search],
      };
    case REGISTER_SUCCESS:
    case REGISTER_FAIL:
    case LOGIN_FAIL:
    case AUTH_ERROR:
    case LOGOUT:
      localStorage.removeItem('token');
      return {
        ...state,
        token: null,
        isAdmin: false,
        isAuthenticated: false,
        loading: false,
        user: {},
        membership: {},
        error: action.payload,
      };
    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
      };
    case RESPONSE_STATUS:
      return {
        ...state,
        responseStatus: action.payload,
      };
    case CLEAR_RESPONSE:
      return {
        ...state,
        responseStatus: '',
      };
    default:
      return state;
  }
};
