/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react';
import Layout from '../../Components/Layout';
import SearchHeader from '../../Components/Search/SearchHeader';
import Filters from '../../Components/Search/Filters';
import SearchList from '../../Components/Search/SearchAuctionList';
import ProductContext from '../../context/product/productContext';
import { onChangeFunction } from '../../Common/Components';
import { Pagination } from '@material-ui/lab';
import Loading from '../../Components/Loading';

const Search = (props) => {
  const productContext = useContext(ProductContext);
  const { search_allauctions, getAllSearchAuctions } = productContext;
  const [loading, setLoading] = useState(true);
  const [viewAuction, setViewAuction] = useState([]);

  useEffect(() => {
    setLoading(false);
    setViewAuction(
      search_allauctions.results.length ? search_allauctions.results : []
    );
  }, [search_allauctions]);

  useEffect(() => {
    setViewAuction([]);
    return () => {
      setViewAuction([]);
    };
  }, []);

  const [search, setSearch] = useState({
    sh_limit: 10,
    page: 1,
    orderby: '1',
    location: [],
    category: [],
    fromprice: 0,
    toprice: 0,
    totalpage: 5,
    searchbar: '',
  });
  const onChange = (e) => {
    setSearch(onChangeFunction(e, search));
  };
  const onChangeCheckBox = (e) => {
    let categorypush = search[e.target.name];
    if (e.target.checked) {
      categorypush.push(e.target.value);
      setSearch({ ...search, [e.target.name]: categorypush });
    } else {
      const index = categorypush.indexOf(e.target.value);
      if (index > -1) {
        categorypush.splice(index, 1);
      }
      setSearch({ ...search, [e.target.name]: categorypush });
    }
  };

  const onHandlePage = (event, value) => {
    setSearch({ ...search, page: value });
  };

  const [listview, setlistview] = useState(false);
  useEffect(() => {
    setLoading(true);
    getAllSearchAuctions(search);
  }, [search]);

  useEffect(() => {
    if (props.match.params.type) {
      if (props.match.params.type === 'list') {
        setlistview(true);
      } else {
        setlistview(false);
      }
    } else {
      setlistview(false);
    }
  }, [props.match.params.type]);

  return (
    <Layout props={props}>
      <Loading loading={loading} />
      <SearchHeader
        fromAuction={1}
        title="SITE"
        subtitle="Auctions"
        listLink="/marketplace/search/list"
        gridLink="/marketplace/search"
        listview={listview}
        onChange={onChange}
        search_allproducts={search_allauctions}
      />
      <div className="container mt-5">
        <div className="row">
          <div className="col-sm-4 col-md-3">
            <Filters
              fromAuction={1}
              onChange={onChange}
              locationItems={[]}
              categoriesCounts={[]}
              upcomingAuction={[]}
              onChangeCheckBox={onChangeCheckBox}
            />
          </div>
          <div className="col-sm-8 col-md-9">
            
            <SearchList listview={listview} allsearchitems={viewAuction} />
            {search_allauctions.results.length ? (
              <div className="d-flex w-100 mb-3 pagination-wrapper">
                <div className="mr-auto mt-2">
                  Showing {search_allauctions.set_disp} of{' '}
                  {search_allauctions.total_pagecnt}{' '}
                </div>
                <Pagination
                  count={Math.ceil(
                    search_allauctions.total_pagecnt / search.sh_limit
                  )}
                  page={search.page}
                  onChange={onHandlePage}
                  siblingCount={3}
                  showFirstButton
                  showLastButton
                  boundaryCount={2}
                />
              </div>
            ) : (
              <div className="np-pro-box"> No Records Found! </div>
            )}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Search;
