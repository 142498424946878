import React from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Layout from '../../Components/Layout';

import DashboardHeader from '../../Components/DashboardHeader';
import ProfileLinks from '../../Components/Profile/ProfileLinks';
import PreferenceForm from '../../Components/Profile/Preference';
import {connect} from 'react-redux';

const NotificationPreference = (props) => {
  return (
    <Layout props={props}>
      <DashboardHeader title={props.Language.profile_caps} subtitle={props.Language.info_caps} />
      <Container className="dashboard-wrapper">
        <Grid container spacing={3}>
          <Grid item xs={12} sm={3}>
            <ProfileLinks
              profilelink="/profile"
              preflink="/preference"
              cpwdlink="/change-password"
              sslink="/saved-search"
            />
          </Grid>
          <Grid item xs={12} sm={9}>
            <PreferenceForm />
          </Grid>
        </Grid>
      </Container>
    </Layout>
  );
};

const mapStateToProps = (state) => {
  return {
    Language : state.Language.languages
   }
 };

export default connect(mapStateToProps,undefined) (NotificationPreference);
