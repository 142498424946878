import React from 'react';
import { Link } from 'react-router-dom';
import './auth.css';
import {connect} from 'react-redux';

const AuthLeft = ({
  home,
  pageTitle,
  subTitle,
  primaryButton,
  secondayTitle,
  ssubTitle,
  secondarySubTitle,
  threeSubTitle,
  fourSubTitle,
  fiveSubTitle,
  secondaryButton,
  primaryHref,
  secondaryHref,
  Language
}) => {
  return (
    <>
      <div className="welcome-text">
  <span className="hbox">{Language.wlcm}</span>
        <span>TO</span>
      </div>
      <h2 className="al-head">{pageTitle}</h2>
      <p className="al-shead">{subTitle}</p>
      <Link to={primaryHref} className="btn btn-primary">
        {primaryButton}
      </Link>
      <hr className="auth-seperator" />
      {home ? (
        <h2 className="pbuyer-head">
          {secondayTitle}
          <small>{ssubTitle}</small>
        </h2>
      ) : (
        <h2 className="al-head">{secondayTitle}</h2>
      )}
      <p className="al-shead">{secondarySubTitle}</p>
      <p className="al-shead">{threeSubTitle}</p>
      <p className="al-shead">{fourSubTitle}</p>
      <p className="al-shead">{fiveSubTitle}</p>
      {secondaryButton ? (
        <Link to={secondaryHref} className="btn btn-secondary">
          {secondaryButton}
        </Link>
      ) : null}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    Language : state.Language.languages
   }
 };


export default connect(mapStateToProps,undefined)(AuthLeft);
