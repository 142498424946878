/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState, useEffect } from 'react';
import {
  Link,
  NavLink,
  Redirect,
  withRouter,
  useHistory,
} from 'react-router-dom';
import './sub-header.css';
import AuthContext from '../../context/auth/authContext';
import AlertContext from '../../context/alert/alertContext';
import {getLanguage} from '../../Actions/Language';
import {compose, bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import Swal from 'sweetalert2';


const Subheader = (props) => {
  const history = useHistory();
  const authContext = useContext(AuthContext);
  const alertContext = useContext(AlertContext);

  const { user, isAuthenticated, isAdmin, logout } = authContext;
  const { setAlert } = alertContext;
  const [toHome, settoHome] = useState(false);
  const [menu, setMenu] = useState(false);
  const onLogOut = () => {
    logout();
    setAlert(props.Language.log_o_s ? props.Language.log_o_s : 'Logged out successfully', 'success');
    settoHome(true);
  };

  const ShowMenu = () => {
    setMenu(!menu);
  };

  useEffect(() => {
    return history.listen(() => setMenu(false));
  }, []);

  useEffect(()=>{
    // console.log(props.Language, 'props.Language')
    console.log(localStorage.getItem("language"), 'props.Language')
  })

  

  return (
    <div className={`sub-header-wrapper ${localStorage.getItem("language")}`}>
      {toHome ? <Redirect to="/" /> : null}
      <div className="d-flex sub-header-container">
        <div className="menu-xs" onClick={ShowMenu}>
          <i className="fa fa-bars"></i>
        </div>
        <ul
          className={`list-inline left-links sh-menu mr-auto ${
            menu ? 'show-menu' : 'hide-menu'
          }`}
        >
          <li className="list-inline-item">
            <NavLink exact to="/" activeClassName="mactive">
              {props.Language.hme_home}
            </NavLink>
          </li>

          {isAuthenticated && isAdmin ? (
            <>
              <li className="list-inline-item">
                <NavLink to="/sellerdashboard" activeClassName="mactive">
                  {props.Language.srll}
                </NavLink>
              </li>
            </>
          ) : (
            <>
              <li className="list-inline-item">
                <NavLink to="/selling" activeClassName="mactive">
                {props.Language.srll}
                </NavLink>
              </li>
            </>
          )}
          {!isAdmin ? (
            <>
              {isAuthenticated ? (
                <>
                  <li className="list-inline-item">
                    <NavLink to="/buyerdashboard" activeClassName="mactive">
                      {props.Language.byr}
                    </NavLink>
                  </li>
                </>
              ) : (
                <>
                  <li className="list-inline-item">
                    <NavLink to="/login">{props.Language.byr}</NavLink>
                  </li>
                </>
              )}

              <li className="list-inline-item">
                <NavLink to="/laad" activeClassName="mactive">
                  {props.Language.laad_one}
                </NavLink>
              </li>
              <li className="list-inline-item">
                <NavLink to="/membership" activeClassName="mactive">
                  {props.Language.pr_crnr}
                </NavLink>
              </li>
              <li className="list-inline-item">
                <NavLink to="/bidder-faq" activeClassName="mactive">
                  {props.Language.bddrfaq}
                </NavLink>
              </li>
              <li className="list-inline-item">
                <NavLink to="/contact-us" activeClassName="mactive">
                  {props.Language.conta_ss}
                </NavLink>
              </li>
              <li className="list-inline-item">
                <NavLink to="/pro-buyer-blog" activeClassName="mactive">
                  {props.Language.blg}
                </NavLink>
              </li>
              <li className="list-inline-item">
                <NavLink to="/news" activeClassName="mactive">
                  {props.Language.nws}
                </NavLink>
              </li>
            </>
          ) : null}
          {!isAuthenticated ? (
            <>
              <li className="list-inline-item">
                <NavLink to="/register" activeClassName="mactive">
                 {props.Language.rester}
                </NavLink>
              </li>
              <li className="list-inline-item">
                <NavLink to="/login" activeClassName="mactive">
                  {props.Language.login}
                </NavLink>
              </li>
            </>
          ) : (
            <>
              <li onClick={onLogOut} className="list-inline-item logout-txt">
                {props.Language.logout}
              </li>
            </>
          )}
          <li className="list-inline-item logout-txt">
            <span className="language-link">
              <div onClick={()=>{
                localStorage.setItem('language','English')
                // props.Language();
                Swal.fire({
                  title:'Language Changed Successfully',
                  icon: 'success',
                  timer: 1500,
                  showConfirmButton: false,
                  position: 'center',
                })
                window.location.reload();
              }}>
              <img src="/images/united-states.png" alt=""  /> English</div>
            </span>
          </li>
          <li className="list-inline-item logout-txt">
            <span className="language-link">
              <div onClick={()=>{
                localStorage.setItem('language','Spanish')
                // props.Language();
                Swal.fire({
                  title:'Idioma cambiado con éxito',
                  icon: 'success',
                  timer: 1500,
                  showConfirmButton: false,
                  position: 'center',
                })
                window.location.reload();
              }}>
              <img src="/images/spain.png" alt="" /> Spanish
              </div>
            </span>
          </li>
        </ul>

        <div className="social-links ml-auto">
          {!isAuthenticated ? (
            <></>
          ) : (
            <>
              {user.avatar ? (
                <>
                  <Link to="/profile" className="buyer-img">
                    <img
                      src={`${global.profile_image_url}${user.avatar}`}
                      alt=""
                    />
                  </Link>
                </>
              ) : (
                <>
                  <Link to="/profile" className="buyer-img">
                    <img src="/images/dash-icon-1.png" alt="" />
                  </Link>
                </>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
        Language : state.Language.languages
      };
 };
  

// const mapDispatchToProps = (dispatch) => ({
//   Languages : () => (dispatch(getLanguage()))
// });

// const mapDispatchToProps = (dispatch) =>
//   bindActionCreators(
//     {
//       Language: getLanguage,
//     },
//     dispatch
//   );


export default compose(
  withRouter,
  connect(mapStateToProps,undefined)
)(Subheader);
