/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useContext, useEffect } from 'react';

import moment from 'moment';
import $ from 'jquery';
import { onChangeFunction } from '../../Common/Components';
import PayContext from '../../context/payment/payContext';
import AlertContext from '../../context/alert/alertContext';
import LAauctionModal from '../Modal';
import {connect} from 'react-redux';

const button = $('#cardSubmitButton');
const AddCard = ({ open, card_profileid, paymentSuccess, editDetails,...props }) => {
  const payContext = useContext(PayContext);
  const alertContext = useContext(AlertContext);
  const { setAlert } = alertContext;
  let date = new Date();
  let currentYear = `${moment(date).format('YYYY')}`;
  let currentMonthFixed = `${moment(date).format('MM')}`;
  const [currentMonth, setcurrentMonth] = useState(1);

  const changeMonth = (e) => {
    if (currentYear === e.target.value) {
      setcurrentMonth(currentMonthFixed);
    } else {
      setcurrentMonth(1);
    }
  };
  const {
    responseStatus,
    addUserCardAuthorize,
    addExtraUserCardAuthorize,
    editUserCard,
    clearResponse,
  } = payContext;

  const [card, setCard] = useState({
    cutoproid: card_profileid,
    cardhname: '',
    extracardnumber: '',
    extracvv: '',
    extrayear: '',
    extradate: '',
  });

  useEffect(() => {
    if (editDetails) {
      setCard({
        card_id: editDetails.id,
        cardtoken: editDetails.card_number,
        card_name: editDetails.card_name,
        card_expiry_month: editDetails.expiration_month,
        card_expiry_year: editDetails.expiration_year,
      });
    }
  }, [editDetails]);

  useEffect(() => {
    if (responseStatus && responseStatus !== '' && open) {
      $('#cardSubmitButton').attr('disabled', false);
      if (responseStatus === 'cardadded') {
        setAlert(props.Language.crd_a_s ? props.Language.crd_a_s : 'Card successfully added', 'success');
        clearResponse();
        paymentSuccess();
      } else if (responseStatus === 'carddeleted') {
        setAlert(props.Language.crd_d_s ? props.Language.crd_d_s : 'Card successfully deleted', 'success');
        clearResponse();
        paymentSuccess();
      } else {
      }
    } else {
      clearResponse();
    }
  }, [responseStatus]);

  const onSubmit = (e) => {
    e.preventDefault();
    $('#cardSubmitButton').attr('disabled', true);
    if ($('#CardForm').parsley().validate()) {
      if (editDetails) {
        editUserCard(card);
      } else {
        if (card_profileid) {
          addExtraUserCardAuthorize(card);
        } else {
          addUserCardAuthorize(card);
        }
      }

      // paymentWithCard(card);
    } else {
      $('#cardSubmitButton').attr('disabled', false);
      return false;
    }
  };
  const onChange = (e) => setCard(onChangeFunction(e, card));
  // onChangeFunction(e, card) ?  : null;

  return (
    <div className="card-form-wrapper">
      <LAauctionModal
        handleClose={paymentSuccess}
        modaltitle={editDetails ?  props.Language.edt_crd ? props.Language.edt_crd :'EDIT CARD' : props.Language.add_crd ? props.Language.add_crd : 'ADD CARD'}
        open={open}
      >
        <form id="CardForm" data-parsley-validate onSubmit={onSubmit}>
          {/* <div className="card-wrap">
  <img src="/images/card.png" alt="" />
</div> 
<div className="d-flex mt-3 cards-radio-wrap">
  <div className="radio form-group">
    <input type="radio" name="card" checked />
    <label></label>
  </div>
  <div className="radio form-group">
    <input type="radio" name="card" />
    <label></label>
  </div>
  <div className="radio form-group">
    <input type="radio" name="card" />
    <label></label>
  </div>
  <div className="radio form-group">
    <input type="radio" name="card" />
    <label></label>
  </div>
</div> */}
          <div className="row">
            <div className="col-12 form-group">
              <label className="sf-label">{props.Language.crd_hold_name ? props.Language.crd_hold_name : "Card Holder Name" }</label>
              <div className="icon-input">
                <div className="icon-box">
                  <img src="/images/id-card.png" alt="" />
                </div>
                <input
                  className="form-control"
                  onChange={onChange}
                  value={card.cardhname}
                  name="cardhname"
                  data-parsley-required="true"
                  data-parsley-error-message={props.Language.thisir ? props.Language.thisir : 'This value is required'}
                  placeholder={props.Language.crd_hold_name ? props.Language.crd_hold_name : "Card Holder Name" }
                />
              </div>
            </div>
            <div className="col-12 form-group">
              <label className="sf-label">{props.Language.crd_num ? props.Language.crd_num : "Card Number" }</label>
              <div className="icon-input">
                <div className="icon-box">
                  <img src="/images/id-card.png" alt="" />
                </div>
                <input
                  className="form-control numberonly"
                  type="text"
                  name="extracardnumber"
                  value={card.extracardnumber}
                  onChange={onChange}
                  minLength="15"
                  maxLength="16"
                  placeholder={props.Language.crd_num ? props.Language.crd_num : "Card Number" }
                  data-parsley-required="true"
                  data-parsley-error-message={props.Language.evcn ? props.Language.evcn : 'Enter valid card number'}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-6 form-group">
                <label className="sf-label">{props.Language.exp_m ? props.Language.exp_m : "Expiration Month" }</label>
              <div className="icon-input">
                <div className="icon-box">
                  <i className="fa fa-calendar"></i>
                </div>
                <input
                  className="form-control numberonly"
                  type="text"
                  name="extradate"
                  value={card.extradate}
                  onChange={onChange}
                  maxLength="2"
                  data-parsley-min={currentMonth}
                  data-parsley-max="12"
                  data-parsley-error-message={props.Language.exp_m ? props.Language.evecpm : "Enter valid expiration month" }
                  data-parsley-required="true"
                  placeholder="MM"
                />
              </div>
            </div>
            <div className="col-6 form-group">
              <label className="sf-label">{props.Language.exp_y ? props.Language.exp_y : "Expiration Year" }</label>
              <div className="icon-input">
                <div className="icon-box">
                  <i className="fa fa-calendar"></i>
                </div>
                <input
                  className="form-control numberonly"
                  type="text"
                  name="extrayear"
                  value={card.extrayear}
                  onChange={(event) => {
                    onChange(event);
                    changeMonth(event);
                  }}
                  maxLength="4"
                  data-parsley-min={currentYear}
                  data-parsley-max="2100"
                  data-parsley-error-message={props.Language.evecpy ? props.Language.evecpy : "Enter valid expiration year" }
                  data-parsley-required="true"
                  placeholder="YYYY"
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-6 form-group">
              <label className="sf-label">CVV</label>
              <div className="icon-input">
                <div className="icon-box">
                  <i className="fa fa-credit-card"></i>
                </div>
                <input
                  className="form-control numberonly"
                  type="text"
                  name="extracvv"
                  value={card.extracvv}
                  onChange={onChange}
                  maxLength="3"
                  data-parsley-min="000"
                  data-parsley-max="999"
                  data-parsley-error-message={props.Language.evcvv ? props.Language.evcvv : "Enter valid CVV" }
                  data-parsley-required="true"
                  placeholder="CVV"
                />
              </div>
            </div>
          </div>

          {/* <div className="row">
  <div className="col-6 form-group">
    <label className="sf-label">CVV</label>
    <div className="icon-input">
      <div className="icon-box">
        <i className="fa fa-credit-card"></i>
      </div>
      <input
        className="form-control numberonly"
        type="text"
        name="card_expiry_year"
        value={card.card_expiry_year}
        placeholder="Enter Your CVV"
      />
    </div>
  </div>
</div> */}
          {/* <FormGroup>
    <FormControlLabel
      control={<Checkbox checked={save} value="save" color="default" />}
      label="Save this card for future checkouts"
    />
  </FormGroup> */}
          <div className="pt-3 btns-wrap">
            <button
              className="btn btn-green"
              id="cardSubmitButton"
              type="submit"
            >
              {props.Language.svecrd_cap ? props.Language.svecrd_cap : "SAVE CARD" }
            </button>
          </div>
        </form>
      </LAauctionModal>

      {/* <LAauctionModal
        modaltitle="PAYMENT HAS BEEN SUCCESFULLY DONE !"
        open={open}
        handleClose={paymentSuccess}
      >
        <div className="cc-img">
          <img src="/images/credit-card.png" alt="" />
        </div>
        <div className="form-group">
          <div className="ta-paid">Total Amount Paid</div>
          <div className="amt-text">$3450.00</div>
        </div>
        <button
          type="button"
          className="btn btn-green"
          onClick={paymentSuccess}
        >
          THANK YOU
        </button>
      </LAauctionModal> */}
    </div>
  );
};

const mapStateToProps = state => {
  return {
    Language : state.Language.languages
  }
}

export default connect(mapStateToProps)(AddCard);
