export const EDIT_PROFILE = 'EDIT_PROFILE';
export const GET_EDIT_PROFILE = 'GET_EDIT_PROFILE';
export const CHANGE_PWD = 'CHANGE_PWD';

export const GET_PFR = 'GET_PFR';

export const RESPONSE_STATUS = 'RESPONSE_STATUS';
export const UPDATE_USER_DYN_PROFILE = 'UPDATE_USER_DYN_PROFILE';
export const CLEAR_RESPONSE = 'CLEAR_RESPONSE';
export const GET_MSG_USER_LIST = 'GET_MSG_USER_LIST';
export const GET_ONE_MSG_USER = 'GET_ONE_MSG_USER';

export const GET_STATIC_PAGE = 'GET_STATIC_PAGE';
export const GET_BLOG_LIST = 'GET_BLOG_LIST';
export const GET_SINGLE_BLOG = 'GET_SINGLE_BLOG';

// export const VIEW_PROFILE = 'VIEW_PROFILE';
