import React from 'react'; 
import Layout from '../../Components/Layout';  
import Accordion from '../../Components/Search/Accordian';

const BidderFaq = () => {  

    return (
        <Layout>
            <div className="container mt-5 mb-5 faq-wrapper">
                <h1 className="main-head mb-4 clearfix">BIDDER FAQ</h1>
                <div className="faq-wrapper">
                    <Accordion title="How does the bidding work? ">
                        <div className="card-body">
                            <p>All bids placed are in the max bid format. A max bid is the highest amount that you are willing to pay for an item and is kept private from other bidders until that amount has been exceeded. Bids are automatically placed on your behalf against other bidders until your maximum amount is reached.
                            </p>
                            <p>Examples:
                            </p>
                            <ul>
                            <li>Starting bid price for an item is $2.00. Bidder 55555 places a $100.00 bid on an item and no other bids were received and the auction closes. Bidder 55555 will remain the high bidder at $2.00 and the rest of his max bid will be unused.
                            </li>
                            <li>Starting bid price for an item is $2.00. Bidder 55555 places a $100.00 bid on an item and becomes the high bidder at $2.00. Bidder 66666 then places a bid of $50.00 and is notified "Bid Received; Higher Bid Exists." Bidder 55555 will remain the high bidder at $52.00 (Assuming the minimum bid increment is at $2.00).
                            </li>
                            </ul>
                            <p>All bids must contain a decimal point preceded by at least one digit and be followed by two digits. eg: 2.55 (no dollar signs or comma separators)
                            </p>
                        </div>
                    </Accordion>
                    <Accordion title="What if an item does not meet the reserve?">
                        <div className="card-body">
                            <p>
                                Items that fail to meet the reserve are not sold and revert back to the seller. Seller information is not provided to bidders.
                            </p>
                        </div>
                    </Accordion>
                    <Accordion title="What if two bidders have the same max bid?">
                        <div className="card-body">
                            <p>
                                The first bidder to submit the max bid will remain the highest bidder.
                            </p>
                        </div>
                    </Accordion>
                    <Accordion title="Can I raise my max bid if I am still the high bidder?">
                        <div className="card-body">
                            <p>
                                The first bidder to submit the max bid will remain the highest bidder.
                            </p>
                        </div>
                    </Accordion> 
                    <Accordion title="Where can I place bids? ">
                        <div className="card-body">
                                <p>There are three locations on the site where an auction item may receive bids.</p>
                            <ul>
                                <li>
                                    Auction Listing page
                                </li>
                                <li>
                                    Auction Item Detail Page
                                </li>
                                <li>
                                    Bidder Dashboard
                                </li>
                            </ul>
                        </div>
                    </Accordion>
                    <Accordion title="Can I delete a bid?">
                        <div className="card-body">
                            <p>
                                No, by placing a bid you are entering into a contract to purchase. Use caution while placing bids and verify the amounts before placing a bid.
                            </p>
                        </div>
                    </Accordion>
                    <Accordion title="What is an outbid notice?">
                        <div className="card-body">
                            <p>
                                An outbid notice will alert you that your bid has been exceeded. Outbid notices are sent to your registered email address with a direct link to the item that you were bidding on. You may opt out of these notices by visiting your account settings
                            </p>
                        </div>
                    </Accordion>
                    <Accordion title="I bid an amount, but another bidder was still in the lead at the exact same price?">
                        <div className="card-body">
                            <p>
                                Your bid was accepted, however most likely another bidder already had already placed a bid for the exact same price. In the example below, bidder 225743 had the exact same bid as the second place bidder (159287). Since BN 225743 placed his bid first, they remain in the lead.
                            </p>
                            <img src="/images/bid-accepted1.png" alt="" />
                            <img src="/images/bid-history.png" alt="" />
                        </div>
                    </Accordion>
                    <h2 className="bidderTitle">FAQ'S Important Before You Bid</h2>
                    <p>Los Angeles Auction Depot</p>
                    <p>It's simple! You bid. You win. You pick up and pay during the auction removal date.
                    </p>
                    <p>Winning bidders are responsible for prompt payment and removal during the specified removal dates. Failure to do so could result in penalties and suspension of bidding privileges on <a href="www.Losangelesauctiondepot.com">www.Losangelesauctiondepot.com</a>
                    </p>
                    <p>Each auction is managed by Los Angeles Auction Depot . It is extremely important that you understand the following information for each auction PRIOR to bidding.
                    </p>
                    <h2 className="bidderTitle">Terms & Conditions</h2>
                    <p>In addition to the site terms LAAD writes customized auction terms for every auction. Bidders are required to indicate they understand and will abide by the auction terms prior to bidding on the auction. Auction specific terms include details like acceptable forms of payments, sales tax information, removal requirements, and any other important information bidders need to know about that particular auction. Individual auction terms are found on the bottom of the page within each auction:</p>
                    <p>
                        The following sections will be explained below so you know what to look for.
                    </p>
                    <p>
                        <b>Details:</b> This section includes important auction dates and any notes that the LAAD wants you to be aware of.
                    </p>
                    <p><b>Inspections Dates:</b> Available dates and times to view items to verify condition.</p>
                    <p>
                        <b>Closing Date:</b> When the auction is scheduled to begin closing. ITEMS WILL NOT CLOSE UNTIL A BID HAS NOT BEEN RECEIVED FOR 3 MINUTES (this is referred to as extended bidding).
                    </p>
                    <p>
                        <b>Removal Date:</b> By bidding on an auction, you agree to pick up and pay for your items at the auction location during this period. There are no exceptions to this rule unless it has been confirmed by Email with LAAD Main Office Personal Only.
                    </p>
                    <p>
                        <b>Terms:</b> You agree to the auction specific terms before bidding on any auction. Be sure to read these terms as important information such as accepted payment types will be explained here.
                    </p>
                    <p>
                        <b>Directions:</b> This is the auction location where you are required to pick up your items during the removal period. Questions regarding an auction or item need to be directed to the managing affiliate at the number listed on this section.
                    </p>
                    <p>
                        <b>Shipping:</b> Shipping can not be assumed but is available on some auctions. Check for specific information regarding the shipping process or call the LAAD Main Office Before you bid on questions regarding shipping.
                    </p>
                    <h2 className="bidderTitle">Important Tips and Info</h2>
                    <ol>
                        <li>
                            Use the inspection times! Each auction has a scheduled inspection date and time or allows you to make an appointment. Make sure you take advantage of these days/hours to make sure the item is exactly what you are looking for. LAAD Will do their best to accurately describe and photograph items, but each item is sold as-is, where-is and we leave it up to the bidders to determine each item's value.
                        </li>
                        <li>
                            Bid with the intent to purchase. Bids will not be removed and you are responsible to pay for any items you have won....even if you change your mind.
                        </li>
                        <li>
                            Every bid is placed in a max bid format. A max bid is the highest amount that you are willing to pay for an item and is kept private from other bidders until that amount has been exceeded.
                        </li>
                        <li>
                            No sniping! Every item is extended a 3 minute overtime after the last bid has been placed. Make sure you are using the refresh button to get the most accurate status of an item.
                        </li>
                        <li>
                            Use your dashboard to watch items, bid, and see the invoices for items that you have won.
                        </li>
                        <li>
                            A buyer’s premium is added on top of the final bid price for each item. This percentage can be found in the terms for each auction, but is typically 15% for items that sell below $4.999.99 and 10% for items that sell above $5,000. Buyer's premium is capped at $2,000/item (real estate excluded). For example, a $100.00 winning bid will equal an invoice amount of $118 plus applicable sales taxes
                        </li>
                    </ol> 
                    <h2 className="bidderTitle">What is a Reserve?</h2>
                    <p>A reserve is the amount that bidding must reach before an item will be sold. Items that have a reserve will state Reserve Not Met underneath the current bid price until that bid price has been met. Once a reserve price has been exceeded, a notice will state Reserve Has Been Met.</p>
                    <p>See the following examples: </p>
                    <img src="/images/reserver-met.png" alt="" className="mb-3" />
                    <p>
                        Reserve has not been met, this item will not sell unless another bid exceeds the reserve price or the reserve is lowered by the affiliate.
                    </p>
                    <p>*In rare cases an item will be sold "on seller approval," meaning that the highest bid will have to be approved by the seller. This is different than a Reserve auction, and in this case, there is no notification of the reserve status and this will be disclosed in the auction terms.</p>
                    <h2 className="bidderTitle">How do I find out how much a reserve is?</h2>
                    <p>
                        The reserve is generally known only to the affiliate running the auction and is not disclosed to bidders. Can a reserve be lowered?
                    </p>
                    <p>
                        Affiliates may lower a reserve at any time before or after an auction closes. If the reserve is lowered while the auction is in progress, the applicable reserve message will be updated (Reserve Not Met or Reserve Has Been Met); the asset will sell to the high bidder if the lowered reserve amount is met. If the reserve is lowered after the auction closes, the highest bidder at auction close will receive a winning bidder invoice.
                    </p>
                     
                    <h2 class="atHead">So you won an item(s), that's great! So now what can you expect?</h2>
                    <p>
                        Winning bidder invoices will be updated to your dashboard within 10 minutes of the auction closing. Here you can view and download your winning bidder invoice. It is recommended that you print your invoice off as it will include pertinent location and contact information.
                    </p>
                    <p>
                        <b>IMPORTANT:</b> Winning bidder notifications are emailed at the conclusion of each auction. However, some email services can be unreliable and put these message into a Junk or Spam folder. Always check your Dashboard for a <u>winning bidder invoice</u> if you have been actively bidding on an auction.
                        At this time you should be well aware of the auction location and the designated time to remove your items (found on the invoice as well as the bottom of every page within the auction on LAAUCTIONDEPOT.COM. You can now go pick up your items! Pay close attention to the following items:
                    </p>
                    {/* <img src="/images/chris-db.png" alt="" className="mb-3" /> */}
                    <p>
                        <b>Location:</b> Look for directional signs around the auction location and double check the auction location prior to leaving. Call LAAD if you have issues finding the location.
                    </p>
                    <p>
                        <b>Removal:</b> This is the time you are expected to pay for and pick up the items. This is found on the bottom of each auction page and on your invoice. Removal dates vary for each auction so verify prior to bidding.
                    </p>
                    <p>
                        <b>Payment Types Accepted:</b> Check the auction terms to verify the payment types that the will accept. Cash and Credit Card are almost always accepted. Checks, Wire Transfers, and other payment types will vary by the Auction. Payment is due to At the Close of the Action or the auction Pick up day.
                        Contact Information: Contact LAAD the auction if you have specific questions. Phone numbers located within the auction page and on your invoice.
                    </p>
                    <p>
                        <b>Shipping:</b> If you require shipping and the auction provides it, follow the instructions on the shipping tab of the auction. Be sure to call the contact number on your invoice if you have specific questions about the removal or shipping.
                    </p>
                    <p>
                        <b>Late Removals:</b> Never assume that late removals are possible. Most auctions have strict timelines that make late removals impossible. Please be sure to follow the terms of the auction to keep your bidding account in good standing on LAAUCTIONDEPOT.COM . If in doubt, contact the LAAD office the auction to clarify.
                    </p>
                    <h2 className="bidderTitle">How do I read my invoice?</h2>
                    <p>
                        When you are the winning bidder on an item, you will access your invoices on your bidder dashboard. It is recommended to print off this invoice prior to going to an auction removal, however it is not required as the affiliate auctioneer should have a copy waiting for you. Below you will see an example of an invoice and the information provided.
                    </p>
                    <img src="/images/bidder-1799.png" alt="" className="mb-3" />
                    <h2 className="bidderTitle">Your Invoice Explained:</h2>
                    <ul>
                    <li>
                        This is your bidder number, it is a unique number assigned to every new bidder. You will be able to login to www.LAAuctiondepot.com using this number or your email address.
                    </li>
                    <li>
                        You will find the information for who contacted and managed the auction, as well as the contact phone number to call with questions. Call this number with questions regarding the removal and payment.
                    </li>
                    <li>
                        The auction address and removal information will be provided in this section. Unless shipping or other arrangements have been made, you are expected to be at this location during this timeframe.
                        The items you won will be listed in this section.
                    </li>
                    <li>
                        This is the total amount due, including applicable Buyer's Premium and sales tax. Sales tax is calculated off the total bid price and applicable buyer's premium.
                    </li>
                    </ul>
                </div>
            </div>
        </Layout>
    )
}

export default BidderFaq