/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Layout from '../../Components/Layout';

import DashboardHeader from '../../Components/DashboardHeader';
import UserContext from '../../context/user/userContext';
import AuthContext from '../../context/auth/authContext';
import { onChangeFunction } from '../../Common/Components';
import AlertContext from '../../context/alert/alertContext';
import { dateFormatFront } from '../../Common/Components';
import $ from 'jquery';
import {connect} from 'react-redux';

const Inbox = (props) => {
  const userContext = useContext(UserContext);
  const authContext = useContext(AuthContext);
  const alertContext = useContext(AlertContext);

  const { setAlert } = alertContext;
  const { user } = authContext;
  const {
    sendUserMessages,
    responseStatus: responseStatusUser,
    clearResponse: clearResponseUser,
    getOneUserBuyerMessages,
    buyerseller_messages,
  } = userContext;

  const [search] = useState({
    chat_id: props.match.params.id,
    asc_order: '1',
  });

  const [sendMsg, setSendMsg] = useState({
    to_id: props.match.params.id,
    message: '',
  });
  const onChangeMessage = (e) => setSendMsg(onChangeFunction(e, sendMsg));
  useEffect(() => {
    if (responseStatusUser && responseStatusUser !== '') {
      if (responseStatusUser === 'message_successfullysend') {
        setAlert(props.Language.msg_s_s ? props.Language.msg_s_s : 'Message successfuly sent', 'success');
        clearResponseUser();
        setSendMsg({ ...sendMsg, message: '' });
        getOneUserBuyerMessages(search);
      } else if (responseStatusUser === 'message_notsend') {
        setAlert(props.Language.msg_n_s ? props.Language.msg_n_s : 'Message not sent!', 'error');
        clearResponseUser();
        getOneUserBuyerMessages(search);
      } else {
        setAlert(responseStatusUser, 'error');
        clearResponseUser();
      }
    } else {
      clearResponseUser();
    }
  }, [responseStatusUser]);

  const sendMessage = (e) => {
    e.preventDefault();
    if ($('#ContactForm').parsley().validate()) {
      sendUserMessages(sendMsg);
    } else {
      return false;
    }
  };

  useEffect(() => {
    if (props.match.params.id) {
      getOneUserBuyerMessages(search);
    } else {
    }
  }, []);

  return (
    <Layout props={props}>
      <DashboardHeader title="MESSAGES" />
      <Container className="dashboard-wrapper">
        <Grid container spacing={3}>
          {buyerseller_messages.length > 0 && (
            <div className="msg-body">
              {buyerseller_messages.map((item, index) => {
                return (
                  <>
                    {user.id === item.from_id && (
                      <span className="reciver-msg">
                        <h6>{item.message}</h6>
                        <p>({dateFormatFront(item.date_sent)})</p>
                      </span>
                    )}
                    {user.id === item.to_id && (
                      <span className="sender-msg">
                        <h6>{item.message}</h6>
                        <p>({dateFormatFront(item.date_sent)})</p>
                      </span>
                    )}
                  </>
                );
              })}
            </div>
          )}
          <Grid item sm={12}>
            <form id="ContactForm" data-parsley-Validate onSubmit={sendMessage}>
              <div className="form-group">
                <textarea
                  autoFocus
                  className="form-control"
                  value={sendMsg.message}
                  onChange={onChangeMessage}
                  name="message"
                  data-parsley-required="true"
                  placeholder="Enter message to send"
                />
              </div>
              <button className="btn btn-green" type="submit">
                SEND MESSAGE
              </button>
            </form>
          </Grid>
        </Grid>
      </Container>
    </Layout>
  );
};

const mapStateToProps = state => {
  return {
    Language : state.Language.languages
  }
}

export default connect(mapStateToProps)(Inbox);
