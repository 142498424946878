/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useContext, useEffect } from 'react';

import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Layout from '../../Components/Layout';
import { Link, Redirect } from 'react-router-dom';
import $ from 'jquery';
import { onChangeFunction } from '../../Common/Components';
import AlertContext from '../../context/alert/alertContext';
import UserContext from '../../context/user/userContext';
import AuthContext from '../../context/auth/authContext';
import {connect} from 'react-redux';

const ForgotPassword = (props) => {
  const alertContext = useContext(AlertContext);
  const authContext = useContext(AuthContext);
  const userContext = useContext(UserContext);

  const { setAlert } = alertContext;
  const {
    forgotPassword,
    responseStatus, 
    clearResponse,
  } = userContext;
  const { isAuthenticated } = authContext;

  
  const [toHome, settoHome] = useState(false);
  const [pass, setPass] = useState({
    email: '',
  });

  useEffect(() => {
    if (isAuthenticated) {
      settoHome(true);
    } else {
    }
  }, [isAuthenticated]);
  const onChange = (e) => setPass(onChangeFunction(e, pass));

  useEffect(() => {
    if (responseStatus && responseStatus !== '') {
      $('#formResetButton').attr('disabled', false);
      if (responseStatus === 'forgot_password_successful') {
        setAlert(props.Language.mail_hsbstrp ? props.Language.mail_hsbstrp : 'Mail has been sent to reset password', 'success');
        clearResponse();
        settoHome(true);
      } else {
        setAlert(responseStatus, 'error');
        clearResponse();
      }
    } else {
      clearResponse();
    }
  }, [responseStatus]);

  const onSubmit = (e) => {
    e.preventDefault();
    $('#formResetButton').attr('disabled', true);
    if ($('#ChangePwdForm').parsley().validate()) {
      forgotPassword(pass);
    } else {
      $('#formResetButton').attr('disabled', false);
      return false;
    }
  };

  return (
    <Layout props={props}>
      {toHome ? <Redirect to="/" /> : null}
      <div className="container">
        <Grid className="formBox">
          <Grid className="lFormHead">
            <Link to="/">
              <img src="/images/auth-logo.png" alt="" />
            </Link>
          </Grid>
          <Grid className="lFormBody">
            <h4 className="main-head">
  <span className="hbox">{props.Language.fgtCaps}</span>
  <span>{props.Language.p_sCaps}</span>
            </h4> 
  <p class="auth-note">{props.Language.rest_y_p_h ? props.Language.rest_y_p_h : "Reset your password here"}</p>
            <form id="ChangePwdForm" data-parsley-validate onSubmit={onSubmit}>
              <Grid container>
                <Grid item xs={12}>
                  <TextField
                    autoFocus={true}
                    name="email"
                    type="email"
                    onChange={onChange}
                    value={pass.email}
                    className="form-control fpwd-input"
                    placeholder={`${props.Language.email} ${props.Language.address}`}
                    inputProps={{
                      'data-parsley-required': 'true',
                      "data-parsley-error-message":props.Language.thisir ? props.Language.thisir : 'This value is required'
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <button className="btn btn-green" id="formResetButton">
                  {props.Language.stim}
                  </button>
                </Grid>
              </Grid>
            </form>
          </Grid>
        </Grid>
      </div>
    </Layout>
  );
};

const mapStateToProps = state => {
  return {
    Language : state.Language.languages
  }
}

export default connect(mapStateToProps)(ForgotPassword);
