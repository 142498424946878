/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useContext, useEffect } from 'react';
import $ from 'jquery';
import './profile.css';
import { Redirect } from 'react-router-dom';
import { onChangeFunction } from '../../Common/Components';
import AlertContext from '../../context/alert/alertContext';
import UserContext from '../../context/user/userContext';
// import FormGroup from '@material-ui/core/FormGroup';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import {connect} from 'react-redux';

const PreferenceForm = (props) => {
  const button = $('#formSubmitButton');
  const alertContext = useContext(AlertContext);
  const userContext = useContext(UserContext);

  const { setAlert } = alertContext;
  const {
    updatepreference,
    responseStatus,
    getpreference,
    preference,
    clearResponse,
  } = userContext;

  const [toHome, settoHome] = useState(false);

  const [notify, setNotify] = useState({
    watchlistemail: false,
    biditememail: false,
    outbidemail: false,
    wonitememail: false,
    savedsearchemail: false,
    ordershippedemail: false,
    watchlistsms: false,
    biditemsms: false,
    outbidsms: false,
    wonitemsms: false,
    savedsearchsms: false,
    ordershippedsms: false,
    notify_id: 0,
  });

  const onChange = (e) => setNotify(onChangeFunction(e, notify));

  useEffect(() => {
    if (responseStatus && responseStatus !== '') {
      if (responseStatus === 'preference_updated_successful') {
        setAlert(props.Language.prefer_s_c ? props.Language.prefer_s_c : 'Preference Successfully updated', 'success');
        clearResponse();
        settoHome(true);
      }
    } else {
      clearResponse();
    }
  }, [responseStatus]);

  useEffect(() => {
    getpreference();
  }, [responseStatus]);

  useEffect(() => {
    console.log(preference);
    if (preference.length > 0) {
      let NotificationEmail = preference[0].email.split(',');
      let NotificationSMS = preference[0].sms.split(',');
      setNotify({
        watchlistemail: NotificationEmail.indexOf('1') === -1 ? false : true,
        biditememail: NotificationEmail.indexOf('2') === -1 ? false : true,
        outbidemail: NotificationEmail.indexOf('3') === -1 ? false : true,
        wonitememail: NotificationEmail.indexOf('4') === -1 ? false : true,
        savedsearchemail: NotificationEmail.indexOf('5') === -1 ? false : true,
        ordershippedemail: NotificationEmail.indexOf('6') === -1 ? false : true,
        watchlistsms: NotificationSMS.indexOf('1') === -1 ? false : true,
        biditemsms: NotificationSMS.indexOf('2') === -1 ? false : true,
        outbidsms: NotificationSMS.indexOf('3') === -1 ? false : true,
        wonitemsms: NotificationSMS.indexOf('4') === -1 ? false : true,
        savedsearchsms: NotificationSMS.indexOf('5') === -1 ? false : true,
        ordershippedsms: NotificationSMS.indexOf('6') === -1 ? false : true,
        notify_id: preference[0].id,
      });
    }
  }, [preference]);

  const onSubmit = (e) => {
    e.preventDefault();
    let email = [];
    let sms = [];
    if (notify.watchlistemail) {
      email.push('1');
    }
    if (notify.watchlistsms) {
      sms.push('1');
    }
    if (notify.biditememail) {
      email.push('2');
    }
    if (notify.biditemsms) {
      sms.push('2');
    }
    if (notify.outbidemail) {
      email.push('3');
    }
    if (notify.outbidsms) {
      sms.push('3');
    }
    if (notify.wonitememail) {
      email.push('4');
    }
    if (notify.wonitemsms) {
      sms.push('4');
    }
    if (notify.savedsearchemail) {
      email.push('5');
    }
    if (notify.savedsearchsms) {
      sms.push('5');
    }
    if (notify.ordershippedemail) {
      email.push('6');
    }
    if (notify.ordershippedsms) {
      sms.push('6');
    }

    let datatoSent = {
      email_settings: email.toString(','),
      sms_settings: sms.toString(','),
      notify_id: notify.notify_id,
    };
    console.log(datatoSent);
    updatepreference(datatoSent);
  };

  return (
    <div className="preferences-form-wrapper">
      {toHome ? <Redirect to="/" /> : null}
      {/* <EditUser /> */}
      <form id="ChangePref" data-parsley-Validate onSubmit={onSubmit}>
        <table className="table stab savesearch">
          <thead>
            <tr>
              <th>{props.Language.notify_buy ? props.Language.notify_buy : "Notification to buyer" }:</th>
              <th>{props.Language.email  ? props.Language.email   : "Email" }</th>
              <th>SMS</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="account_p_lbl">{props.Language.iatw  ? props.Language.iatw   : "Item added to watchlist" }</td>
              <td className="account_p_lbl">
                <Checkbox
                  checked={notify.watchlistemail}
                  name="watchlistemail"
                  onChange={(event, value) => {
                    onChange(event);
                  }}
                  value="true"
                  color="default"
                />
              </td>
              <td className="account_p_lbl">
                <Checkbox
                  checked={notify.watchlistsms}
                  name="watchlistsms"
                  onChange={(event, value) => {
                    onChange(event);
                  }}
                  value="true"
                  color="default"
                />
              </td>
            </tr>
            <tr>
                <td className="account_p_lbl">{props.Language.yuboi ? props.Language.yuboi : "You have bid on item"}</td>
              <td className="account_p_lbl">
                <Checkbox
                  checked={notify.biditememail}
                  name="biditememail"
                  onChange={(event, value) => {
                    onChange(event);
                  }}
                  value="true"
                  color="default"
                />
              </td>
              <td className="account_p_lbl">
                <Checkbox
                  checked={notify.biditemsms}
                  name="biditemsms"
                  onChange={(event, value) => {
                    onChange(event);
                  }}
                  value="true"
                  color="default"
                />
              </td>
            </tr>
            <tr>
                <td className="account_p_lbl">{props.Language.ywotbot ? props.Language.ywotbot : "You were outbid on item" }</td>
              <td className="account_p_lbl">
                <Checkbox
                  checked={notify.outbidemail}
                  name="outbidemail"
                  onChange={(event, value) => {
                    onChange(event);
                  }}
                  value="true"
                  color="default"
                />
              </td>
              <td className="account_p_lbl">
                <Checkbox
                  checked={notify.outbidsms}
                  name="outbidsms"
                  onChange={(event, value) => {
                    onChange(event);
                  }}
                  value="true"
                  color="default"
                />
              </td>
            </tr>
            <tr>
                <td className="account_p_lbl">{props.Language.ywanite ? props.Language.ywanite : "You won an item"}</td>
              <td className="account_p_lbl">
                <Checkbox
                  checked={notify.wonitememail}
                  name="wonitememail"
                  onChange={(event, value) => {
                    onChange(event);
                  }}
                  value="true"
                  color="default"
                />
              </td>
              <td className="account_p_lbl">
                <Checkbox
                  checked={notify.wonitemsms}
                  name="wonitemsms"
                  onChange={(event, value) => {
                    onChange(event);
                  }}
                  value="true"
                  color="default"
                />
              </td>
            </tr>
            <tr>
                <td className="account_p_lbl">{props.Language.svdsrch ? props.Language.svdsrch+"s" : "Saved searches"}</td>
              <td className="account_p_lbl">
                <Checkbox
                  checked={notify.savedsearchemail}
                  name="savedsearchemail"
                  onChange={(event, value) => {
                    onChange(event);
                  }}
                  value="true"
                  color="default"
                />
              </td>
              <td className="account_p_lbl">
                <Checkbox
                  checked={notify.savedsearchsms}
                  name="savedsearchsms"
                  onChange={(event, value) => {
                    onChange(event);
                  }}
                  value="true"
                  color="default"
                />
              </td>
            </tr>
            <tr>
                <td className="account_p_lbl">{props.Language.ohasship ? props.Language.ohasship : "Order has shipped" }</td>
              <td className="account_p_lbl">
                <Checkbox
                  checked={notify.ordershippedemail}
                  name="ordershippedemail"
                  onChange={(event, value) => {
                    onChange(event);
                  }}
                  value="true"
                  color="default"
                />
              </td>
              <td className="account_p_lbl">
                <Checkbox
                  checked={notify.ordershippedsms}
                  name="ordershippedsms"
                  onChange={(event, value) => {
                    onChange(event);
                  }}
                  value="true"
                  color="default"
                />
              </td>
            </tr>
          </tbody>
        </table>
        <div className="pt-3 btns-wrap">
          <button type="submit" className="btn btn-green" id="formSubmitButton">
                {props.Language.save ? props.Language.save : "SAVE"}
          </button>
        </div>
      </form>
    </div>
  );
};

const mapStateToProps = state =>{
  return {
    Language : state.Language.languages
  }
}


export default connect(mapStateToProps)(PreferenceForm);
