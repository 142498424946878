/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useContext, useEffect } from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Layout from '../../Components/Layout';
import { Link } from 'react-router-dom';
import DashboardHeader from '../../Components/DashboardHeader';
import MakePayment from '../../Components/Payment/makePayment';
import UserContext from '../../context/user/userContext';
import AlertContext from '../../context/alert/alertContext';
import AuthContext from '../../context/auth/authContext';
import { dateFormatFront } from '../../Common/Components';
import LAauctionModal from '../../Components/Modal';
import {connect} from 'react-redux';

const Membership = (props) => {
  const userContext = useContext(UserContext);
  const alertContext = useContext(AlertContext);
  const authContext = useContext(AuthContext);

  const { user, membership, loadUser, isAuthenticated } = authContext;
  const [open, setOpen] = useState(false);
  const openPayment = () => setOpen(!open);

  const [openmember, setOpenMember] = useState(false);
  const openMember = () => setOpenMember(!openmember);

  const {
    responseStatus,
    updateUserProfileDynamic,
    clearResponse,
  } = userContext;
  const { setAlert } = alertContext;
  const onSuccessFunction = () => {
    updateUserProfileDynamic({ renewalYear: 1, userdetails: { role: 3 } });
  };
  const onChangeMembershipFunction = () => {
    updateUserProfileDynamic({ userdetails: { role: 1 } });
  };

  useEffect(() => {
    if (responseStatus && responseStatus !== '') {
      if (responseStatus === 'user_updated_successfull') {
        setAlert(props.Language.account_u_s ? props.Language.account_u_s : 'Account updated successfully', 'success');
        clearResponse();
        loadUser();
        if (openmember) {
          openMember();
        }
      } else {
        setAlert(responseStatus, 'error');
        clearResponse();
      }
    } else {
      clearResponse();
    }
  }, [responseStatus]);

  return (
    <Layout props={props}>
      {isAuthenticated ? (
        <DashboardHeader title="PRO" subtitle="MEMBERSHIP UPGRADE" />
      ) : null}
      {isAuthenticated ? (
        <>
          {user.role !== '3' ? (
            <>
              <Container className="dashboard-wrapper">
                <h4 className="pmem-head">
                  {props.Language.laaddpm} <br />
                  {props.Language.vip_cust_ser}
                </h4>
                <table className="table table-sm table-bordered table-hover pmem-table">
                  <tbody>
                    <tr>
                      <td>1 Year</td>
                      {/* <td>2 Year</td> */}
                    </tr>
                    <tr>
          <td>$100 {props.Language.peryr}</td>
                      {/* <td>$150 per year</td> */}
                    </tr>
                    <tr>
          <td>$100 {props.Language.rec_eve_yr}</td>
                      {/* <td>$150 recurring every 2 years</td> */}
                    </tr>
                    <tr>
                      <td>
                        <button className="btn btn-green" onClick={openPayment}>
                          {props.Language.bit_nw} 
                        </button>
                        {user.role !== '1' ? (
                          <MakePayment
                            open={open}
                            openPayment={openPayment}
                            amount={100}
                            onSuccess={onSuccessFunction}
                            payType="membership_pay"
                          />
                        ) : null}
                      </td>
                      {/* <td>
                      <button className="btn btn-green">
                          BUY IT NOW
                      </button> */}
                      {/* </td> */}
                    </tr>
                  </tbody>
                </table>
                <Grid container spacing={3}>
                  <Grid item sm={8}>
                    <h5 className="pm-benfits-head">{props.Language.pmbenef}</h5>

                    <h6 className="pm-benfits-shead">{props.Language.lwium}</h6>
                    <p>{props.Language.pre_lo_prmem}</p>
                    <p>
                      {props.Language.when_u_win}
                    </p>
                    <p>
                     {props.Language.itsimpleneedhlp}
                    </p>

                    <h6 className="pm-benfits-shead">
                    {props.Language.pmemvhlp}{' '}
                    </h6>
                    <p>
                      {props.Language.when_th_phar}
                    </p>

                    <h6 className="pm-benfits-shead">
                      {props.Language.site_aupr}
                    </h6>
                    <p>
                      {props.Language.accsite}
                    </p>

                    <h6 className="pm-benfits-shead">
                      {props.Language.jopbb}
                    </h6>
                    <p>
                     {props.Language.we_pr_buy}
                    </p>
                    <h6 className="pm-benfits-shead">
                      {props.Language.vip_site_pick}
                    </h6>
                    <p>
                      {props.Language.pbwh}
                    </p>
                    <p>
                      {props.Language.vip_coff_sn}
                    </p>
                  </Grid>
                  <Grid item sm={4}>
                    <h5 className="pm-benfits-head">{props.Language.mem_type}</h5>

                    <div className="card text-center mtop-25">
                      <div className="mc-heading"></div>
                      <div className="card-body">
                        <h4 className="card-title">
                          {user.first_name} {user.last_name}
                        </h4>
                        <p className="card-text">
                          <b>{props.Language.mem_sin ? props.Language.mem_sin : "Member Since"}</b> March 09, 2019
                        </p>
                        {user.role === '1' ? (
                          <>
                            <div className="form-group">
                              <button
                                class="btn btn-secondary"
                                onClick={openPayment}
                              >
                                {props.Language.upip} 11
                              </button>
                            </div>
                            <MakePayment
                              open={open}
                              openPayment={openPayment}
                              amount={100}
                              onSuccess={onSuccessFunction}
                              payType="membership_pay"
                            />
                          </>
                        ) : (
                          <div className="form-group">
                            <button
                              class="btn btn-primary"
                              onClick={openMember}
                            >
                              {props.Language.chmemty} 
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </Container>{' '}
            </>
          ) : (
            <Container className="dashboard-wrapper">
              <Grid container spacing={3}>
                <Grid item sm={3}></Grid>
                <Grid item sm={6}>
          <h5 className="pm-benfits-head">{props.Language.memtypeproo}</h5>
                  <div className="card text-center mtop-25">
                    <div className="mc-heading"></div>
                    <div className="card-body">
                      <h4 className="card-title">
                        {user.first_name} {user.last_name}
                      </h4>
                      <p className="card-text">
                        <b>{props.Language.mem_sin}: </b>
                        {dateFormatFront(membership.added_date)}
                      </p>
                      <p className="card-text">
                        <b>Expires on:</b>
                        {dateFormatFront(membership.renewal_date)}
                      </p>
                      {membership.status !== 'active' ? (
                        <>
                          <div className="form-group">
                            <button
                              class="btn btn-secondary"
                              onClick={openPayment}
                            >
                              {props.Language.rnpyn}
                            </button>
                          </div>
                          <MakePayment
                            open={open}
                            openPayment={openPayment}
                            amount={100}
                            onSuccess={onSuccessFunction}
                            payType="membership_renewal"
                          />
                        </>
                      ) : (
                        <> </>
                        // <div className="form-group">
                        //   <button class="btn btn-primary" onClick={openMember}>
                        //     CHANGE MEMBERSHIP TYPE
                        //   </button>
                        // </div>
                      )}
                      {/* <span className="lbm-text">Log in for Basic Membership</span> */}
                    </div>
                  </div>
                </Grid>
              </Grid>
            </Container>
          )}
          <LAauctionModal
            handleClose={openMember}
            modaltitle={props.Language.change_mem ? props.Language.change_mem :  "CHANGE MEMBERSHIP"}
            open={openmember}
          >
            <>
              {/* <NavLink to="/payments">ADD CARD</NavLink> */}
              <span>
                {props.Language.areyousure}
              </span>
              <br />
              <br />
              <button
                className="btn btn-green"
                onClick={onChangeMembershipFunction}
              >
                {props.Language.cnf}
              </button>
            </>
          </LAauctionModal>
        </>
      ) : (
        <>
          {' '}
          <Container className="dashboard-wrapper">
            <h4 className="pmem-head">
              {props.Language.laaddpm} <br />
              {props.Language.vip_cust_ser}
            </h4>
            <table className="table table-sm table-bordered table-hover pmem-table">
              <tbody>
                <tr>
                  <td>1 Year</td>
                  {/* <td>2 Year</td> */}
                </tr>
                <tr>
      <td>$100 {props.Language.peryr}</td>
                  {/* <td>$150 per year</td> */}
                </tr>
                <tr>
      <td>$100 {props.Language.rec_eve_yr}</td>
                  {/* <td>$150 recurring every 2 years</td> */}
                </tr>
                <tr>
                  <td>
                    <Link to={`/login`} className="btn btn-green">
                      {props.Language.bit_nw}
                    </Link>
                  </td>
                  {/* <td>
              <button className="btn btn-green">
                  BUY IT NOW
              </button> */}
                  {/* </td> */}
                </tr>
              </tbody>
            </table>
            <Grid container spacing={3}>
              <Grid item sm={12}>
            <h5 className="pm-benfits-head">{props.Language.pmbenef}</h5>

            <h6 className="pm-benfits-shead">{props.Language.lwium}</h6>
            <p>{props.Language.pre_lo_prmem}</p>
                <p>
                  {props.Language.when_u_win}
                </p>
                <p>
                  {props.Language.itsimpleneedhlp}
                </p>

                <h6 className="pm-benfits-shead">{props.Language.pmemvhlp} </h6>
                <p>
                  {props.Language.when_the_phnp}
                </p>

            <h6 className="pm-benfits-shead">{props.Language.vip_ste}</h6>
                <p>
                  {props.Language.acchrs}
                </p>

            <h6 className="pm-benfits-shead">{props.Language.joinpbblog}</h6>
                <p>
                  {props.Language.we_pbrblog}
                </p>
            <h6 className="pm-benfits-shead">{props.Language.vip_stpickup}</h6>
                <p>
                 {props.Language.pbwnpb}
                </p>
                <p>
                 {props.Language.vip_coff}
                </p>
              </Grid>
            </Grid>
          </Container>{' '}
        </>
      )}
    </Layout>
  );
};

const mapStateToProps = (state) => {
  return {
    Language : state.Language.languages
   }
 };

export default connect(mapStateToProps,undefined)(Membership);
