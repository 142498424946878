import React from 'react';
import Checkbox from '../Checkbox/Checkbox';
import Accordion from './Accordian';
import { NavLink } from 'react-router-dom';
import {
  dateFormatFrontDay,
  dateFormatFrontOnlyDate,
} from '../../Common/Components';
import {connect} from 'react-redux';

const Filters = ({
  onChange,
  search,
  removeSublot,
  locationItems,
  categoriesCounts,
  subcategoriesCounts,
  upcomingAuction,
  onChangeCheckBox,
  fromAuction,
  Language
}) => {
  return (
    <div className="search-category-box">
      <div className="search-filters">
        <NavLink to="/search" className="sp-links">
          {Language.aceion} <i className="fa fa-angle-right"></i>
        </NavLink>
        <NavLink to="/marketplace/search" className="sp-links">
          {Language.teion} <i className="fa fa-angle-right"></i>
        </NavLink>
        {fromAuction === 0 ? (
          <>
            <Accordion title={Language.upauct}>
              <div className="card-body">
                <Checkbox
                  onChange={onChangeCheckBox}
                  value="All"
                  name="storedate"
                  checkvalue=""
                  checked={search.storedate.length > 0 ? false : true}
                />
                {upcomingAuction.map((upa, index) => (
                  <Checkbox
                    key={`upauction-${index}`}
                    onChange={onChangeCheckBox}
                    value={dateFormatFrontDay(upa.upcoming_auctions)}
                    name="storedate"
                    checked={
                      search.storedate.length > 0
                        ? search.storedate.indexOf(
                            dateFormatFrontOnlyDate(upa.upcoming_auctions)
                          ) > -1
                        : false
                    }
                    checkvalue={dateFormatFrontOnlyDate(upa.upcoming_auctions)}
                  />
                ))}
              </div>
            </Accordion>
            <Accordion title={Language.categ}>
              <div className="card-body">
                {global.categories.map((category, index) => (
                  <>
                    {categoriesCounts.map((count, index) => (
                      <span>
                        {parseInt(count.category_id) ===
                          parseInt(category.id) &&
                        parseInt(count.CountCat) > 0 ? (
                          <>
                            <label className="custom-checkbox pure-material-checkbox">
                              {`${category.name} (${count.CountCat})`}
                              <input
                                type="checkbox"
                                name="category"
                                value={category.id}
                                onChange={(event, value) => {
                                  onChangeCheckBox(event);
                                  removeSublot(category.id);
                                }}
                                checked={
                                  search.category.indexOf(category.id) > -1
                                }
                              />
                              <span></span>
                            </label>

                            {subcategoriesCounts.map((subcount, index) => (
                              <>
                                {global.subcategories.map(
                                  (subcategory, index) => (
                                    <>
                                      {parseInt(subcategory.master_id) ===
                                        parseInt(category.id) &&
                                      parseInt(subcount.subcategory) ===
                                        parseInt(subcategory.id) &&
                                      search.category.indexOf(category.id) >
                                        -1 &&
                                      parseInt(subcount.CountSubCat) > 0 ? (
                                        <>
                                          <label className="custom-checkbox pure-material-checkbox">
                                            {`${subcategory.name} (${subcount.CountSubCat})`}
                                            <input
                                              type="checkbox"
                                              name="subcategory"
                                              datamasterid={
                                                subcategory.master_id
                                              }
                                              data-subid={subcategory.id}
                                              value={subcategory.id}
                                              onChange={onChangeCheckBox}
                                              checked={
                                                search.subcategory.indexOf(
                                                  subcategory.id
                                                ) > -1
                                              }
                                            />
                                            <span></span>
                                          </label>
                                        </>
                                      ) : null}
                                    </>
                                  )
                                )}
                              </>
                            ))}
                          </>
                        ) : null}
                      </span>
                    ))}
                  </>
                ))}
              </div>
            </Accordion>
            <Accordion title={Language.pricingg}>
              <div className="card-body">
                <div className="row">
                  <div className="col-12 form-group">
                                            <label className="pp-label">{Language.frmprce}</label>
                    <input
                      className="form-control numberonly"
                      type="text"
                      name="fromprice"
                      onChange={onChange}
                      value={search.fromprice}
                    />
                  </div>
                  <div className="col-12 form-group">
                                            <label className="pp-label">{Language.oice}</label>
                    <input
                      className="form-control numberonly"
                      type="text"
                      name="toprice"
                      onChange={onChange}
                      value={search.toprice}
                    />
                  </div>
                </div>
              </div>
            </Accordion>
            <Accordion title={Language.loc}>
              <div className="card-body">
                {locationItems.map((loc, index) => (
                  <Checkbox
                    key={`category-${index}`}
                    onChange={onChangeCheckBox}
                    value={loc.city}
                    name="city"
                    checked={search.city.indexOf(loc.city) > -1}
                    checkvalue={loc.city}
                  />
                ))}
              </div>
            </Accordion>
          </>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    Language : state.Language.languages
   }
 };

export default connect( mapStateToProps,undefined )(Filters);