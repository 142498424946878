import Axios from 'axios';

export const LanguageAction = (list) => ({
    type: 'SET_LANGUAGE',
    list
 });


const token = localStorage.getItem('token')

const headers = {
    Authorization : `Bearer ${token}`,
    domain:'la.auctionsoftware.com'
}

 export const getLanguage = () => {

return (dispatch) => {

let language = localStorage.getItem('language')
? localStorage.getItem('language')
: 'English';

Axios.get(`${global.site_url}/mobileapi/langauge/${language}`,{},{headers : headers})
.then((res)=>{
    console.log(res.data.msg,'LANGUAGEEEEEEEE');
    if(res.data.msg === 'success'){
    dispatch(LanguageAction(res.data.returndata[language]))
    }
})
.catch((err)=>{
    console.log(err);
})
}
 };

