import {createStore , combineReducers , applyMiddleware , compose } from 'redux';
import thunk from 'redux-thunk';
import LanguageReducer from '../Reducers/Language';



const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export default () => {

const store = createStore(
    combineReducers({
   Language: LanguageReducer
    }),
    composeEnhancers(applyMiddleware(thunk))
);

return store;

};

