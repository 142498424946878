/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from 'react';
import './profile.css';
import $ from 'jquery';
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';
import { onChangeFunction } from '../../Common/Components';
import AuthContext from '../../context/auth/authContext';
import AlertContext from '../../context/alert/alertContext';
import UserContext from '../../context/user/userContext';
import { Redirect } from 'react-router-dom';

import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';

import { GetFileFromBase64 } from '../../Common/ImageHandler';

import {connect} from 'react-redux';

const ProfileForm = (props) => {
  const button = $('#formSubmitButton');
  const authContext = useContext(AuthContext);
  const alertContext = useContext(AlertContext);
  const userContext = useContext(UserContext);

  const { user, loadUser } = authContext;
  const { setAlert } = alertContext;
  const { editProfile, responseStatus, clearResponse } = userContext;

  const [toHome, settoHome] = useState(false);

  const [profile, setProfile] = useState({
    profile_id: '',
    first_name: '',
    last_name: '',
    email: '',
    address1: '',
    address2: '',
    city: '',
    state: '',
    country: 'United States',
    zip: '',
    alternate_phone: '',
    user_profile_image: [],
    user_profile: [],
    phone: '',
    role: '',
    product_imagefile: [],
  });

  useEffect(() => {
    if (Object.keys(user).length !== 0) {
      setProfile({
        ...profile,
        first_name: user.first_name,
        profile_id: user.profile_id,
        last_name: user.last_name,
        alternate_phone: user.alternate_phone,
        address1: user.address1,
        address2: user.address2,
        email: user.email,
        country: user.country ? user.country : 'United States',
        city: user.city,
        zip: user.zip,
        state: user.state,
        role: user.role,
        user_profile: user.avatar ? [user.avatar] : [],
        user_profile_image: [],
        phone: user.phone,
      });
    }
  }, [user]);

  const onChange = (e) => {
    setProfile(onChangeFunction(e, profile))
  };

  const onChangeMobile = (e) => {
    $('#ph_err').html("")
    setProfile({ ...profile, phone: e });
  };

  const onChangeCountry = (e) => {
    $('#location_err').html('');
    $('#state_err').html('');
    setProfile({ ...profile, country: e });
  };

  const onChangeRegion = (e) => {
    $('#location_err').html('');
    $('#state_err').html('');
    setProfile({ ...profile, state: e });
  };

  const errorTextMsg = (text) => {
    return `<ul class="parsley-errors-list filled"><li class="parsley-required">${text}</li></ul>`;
  };

  const scrollFunction = (id) => {
    $('html, body').animate(
      {
        scrollTop: $(`#${id}`).offset().top,
      },
      2000
    );
  };

  const customValidation = (data) => {
    return new Promise(async (resolve, reject) => {
      let error = 0;
      if (!$('#ProfileForm').parsley().validate()) {
        error = 1;
      }
      if (!data.user_profile.length && !data.user_profile_image.length) {
        $('#image_err').html(errorTextMsg(props.Language.thisir ? props.Language.thisir : 'This value is required.'));
        if (error === 0) {
          scrollFunction('image_top');
        }
        error = 1;
      } 
      if (!data.country) {
        $('#location_err').html(errorTextMsg(props.Language.thisir ? props.Language.thisir : 'This value is required.'));
        if (error === 0) {
          scrollFunction('location_top');
        }
        error = 1;
      }
      if (!data.state) {
        $('#state_err').html(errorTextMsg(props.Language.thisir ? props.Language.thisir : 'This value is required.'));
        if (error === 0) {
          scrollFunction('location_top');
        }
        error = 1;
      }
      
      if(data.phone == '' || typeof data.phone === 'undefined' || data.phone === 'undefined'){
        $('#ph_err').html(errorTextMsg(props.Language.thisir ? props.Language.thisir : 'This value is required.'));
        error = 1;
      }
      if (error === 1) {
        resolve(false);
      } else {
        resolve(true);
      }
    });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    button.attr('disabled', true);
    let [customValid] = await Promise.all([customValidation(profile)]);
    if ($('#ProfileForm').parsley().validate() && customValid) {
      const formDataSend = new FormData();
      for (var key in profile) {
        if (key === 'user_profile_image') {
          if (profile[key].length) {
            for (var inside in profile[key]) {
              let [FileGenerated] = await Promise.all([
                GetFileFromBase64(profile[key][inside]),
              ]);
              formDataSend.append(key, FileGenerated);
            }
          } else {
            for (var alrins in profile['user_profile']) {
              formDataSend.append(key, profile['user_profile'][alrins]);
            }
          }
        } else {
          if (typeof profile[key] == 'object') {
            for (let insideout in profile[key]) {
              formDataSend.append(key, profile[key][insideout]);
            }
          } else {
            formDataSend.append(key, profile[key]);
          }
        }
        if (key === 'product_imagefile') {
          editProfile(formDataSend);
          return false;
        }
      }
    } else {
      button.attr('disabled', false);
      return false;
    }
  };

  function toReRenderImages() {
    let imageSelected = [];
    let arraytoPush = [];
    imageSelected = profile.product_imagefile;
    if (imageSelected.length) {
      var reader = new FileReader();
      reader.readAsDataURL(imageSelected[0]);
      reader.onloadend = function (e) {
        arraytoPush.push(reader.result);
        setProfile({
          ...profile,
          user_profile_image: arraytoPush,
          user_profile: [],
          product_imagefile: [],
        });
        document.getElementById('product_imagefile').value = '';
        $('#image_err').html('');
      };
    } else {
      document.getElementById('product_imagefile').value = '';
      $('#image_err').html('');
    }
  }

  useEffect(() => {
    if (profile.product_imagefile.length) {
      toReRenderImages();
    }
  }, [profile.product_imagefile]);

  useEffect(() => {
    if (responseStatus && responseStatus !== '') {
      button.attr('disabled', false);
      if (responseStatus === 'profile_updated_completed') {
        setAlert(props.Language.prof_s_u ? props.Language.prof_s_u : 'Profile Successfully updated', 'success');
        clearResponse();
        loadUser();
        settoHome(true);
      } else {
        setAlert(responseStatus, 'error');
        clearResponse();
      }
    } else {
      clearResponse();
    }
  }, [responseStatus]);

  return (
    <div className="profile-form-wrapper">
      {toHome ? <Redirect to="/" /> : null}
      {/* <EditUser /> */}
      {profile.user_profile.length || profile.user_profile_image.length ? (
        <></>
      ) : (
        <div className="lb-breadcrumb alert alert-warning">
         {props.Language.sptvlaauction ? props.Language.sptvlaauction : "SAVE PROFILE TO BROWSE LA AUCTION DEPOT" } 
        </div>
      )}

      <form id="ProfileForm" data-parsley-validate onSubmit={onSubmit}>
        <div className="ppic-wrap">
          <span id="image_top"> </span>
          {profile.user_profile.length || profile.user_profile_image.length ? (
            <>
              {profile.user_profile.map((item, index) => {
                return (
                  <span key={index}>
                    <img
                      src={`${global.profile_image_url}${item}`}
                      className="pp-img"
                      alt=""
                    />
                  </span>
                );
              })}
              {profile.user_profile_image.map((item, index) => {
                return (
                  <span key={index}>
                    <img src={item} className="pp-img" alt="" />
                  </span>
                );
              })}
            </>
          ) : (
            <>
              <img src="/images/dash-icon-1.png" className="pp-img" alt="" />
            </>
          )}
          <div className="pp-edit">
            <img src="/images/edit.png" alt="" />
            <input
              type="file"
              id="product_imagefile"
              className="form-control"
              name="product_imagefile"
              data-parsley-errors-container="#image_err"
              onChange={onChange}
            />
          </div>
          <span id="image_err"> </span>
        </div>
        <div className="row">
          <div className="col-sm-6 form-group">
          <label className="sf-label">{props.Language.first_name ? props.Language.first_name : 'First Name' }</label>
            <div className="icon-input">
              <div className="icon-box">
                <img src="/images/user.png" alt="" />
              </div>
              <input
                name="first_name"
                data-parsley-required="true"
                data-parsley-pattern="^[a-zA-Z ]*$"
                data-parsley-error-message={props.Language.thisir ? props.Language.thisir : 'This value is required'}
                onChange={onChange}
                value={profile.first_name}
                className="form-control"
                placeholder={props.Language.first_name ? props.Language.first_name : 'First Name' }
              />
            </div>
          </div>
          <div className="col-sm-6 form-group">
            <label className="sf-label">{props.Language.first_name ? props.Language.last_name : 'Last Name' }</label>
            <div className="icon-input">
              <div className="icon-box">
                <img src="/images/user.png" alt="" />
              </div>
              <input
                name="last_name"
                data-parsley-required="true"
                data-parsley-pattern="^[a-zA-Z ]*$"
                data-parsley-error-message={props.Language.thisir ? props.Language.thisir : 'This value is required'}
                onChange={onChange}
                value={profile.last_name}
                className="form-control"
                placeholder={props.Language.first_name ? props.Language.last_name : 'Last Name' }
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 col-md-6 form-group">
            <label className="sf-label">{props.Language.email ? props.Language.email : 'Email' } Id</label>
            <div className="icon-input">
              <div className="icon-box">
                <img src="/images/msg.png" alt="" />
              </div>
              <input
                disabled
                name="username"
                value={profile.email}
                className="form-control"
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12 form-group">
            <label className="sf-label">{props.Language.address ? props.Language.address : 'Address' }</label>
            <div className="icon-input">
              <div className="icon-box">
                <img src="/images/msg.png" alt="" />
              </div>
              <input
                className="form-control"
                name="address1"
                id=""
                onChange={onChange}
                value={profile.address1}
                placeholder={props.Language.address ? props.Language.address : 'Address' }
                data-parsley-required="true"
                data-parsley-error-message={props.Language.thisir ? props.Language.thisir : 'This value is required'}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12 form-group">
            <label className="sf-label">{props.Language.address ? props.Language.address : 'Address' } {props.Language.line ? props.Language.line : 'Line' } 2 ({props.Language.optional ? props.Language.optional : 'Optional' })</label>
            <div className="icon-input">
              <div className="icon-box">
                <img src="/images/msg.png" alt="" />
              </div>
              <input
                className="form-control"
                name="address2"
                id=""
                onChange={onChange}
                value={profile.address2}
                placeholder={props.Language.address ? props.Language.address + " second" : 'Address second' }
              />
            </div>
          </div>
        </div>

        <div className="row" id="location_top">
          <div className="col-sm-6 form-group">
            <label className="sf-label">{props.Language.country ? props.Language.country : 'Country' }</label>
            <div className="icon-input">
              <CountryDropdown
                name="country"
                disabled
                onChange={onChangeCountry}
                value={profile.country}
                className="form-control"
              />
              <span id="location_err"></span>
            </div>
          </div>
          <div className="col-sm-6 form-group">
          <label className="sf-label">{props.Language.state ? props.Language.state : 'State'}</label>
            <div className="icon-input">
              <RegionDropdown
                name="state"
                country={profile.country}
                onChange={onChangeRegion}
                value={profile.state}
                className="form-control"
              />
              <span id="state_err"></span>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-6 form-group">
            <label className="sf-label">{props.Language.city ? props.Language.city : 'City'}</label>
            <div className="icon-input">
              <div className="icon-box">
                <img src="/images/placeholder.png" alt="" />
              </div>
              <input
                className="form-control"
                name="city"
                id=""
                onChange={onChange}
                value={profile.city}
                placeholder={props.Language.city ? props.Language.city : 'City'}
                data-parsley-required="true"
                data-parsley-error-message={props.Language.thisir ? props.Language.thisir : 'This value is required'}
              />
            </div>
          </div>
          <div className="col-sm-6 form-group">
            <label className="sf-label">{props.Language.zcde ? props.Language.zcde : 'Zip code'}</label>
            <div className="icon-input">
              <div className="icon-box">
                <img src="/images/placeholder.png" alt="" />
              </div>
              <input
                name="zip"
                type="text"
                maxLength="10"
                onChange={onChange}
                value={profile.zip}
                className="form-control numberonly"
                placeholder={props.Language.zcde ? props.Language.zcde : 'Zip code'}
                data-parsley-required="true"
                data-parsley-error-message={props.Language.thisir ? props.Language.thisir : 'This value is required'}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-6 form-group">
            <label className="sf-label">{props.Language.mob_num ? props.Language.mob_num : 'Mobile Number'}</label>
            <div className="icon-input">
              <PhoneInput
                placeholder={props.Language.mob_num ? props.Language.mob_num : 'Mobile Number'}
                className="form-control numberplusonly"
                onChange={onChangeMobile}
                name="phone"
                value={profile.phone}
              />
            </div>
            <span id="ph_err"></span>
          </div>
          <div className="col-sm-6 form-group">
            <label className="sf-label">{props.Language.p_number ? props.Language.p_number : 'Phone Number'}</label>
            <div className="icon-input">
              <div className="icon-box">
                <img src="/images/phone-call.png" alt="" />
              </div>
              <input
                name="alternate_phone"
                maxLength="15"
                onChange={onChange}
                value={profile.alternate_phone}
                className="form-control numberplusonly"
                placeholder={props.Language.p_number ? props.Language.p_number : 'Phone Number'}
                data-parsley-required="true"
                data-parsley-error-message={props.Language.thisir ? props.Language.thisir : 'This value is required'}
              />
            </div>
          </div>
        </div>
        <div className="pt-3 btns-wrap">
          <button className="btn btn-green" id="formSubmitButton">
          {props.Language.save ? props.Language.save : 'SAVE'}
          </button>
        </div>
      </form>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    Language : state.Language.languages
  }
}

export default connect(mapStateToProps)(ProfileForm);
