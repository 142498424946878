import React, { useContext, useEffect } from 'react';
import { Switch, Route } from 'react-router-dom';
import setAuthToken from '../Common/ApiHeader';

//Buyer Links

import Search from '../Containers/Search/SearchAuction';
import SearchMarketList from '../Containers/Search/SearchMarket';
import SavedSearch from '../Containers/Search/SavedSearch';
import Profile from '../Containers/Profile';
import Changepassword from '../Containers/Changepassword';
import NotificationPreference from '../Containers/NotificationPreference';
import Inbox from '../Containers/Messages/Inbox';
import MessageInbox from '../Containers/Messages/Messages';
import Membership from '../Containers/Membership';
import BuyerDashboard from '../Containers/BuyerDashboard';
import WatchList from '../Containers/BuyerDashboard/watchlist';
import Transactions from '../Containers/Transactions';
import Payment from '../Containers/Payment';
import ProductView from '../Containers/ProductView';
//import Invoice from '../Containers/Invoice';
import InvoiceNew from '../Containers/InvoiceNew';

import PostProject from '../Containers/PostProject/PostProject';
import PostAuction from '../Containers/PostAuction/PostAuction';
//Seller Links

import SellerDashboard from '../Containers/SellerDashboard';

//Common Links
import NewsPost from '../Containers/News/NewsGrid';
import BlogPost from '../Containers/Blog/BlogPost';
import BlogGrid from '../Containers/Blog/BlogGrid';
import BlogDetail from '../Containers/Blog/BlogDetail';

import Login from '../Containers/Login';
import Register from '../Containers/Register';
import ProRegister from '../Containers/Register/ProRegister';

import ForgotPassword from '../Containers/ForgotPassword';
import ChangePassword from '../Containers/ForgotPassword/changePassword';
import Home from '../Containers/Home';
import ContactUs from '../Containers/Static/ContactUs';
import Static from '../Containers/Static';
import Faq from '../Containers/Static/Faq';
import BidderFaq from '../Containers/Static/BidderFaq';
import Career from '../Containers/Static/Career';
import Selling from '../Containers/Static/Selling';
import BiddersReport from '../Containers/BiddersReport/BiddersReport';
import AuthContext from '../context/auth/authContext';

if (localStorage.token) {
  setAuthToken(localStorage.token);
}

export default function Routes() {
  const authContext = useContext(AuthContext);
  const { loadUser, getGlobalVariable } = authContext;
  useEffect(() => {
    loadUser();
    getGlobalVariable();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Switch>
      <Route exact path="/biddersreport" component={BiddersReport} />
      <Route exact path="/product-view" component={ProductView} />
      <Route exact path="/product-view/:id" component={ProductView} />
      <Route exact path="/buyerdashboard" component={BuyerDashboard} />
      <Route exact path="/buyerdashboard/:id" component={BuyerDashboard} />
      <Route exact path="/watchlist" component={WatchList} />
      <Route exact path="/profile" component={Profile} />
      <Route exact path="/saved-search" component={SavedSearch} />
      <Route exact path="/change-password" component={Changepassword} />
      <Route exact path="/preference" component={NotificationPreference} />

      <Route exact path="/payments" component={Payment} />
      <Route exact path="/transactions" component={Transactions} />
      <Route exact path="/sellerdashboard" component={SellerDashboard} />
      <Route exact path="/messages" component={Inbox} />
      <Route exact path="/messages/:id" component={MessageInbox} />
      <Route exact path="/membership" component={Membership} />
      <Route exact path="/search" component={SearchMarketList} />
      <Route exact path="/search/:type" component={SearchMarketList} />
      <Route exact path="/marketplace/search/" component={Search} />
      <Route exact path="/marketplace/search/:type" component={Search} />
      <Route exact path="/post-project" component={PostProject} />
      <Route exact path="/post-auction" component={PostAuction} />
      <Route exact path="/edit-auction/:id" component={PostAuction} />
      <Route exact path="/edit-project/:id" component={PostProject} />
      <Route exact path="/press" component={Static} />

      <Route exact path="/trust" component={Static} />
      <Route exact path="/terms" component={Static} />
      <Route exact path="/contact-us" component={ContactUs} />
      <Route exact path="/privacy" component={Static} />
      <Route exact path="/cookie-policy" component={Static} />
      <Route exact path="/about" component={Static} />
      <Route exact path="/blog" component={Static} />
      <Route exact path="/laad" component={Static} />
      <Route exact path="/forgotpassword" component={Static} />

      <Route exact path="/invoice/:id" component={InvoiceNew} />
      <Route exact path="/invoice/:id/:userid" component={InvoiceNew} />

      <Route exact path="/faq" component={Faq} />
      <Route exact path="/bidder-faq" component={BidderFaq} />
      <Route exact path="/career" component={Career} />
      <Route exact path="/selling" component={Selling} />
      <Route exact path="/news" component={NewsPost} />
      <Route exact path="/create-blog" component={BlogPost} />
      <Route exact path="/pro-buyer-blog" component={BlogGrid} />
      <Route exact path="/pro-blog-details/:id" component={BlogDetail} />
      <Route exact path="/forgot-password" component={ForgotPassword} />
      <Route exact path="/login/forgot_password" component={ChangePassword} />
      <Route exact path="/register" component={Register} />
      <Route exact path="/proregister" component={ProRegister} />
      <Route exact path="/login" component={Login} />
      <Route exact path="/" component={Home} />
    </Switch>
  );
}
