/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import Layout from '../../Components/Layout';
//import { DirectAPICAll } from '../../Common/Components';
import axios from 'axios';
import Loading from '../../Components/Loading';
import {connect} from 'react-redux';

const NewsGrid = (props) => {
  const [newsdata, setNewsdata] = useState({
    articles: [],
  });

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function fetchData() {
      // let searchKeyword = 'auction';
      //let urlNews = `https://newsapi.org/v2/everything?q=${searchKeyword}&apiKey=${global.newsapi_key}`;
      axios({
        method: 'GET',
        url:
          'https://bloomberg-market-and-financial-news.p.rapidapi.com/stories/list',
        headers: {
          'content-type': 'application/octet-stream',
          'x-rapidapi-host':
            'bloomberg-market-and-financial-news.p.rapidapi.com',
          'x-rapidapi-key':
            'a4af71548bmshba195396cae869bp145be2jsn3d81994a3d82',
          useQueryString: true,
        },
        params: {
          template: 'COMMODITY',
          id: 'auction',
        },
      })
        .then((response) => {
          console.log('response', response);
          setLoading(false);
          setNewsdata({ ...newsdata, articles: response.data.stories });
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
      // const [response] = await Promise.all([
      //   DirectAPICAll('get', urlBloomberg),
      // ]);
    }
    setLoading(true);
    fetchData();
  }, []);

  return (
    <Layout>
      <Loading loading={loading} />
      <div className="container search-content-wrap mb-5">
        <h2 className="main-head clearfix text-left mt-5 mb-3 pblog-head">
  <span className="hbox">{props.Language.pbyyy}</span>
  <span>{props.Language.ewss}</span>
        </h2>

        <div className="clearfix"></div>

        {newsdata.articles.length ? (
          <div className="row blog-lwrap">
            {newsdata.articles.map((item, index) => {
              return (
                <div
                  className="col-sm-6 col-md-4 col-lg-3 form-group"
                  key={index}
                >
                  <div className="card grid-card">
                    <a
                      target="_blank"
                      rel="noreferrer noopener"
                      href={item.longURL}
                    >
                      <img
                        src={item.thumbnailImage}
                        alt={item.title}
                        className="bgc-img"
                      />
                    </a>
                    <div className="card-body">
                      {/* <div className="clearfix">
                        <div className="float-left">{item.source.name}</div>
                      </div> */}

                      {item.title}

                      {/* <div className="blog-descp">{item.description}</div> */}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        ) : (
          <div className="np-pro-box"> {props.Language.ewsavbb} </div>
        )}
      </div>
    </Layout>
  );
};

const mapStateToProps = (state) => {
  return {
    Language : state.Language.languages
   }
 };


export default connect(mapStateToProps,undefined) (NewsGrid);
