/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState, useEffect } from 'react';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import { Link } from 'react-router-dom';

import ProductContext from '../../context/product/productContext';
import { dateFormatFront } from '../../Common/Components';
import { Pagination } from '@material-ui/lab';

import {connect} from 'react-redux'

const AllBids = (props) => {
  const productContext = useContext(ProductContext);
  const { seller_allauctions, getAllSellerAuctions } = productContext;

  const [search, setSearch] = useState({
    limit: 10,
    page: 1,
  });

  const onHandlePage = (event, value) => {
    setSearch({ ...search, page: value });
  };

  useEffect(() => {
    getAllSellerAuctions(search);
  }, [search]);

  return (
    <Container>
      {seller_allauctions.result.length ? (
        <>
          {seller_allauctions.result.map((item, index) => {
            return (
              <Box className="db-pro-box" key={index}>
                <div className="row">
                  <div className="col-sm-4">
                    <div className="media">
                      <div className="media-left">
                        <img
                          className="pro-img"
                          src={`${global.image_url}${item.avatar}`}
                          alt={item.title}
                        />
                      </div>
                      <div className="media-body">
                        <div className="pro-title">{item.title}</div>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-8">
                    <div className="row">
                      <div className="col-sm-4">
                        <div>
                          <div>Close Time :</div>
                          <div>
                            <b>{dateFormatFront(item.date_closed)}</b>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-4">
                        <div>
                          No of lots :{' '}
                          <b>
                            {item.activecount} ({item.lotscount})
                          </b>
                        </div>
                        <div>
                          Status :{' '}
                          <b style={{ textTransform: 'capitalize' }}>
                            {parseInt(item.lotscount) > 0
                              ? parseInt(item.activecount) > 0
                                ? 'Open'
                                : 'Closed'
                              : 'No Items'}
                          </b>
                        </div>
                      </div>
                      <div className="col-sm-4">
                        <Link to={`edit-auction/${item.id}`}>
                          <label className="label cursorPointer label-success">
                            EDIT
                          </label>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </Box>
            );
          })}

          <div className="d-flex mt-3">
            <div className="ml-auto mt-2">
              Showing {search.limit * (search.page - 1) + 1} -{' '}
              {search.limit * search.page > seller_allauctions.length
                ? seller_allauctions.length
                : search.limit * search.page}{' '}
              of {seller_allauctions.length}
            </div>
            <Pagination
              count={Math.ceil(seller_allauctions.length / search.limit)}
              page={search.page}
              onChange={onHandlePage}
            />
          </div>
        </>
      ) : (
        <>
          <Box className="db-pro-box">
            <div className="row">
              <div className="col-sm-12 text-center"> {props.Language.noauct ? props.Language.noauct : 'No Auctions'} </div>
            </div>
          </Box>
        </>
      )}
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    Language : state.Language.languages
   }
 };
export default connect(mapStateToProps)(AllBids);

