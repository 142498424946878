

const Languagestate = {
  languages:[]
};

const LanguageReducer = (state = Languagestate,action) => {

switch (action.type){

case 'SET_LANGUAGE' : 

return {
  ...state,
  languages:action.list
}
    


default :

return state;

   };
 };


export default LanguageReducer;