import React from 'react';
import { Link } from 'react-router-dom';
import Timer from '../../Common/Timer';
import FavouriteButton from '../Favourite/FavouriteButton';
import {connect} from 'react-redux';

const ListCard = ({ item, index, isAuthenticated, imgLoaded, Language }) => {
  return (
    <tr key={`sg-${index}`}>
      <td>
        <div className="fpos-rel">
          <Link to={`/product-view/${item.id}`}>
            <img
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = './images/no-image.png';
              }}
              src={
                imgLoaded
                  ? './images/imgDefault.gif'
                  : `${global.image_url}${item.file_name}`
              }
              alt={item.title}
              className="sal-img"
            />
          </Link> 
        </div>
      </td>
      <td>
        <Link to={`/product-view/${item.id}`} className="details-link">
          {' '}
          {item.title}{' '}
        </Link>
      </td>
      <td>{item.auction === 1 ? `$ ${item.wprice}` : `$ ${item.bprice}`} </td>
      <td>
        <div className="slv-timer">
          <Timer
            date_added={item.date_added}
            date_closed={item.date_closed}
            withText={0}
          ></Timer>
        </div>
      </td>
      <td>
        <div className="gv-btns-wrap mt-2">
          <Link to={`/product-view/${item.id}`} className="btn btn-green">{Language.dnoww}</Link>
          {isAuthenticated && <FavouriteButton watchlisted={item.watchlisted} project_id={item.id} />}
        </div>
      </td>
    </tr>
  );
};

const mapStateToProps = (state) => {
  return {
    Language : state.Language.languages
   }
 };


export default connect(mapStateToProps,undefined)(ListCard);
