import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Link } from 'react-router-dom';
import Timer from '../../Common/Timer';

import Favourite from '../Favourite';

import {connect} from 'react-redux';

const SimilarAuctions = ({ products, ...props }) => {
  let settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    adaptiveHeight: true,
    initialSlide: 0,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 550,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <>
      {products.results.length ? (
        <>
          <div className="container search-content-wrap mb-5 clearfix">
            <h2 className="main-head text-left pb-5">
              <span className="hbox">SIMILAR</span>
              <span>{props.Language.aucti_cap}</span>
            </h2>
            <Slider {...settings}>
              {products.results.map((item, index) => {
                return (
                  <>
                    <div className="card grid-card">
                      <Link to={`/product-view/${item.id}`}>
                        <img
                          src={`${global.image_url}${item.file_name}`}
                          alt={item.title}
                        />
                      </Link>
                      <Favourite
                        watchlisted={item.watchlisted}
                        project_id={item.id}
                      />
                      <div className="card-body">
                        <div className="price-txt">${item.wprice}</div>
                        <Link
                          to={`/product-view/${item.id}`}
                          className="details-link"
                        >
                          <div className="name-txt">{item.title}</div>
                        </Link>
                        <div className="timer-text">
                          <Timer
                            date_added={item.date_added}
                            date_closed={item.date_closed}
                            withText={0}
                          ></Timer>
                        </div>
                      </div>
                    </div>
                  </>
                );
              })}
            </Slider>
          </div>
        </>
      ) : null}
    </>
  );
};

const mapStateToProps = state => {
  return {
    Language : state.Language.languages
  }
}

export default connect(mapStateToProps)(SimilarAuctions);
