/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useContext, useEffect } from 'react';
import $ from 'jquery';
import Layout from '../../Components/Layout';
import { Redirect } from 'react-router-dom';
import AlertContext from '../../context/alert/alertContext';
import UserContext from '../../context/user/userContext';
import { onChangeFunction } from '../../Common/Components';
import Loading from '../../Components/Loading';
import {connect} from 'react-redux';

const Selling = (props) => {
  const userContext = useContext(UserContext);
  const alertContext = useContext(AlertContext);
  const [loading, setLoading] = useState(true);
  const [toHome, settoHome] = useState(false);

  const { setAlert } = alertContext;
  const {
    sellingForm,
    getStaticPage,
    static_page,
    responseStatus,
    clearResponse,
  } = userContext;

  useEffect(() => {
    setLoading(true);
    getStaticPage({ page_id: 'selling' });
  }, []);

  useEffect(() => {
    setLoading(false);
  }, [static_page]);

  const [selling, setSelling] = useState({
    first_name: '',
    last_name: '',
    phone_number: '',
    email: '',
    zip_code: '',
    product_type: '',
    captcha: '',
  });

  const onChange = (e) => setSelling(onChangeFunction(e, selling));

  useEffect(() => {
    if (responseStatus) {
      if (responseStatus === 'Seller successful') {
        $('#formSellingButton').attr('disabled', false);
        setAlert(props.Language.req_s_s ? props.Language.req_s_s : 'Request sent successfully', 'success');
        clearResponse();
        settoHome(true);
      } else {
        $('#formSellingButton').attr('disabled', false);
        setAlert(responseStatus, 'error');
        clearResponse();
      }
    }
  }, [responseStatus]);

  const customValidation = (data) => {
    return new Promise(async (resolve, reject) => {
      let error = 0;
      if (error === 1) {
        resolve(false);
      } else {
        resolve(true);
      }
    });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    $('#formSellingButton').attr('disabled', true);
    let [customValid] = await Promise.all([customValidation(selling)]);
    if ($('#sellingForm').parsley().validate() && customValid) {
      sellingForm(selling);
    } else {
      $('#formSellingButton').attr('disabled', false);
      return false;
    }
  };

  return (
    <Layout>
      {toHome ? <Redirect to="/" /> : null}
      <Loading loading={loading} />
      <div className="container mt-5 mb-5">
        <div
          dangerouslySetInnerHTML={{
            __html: static_page.content,
          }}
        ></div>
        <div className="row">
          <div className="col-sm-6">
            <form id="sellingForm" data-parsley-validate onSubmit={onSubmit}>
              <div className="row">
                <div className="col-sm-6 form-group">
        <label className="sf-label">{props.Language.first_name}</label>

                  <input
                    className="form-control"
                    name="first_name"
                    value={selling.first_name}
                    onChange={onChange}
                    data-parsley-required="true"
                    data-parsley-error-message={props.Language.thisir ? props.Language.thisir : 'This value is required.'}
                    placeholder={props.Language.efnn}
                  />
                </div>
                <div className="col-sm-6 form-group">
        <label className="sf-label">{props.Language.last_name}</label>
                  <input
                    className="form-control"
                    name="last_name"
                    value={selling.last_name}
                    onChange={onChange}
                    data-parsley-required="true"
                    data-parsley-error-message={props.Language.thisir ? props.Language.thisir : 'This value is required.'}
                    placeholder={props.Language.eln}
                  />
                </div>
              </div>
              <div className="form-group">
        <label className="sf-label">{props.Language.p_number}</label>
                <input
                  className="form-control numberonly"
                  name="phone_number"
                  type="text"
                  maxLength="15"
                  value={selling.phone_number}
                  onChange={onChange}
                  data-parsley-required="true"
                  data-parsley-error-message={props.Language.thisir ? props.Language.thisir : 'This value is required.'}
                  placeholder={props.Language.epnr}
                />
              </div>
              <div className="form-group">
        <label className="sf-label">{props.Language.em_l}</label>
                <input
                  className="form-control"
                  name="email"
                  type="email"
                  value={selling.email}
                  onChange={onChange}
                  data-parsley-required="true"
                  data-parsley-error-message={props.Language.thisir ? props.Language.thisir : 'This value is required.'}
                  placeholder={props.Language.ent_ur_email}
                />
              </div>
              <div className="form-group">
        <label className="sf-label">{props.Language.zcde}</label>
                <input
                  className="form-control numberonly"
                  type="text"
                  maxLength="10"
                  name="zip_code"
                  value={selling.zip_code}
                  onChange={onChange}
                  data-parsley-required="true"
                  data-parsley-error-message={props.Language.thisir ? props.Language.thisir : 'This value is required.'}
                  placeholder={props.Language.ezp}
                />
              </div>
              <div className="form-group">
        <label className="sf-label">{props.Language.wwyls}</label>
                <input
                  className="form-control"
                  name="product_type"
                  value={selling.product_type}
                  onChange={onChange}
                  data-parsley-required="true"
                  data-parsley-error-message={props.Language.thisir ? props.Language.thisir : 'This value is required.'}
                  placeholder={props.Language.etptw}
                />
              </div>
              {/* <div className="form-group">
                <label className="sf-label">
                  Please Enter the word you see in the image
                </label>
                <input
                  className="form-control"
                  name="start"
                  value={selling.captcha}
                  onChange={onChange}
                  data-parsley-required="true"
                  data-parsley-error-message={props.Language.thisir ? props.Language.thisir : 'This value is required.'}
                  placeholder="Enter captcha code"
                />
              </div> */}
              <div className="pt-3 mb-4">
                <button
                  id="formSellingButton"
                  type="submit"
                  className="btn btn-green"
                >
                  {props.Language.stim}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Layout>
  );
};

const mapStateToProps = (state) => {
  return {
    Language : state.Language.languages
   }
 };

export default connect(mapStateToProps,undefined) (Selling);
