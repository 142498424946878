/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Layout from '../../Components/Layout';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import DashboardHeader from '../../Components/DashboardHeader';
import ProfileLinks from '../../Components/Profile/ProfileLinks';
import LAauctionModal from '../../Components/Modal';
import AuthContext from '../../context/auth/authContext';
import { dateFormatFront } from '../../Common/Components';
import { onChangeFunction } from '../../Common/Components';
import AlertContext from '../../context/alert/alertContext';
import { Pagination } from '@material-ui/lab';
import {connect} from 'react-redux';

const SavedSearch = (props) => {
  const authContext = useContext(AuthContext);
  const alertContext = useContext(AlertContext);

  const { setAlert } = alertContext;

  const [search, setSearch] = useState({
    sh_limit: 10,
    page: 1,
  });

  const {
    recentSearch,
    responseStatus,
    clearResponse,
    recent_search,
    saveSearch,
    deleteSearch,
  } = authContext;
  const [saveSearchs, setSaveSearchs] = useState(false);

  const [savedsearch, setSavedSearch] = useState({
    searchbar: '',
  });
  const onChange = (e) => setSavedSearch(onChangeFunction(e, savedsearch));

  useEffect(() => {
    if (responseStatus && responseStatus !== '') {
      if (responseStatus === 'search_word_added') {
        setAlert(props.Language.svdsr_s_s ? props.Language.svdsr_s_s : 'Saved Search Successfully', 'success');
        openSaveSearchs();
        recentSearch(search);
        clearResponse();
      } else if (responseStatus === 'search_word_existing') {
        setAlert(props.Language.svdsr_a_e ? props.Language.svdsr_a_e :'Search already Saved!', 'success');
        openSaveSearchs();
        recentSearch(search);
        clearResponse();
      } else if (responseStatus === 'search_word_deleted') {
        setAlert(props.Language.svdsr_d_s ? props.Language.svdsr_d_s :'Search Successfully Deleted', 'success');
        recentSearch(search);
        clearResponse();
      }
    } else {
      clearResponse();
    }
  }, [responseStatus]);

  const onSubmit = async (e) => {
    e.preventDefault();
    if ($('#save-search').parsley().validate()) {
      saveSearch(savedsearch);
    }
  };

  const deleteRecent = (id) => {
    deleteSearch({ id: id });
  };

  const onHandlePage = (event, value) => {
    setSearch({ ...search, page: value });
  };

  useEffect(() => {
    recentSearch(search);
  }, [search]);

  console.log('recent_search', recent_search);

  const openSaveSearchs = () => {
    setSaveSearchs(!saveSearchs);
  };

  return (
    <Layout props={props}>
      <DashboardHeader title="SAVED" subtitle="SEARCH" />
      <Container className="dashboard-wrapper">
        <Grid container spacing={3}>
          <Grid item xs={12} sm={3}>
            <ProfileLinks
              profilelink="/profile"
              preflink="/preference"
              cpwdlink="/change-password"
              sslink="/saved-search"
            />
          </Grid>
          <Grid item xs={12} sm={9}>
            <table className="table stab savesearch">
              <thead>
                <tr>
                  <th>
                    <h5>{props.Language.svdsrch ? props.Language.svdsrch+"s" : 'Saved Searches'}</h5>
                  </th>
                  <th>&nbsp;</th>
                  <th>
                    <button className="btn btn-green" onClick={openSaveSearchs}>
                    {props.Language.add ? props.Language.add : 'Add'} {props.Language.new ? props.Language.new : 'New'} {props.Language.svdsrch ? props.Language.svdsrch+"s" : 'Saved Searches'}
                    </button>
                  </th>
                </tr>
              </thead>
              <tbody>
                {recent_search.results.length ? (
                  <>
                    {recent_search.results.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td data-label="Title" className="account_p_lbl">
                            <div>
                              <div className="stab2">{item.searchtext}</div>
                              <div className="stab3">
                               {props.Language.added ? props.Language.added : 'Added'}  {dateFormatFront(item.date_added)}
                              </div>
                            </div>
                          </td>
                          <td data-label="Criteria" className="account_p_lbl">
                            <Link
                              to={`/search?q=${item.searchtext}`}
                              className="search-link"
                            >
                              {props.Language.gttsr ? props.Language.gttsr : 'Go to this search result'}
                            </Link>
                          </td>
                          <td data-label="Action" className="account_p_lbl">
                            <span
                              onClick={() => deleteRecent(item.id)}
                              className="ss-trash"
                            >
                              <i className="fa fa-trash"></i>
                            </span>
                          </td>
                        </tr>
                      );
                    })}
                  </>
                ) : (
                  <tr>
                    <td colspan="3">
                      <div className="np-pro-box">{props.Language.nssearches ? props.Language.nssearches : "No Saved Searches" }  </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            {recent_search.results.length ? (
              <>
                <div className="d-flex mt-3">
                  <div className="ml-auto mt-2">
                    Showing {search.sh_limit * (search.page - 1) + 1} -{' '}
                    {search.sh_limit * search.page > recent_search.total_pagecnt
                      ? recent_search.total_pagecnt
                      : search.sh_limit * search.page}{' '}
                    of {recent_search.total_pagecnt}
                  </div>
                  <Pagination
                    count={Math.ceil(
                      recent_search.total_pagecnt / search.sh_limit
                    )}
                    page={search.page}
                    onChange={onHandlePage}
                  />
                </div>
              </>
            ) : null}
          </Grid>
        </Grid>
      </Container>
      <LAauctionModal
        open={saveSearchs}
        handleClose={openSaveSearchs}
        modaltitle={props.Language.svdsrch ? props.Language.svdsrch+"s" : 'Saved Searches'}
      >
        <p>
          {props.Language.ycstsiaofp ? props.Language.ycstsiaofp : 'You can now save this search into an organized folder in Profile'} >>
          {props.Language.sslymyrme ? props.Language.sslymyrme : "Saved Search letting you manage your results more easily."}
        </p>
        <form id="save-search" data-parsley-validate onSubmit={onSubmit}>
          <div className="form-group">
                    <label>{props.Language.totss ? props.Language.totss : "Title of this saved search"}</label>
            <input
              name="searchbar"
              value={savedsearch.searchbar}
              onChange={onChange}
              data-parsley-required="true"
              data-parsley-error-message={props.Language.thisir ? props.Language.thisir : 'This value is required'}
              className="form-control"
              placeholder={props.Language.ett ? props.Language.ett : "Enter the Title"}
            />
            <button className="btn btn-green" type="submit">
            {props.Language.save ? props.Language.save : "SAVE"}
            </button>
          </div>
        </form>
      </LAauctionModal>
    </Layout>
  );
};

const mapStateToprops = state => {
  return {
    Language : state.Language.languages
  }
}

export default connect(mapStateToprops)(SavedSearch);
