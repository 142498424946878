import React, { useReducer } from 'react';
import PayContext from './payContext';
import PayReducer from './payReducer';
import apiCall from '../../Common/ApiCall';

import {
  PAYMENT_WITH_CARD,
  RESPONSE_STATUS,
  CLEAR_RESPONSE,
  ADD_USER_CARD,
  GET_USER_CARD,
} from './payTypes';

const PayState = (props) => {
  const initialState = {
    responseStatus: null,
    usercards: [],
  };

  const [state, dispatch] = useReducer(PayReducer, initialState);

  const deleteUserCardAuthorize = async (formData) => {
    try {
      const [res] = await Promise.all([
        apiCall('post', 'deleteCardPay', formData, ''),
      ]);
      if (res.data.success) {
        dispatch({
          type: ADD_USER_CARD,
          payload: 'carddeleted',
        });
      } else {
        dispatch({
          type: RESPONSE_STATUS,
          payload: res.data.data.error,
        });
      }
    } catch (err) {
      dispatch({
        type: RESPONSE_STATUS,
        payload: 'Something went wrong!',
      });
    }
  };
  const getAllUserCardsAuthorize = async (formData) => {
    try {
      const [res] = await Promise.all([
        apiCall('post', 'getcardprofile', formData, ''),
      ]);
      if (res.data.success) {
        dispatch({
          type: GET_USER_CARD,
          payload: res.data.results.usercard,
        });
      } else {
        dispatch({
          type: RESPONSE_STATUS,
          payload: res.data.data.error,
        });
      }
    } catch (err) {
      dispatch({
        type: RESPONSE_STATUS,
        payload: 'Something went wrong!',
      });
    }
  };

  const getAllUserCards = async (formData) => {
    try {
      const [res] = await Promise.all([
        apiCall('post', 'get_all_creditcard', formData, '', 'auctionpay'),
      ]);
      if (res.data.success) {
        dispatch({
          type: GET_USER_CARD,
          payload: res.data.data,
        });
      } else {
        dispatch({
          type: RESPONSE_STATUS,
          payload: res.data.data.error,
        });
      }
    } catch (err) {
      dispatch({
        type: RESPONSE_STATUS,
        payload: 'Something went wrong!',
      });
    }
  };

  const editUserCard = async (formData) => {
    try {
      const [res] = await Promise.all([
        apiCall('post', 'edit_creditcard', formData, '', 'auctionpay'),
      ]);
      if (res.data.success) {
        dispatch({
          type: ADD_USER_CARD,
          payload: 'cardedited',
        });
      } else {
        dispatch({
          type: RESPONSE_STATUS,
          payload: res.data.data.error,
        });
      }
    } catch (err) {
      dispatch({
        type: RESPONSE_STATUS,
        payload: 'Something went wrong!',
      });
    }
  };

  const addUserCard = async (formData) => {
    try {
      const [res] = await Promise.all([
        apiCall('post', 'add_new_creditcard', formData, '', 'auctionpay'),
      ]);
      if (res.data.success) {
        dispatch({
          type: ADD_USER_CARD,
          payload: 'cardadded',
        });
      } else {
        dispatch({
          type: RESPONSE_STATUS,
          payload: res.data.data.error,
        });
      }
    } catch (err) {
      dispatch({
        type: RESPONSE_STATUS,
        payload: 'Something went wrong!',
      });
    }
  };

  const addExtraUserCardAuthorize = async (formData) => {
    try {
      const [res] = await Promise.all([
        apiCall('post', 'insertExtraCard', formData, ''),
      ]);
      if (res.data.success === 'yes') {
        dispatch({
          type: ADD_USER_CARD,
          payload: 'cardadded',
        });
      } else {
        dispatch({
          type: RESPONSE_STATUS,
          payload: res.data.failedreason.message,
        });
      }
    } catch (err) {
      dispatch({
        type: RESPONSE_STATUS,
        payload: 'Something went wrong!',
      });
    }
  };

  const addUserCardAuthorize = async (formData) => {
    try {
      const [res] = await Promise.all([
        apiCall('post', 'checkvalidcard_firstime', formData, ''),
      ]);
      if (res.data.success === 'yes') {
        dispatch({
          type: ADD_USER_CARD,
          payload: 'cardadded',
        });
      } else {
        dispatch({
          type: RESPONSE_STATUS,
          payload: res.data.failedreason.message,
        });
      }
    } catch (err) {
      dispatch({
        type: RESPONSE_STATUS,
        payload: 'Something went wrong!',
      });
    }
  };

  const paymentWithCardAuthorize = async (formData) => {
    console.log('payment initiated', formData);
    try {
      const [res] = await Promise.all([
        apiCall('post', 'SlibuyTransPaymentnew', formData, ''),
      ]);
      console.log('response getting', res);
      if (res.data.error === 'no') {
        dispatch({
          type: PAYMENT_WITH_CARD,
          payload: 'paymentsuccessful',
          // payload: res.data.data.result
        });
      } else if (res.data.error === 'yes') {
        if (res.data.errormsg.message) {
          dispatch({
            type: RESPONSE_STATUS,
            payload: res.data.errormsg.message,
          });
        } else {
          dispatch({
            type: RESPONSE_STATUS,
            payload: res.data.errormsg,
          });
        }
      } else {
        dispatch({
          type: RESPONSE_STATUS,
          payload: res.data.data.error,
        });
      }
    } catch (err) {
      dispatch({
        type: RESPONSE_STATUS,
        payload: 'Something went wrong!',
      });
    }
  };

  const paymentWithCard = async (formData) => {
    console.log('payment initiated', formData);
    try {
      const [res] = await Promise.all([
        apiCall('post', 'zift_card_paymentapi', formData, '', 'auctionpay'),
      ]);
      console.log('response getting', res);
      if (res.data.success) {
        dispatch({
          type: PAYMENT_WITH_CARD,
          payload: 'paymentsuccessful',
          // payload: res.data.data.result
        });
      } else if (res.data.error) {
        if (res.data.data.failureMessage) {
          dispatch({
            type: RESPONSE_STATUS,
            payload: res.data.data.failureMessage,
          });
        } else {
          dispatch({
            type: RESPONSE_STATUS,
            payload: res.data.data.error,
          });
        }
      } else {
        dispatch({
          type: RESPONSE_STATUS,
          payload: res.data.data.error,
        });
      }
    } catch (err) {
      dispatch({
        type: RESPONSE_STATUS,
        payload: 'Something went wrong!',
      });
    }
  };

  const clearResponse = () =>
    dispatch({
      type: CLEAR_RESPONSE,
    });
  return (
    <PayContext.Provider
      value={{
        responseStatus: state.responseStatus,
        usercards: state.usercards,
        getAllUserCards,
        getAllUserCardsAuthorize,
        deleteUserCardAuthorize,
        paymentWithCard,
        paymentWithCardAuthorize,
        addUserCard,
        addUserCardAuthorize,
        addExtraUserCardAuthorize,
        editUserCard,
        clearResponse,
      }}
    >
      {props.children}
    </PayContext.Provider>
  );
};

export default PayState;
