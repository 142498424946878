import openSocket from 'socket.io-client';
 let url = 'https://forwardapidev.auctionsoftware.com/';
//let url = 'https://auctionsoftware.net/';
// let url = 'http://localhost:2001/';
//url = global.site_url;
const socket = openSocket(url, { transports: ['websocket'] });

socket.on('connect', function () {
  console.log('Socket Connected');
});

function bidAddtime(cb) {
  socket.on('bidAddtime', (data) => {
    cb(data);
  });
}

function realClosedUpdates(cb) {
  socket.on('realclosedupdates', (data) => {
    cb(data);
  });
}

function refreshbuyer(cb) {
  socket.on('refreshbuyer', (data) => {
    cb(data);
  });
}
function refreshbuyerOff() {
  socket.off('refreshbuyer')
}
function bid_extended(cb) {
  socket.on('bid_extended', (data) => {
    cb(data);
  });
}
function bid_extendedOff() {
  socket.off('bid_extended');
}
socket.on('disconnect', function () {
  console.log('Socket Disconnected');
});
export { bidAddtime, realClosedUpdates, refreshbuyer, socket, refreshbuyerOff, bid_extended, bid_extendedOff };
