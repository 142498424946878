/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Layout from '../../Components/Layout';
import { Link } from 'react-router-dom';
import { Pagination } from '@material-ui/lab';
import DashboardHeader from '../../Components/DashboardHeader';
// import MessageLinks from '../../Components/Messages/MessageLinks';
// import Checkbox from '../../Components/Checkbox/Checkbox';
import UserContext from '../../context/user/userContext';
import { dateFormatFront } from '../../Common/Components';
import {connect} from 'react-redux';

const Inbox = (props) => {
  const userContext = useContext(UserContext);
  const { getUserMessages, userlist_messages } = userContext;

  const [search, setSearch] = useState({
    search_key: ' ',
    onlyactive: 1,
    sh_limit: 10,
    page: 1,
  });

  const onHandlePage = (event, value) => {
    setSearch({ ...search, page: value });
  };

  useEffect(() => {
    getUserMessages(search);
  }, [search]);

  return (
    <Layout props={props}>
      <DashboardHeader title={props.Language.nboxi} />
      <Container className="dashboard-wrapper">
        <Grid container spacing={3}>
          <Grid item sm={12}>
            {userlist_messages.results.length ? (
              <div className="table-responsive">
                <table class="table table-hover">
                  <thead>
                    <tr>
            <th>{props.Language.rfom}</th>
            <th>{props.Language.messge_s}</th>
            <th>{props.Language.recieved_caps}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {userlist_messages.results.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td>
                            <div className="media">
                              {/* <div className="media-left">
                                  <img
                                    src={item.avatar}
                                    alt=""
                                    className="buyer-pp"
                                  />
                                </div> */}
                              <div className="media-body">
                                <Link to={`messages/${item.id}`}>
                                  {item.first_name || item.last_name
                                    ? item.first_name + '' + item.last_name
                                    : 'USERNAME'}
                                  <span>
                                    {parseInt(item.msg_count) > 0
                                      ? `(${item.msg_count})`
                                      : null}
                                  </span>
                                </Link>
                              </div>
                            </div>
                          </td>
                          <td>{item.message}</td>
                          <td>{dateFormatFront(item.date_sent)}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            ) : (
              <>
                <div className="np-pro-box"> {props.Language.no_messages}</div>
              </>
            )}

            {userlist_messages.results.length ? (
              <>
                <div className="d-flex mt-3">
                  <div className="ml-auto mt-2">
                    {props.Language.showoff} {search.sh_limit * (search.page - 1) + 1} -{' '}
                    {search.sh_limit * search.page >
                    userlist_messages.total_pagecnt
                      ? userlist_messages.total_pagecnt
                      : search.sh_limit * search.page}{' '}
                    of {userlist_messages.total_pagecnt}
                  </div>
                  <Pagination
                    count={Math.ceil(
                      userlist_messages.total_pagecnt / search.sh_limit
                    )}
                    page={search.page}
                    onChange={onHandlePage}
                  />
                </div>
              </>
            ) : null}
          </Grid>
        </Grid>
      </Container>
    </Layout>
  );
};

const mapStateToProps = (state) => {
  return {
    Language : state.Language.languages
   }
 };

export default connect(mapStateToProps,undefined) (Inbox);
