/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from 'react';
import Layout from '../../Components/Layout';
import ProductTop from './ProductTop';
import ProductTabs from './ProductTabs';
import { Redirect } from 'react-router-dom';
import SimilarAuctions from '../../Components/ItemCards/SimilarAuctions';
import BuyerContext from '../../context/buyer/buyerContext';
import AuthContext from '../../context/auth/authContext';
import AlertContext from '../../context/alert/alertContext';
import ProductContext from '../../context/product/productContext';
import UserContext from '../../context/user/userContext';
import MakePayment from '../../Components/Payment/makePayment';
import LAauctionModal from '../../Components/Modal';

import { onChangeFunction } from '../../Common/Components';
import { bidAddtime, realClosedUpdates } from '../../Common/Socket';
import Loading from '../../Components/Loading';
import $ from 'jquery';
import { GoogleApiWrapper } from 'google-maps-react';
import DialogComp from '../../Components/Dialog/Dialog';
import {connect} from 'react-redux';

let getUserID = () => {
  return 0;
};
let getProjectID = () => {
  return 0;
};
let getProductSocket = () => {
  return {};
};
let updateProductSocket = () => {};
let updatecloseDate = () => {};

const ProductView = (props) => {
  let button = $('#bidSubmitButton');
  const authContext = useContext(AuthContext);
  const buyerContext = useContext(BuyerContext);
  const alertContext = useContext(AlertContext);
  const productContext = useContext(ProductContext);
  const userContext = useContext(UserContext);
  const [openDialog,setOpenDialog] = useState(false);
  const {
    sendUserMessages,
    responseStatus: responseStatusUser,
    clearResponse: clearResponseUser,
  } = userContext;
  // getShippingRates,
  const {
    responseStatus,
    search_allsimilarproducts,
    getAllSimilarProducts,
    buyNowProduct,
    clearResponse,
    shipping_rates,
    productViewed
  } = productContext;
  const { isAuthenticated, user, onlyOnce, setOnlyOnce } = authContext;
  const { setAlert } = alertContext;
  let {
    product_view,
    product_bidhistory,
    getViewProduct,
    getBidHistoryProduct,
    bidConfirm,
  } = buyerContext;
  const [toHome, settoHome] = useState(false);
  const [toBackSearch, settoBackSearch] = useState(false);
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState({
    sh_limit: 3,
    page: 1,
    orderby: '1',
    lotof: '',
    category: '',
    location: '',
    exempt_id: 0,
    similar_auction: '1',
  });
  const [toRelink] = useState(false);
  const [product, setProduct] = useState({
    product_details: {},
    product_biddetails: {},
    product_bidhistory: [],
    product_existingimage: [],
  });
  const [proddate_closed, setProddate_closed] = useState(null);
  const [shippingCost, setShippingCost] = useState(global.ship_cost);

  useEffect(() => {
    getAllSimilarProducts(search);
  }, [search]);
  const [productSocket, setproductSocket] = useState({
    next_bid: 0,
    current_price: 0,
    bid_count: 0,
    bid_or_not: 0,
    top_message: '',
    bid_class: 'no-bid',
  });

  const [sendMsg, setSendMsg] = useState({
    to_id: '',
    message: '',
  });

  const [bidForm, setbidForm] = useState({
    wsprice: '',
    id: props.match.params.id,
    bid_increment: 1,
    email: '',
    first_name: '',
    last_name: '',
  });
  const onChange = (e) => setbidForm(onChangeFunction(e, bidForm));
  const onChangeMessage = (e) => setSendMsg(onChangeFunction(e, sendMsg));
  const historyBack = () => {
    settoBackSearch(true);
    // window.history.back();
    // props.history.goBack();
  };
  useEffect(()=>{
    product_view = {};
  },[])
  useEffect(() => {
    if (props.match.params.id) {
      setLoading(true);

      const loadData = setTimeout(() => {
        setLoading(false);
      }, 1500);

      getViewProduct({ product_id: props.match.params.id });
      getBidHistoryProduct({ product_id: props.match.params.id });
      
      return () => clearTimeout(loadData);
    } else {
    }
    window.scrollTo(0, 0);
  }, [props.match.params.id]);

  useEffect(() => {
    if (Object.keys(user).length !== 0) {
      setbidForm({
        ...bidForm,
        email: user.email,
        first_name: user.first_name,
        last_name: user.last_name,
      });

      getUserID = () => {
        return parseInt(user.id);
      };
    }
  }, [user]);
  useEffect(() => {
    console.log('triiger change');
  }, [productSocket]);

  useEffect(() => {
    updateProductSocket = (data) => {
      setproductSocket({
        ...data,
      });
    };
    updatecloseDate = (data) => {
      setProddate_closed(data);
    };
  }, [props.match.params.id]);

  useEffect(() => {
    if (!onlyOnce) {
      setOnlyOnce();
      realClosedUpdates((data) => {
         let user_id = getUserID();
        let project_id = getProjectID();
        console.log('----------------------------------------------')
        console.log(data.pid,'data pid');
        console.log(data.use,'data.usr');
        console.log(user_id,'user_id')
        console.log('----------------------------------------------')
        if (parseInt(data.pid) === project_id) {
          functionReset();
          if(data.usr == user_id){
            setOpenDialog(true);
          }
          // if (data.usr !== '') {
          //   if (parseInt(user.id) === data.usr) {
          //     setproductSocket({
          //       ...productSocket,
          //       top_message: `Congratulations! You won this item.`,
          //       bid_class: 'bid-success',
          //     });
          //   } else if (
          //     parseInt(productSocket.bid_or_not) > 0 ||
          //     parseInt(bid_or_not) > 0
          //   ) {
          //     setproductSocket({
          //       ...productSocket,
          //       top_message: `Unfortunately, you lost this item.`,
          //       bid_class: 'bid-lost',
          //     });
          //   }
          // } else {
          //   if (
          //     parseInt(productSocket.bid_or_not) > 0 ||
          //     parseInt(bid_or_not) > 0
          //   ) {
          //     setproductSocket({
          //       ...productSocket,
          //       top_message: `Unfortunately, you lost this item.`,
          //       bid_class: 'bid-lost',
          //     });
          //   }
          // }
        }
      });
      bidAddtime((data) => {
        let project_id = getProjectID();
        let user_id = getUserID();
        let socketProduct = getProductSocket();
        let lolang = localStorage.getItem('language')
        console.log('project_id', project_id);
        console.log('user_id', user_id);
        console.log('socketProduct', socketProduct);
        console.log(data);
        if (parseInt(data.id) === project_id) {
          console.log('coming inside 111');
          if (parseInt(data.bpop_belowFive) === 1) {
            updatecloseDate(data.bpop_belowFiveIncrement);
          }
          $('#bidSubmitButton').attr('disabled', false);
          if (
            data.status === 'failed' &&
            user_id === parseInt(data.bpop_cbidder)
          ) {
            console.log(data.error);
            setAlert(data.error, 'error');
          }
          if (data.status !== 'failed') {
            console.log('coming inside 22222');
            if (user_id === parseInt(data.bpop_cbidder)) {
              console.log('coming inside 44444');
              setAlert(`${lolang === 'Spanish' ? "Oferta realizada correctamente" : "Bid placed successfully"}`, 'success');
              let bid_class =
                data.bpop_bidstatus.indexOf('losing') > -1
                  ? 'bid-lost'
                  : 'bid-success';
               if(lolang === 'Spanish'){
                data.bpop_bidstatus=  data.bpop_bidstatus.replace("You are winning this item at","Estás ganando este artículo en")        
                data.bpop_bidstatus=  data.bpop_bidstatus.replace("Your autoBid of","Tu autoBid de")        
                data.bpop_bidstatus=  data.bpop_bidstatus.replace("has been placed successfully.","se ha colocado correctamente.")        
               
                data.bpop_bidstatus=  data.bpop_bidstatus.replace("You are losing this item, user","Estás perdiendo este artículo, usuario")        
                data.bpop_bidstatus=  data.bpop_bidstatus.replace("has placed a higher autoBid","ha realizado una oferta automática más alta")
              }
              updateProductSocket({
                next_bid: data.bpop_cuser_nextbid,
                current_price: data.bpop_wprice,
                bid_count: data.bpop_bidcount,
                top_message: data.bpop_bidstatus,
                bid_class: bid_class,
                bid_or_not: 1,
              });
            } else {
              console.log('coming inside 3333');
              if (parseInt(socketProduct.bid_or_not) > 0) {
                console.log('coming inside 55555');
                if (user_id === parseInt(data.bpop_higher)) {
                  if (
                    parseInt(socketProduct.next_bid) <
                    parseInt(data.bpop_nextbid)
                  ) {
                    updateProductSocket({
                      next_bid: data.bpop_nextbid_org,
                      current_price: data.bpop_wprice,
                      top_message: `${lolang === 'Spanish' ? "Estás ganando este artículo en" : "You are winning this item at"} $${data.bpop_wprice}. ${lolang === 'Spanish' ? "Tu autoBid de" : "Your autoBid of"} $${data.bpop_wprice_morehigh} ${lolang === 'Spanish' ? "se ha colocado correctamente." : "has been placed successfully"}.`,
                      bid_count: data.bpop_bidcount,
                      bid_class: 'bid-success',
                      bid_or_not: 1,
                    });
                  } else {
                    updateProductSocket({
                      ...socketProduct,
                      current_price: data.bpop_wprice,
                      top_message: `${lolang === 'Spanish' ? "Estás ganando este artículo en" : "You are winning this item at"}. ${lolang === 'Spanish' ? "Tu autoBid de" : "Your autoBid of"} $${data.bpop_wprice_morehigh} ${lolang === 'Spanish' ? "se ha colocado correctamente." : "has been placed successfully"}.`,
                      bid_count: data.bpop_bidcount,
                      bid_class: 'bid-success',
                    });
                  }
                } else {
                  let top_message = `${lolang === 'Spanish' ? "Estás perdiendo este artículo, usuario" : "You are losing this item, user"} ${data.bpop_biddersname.charAt(
                    0
                  )}****${data.bpop_biddersname.charAt(
                    data.bpop_biddersname.length - 1
                  )} ${lolang === 'Spanish' ? "ha realizado una oferta automática más alta" : "has placed a higher autoBid"} .`;
                  updateProductSocket({
                    current_price: data.bpop_wprice,
                    next_bid: data.bpop_nextbid_org,
                    top_message: top_message,
                    bid_count: data.bpop_bidcount,
                    bid_class: 'bid-lost',
                    bid_or_not: 1,
                  });
                }
              } else {
                console.log('coming inside 6777777');
                updateProductSocket({
                  ...socketProduct,
                  current_price: data.bpop_wprice,
                  next_bid: data.bpop_nextbid_org,
                  bid_count: data.bpop_bidcount,
                });
              }
            }
            getBidHistoryProduct({ product_id: project_id });
          }
        }
      });
    }
  }, [onlyOnce]);

  useEffect(() => {
    if (product_bidhistory.length && props.match.params.id) {
      setProduct({
        ...product,
        product_bidhistory: product_bidhistory,
      });
    }
  }, [product_bidhistory]);

  useEffect(() => {
    if (product_view.results && props.match.params.id) {
      setSendMsg({
        ...sendMsg,
        to_id: product_view.results.user_id,
      });
      setBuynowDetails({
        ...buynowDetails,
        price: product_view.results.bprice,
        pid: props.match.params.id,
      });
      let lang = localStorage.getItem('language')
      if(lang === 'Spanish'){
        product_view.biddetails.bidtopstatus=  product_view.biddetails.bidtopstatus.replace("You are winning this item at","Estás ganando este artículo en")        
        product_view.biddetails.bidtopstatus=  product_view.biddetails.bidtopstatus.replace("Your autoBid of","Tu autoBid de")        
        product_view.biddetails.bidtopstatus=  product_view.biddetails.bidtopstatus.replace("has been placed successfully.","se ha colocado correctamente.")        
        
        product_view.biddetails.bidtopstatus=  product_view.biddetails.bidtopstatus.replace("You are losing this item, user","Estás perdiendo este artículo, usuario")        
        product_view.biddetails.bidtopstatus=  product_view.biddetails.bidtopstatus.replace("has placed a higher autoBid","ha realizado una oferta automática más alta") 

        product_view.biddetails.bidtopstatus=  product_view.biddetails.bidtopstatus.replace("Congratulations! You won this item.","Felicidades! ganasteste ariculo");
        product_view.biddetails.bidtopstatus=  product_view.biddetails.bidtopstatus.replace("Unfortunately, you lost this item.","Desafortunadamente, perdiste este articulo");
        
               
      }
      setproductSocket({
        ...productSocket,
        next_bid: product_view.biddetails.next_bid,
        current_price: product_view.results.wprice,
        bid_count: product_view.results.bids,
        top_message: product_view.biddetails.bidtopstatus,
        bid_class: product_view.biddetails.bidtopwinner
          ? 'bid-success'
          : 'bid-lost',
        bid_or_not: parseInt(product_view.results.cbidnot),
      });
      setbidForm({
        ...bidForm,
        id: props.match.params.id,
        wsprice: '',
        bid_increment: product_view.incrementamt,
      });
      setProduct({
        ...product,
        product_details: product_view.results,
        product_biddetails: product_view.biddetails,
        product_existingimage: product_view.attachments.map((i) => i.src),
      });
      setProddate_closed(product_view.results.date_closed);
      setSearch({
        ...search,
        lotof: product_view.results.lotof,
        category: product_view.results.category_id,
        location: product_view.results.location,
        exempt_id: product_view.results.id,
      });
      setLoading(false);
    }
  }, [product_view.results]);

  getProductSocket = () => {
    return {
      ...productSocket,
    };
  };

  useEffect(() => {
    getProjectID = () => {
      return parseInt(props.match.params.id);
    };
  }, [props.match.params.id]);

  useEffect(() => {
    if (Object.keys(user).length !== 0) {
      if (product_view.results && props.match.params.id) {
        if (parseInt(product_view.results.hasshipping) === 1) {
          // let dataRate = {
          //   mode: 'sandbox',
          //   fromzipcode: '91601',
          //   zipcode: user.zip,
          //   weight: parseInt(product_view.results.weight),
          //   length: parseInt(product_view.results.length),
          //   width: parseInt(product_view.results.width),
          //   height: parseInt(product_view.results.height),
          // };
          // getShippingRates(dataRate);
        }
      }
    }
  }, [user, product_view.results]);
  useEffect(() => {
    if (getProjectID() && getUserID() && (product.product_details.user_id && product.product_details.user_id != user.id)) {
      productViewed(getProjectID(), getUserID())
    }
  }, [user, product.product_details]);
  const onSubmit = async (e) => {
    e.preventDefault();
    button.attr('disabled', true);
    if ($('#BidForm').parsley().validate()) {
      if (props.match.params.id) {
        bidConfirm(bidForm);
      } else {
        button.attr('disabled', false);
      }
      return false;
    } else {
      button.attr('disabled', false);
      return false;
    }
  };

  const sendMessage = (e) => {
    e.preventDefault();
    if ($('#ContactForm').parsley().validate()) {
      sendUserMessages(sendMsg);
    } else {
      return false;
    }
  };

  const [buynowDetails, setBuynowDetails] = useState({
    price: 0,
    pid: 0,
    paid: 1,
    sales_tax: 0,
    sales_tax_amount: 0,
    shipping_amount: 0,
    total_invoice: 0,
  });
  const [open, setOpen] = useState(false);
  const [contact, setContact] = useState(false);

  const openPayment = () => {
    setOpen(!open);
  };

  const functionReset = () => {
    let project_id = getProjectID();
    getViewProduct({ product_id: project_id });
    if (open) {
      setOpen(!open);
    }
  };

  const openSellerContact = () => {
    setContact(!contact);
  };
  const onSuccessFunction = (payment) => {
    buynowDetails.sales_tax = payment.taxPercent;
    buynowDetails.sales_tax_amount = payment.taxAmount;
    buynowDetails.shipping_amount = payment.shippingAmount;
    buynowDetails.total_invoice = payment.totalAmount;
    buyNowProduct(buynowDetails);
  };

  useEffect(() => {
    if (responseStatusUser && responseStatusUser !== '') {
      if (responseStatusUser === 'message_successfullysend') {
        setAlert('Message successfuly sent', 'success');
        clearResponseUser();
        openSellerContact();
      } else if (responseStatusUser === 'message_notsend') {
        setAlert('Message not sent!', 'error');
        clearResponseUser();
        openSellerContact();
      } else {
        setAlert(responseStatusUser, 'error');
        clearResponseUser();
      }
    } else {
      clearResponseUser();
    }
  }, [responseStatusUser]);

  useEffect(() => {
    if (responseStatus && responseStatus !== '') {
      if (responseStatus === 'buynow_paid_successfull') {
        setAlert('Product successfully purchased', 'success');
        settoHome(true);
        clearResponse();
      } else if (
        responseStatus === 'watchlistadded_successfully' ||
        responseStatus === 'watchlistremoved_successfully'
      ) {
      } else {
        setAlert(responseStatus, 'error');
        clearResponse();
      }
    } else {
      clearResponse();
    }
  }, [responseStatus]);

  useEffect(() => {
    if (shipping_rates.length) {
      setShippingCost(shipping_rates[0].shipmentCost);
    }
  }, [shipping_rates]);
  const handleClickClose = () =>{ 
    setOpenDialog(false)
  };
  return (
    <Layout props={props}>
          <DialogComp open={openDialog}
            title='You are the winner!'
            desc = {<><p>Dear {user.first_name} {user.last_name},</p><p> You have won the product {product.product_details.title}</p></>}
            handleClickClose = {handleClickClose}
          />
          {toBackSearch ? <Redirect to="/search/back" /> : null}
          {toHome ? <Redirect to="/buyerdashboard" /> : null}
          {toRelink ? <Redirect to="/buyerdashboard" /> : null}
          <Loading loading={loading} />
          <ProductTop
            historyBack={historyBack}
            proddate_closed={proddate_closed}
            product={product}
            productSocket={productSocket}
            bidForm={bidForm}
            user={user}
            onChange={onChange}
            onSubmit={onSubmit}
            openPayment={openPayment}
            openSellerContact={openSellerContact}
            isAuthenticated={isAuthenticated}
          />
          <ProductTabs
            google={props.google}
            proddetail={product.product_details}
            biddetails={product.product_biddetails}
            bidhistory={product.product_bidhistory}
          />
          <SimilarAuctions products={search_allsimilarproducts} />
          <MakePayment
            open={open}
            shippingCost={shippingCost}
            openPayment={openPayment}
            amount={buynowDetails.price}
            onSuccess={onSuccessFunction}
            payType="buynow_pay"
            sendID={buynowDetails.pid}
          />
          <LAauctionModal
            open={contact}
            handleClose={openSellerContact}
            modaltitle="CONTACT SELLER"
          >
            <form id="ContactForm" data-parsley-Validate onSubmit={sendMessage}>
              <textarea
                className="form-control"
                onChange={onChangeMessage}
                name="message"
                data-parsley-required="true"
                placeholder="Enter message to send"
              />
              <button className="btn btn-green" type="submit">
                SEND MESSAGE
              </button>
            </form>
          </LAauctionModal>
    </Layout>
  );
};

const mapStateToProps = state => {
  return {
    Language : state.Language.languages
  }
}

export default connect(mapStateToProps)(GoogleApiWrapper({
  apiKey: 'AIzaSyDgv0jL6CUSHaxg4I8ZoD-ADuQqT6UGsP0',
})(ProductView)); 
