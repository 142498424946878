import React, { useReducer } from 'react';
import AuthContext from './authContext';
import AuthReducer from './authReducer';
import apiCall from '../../Common/ApiCall';
import setAuthToken from '../../Common/ApiHeader';

import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  USER_LOADED,
  USER_LOADED_INITAL,
  CLEAR_INITIAL,
  ONLY_ONCE,
  AUTH_ERROR,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  ADD_RECENT_SEARCH,
  LOGOUT,
  CLEAR_ERROR,
  RESPONSE_STATUS,
  CLEAR_RESPONSE,
  RECENT_SEARCH,
} from '../types';

const AuthState = (props) => {
  const initialState = {
    token: localStorage.getItem('token'),
    initialLogin: false,
    isAuthenticated: null,
    onlyOnce: false,
    isAdmin: null,
    loading: true,
    user: {},
    recent_search: {
      results: [],
      total_pagecnt: 0,
      total_only: 0,
    },
    membership: {},
    error: null,
    responseStatus: null,
  };

  const [state, dispatch] = useReducer(AuthReducer, initialState);

  // Register User
  const register = async (formData) => {
    console.log('triggering here');
    try {
      const [res] = await Promise.all([apiCall('post', 'register', formData)]);
      if (res.data.status === 'yes') {
        dispatch({
          type: REGISTER_SUCCESS,
          payload: res.data.message,
        });
      } else {
        dispatch({
          type: REGISTER_FAIL,
          payload: res.data.failedreason,
        });
      }
    } catch (err) {
      dispatch({
        type: REGISTER_FAIL,
        payload: 'Something went wrong!',
      });
    }
  };
  // Login User
  const login = async (formData) => {
    try {
      const [res] = await Promise.all([apiCall('post', 'login', formData)]);
      if (res.data.status === 'yes') {
        dispatch({
          type: LOGIN_SUCCESS,
          payload: res.data,
        });
        loadUser('initial');
      } else {
        dispatch({
          type: LOGIN_FAIL,
          payload: res.data.message,
        });
      }
    } catch (err) {
      dispatch({
        type: LOGIN_FAIL,
        payload: 'Something went wrong!',
      });
    }
  };
  // Load User
  const loadUser = async (type) => {
    if (localStorage.token) {
      setAuthToken(localStorage.token);
    }
    const [res] = await Promise.all([apiCall('post', 'getUserProfileDetails')]);
    if (res.data.success === 'yes') {
      if (type === 'initial') {
        dispatch({
          type: USER_LOADED_INITAL,
          payload: res.data,
        });
      } else {
        dispatch({
          type: USER_LOADED,
          payload: res.data,
        });
      }
    } else {
      dispatch({
        type: AUTH_ERROR,
      });
    }
  };
  const getGlobalVariable = async (type) => {
    const [res] = await Promise.all([apiCall('post', 'getSettings')]);
    if (res.data.success === 'yes') {
      res.data.settings.forEach((valdata) => {
        if (valdata.type === 'number') {
          global[valdata.variable] = parseInt(valdata.value);
        } else {
          global[valdata.variable] = valdata.value;
        }
      });
      global['salestax_settings'] = res.data.salestax;
      global['admin_bid_increment'] = res.data.bid_increment;
      global['admin_bid_default'] = res.data.default_value;
    }
  };

  const deleteSearch = async (formData) => {
    try {
      const [res] = await Promise.all([
        apiCall('post', 'delete_save_search', formData),
      ]);
      if (res.data.success === 'yes') {
        dispatch({
          type: RESPONSE_STATUS,
          payload: 'search_word_deleted',
        });
      }
    } catch (err) {
      console.log('Error', err);
    }
  };
  const saveSearch = async (formData) => {
    try {
      const [res] = await Promise.all([
        apiCall('post', 'add_save_search', formData),
      ]);
      if (res.data.success === 'yes') {
        dispatch({
          type: RESPONSE_STATUS,
          payload: 'search_word_added',
        });
      } else {
        dispatch({
          type: RESPONSE_STATUS,
          payload: 'search_word_existing',
        });
      }
    } catch (err) {
      console.log('Error', err);
    }
  };

  // get Recent Search
  const recentSearch = async (formData) => {
    try {
      const [res] = await Promise.all([
        apiCall('post', 'recent_searchwithpagination', formData),
      ]);
      if (res.data.success === 'yes') {
        dispatch({
          type: RECENT_SEARCH,
          payload: res.data,
        });
      } else {
        dispatch({
          type: RECENT_SEARCH,
          payload: [],
        });
      }
    } catch (err) {
      dispatch({
        type: RECENT_SEARCH,
        payload: [],
      });
    }
  };

  const clearResponse = () =>
    dispatch({
      type: CLEAR_RESPONSE,
    });

  // get Recent Search
  const addRecentSearch = async (data) => {
    if (!state.recent_search.find((o) => o.searchtext === data)) {
      dispatch({
        type: ADD_RECENT_SEARCH,
        payload: { searchtext: data },
      });
    }
  };

  // Log out
  const logout = () =>
    dispatch({
      type: LOGOUT,
    });
  // Clear Error
  const clearErrors = () =>
    dispatch({
      type: CLEAR_ERROR,
    });

  const clearInitial = () =>
    dispatch({
      type: CLEAR_INITIAL,
    });

  const setOnlyOnce = () =>
    dispatch({
      type: ONLY_ONCE,
    });
  return (
    <AuthContext.Provider
      value={{
        token: state.token,
        initialLogin: state.initialLogin,
        isAuthenticated: state.isAuthenticated,
        isAdmin: state.isAdmin,
        loading: state.loading,
        user: state.user,
        membership: state.membership,
        error: state.error,
        recent_search: state.recent_search,
        responseStatus: state.responseStatus,
        onlyOnce: state.onlyOnce,
        register,
        login,
        addRecentSearch,
        saveSearch,
        deleteSearch,
        logout,
        loadUser,
        getGlobalVariable,
        recentSearch,
        clearErrors,
        clearInitial,
        clearResponse,
        setOnlyOnce,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthState;
