import React from 'react';
import Layout from '../../Components/Layout';
import Accordion from '../../Components/Search/Accordian';

const Faq = () => {
  return (
    <Layout>
      <div className="container mt-5 mb-5 faq-wrapper">
        <h1 className="main-head mb-4 clearfix">FAQ</h1>
        <div className="faq-wrapper">
          <Accordion title="My Dashboard ">
            <div className="card-body">
              <p>
                Buyer Dashboard: Buyer dashboard feature gives bidders the ability to watch specific items, bid on multiple active auctions at the same time, have unlimited access to invoices, update basic profile details and view or update membership.
              </p>
              <img src="/images/db-pic1.png" alt="" className="mb-3" />
            </div>
          </Accordion>
         
          <Accordion title="Your Watchlist ">
            <div className="card-body">
              <p>
                Lists all items that you have bid on or watched in closing date
                order. Bidding from your dashboard is a great way to keep track
                of items if they are in different auctions but are closing in
                the same time frame. Once an item closes, it will be removed
                from your dashboard and moved to the View Closed Items page.
              </p>
              <p>
                *Tip - If bidding from your dashboard, be sure to click on the
                "Refresh Item Status" to get the most current view of the status
                of an item.
              </p>
              <img src="/images/watchlist.png" alt="" className="mb-3" />
            </div>
          </Accordion>
          <Accordion title="Your Bid History">
            <div className="card-body">
              <p>
                Will show you complete bid history for items that you have bid
                on. The Current Bid Summary total will show you the total
                maximum bids and current bids that you have on active auctions.
              </p>
              <img src="/images/bidhis.png" alt="" className="mb-3" />
            </div>
          </Accordion>
          <Accordion title="Your Invoices ">
            <div className="card-body">
              <p>
                Invoices for items won are stored on the bidder dashboard in a
                downloadable PDF format. At the close of an auction, bidders
                will receive an emailed notification that a new invoice is ready
                to view within their dashboard. Bidders will have a complete
                history of items purchased, the amount paid and the status of an
                invoice.
              </p>
              <img src="/images/invoice.png" alt="" className="mb-3" /> 
          </div>
          </Accordion>
          <Accordion title="I Won an Item! Now what ? ">
            <div className="card-body"> 
              <p>Winning bidder invoices will be updated to your dashboard within 10 minutes of the auction closing. Here you can view and download your winning bidder invoice. It is recommended that you print your invoice off as it will include pertinent location and contact information.</p>
              <p><b>IMPORTANT:</b> Winning bidder notifications are emailed at the conclusion of each auction. However, some email services can be unreliable and put these message into a Junk or Spam folder. Always check your Dashboard for a winning bidder invoice if you have been actively bidding on an auction.</p>
              <p>
                At this time you should be well aware of the auction location and the designated time to remove your items (found on the invoice as well as the bottom of every page) within the auction on LAAUCTIONDEPOT.COM . You can now go pick up your items! Pay close attention to the following items:
              </p> 
              <img src="/images/db-pic2.png" alt="" />
              <p>
                <b>Location:</b> Look for directional signs around the auction location and double check the auction location prior to leaving. Call LAAD if you have issues finding the location.
              </p>
              <p>
                <b>Removal:</b>This is the time you are expected to pay for and pick up the items. This is found on the bottom of each auction page and on your invoice. Removal dates vary for each auction so verify prior to bidding.
              </p>
              <p>
                <b>Payment Types Accepted:</b> Check the auction terms to verify the payment types that will accept. Cash and Credit Card are almost always accepted. Checks, Wire Transfers, and other payment types will vary by the Auction. Payment is due to the Close of the Action or the auction Pick up day. 
              </p>

              <p>
                <b>Contact Information:</b> Contact LAAD the auction if you have specific questions. Phone numbers located within the auction page and on your invoice.
              </p>
              <p>
                <b>Shipping:</b> If you require shipping and the auction provides it, follow the instructions on the shipping tab of the auction. Be sure to call the contact number on your invoice if you have specific questions about the removal or shipping.
              </p>
              <p>
                <b>Late Removals:</b> Never assume that late removals are possible. Most auctions have strict timelines that make late removals impossible. Please be sure to follow the terms of the auction to keep your bidding account in good standing on LAAUCTIONDEPOT.COM. If in doubt, contact the LAAD office the auction to clarify.
              </p>

              <img src="/images/bidder-1799.png" alt="" />
            </div>
          </Accordion>
          <Accordion title="How Does Bidding works ?">
            <div className="card-body">
              <img src="/images/bid-quan.png" alt="" />
              <img src="/images/bid-accepted.png" alt="" />
              <img src="/images/same-bid.png" alt="" />
            </div>
          </Accordion>
          <Accordion title="How Do I Pay Invoices OnLine">
            <div className="card-body">
              <p>
                Bidders now have the option to pay auction invoices online!
                These payments will go directly to the LA Auction Depot LLC
                managing the auction through the payment gateway.
              </p>
              <p>
                Bidders are still expected to pick up their items during the
                scheduled removal dates. Paying your invoice online does not
                guarantee alternate pickup options or that an item can be
                shipped.
              </p>
              <p>
                This quick tutorial will explain the online payment process:
              </p>
              <p>
                1. After the auction has closed, winning bidders will see the
                ability to initiate payment from the Your Invoices section of
                the bidder dashboard. The button shown to initiate payment will
                vary depending on which payment
              </p>
              <p>
                When the bidder clicks "Pay" popup will be appear to make the
                payment
              </p>

              <img src="/images/pay.png" alt="" className="mb-3" />
              <img src="/images/make-payment.png" alt="" className="mb-3" />
              <p>
                Once payment has been made the status will be changed to "Paid"
                and "Invoice" link will be appear to check the invoice details
              </p>
              <img src="/images/paid.png" alt="" className="mb-3" />
              </div>
          </Accordion>
          <Accordion title="What if I want to use a different card?">
            <div className="card-body"> 
              <p>
                If you want to pay with a different card than the one currently
                stored, you will need to go to payment page and add more your
                card by clicking on "Add Card" button. Once you submit card
                details it will appear in make payment popup
              </p>
              <img src="/images/add-more.png" alt="" className="mb-3" />
              <img src="/images/make-payment1.png" alt="" className="mb-3" />
            </div>
          </Accordion>
          <Accordion title="What if I  forget my Password/ Username ">
            <div className="card-body">
              <p>
                You can request a password reset by clicking on the "Forgot
                password" link on the log in page.
              </p>
              <img src="/images/fp-pic1.png" alt="" className="mb-3" />
              <p>
                You will be prompted to enter the email address you used to
                register:
              </p>
              <img src="/images/fp-pic2.png" alt="" className="mb-3" />
              <p>
                Check your email (including your junk folder!) for a link to
                reset your password.{' '}
              </p>
              <p>email From us with link here to reset your password </p>
              <img src="/images/fp-pic4.png" alt="" className="mb-3" />
              <img src="/images/fp-pic3.png" alt="" className="mb-3" />
            </div>
          </Accordion>
          <Accordion title="What is a Max Bid?">
            <div className="card-body">
              <p>
                All bids placed are in the max bid format. A max bid is the
                highest amount that you are willing to pay for an item and is
                kept private from other bidders until that amount has been
                exceeded. Bids are automatically placed on your behalf against
                other bidders until your maximum amount is reached.
              </p>
              <p>
                <b>Examples:</b>
              </p>
              <p>
                Starting bid price for an item is $5.00. Bidder 55555 places a
                $100.00 bid. No other bids are received and the auction closes.
                Bidder 55555 will remain the high bidder at $5.00 and the rest
                of his max bid will be unused.
              </p>
              <p>
                Starting bid price for an item is $5.00. Bidder 55555 places a
                $100.00 bid and becomes the high bidder at $5.00. Bidder 66666
                places a bid of $50.00 and is notified "Bid Received; Higher Bid
                Exists." The software will automatically place a bid for Bidder
                55555 for $51.00 (assuming the current bid increment is $1.00)
                so that they will remain the high bidder.
              </p>
              <p>
                Starting bid price for an item is $5.00. Bidder 55555 places a
                $100.00 bid and becomes the high bidder at $5.00. Bidder 66666
                places a bid of $110.00 and becomes the high bidder at $101.00
                (bidder 55555's max bid + the current bid increment).
              </p>
              <p>
                All bids must contain a decimal point preceded by at least one
                digit and be followed by two digits. eg: 2.55 (no dollar signs
                or comma separators).
              </p>
            </div>
          </Accordion>
          <Accordion title="What is a reserve? ">
            <div className="card-body">
              <p>
                A reserve is the amount that bidding must reach before an item
                will be sold. Items that have a reserve will state Reserve Not
                Met underneath the current bid price until that bid price has
                been met. Once a reserve price has been exceeded, a notice will
                state Reserve Has Been Met. Bids placed against an item that
                have a reserve will be consumed until the reserve price has been
                exceeded.
              </p>
            </div>
          </Accordion>
          <Accordion title="Is the item's owner Allowed to bid against me? ">
            <div className="card-body">
              <p>
                Absolutely not! An owner is never allowed to bid on their own
                items. Shill bidding is a direct breach of the terms of our
                website and affiliate agreement. If you have confirmation of any
                shill bidding please notify us immediately using this contact
                form.
              </p>
              <a class="btn btn-green" href="/contact-us">
                CONTACT US
              </a>
              {/* <img src="/images/fp-pic3.png" alt=""  className="mb-3"/> */}
            </div>
          </Accordion>
          <Accordion title="What is Buyer's Premium? ">
            <div className="card-body">
              <p>
                Buyer's premium (BP) is a percentage that is added on top of the
                final bid price. BP will be collected on every item sold on
                LAAD. This percentage can be found in the terms for each
                auction, but is typically 15% for items that sell below $7,000
                and 12% for items that sell above $7,000. Buyer's premium is
                capped at $1,000/item . For example, a $100.00 winning bid will
                equal an invoice amount of $118 plus applicable sales taxes.
              </p>
              <p>
                Bid Price
                <br />
                Buyer's Premium Rate Cap
                <br />
                0 - $6,999.99
                <br />
                15%
                <br />
                <br />
                $7,000+
                <br />
                12%
                <br />
                $1000/item Buyer’s Premium
                <br />
              </p>
              <p>
                The buyer’s premium on real estate auctions is not capped and
                varies by auction. The buyer’s premium for a real estate auction
                will be listed in the terms for that auction.
              </p>
            </div>
          </Accordion> 
          <Accordion title="Is shipping available for this auction/item?">
            <div className="card-body">
              <p>
                LA Auction Depot decides whether or not to offer to ship for
                some or all of the items in their auctions. It is important to
                verify shipping information for each auction before bidding on
                items that you are interested in. In order to find shipping
                information, visit the shipping tab on the bottom of the page
                within each auction or item:
              </p>
              <img src="/images/shipping1.png" alt="" className="mb-3" />
              <p>
                If you can not find the information that you are looking for, be
                sure to contact the LAAD who is managing the auction. The
                contact number for each auction can be found within the
                directions tab or the top of each page within the auction or
                item:
              </p>
              {/* <img src="/images/shipping2.png" alt=""  className="mb-3"/> */}
            </div>
          </Accordion>
          <Accordion title="What if I am not getting emails? ">
            <div className="card-body">
              <p>
                LA AUCTIONS DEPOT sends out the following emails: Outbid
                notifications. Invoice notifications. Password Reset Requests.
                Promotional Emails and Auction Notifications. To make sure you
                never miss an email from us, please add the following email
                addresses as contacts in your email service:
                <a href="mailto:postmaster@LAAUCTIONDEPOT.COM">
                  postmaster@LAAUCTIONDEPOT.COM
                </a>{' '}
                (For outbid notifications and invoice notifications)
                <a href="mailto:marketing@LAAUCTIONDEPOT.COM">
                  marketing@LAAUCTIONDEPOT.COM
                </a>{' '}
                (For informing you of all the new auctions) To manage your email
                preferences, navigate to your Account Page while logged into
              </p>
            </div>
          </Accordion>
          <Accordion title="How was I outbid by less than the next required bid increment?">
            <div className="card-body">
              <p>
                Being outbid by less than a bid increment is definitely possible
                and, in fact, occurs frequently. To understand how this can
                happen, it’s important to remember the ‘Next Required Bid’
                simply indicates the minimum amount of the next bid. As long as
                the next bid placed is equal to or greater than the ‘Next
                Required Bid’, the bid will be accepted. The following example
                illustrates how a bidder can be outbid by less than the next
                required bid increment.
              </p>
              <p>
                The high bid on an active auction item is currently $500.00; the
                bid increment is $50.00. Remember that every bid placed is
                placed in a max bid format.
              </p>
              <p>
                Bidder 55555 places a max bid of $700.00. Bidder 55555 is now in
                the lead with a high bid of $550, or one bid increment above the
                previous high bid of $500.00. The ‘Next Required Bid’ is now
                $600.00.
              </p>
              <p>
                Bidder 66666 places a bid for $600. Since bidder 55555 placed a
                max bid of $700.00, he will remain the high bidder with a
                current bid of $650.00, or one bid increment above bidder
                66666's bid of $600. The next required bid is now $700.00; which
                indicates the minimum amount of the next bid must be equal to or
                greater than $700.
              </p>
              <p>
                Bidder 66666 decides to place a bid of $710.00. Since bidder
                55555's max bid of $700.00 was reached and surpassed, bidder
                66666 is the high bidder at $710.00. The ‘Next Required Bid’ is
                now $760.00, or one bid increment above bidder 66666's bid of
                $710.00.
              </p>
              <p>
                If no other bids are placed before the auction closes, bidder
                66666 will be the winning bidder of the item with a high bid of
                $710.
              </p>
            </div>
          </Accordion>
          <Accordion title="What if I'm Exempt from Sales Taxes ">
            <div className="card-body">
              <p>
                Proof of tax exemption must be provided at or before picking up
                your items from the auction location.
              </p>
              <p>
                For auctions held in California , tax-exempt buyers must provide
                a form for each Auction
              </p>
              <a
                className="btn btn-green"
                href="https://www.cdtfa.ca.gov/formspubs/cdtfa230.pdf"
                rel="noopener noreferrer"
                target="_blank"
              >
                Click here for Resale Certificate Form
              </a>
            </div>
          </Accordion>
          <Accordion title="How Does the Auction Process Work for the Seller?">
            <div className="card-body">
              <p>
                Below are the general operating procedures followed by many
                Sellers.
              </p>
              <p>
                <b>Initial Contact with Seller</b> – The seller provides the affiliate
                with information on the asset(s) they have to sell.
              </p>
              <p>
                <b>Consignment Agreement</b> – After the terms of the sale are
                negotiated, the LAAD is responsible for drawing up a consignment
                agreement that details all relevant issues and expectations of
                the sale -- both parties sign the agreement.
              </p>
              <p>
                <b>Asset Descriptions & Pictures</b> – This is the most crucial step of
                any auction. The quality and reliability of an asset’s
                description and pictures directly impacts the final bid price
                and ultimately the successful sale of the asset(s). Sellers
                provide the LAAD with detailed information about their
                asset(s); the LAAD translates this information into an item
                description specific to that asset. The LAAD also photographs
                the asset ensuring the photos accurately depict the asset.
              </p>
              <p>
                <b>Important Note:</b> Full disclosure is a fundamental requirement for
                every asset listed on LAAUCTIONDEPOT.COM. Item descriptions and
                pictures are assumed to be complete and accurate. Bidders are
                not penalized for not honoring bids for assets with item
                descriptions that failed to disclose information material to the
                asset’s value.
              </p>
              <p>
                <b>Auction Published</b> - Once an auction goes “live”, the LAAD is
                responsible for fielding all questions relating to the auction.
                The LAAD will hold an inspection for the auction, allowing
                interested bidders an opportunity to see, touch and ask
                questions about the assets being sold. Auction Close - After the
                auction closes, auction invoices appear on the dashboard of
                winning bidders.
              </p>
              <p>
                <b>Removal Day</b> – The LAAD is responsible for managing all aspects
                of the auction removal --- from ensuring invoice and payment
                accuracy to dispensing assets to the winning bidder. Settlements
                and Sales Taxes – (15) Fifteen days after the auction closes,
                the affiliate provides the seller with a detailed settlement
                sheet and payment for the assets sold on the auction (final bid
                prices less any commission and fees detailed in the consignment
                agreement). The LAAD is responsible for submitting the sales
                taxes collected for the auction to the appropriate state agency.
              </p>
            </div>
          </Accordion>
          <Accordion title="How do I know my assets will sell on LAAUCTIONDEPOT.COM? ">
            <div className="card-body">
              <p>
                There are many factors that will make up a successful auction on{' '}
                <b>LAAUCTIONDEPOT.COM</b>.
              </p>
              <p>
                <b>Value of the items:</b> Buyers are only interested in the
                assets that are available. Higher value items will inevitably
                receive higher bid prices and more competition. Low value items
                or "garage sale" leftovers typically perform poorly and most
                affiliates are unable to accept them or liquidate them
                profitably.
              </p>
              <p>
                <b>Condition of the items:</b> Assets in clean, working
                condition perform the best on LAAUCTIONDEPOT.COM. Potential
                buyers make purchasing decisions based off the pictures that are
                provided. A clean and orderly sale location can make a potential
                buyer comfortable with the assets they are bidding on.
              </p>
              <p>
                <b>Number of buyers:</b> Presenting your auction on
                LAAUCTIONDEPOT.COM. already gives you a leg up when it comes to
                potential buyers. With nearly 10,000 page views each month and
                thousands active bidders, LAAD is the premier online auction
                website in the Southern California. However, certain auctions
                and situations will deserve extra exposure in order to find the
                "right" buyers. LAAD have access to comprehensive marketing
                options and expertise to help sellers find the right target
                audience for their assets.
              </p>
              <p>
                <b>Experience:</b> Many LAAD affiliates have years of experience
                in the auction field. This experience can help maximize return
                in the online auction format. There are many options when it
                comes to choosing how to liquidate your assets, make sure that
                you do not discount the importance that an experienced network
                can provide.
              </p>
            </div>
          </Accordion>
          <Accordion title="What if I can't See or Download Pictures">
            <div className="card-body">
              <p>
                If you are having issues seeing pictures on LAAUCTIONDEPOT.COM,
                it may be an issue with a security product installed on your
                computer. If you do have a security product installed on your
                computer, try disabling it on LAAUCTIONDEPOT.COM or adding
              </p>
              <p>LAAUCTIONDEPOT.COM on the firewall whitelist.</p>
              <p>Are you using Spectrum Security Suite?</p>
              <p>Try the following three solutions:</p>
              <ul>
                <li>
                  1. Adding www.LAAuctiondepot.com to the firewall rules
                  exceptions.
                </li>
                <li>
                  2. Turning off the Security Suite Protections and then turning
                  them back on.
                </li>
                <li>
                  3. Contact Your Internet Provider Security for further
                  assistance{' '}
                </li>
              </ul>
            </div>
          </Accordion>
           <Accordion title="Description of condition names in Post your marketplace auction">
            <div className="card-body">
               <div className="media">
                  <div className="media-left">
                      <img src="/images/new-box.png" alt="" className="mb-3" />
                  </div>
                  <div className="media-left">
                     <h5>New</h5>
                     <p>Never been used. Fully functional. Comes in original retail packaging, which may show wear and/or contains stickers.</p>
                  </div>
               </div>
               <div className="media">
                  <div className="media-left">
                    <img src="/images/like-new.png" alt="" className="mb-3" />
                  </div>
                  <div className="media-left">
                     <h5>Like New</h5>
                     <p>Fully functional, Will show signs of light use. May come in a bag or brown box and may contain stickers. May be missing manuals.</p>
                  </div>
               </div>
               <div className="media">
                  <div className="media-left">
                     <img src="/images/uninspect.png" alt="" className="mb-3" />
                  </div>
                  <div className="media-left">
                     <h5>Uninspected Returns</h5>
                     <p>Condition is unknown. May be working, or may be non-functional. May not come in original packaging. Sets may not be complete.</p>
                  </div>
               </div>
               <div className="media">
                  <div className="media-left">
                    <img src="/images/scratch.png" alt="" className="mb-3" />
                  </div>
                  <div className="media-left">
                     <h5>Scratch & Dent</h5>
                     <p>Siginificant cosmetic and/or functional defects, May be missing manuals, batteries or accessories. Often not in original retails packaging</p>
                  </div>
               </div>
               <div className="media">
                  <div className="media-left">
                     <img src="/images/slavage.png" alt="" className="mb-3" />
                  </div>
                  <div className="media-left">
                     <h5>Salvage</h5>
                     <p>Not functional and usually can only be used for parts. Missing manuals, batteries, chargers and other accessories.</p>
                  </div>
               </div>
            </div>
          </Accordion>
        </div>
      </div>
    </Layout>
  );
};

export default Faq;
