import {
  BID_PRODUCT,
  GET_VIEW_PRODUCT,
  GET_BID_HISTORY,
  RESPONSE_STATUS,
  CLEAR_RESPONSE,
  GET_ALL_BUYER,
  GET_ALL_TRANSACTION
} from './buyerTypes';

export default (state, action) => {
  switch (action.type) {
    case BID_PRODUCT:
      return {
        ...state,
        responseStatus: action.payload
      };
    case GET_VIEW_PRODUCT:
      return {
        ...state,
        product_view: action.payload
      };
    case GET_BID_HISTORY:
      return {
        ...state,
        product_bidhistory: action.payload
      };
    case GET_ALL_BUYER:
      return {
        ...state,
        buyer_allproducts: action.payload
      };
    case GET_ALL_TRANSACTION:
      return {
        ...state,
        buyer_alltransaction: action.payload
      };
    case RESPONSE_STATUS:
      return {
        ...state,
        responseStatus: action.payload
      };
    case CLEAR_RESPONSE:
      return {
        ...state,
        responseStatus: ''
      };
    default:
      return state;
  }
};
