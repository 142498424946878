import React, { useReducer } from 'react';
import UserContext from './userContext';
import ProfileReducer from './userReducer';
import apiCall from '../../Common/ApiCall';

import {
  EDIT_PROFILE,
  GET_EDIT_PROFILE,
  CHANGE_PWD,
  GET_PFR,
  GET_MSG_USER_LIST,
  GET_BLOG_LIST,
  GET_SINGLE_BLOG,
  GET_ONE_MSG_USER,
  GET_STATIC_PAGE,
  CLEAR_RESPONSE,
  UPDATE_USER_DYN_PROFILE,
  RESPONSE_STATUS,
} from './userTypes';

const UserState = (props) => {
  const initialState = {
    buyerseller_messages: [],
    preference: [],
    blogposted: {
      results: [],
      total_pagecnt: 0,
      total_only: 0,
    },
    blogcontent: {},
    userlist_messages: {
      results: [],
      total_pagecnt: 0,
      total_only: 0,
    },
    static_page: {
      content: '',
    },
    edit_profile: {},
    responseStatus: null,
  };

  const [state, dispatch] = useReducer(ProfileReducer, initialState);

  // const updateProfile = async formData => {
  //   console.log('updating profile',formData);
  //   try {
  //     const [res] = await Promise.all([
  //       apiCall('post', 'updateUserProfile', formData, 'formdata')
  //     ]);
  //     if (res.data.status === 'yes') {
  //       dispatch({
  //         type: EDIT_PROFILE,
  //         payload: res.data.message
  //       });
  //     } else {
  //       dispatch({
  //         type: RESPONSE_STATUS,
  //         payload: res.data.failedreason
  //       });
  //     }
  //   } catch (err) {
  //     dispatch({
  //       type: RESPONSE_STATUS,
  //       payload: err.response.data
  //     });
  //   }
  // };

  const editProfile = async (formData) => {
    try {
      const [res] = await Promise.all([
        apiCall('post', 'updateUserProfile', formData, 'formdata'),
      ]);
      if (res.data.status === 'yes') {
        dispatch({
          type: EDIT_PROFILE,
          payload: 'profile_updated_completed',
          // payload: res.data.message
        });
      } else {
        dispatch({
          type: RESPONSE_STATUS,
          payload: res.data.failedreason,
        });
      }
    } catch (err) {
      dispatch({
        type: RESPONSE_STATUS,
        payload: 'Something went wrong!',
      });
    }
  };

  const updateUserProfileDynamic = async (formData) => {
    try {
      const [res] = await Promise.all([
        apiCall('post', 'updateUserDetails', formData),
      ]);
      if (res.data.status === 'yes') {
        dispatch({
          type: UPDATE_USER_DYN_PROFILE,
          payload: 'user_updated_successfull',
          // payload: res.data.data.result
        });
      } else {
        dispatch({
          type: RESPONSE_STATUS,
          payload: res.data.message,
        });
      }
    } catch (err) {
      dispatch({
        type: RESPONSE_STATUS,
        payload: 'Something went wrong!',
      });
    }
  };

  const getEditProfile = async (formData) => {
    try {
      const [res] = await Promise.all([
        apiCall('post', 'getUserDetails', formData),
      ]);
      if (res.data.status === 'yes') {
        dispatch({
          type: GET_EDIT_PROFILE,
          payload: res.data.data,
          // payload: res.data.data.result
        });
      } else {
        dispatch({
          type: RESPONSE_STATUS,
          payload: res.data.failedreason,
        });
      }
    } catch (err) {
      dispatch({
        type: RESPONSE_STATUS,
        payload: 'Something went wrong!',
      });
    }
  };

  const changeForgotPassword = async (formData) => {
    try {
      const [res] = await Promise.all([
        apiCall('post', 'change-password', formData),
      ]);
      if (res.data.success === 'yes') {
        dispatch({
          type: CHANGE_PWD,
          payload: 'password_changed_successful',
        });
      } else {
        dispatch({
          type: RESPONSE_STATUS,
          payload: res.data.reason,
        });
      }
    } catch (err) {
      dispatch({
        type: RESPONSE_STATUS,
        payload: 'Something went wrong!',
      });
    }
  };

  const forgotPassword = async (formData) => {
    try {
      const [res] = await Promise.all([
        apiCall('post', 'forgot_password', formData),
      ]);
      if (res.data.success === 'yes') {
        dispatch({
          type: CHANGE_PWD,
          payload: 'forgot_password_successful',
        });
      } else {
        dispatch({
          type: RESPONSE_STATUS,
          payload: res.data.message,
        });
      }
    } catch (err) {
      dispatch({
        type: RESPONSE_STATUS,
        payload: 'Something went wrong!',
      });
    }
  };

  const getpreference = async (formData) => {
    try {
      const [res] = await Promise.all([
        apiCall('post', 'getnotification', formData),
      ]);
      if (res.data.success === 'yes') {
        dispatch({
          type: GET_PFR,
          payload: res.data.data,
        });
      } else {
        dispatch({
          type: RESPONSE_STATUS,
          payload: res.data.successreason
            ? res.data.successreason
            : res.data.message,
        });
      }
    } catch (err) {
      dispatch({
        type: RESPONSE_STATUS,
        payload: 'Something went wrong!',
      });
    }
  };

  const updatepreference = async (formData) => {
    try {
      const [res] = await Promise.all([
        apiCall('post', 'updatenotification', formData),
      ]);
      if (res.data.success === 'yes') {
        dispatch({
          type: RESPONSE_STATUS,
          payload: 'preference_updated_successful',
        });
      } else {
        dispatch({
          type: RESPONSE_STATUS,
          payload: res.data.successreason
            ? res.data.successreason
            : res.data.message,
        });
      }
    } catch (err) {
      dispatch({
        type: RESPONSE_STATUS,
        payload: 'Something went wrong!',
      });
    }
  };
  const changepwd = async (formData) => {
    try {
      const [res] = await Promise.all([
        apiCall('post', 'updatepassword_in_profile', formData),
      ]);
      if (res.data.success === 'yes') {
        dispatch({
          type: CHANGE_PWD,
          payload: 'password_updated_successful',
        });
      } else {
        dispatch({
          type: RESPONSE_STATUS,
          payload: res.data.successreason
            ? res.data.successreason
            : res.data.message,
        });
      }
    } catch (err) {
      dispatch({
        type: RESPONSE_STATUS,
        payload: 'Something went wrong!',
      });
    }
  };

  const addNewBlogPost = async (formData) => {
    try {
      const [res] = await Promise.all([
        apiCall('post', 'insertBlogPost', formData),
      ]);
      if (res.data.status === 'yes') {
        dispatch({
          type: RESPONSE_STATUS,
          payload: 'blog_successfullysend',
        });
      } else {
        dispatch({
          type: RESPONSE_STATUS,
          payload: 'blog_notsend',
        });
      }
    } catch (err) {
      dispatch({
        type: RESPONSE_STATUS,
        payload: 'blog_notsend',
      });
    }
  };

  const getOneBlog = async (formData) => {
    try {
      const [res] = await Promise.all([
        apiCall('post', 'get_blogPost', formData),
      ]);
      if (res.data.success === 'yes') {
        dispatch({
          type: GET_SINGLE_BLOG,
          payload: res.data.results,
        });
      } else {
        dispatch({
          type: RESPONSE_STATUS,
          payload: res.data.failedreason,
        });
      }
    } catch (err) {
      dispatch({
        type: RESPONSE_STATUS,
        payload: 'Something went wrong!',
      });
    }
  };

  const getBlogDetails = async (formData) => {
    try {
      const [res] = await Promise.all([
        apiCall('post', 'list_BlogPagination', formData),
      ]);
      if (res.data.success === 'yes') {
        dispatch({
          type: GET_BLOG_LIST,
          payload: res.data,
        });
      } else {
        dispatch({
          type: RESPONSE_STATUS,
          payload: res.data.failedreason,
        });
      }
    } catch (err) {
      dispatch({
        type: RESPONSE_STATUS,
        payload: 'Something went wrong!',
      });
    }
  };

  const sendUserMessages = async (formData) => {
    try {
      const [res] = await Promise.all([
        apiCall('post', 'insertMessages', formData),
      ]);
      if (res.data.status === 'yes') {
        dispatch({
          type: RESPONSE_STATUS,
          payload: 'message_successfullysend',
        });
      } else {
        dispatch({
          type: RESPONSE_STATUS,
          payload: 'message_notsend',
        });
      }
    } catch (err) {
      dispatch({
        type: RESPONSE_STATUS,
        payload: 'message_notsend',
      });
    }
  };

  const getUserMessages = async (formData) => {
    try {
      const [res] = await Promise.all([
        apiCall('post', 'list_buyerPagination', formData),
      ]);
      if (res.data.success === 'yes') {
        dispatch({
          type: GET_MSG_USER_LIST,
          payload: res.data,
        });
      } else {
        dispatch({
          type: RESPONSE_STATUS,
          payload: res.data.failedreason,
        });
      }
    } catch (err) {
      dispatch({
        type: RESPONSE_STATUS,
        payload: 'Something went wrong!',
      });
    }
  };

  const getOneUserBuyerMessages = async (formData) => {
    try {
      const [res] = await Promise.all([
        apiCall('post', 'get_chathistory', formData),
      ]);
      if (res.data.status === 'yes') {
        dispatch({
          type: GET_ONE_MSG_USER,
          payload: res.data.data,
        });
      } else if (res.data.status === 'no') {
        dispatch({
          type: GET_ONE_MSG_USER,
          payload: [],
        });
      } else {
        dispatch({
          type: RESPONSE_STATUS,
          payload: res.data.failedreason,
        });
      }
    } catch (err) {
      dispatch({
        type: RESPONSE_STATUS,
        payload: 'Something went wrong!',
      });
    }
  };

  // Register User
  const careerForm = async (formData) => {
    try {
      const [res] = await Promise.all([
        apiCall('post', 'send_careerform', formData, 'formdata'),
      ]);
      if (res.data.success) {
        dispatch({
          type: RESPONSE_STATUS,
          payload: 'Career successful',
        });
      } else {
        dispatch({
          type: RESPONSE_STATUS,
          payload: res.data.error,
        });
      }
    } catch (err) {
      dispatch({
        type: RESPONSE_STATUS,
        payload: 'Something went wrong!',
      });
    }
  };

  // Register User
  const sellingForm = async (formData) => {
    try {
      const [res] = await Promise.all([
        apiCall('post', 'send_sellingform', formData),
      ]);
      if (res.data.success) {
        dispatch({
          type: RESPONSE_STATUS,
          payload: 'Seller successful',
        });
      } else {
        dispatch({
          type: RESPONSE_STATUS,
          payload: res.data.error,
        });
      }
    } catch (err) {
      dispatch({
        type: RESPONSE_STATUS,
        payload: 'Something went wrong!',
      });
    }
  };

  // Register User
  const contactUs = async (formData) => {
    try {
      const [res] = await Promise.all([
        apiCall('post', 'send_contactus', formData),
      ]);
      if (res.data.success) {
        dispatch({
          type: RESPONSE_STATUS,
          payload: 'Contact successful',
        });
      } else {
        dispatch({
          type: RESPONSE_STATUS,
          payload: res.data.error,
        });
      }
    } catch (err) {
      dispatch({
        type: RESPONSE_STATUS,
        payload: 'Something went wrong!',
      });
    }
  };

  const getStaticPage = async (formData) => {
    try {
      const [res] = await Promise.all([
        apiCall('post', 'show_static_content', formData),
      ]);
      if (res.data.status === 'true') {
        dispatch({
          type: GET_STATIC_PAGE,
          payload: res.data.data[0],
          // payload: res.data.data.result
        });
      } else {
        dispatch({
          type: RESPONSE_STATUS,
          payload: res.data.message,
        });
      }
    } catch (err) {
      dispatch({
        type: RESPONSE_STATUS,
        payload: 'Something went wrong!',
      });
    }
  };

  const clearResponse = () =>
    dispatch({
      type: CLEAR_RESPONSE,
    });
  return (
    <UserContext.Provider
      value={{
        // profile: state.profile,
        responseStatus: state.responseStatus,
        editProfile,
        clearResponse,
        updateUserProfileDynamic,
        getUserMessages,
        getBlogDetails,
        getOneBlog,
        getOneUserBuyerMessages,
        sendUserMessages,
        addNewBlogPost,
        // updateProfile,
        edit_profile: state.edit_profile,
        preference: state.preference,
        static_page: state.static_page,
        userlist_messages: state.userlist_messages,
        blogcontent: state.blogcontent,
        blogposted: state.blogposted,
        buyerseller_messages: state.buyerseller_messages,
        getEditProfile,
        changepwd,
        updatepreference,
        getpreference,
        forgotPassword,
        changeForgotPassword,
        getStaticPage,
        contactUs,
        sellingForm,
        careerForm,
        // viewProfile
      }}
    >
      {props.children}
    </UserContext.Provider>
  );
};

export default UserState;
