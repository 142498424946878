/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState, useEffect } from 'react';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import { Redirect } from 'react-router-dom';
import BuyerContext from '../../context/buyer/buyerContext';
import ProductContext from '../../context/product/productContext';
import { Pagination } from '@material-ui/lab';
import Timer from '../../Common/Timer';
import apiCall from '../../Common/ApiCall';
import { dateFormatFront } from '../../Common/Components';
import { Link } from 'react-router-dom';
import Loading from '../../Components/Loading';
import {connect} from 'react-redux';

const AllBids = (props) => {
  const buyerContext = useContext(BuyerContext);
  const productContext = useContext(ProductContext);

  const { buyer_allproducts, getAllBuyerProducts } = buyerContext;
  const [loading, setLoading] = useState(true);
  const [toInvoice, settoInvoice] = useState(false);
  const [toInvoiceID, settoInvoiceID] = useState(false);
  const { responseStatus } = productContext;
  const [search, setSearch] = useState({
    status: props.page,
    sh_limit: 10,
    page: 1,
    order: 1,
  });

  const onHandlePage = (event, value) => {
    setSearch({ ...search, page: value });
  };
  useEffect(() => {
    setLoading(false);
  }, [buyer_allproducts]);
  useEffect(() => {
    if (responseStatus && responseStatus !== '') {
      if (responseStatus === 'buynow_updated_successfull') {
        getAllBuyerProducts(search);
      } else {
      }
    } else {
    }
  }, [responseStatus]);

  useEffect(() => {
    setLoading(true);
    getAllBuyerProducts(search);
  }, [search]);

  const onClickUnPaidInvoice = async (inv_id) => {
    if (inv_id) {
      settoInvoiceID(inv_id);
      let invoiceData = {
        cinvoice: inv_id,
      };
      const [res] = await Promise.all([
        apiCall('post', 'unpaidadminivoicealgo', invoiceData, ''),
      ]);
      if (res.data.msg === 'fail') {
      } else {
        settoInvoice(true);
      }
    }
  };

  return (
    <Container>
      {toInvoice ? <Redirect to={`invoice/${toInvoiceID}`} /> : null}
      <Loading loading={loading} />
      {buyer_allproducts.results.length ? (
        <>
          {buyer_allproducts.results.map((item, index) => {
            return (
              <Box className="db-pro-box" key={index}>
                <div className="row">
                  <div className="col-sm-4">
                    <div className="media">
                      <div className="media-left">
                        <img
                          className="pro-img"
                          src={`${global.image_url}${item.avatar}`}
                          alt={item.title}
                        />
                      </div>
                      <div className="media-body">
                        <div className="pro-title">{item.title}</div>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-8">
                    <div className="row">
                      {props.page === 'bidactive' ? (
                        <>
                          <div className="col-sm-6 col-lg-3">
                            <div className="slv-timer">
                              <Timer
                                date_added={item.date_added}
                                date_closed={item.date_closed}
                                withText={1}
                              ></Timer>
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          {props.page === 'watchlist' ? (
                            <>
                              {item.market_status === 'open' ? (
                                <div className="col-sm-6 col-lg-3">
                                  <div className="slv-timer">
                                    <Timer
                                      date_added={item.date_added}
                                      date_closed={item.date_closed}
                                      withText={1}
                                    ></Timer>
                                  </div>
                                </div>
                              ) : (
                                <div className="col-sm-6 col-lg-3">
                                  {props.Language.date_closed ? props.Language.date_closed : "Date Closed"}
                                  {dateFormatFront(item.buynowdate_added)}
                                </div>
                              )}
                            </>
                          ) : (
                            <div className="col-sm-6 col-lg-3">
                              {props.Language.date_closed ? props.Language.date_closed : "Date Closed"}
                              {dateFormatFront(item.buynowdate_added)}
                            </div>
                          )}
                        </>
                      )}

                      <div className="col-sm-6 col-lg-3">
                        <div>
                        {props.Language.start_prc ? props.Language.start_prc : 'Start Price'} <b>${item.sprice}</b>
                        </div>
                        <div>
                        {props.Language.quantttt?props.Language.quantttt:"Quantity"} :{' '}
                          <b>
                            {props.page === 'buynow'
                              ? item.buynowquantity
                              : item.qty}
                          </b>
                        </div>
                      </div>  
                      <div className="col-sm-6 col-lg-4">
                        <div>
                        {props.Language.loc ? props.Language.loc : "Location"} : <b>{item.location}</b>
                        </div>
                        {props.page === 'bidactive' ? (
                          <>
                            <div>
                              Current price : <b>${item.wprice}</b>
                            </div>
                          </>
                        ) : (
                          <>
                            {props.page === 'bidwon' ||
                            props.page === 'buynow' ? (
                              <>
                                <div>
                                {props.Language.ham_prce ? props.Language.ham_prce : 'Hammer Price'} :{' '}
                                  <b>
                                    $
                                    {parseInt(item.content_head1) > 0
                                      ? parseFloat(
                                          parseInt(item.buynowamount) *
                                            parseInt(item.qty)
                                        ).toFixed(2)
                                      : item.buynowamount}
                                  </b>
                                </div>
                              </>
                            ) : (
                              <>
                                {item.market_status === 'open' ? (
                                  <>
                                    <div>
                                    {props.Language.currPric ? props.Language.currPric : 'Current price'} : <b>${item.wprice}</b>
                                    </div>
                                  </>
                                ) : (
                                  <>
                                    <div className="">
                                    {props.Language.finalPric ? props.Language.finalPric : 'Final price'} :${item.wprice}
                                    </div>
                                  </>
                                )}
                              </>
                            )}
                          </>
                        )}
                      </div>
                      <div className="col-sm-6 col-lg-2">
                        {props.page === 'bidwon' ? (
                          <>
                            {item.buynowpaid === 1 ? (
                              <>
                                <Link to={`invoice/${item.common_invoice}`}>
                                  <label className="label cursorPointer label-success mb-2">
                                  {props.Language.inv_cap ? props.Language.inv_cap : 'INVOICE'}
                                  </label>
                                </Link>
                              </>
                            ) : null}
                          </>
                        ) : props.page === 'buynow' ? (
                          <>
                            <Link to={`invoice/${item.common_invoice}`}>
                              <label className="label cursorPointer label-success mb-2">
                              {props.Language.inv_cap ? props.Language.inv_cap : 'INVOICE'}
                              </label>
                            </Link>
                          </>
                        ) : null}

                        {props.page === 'bidactive' ? (
                          <>
                            <Link to={`product-view/${item.id}`}>
                              <label className="label cursorPointer label-success mb-2">
                                {item.market_status}
                              </label>
                            </Link>
                          </>
                        ) : (
                          <>
                            {props.page === 'bidwon' ? (
                              <>
                                {item.buynowpaid === 1 ? (
                                  <>
                                    <Link to={`product-view/${item.id}`}>
                                     <label className="label cursorPointer label-success mb-2">
                                        PAID
                                      </label>
                                    </Link>
                                  </>
                                ) : (
                                  <>
                                    {/* <label className="label label-success">
                                      <span
                                        onClick={() =>
                                          props.openPayment(
                                            item.buynowid,
                                            item.buynowamount,
                                            item.id
                                          )
                                        }
                                      >
                                        PAY
                                      </span> 
                                      </label>*/}
                                    <label
                                      onClick={() =>
                                        onClickUnPaidInvoice(
                                          item.common_invoice
                                        )
                                      }
                                      className="label cursorPointer label-success mb-2"
                                    >
                                     {props.Language.inv_cap ? props.Language.inv_cap : 'INVOICE'}
                                    </label>
                                  </>
                                )}
                              </>
                            ) : (
                              <>
                                <Link to={`product-view/${item.id}`}>
                                  <label className="label cursorPointer label-success mb-2">
                                    {props.page === 'buynow'
                                      ? 'PAID'
                                      : item.market_status}
                                  </label>
                                </Link>
                              </>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </Box>
            );
          })}

          <div className="d-flex mt-3">
            <div className="ml-auto mt-2">
            {props.Language.owningsh ? props.Language.owningsh : "Showing"} {search.sh_limit * (search.page - 1) + 1} -{' '}
              {search.sh_limit * search.page > buyer_allproducts.total_pagecnt
                ? buyer_allproducts.total_pagecnt
                : search.sh_limit * search.page}{' '}
              of {buyer_allproducts.total_pagecnt}
            </div>
            <Pagination
              count={Math.ceil(
                buyer_allproducts.total_pagecnt / search.sh_limit
              )}
              page={search.page}
              onChange={onHandlePage}
            />
          </div>
        </>
      ) : (
        <>
          <Box className="db-pro-box">
            <div className="row">
      <div className="col-sm-12 text-center"> {props.Language.noprod ? props.Language.noprod : 'No Products'} </div>
            </div>
          </Box>
        </>
      )}
    </Container>
  );
};

const mapStateToProps = state => {
  return {
    Language : state.Language.languages
  }
}

export default connect(mapStateToProps)(AllBids);
