import moment from 'moment';
import $ from 'jquery';
import axios from 'axios';
import { socket } from './Socket';
let serverTime = new Date();

if (socket) {
  socket.on('sliservertime', (data) => {
    if (moment(data.dTime).isValid()) {
      serverTime = new Date(data.dTime);
    }
  });
}

export const onChangeFunction = (e, data) => {
  if (e.target.type === 'checkbox') {
    return {
      ...data,
      [e.target.name]: e.target.checked ? 1 : 0,
    };
  } else if (e.target.type === 'file') {
    const files = e.target.files;
    const newImages = [];
    let itemsProcessed = 0;
    for (var i in files) {
      if (typeof files[i] == 'object') {
        newImages.push(files[i]);
      }
      itemsProcessed++;
      console.log('itemsProcessed', itemsProcessed, files.length);
      if (itemsProcessed === files.length) {
        console.log('changin ghere');
        return {
          ...data,
          [e.target.name]: newImages,
        };
      }
    }
  } else if (e.target.type === 'select-multiple') {
    return {
      ...data,
      [e.target.name]: [...e.target.options]
        .filter((x) => x.selected)
        .map((x) => x.value),
    };
  } else {
    let classNames = $(e.target).attr('class').split(' ');
    if (classNames.includes('numberonly')) {
      let patt = new RegExp('^[0-9]*$');
      if (!patt.test(e.target.value)) {
        return {
          ...data,
        };
      } else {
        return {
          ...data,
          [e.target.name]: e.target.value,
        };
      }
    }
    if (classNames.includes('numberplusonly')) {
      let patt = new RegExp('^[0-9+]*$');
      if (!patt.test(e.target.value)) {
        return {
          ...data,
        };
      } else {
        return {
          ...data,
          [e.target.name]: e.target.value,
        };
      }
    } else {
      return {
        ...data,
        [e.target.name]: e.target.value,
      };
    }
  }
};

export const dateFormatFront = (data) => {
  return `${moment(data).format('MM/DD/YYYY')} at ${moment(data).format(
    'h:mm a'
  )}`;
};

export const currencyFormat = (data) => {
  return parseFloat(data).toLocaleString('en-US');
};

export const checkProductOpen = (date_added, user_role) => {
  let startDate = new Date(serverTime);
  let addDate = new Date(date_added);
  let milliSeconds = 0;
  // if (parseInt(user_role) !== 3) {
  //   milliSeconds = 1 * 3600000;
  // } else {
  // }
  let incrementedTime = addDate.getTime() + parseInt(milliSeconds);
  let newaddDate = new Date(incrementedTime);
  if (newaddDate > startDate) {
    return false;
    // let incrementedTime = addDate.getTime() + parseInt(milliSeconds);
    // let timerTime = incrementedTime - startDate.getTime();
    // if (timerTime <= 0) {
    //   return true;
    // } else {
    //   return false;
    // }
  } else {
    return true;
  }
};

export const maskEmailFront = (data) => {
  data = data.toString();
  var first4 = data.substring(0, 4);
  var last5 = data.substring(data.length - 5);
  var mask = data.substring(4, data.length - 5).replace(/[^]/g, '*');
  return first4 + mask + last5;
};

export const dateFormatFrontDay = (data) => {
  return `${moment(data).format('MM/DD/YYYY')} (${moment(data).format(
    'dddd'
  )})`;
};

export const dateFormatFrontOnlyDate = (data) => {
  return `${moment(data).format('YYYY-MM-DD')}`;
};

export const DirectAPICAll = (method, url) => {
  return new Promise(async (resolve, reject) => {
    try {
      if (method === 'get') {
        let res = await axios.get(`${url}`);
        console.log('responsode from api', res);
        resolve(res);
      } else if (method === 'post') {
        let res = await axios.post(`${url}`);
        resolve(res);
      }
    } catch (err) {
      console.log('responsode error from api', err);
      resolve(err);
    }
  });
};
