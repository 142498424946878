/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import MakePayment from '../../Components/Payment/makePayment';

import Container from '@material-ui/core/Container';
// import Grid from '@material-ui/core/Grid';
import Layout from '../../Components/Layout';

import ProductContext from '../../context/product/productContext';
import AlertContext from '../../context/alert/alertContext';

import DashboardHeader from '../../Components/DashboardHeader';
import AllBids from '../../Components/DashboardBuyer/AllBids';
import {connect} from 'react-redux';
// import ActiveBids from '../../Components/DashboardBuyer/ActiveBids';
// import WonBids from '../../Components/DashboardBuyer/WonBids';
// import LostBids from '../../Components/DashboardBuyer/LostBids';

const TabPanel = ({ children, value, index, ...other }) => {
  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `buyer-dashboard-${index}`,
    'aria-controls': `buyer-dashboard-tabpanel-${index}`,
  };
}

const BuyerDashboard = (props) => {
  const productContext = useContext(ProductContext);
  const alertContext = useContext(AlertContext);

  const { responseStatus, updateBuyNowDynamic, clearResponse } = productContext;
  const { setAlert } = alertContext;
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [bidDetails, setBidDetails] = useState({
    amount: 0,
    bid_id: 0,
    proj_id: 0,
  });
  const [open, setOpen] = useState(false);
  const openPayment = (cmgbid_id, cmgamount, cmgprojid) => {
    if (!open && cmgamount) {
      setBidDetails({
        ...bidDetails,
        amount: cmgamount,
        bid_id: cmgbid_id,
        proj_id: cmgprojid,
      });
    }
    setOpen(!open);
  };
  const onSuccessFunction = (payment) => {
    updateBuyNowDynamic({
      bid_id: bidDetails.bid_id,
      buynowdetails: {
        paid: '1',
        shipping_status: 'yet to process',
        sales_tax: payment.taxPercent,
        sales_tax_amount: payment.taxAmount,
        shipping_amount: payment.shippingAmount,
        total_invoice: payment.totalAmount,
      },
    });
  };

  useEffect(() => {
    if (responseStatus && responseStatus !== '') {
      if (responseStatus === 'buynow_updated_successfull') {
        // setAlert('Paid successfully', 'success');
        clearResponse();
      } else {
        setAlert(responseStatus, 'error');
        clearResponse();
      }
    } else {
      clearResponse();
    }
  }, [responseStatus]);

  return (
    <Layout props={props}>
      <DashboardHeader title={props.Language.my} subtitle={props.Language.bds_cap} />
      <Container className="db-bids-wrapper">
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="buyer-dashboard"
        >
          {/* <Tab label="ALL" {...a11yProps(0)} /> */}
          {/* <Tab label="BUYNOW" {...a11yProps(2)} /> */}
          <Tab label={props.Language.act} {...a11yProps(0)} />
          <Tab label="WON" {...a11yProps(1)} />
          <Tab label={props.Language.lost_cap} {...a11yProps(2)} />
        </Tabs>
        {/* <TabPanel value={value} index={0}>
          <AllBids page="" />
        </TabPanel> 
        <TabPanel value={value} index={2}>
          <AllBids page="buynow" />
        </TabPanel>
        */}
        <TabPanel value={value} index={0}>
          <AllBids page="bidactive" />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <AllBids page="bidwon" openPayment={openPayment} />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <AllBids page="bidlost" />
        </TabPanel>
      </Container>

      <MakePayment
        open={open}
        openPayment={openPayment}
        amount={bidDetails.amount}
        onSuccess={onSuccessFunction}
        payType="auction_pay"
        sendID={bidDetails.proj_id}
      />
    </Layout>
  );
};

const mapStateToProps = state => {
  return {
    Language : state.Language.languages
  }
}

export default connect(mapStateToProps)(BuyerDashboard);
