/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useContext } from 'react';
import Layout from '../../Components/Layout';
import { Link } from 'react-router-dom';
import './invoice.css';
import ProductContext from '../../context/product/productContext';
import AlertContext from '../../context/alert/alertContext';
import AuthContext from '../../context/auth/authContext';
import apiCall from '../../Common/ApiCall';
import { dateFormatFront } from '../../Common/Components';
import Loading from '../../Components/Loading';
import Pdf from 'react-to-pdf';
import $ from 'jquery';
import MakePayment from '../../Components/Payment/makePayment';
import MomentUtils from '@date-io/moment';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import { onChangeFunction } from '../../Common/Components';
import { refreshbuyer, refreshbuyerOff } from '../../Common/Socket';
import PDFexport from '../../Components/PDFexport/PDFexport';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
  KeyboardTimePicker
} from '@material-ui/pickers';
import moment from 'moment';
import {connect} from 'react-redux'

let getInvoiceID = () => {
  return 0;
};
var counter = 0;
const Invoice = (props) => {
  const productContext = useContext(ProductContext);
  const alertContext = useContext(AlertContext);
  const authContext = useContext(AuthContext);

  const { user, isAuthenticated, isAdmin } = authContext;
  const ref = React.createRef();
  const [loading, setLoading] = useState(true);
  const [initLoad, setInitLoad] = useState(false);
  const [pdfexp,setPdfexp] = useState(0)
  const dataArray = [
    {
      date: 0,
      minTime: global.local_sunday_start,
      maxTime: global.local_sunday_end,
    },
    {
      date: 1,
      minTime: global.local_monday_start,
      maxTime: global.local_monday_end,
    },
    {
      date: 2,
      minTime: global.local_tuesday_start,
      maxTime: global.local_tuesday_end,
    },
    {
      date: 3,
      minTime: global.local_wednesday_start,
      maxTime: global.local_wednesday_end,
    },
    {
      date: 4,
      minTime: global.local_thursday_start,
      maxTime: global.local_thursday_end,
    },
    {
      date: 5,
      minTime: global.local_friday_start,
      maxTime: global.local_friday_end,
    },
    {
      date: 6,
      minTime: global.local_saturday_start,
      maxTime: global.local_saturday_end,
    },
  ];


  const [pickUpTime, setpickUpTime] = useState({
    enable: 0,
    date: new Date(),
    time: '10:00',
    minTime: '10:00',
    maxTime: '20:00',
    val : new Date(),
    value(timeVal){
      return timeVal;
    }
  });
  const clearError = (name) => {
    $('#' + name + '').html('');
  };
  const onChangeText = (e) => setpickUpTime(onChangeFunction(e, pickUpTime));
  const onChangeEndDate = (e) => {
    $('#startdate_err').html('');
    let weekDate = parseInt(moment(e).format('d'));
    let selectedWeekDay = dataArray.filter((x) => x.date === weekDate);
    if (selectedWeekDay.length > 0) {
      if (selectedWeekDay[0].minTime && selectedWeekDay[0].maxTime) {
        setInitLoad(true);
        setpickUpTime({
          ...pickUpTime,
          date: moment(e).toDate(),
          minTime: selectedWeekDay[0].minTime,
          maxTime: selectedWeekDay[0].maxTime,
        });
      } else {
      }
    }
  };
  if (!initLoad) {
    if (global.local_sunday_start) {
      onChangeEndDate(new Date());
    }
  }
  const useStyles = makeStyles((theme) => ({
    container: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: 150,
    },
    datepicker: {
      width: 150,
      marginTop: 0,
    },
  }));
  const classes = useStyles();

  const {
    group_invoice_details,
    getInvoiceNew,
    responseStatus,
    clearResponse,
  } = productContext;

  const { setAlert } = alertContext;

  useEffect(() => {
    if (responseStatus && responseStatus !== '') {
      if (responseStatus === 'buynow_updated_successfull') {
        clearResponse();
      } else if (responseStatus === 'successfully_shipmentapi') {
        setAlert(props.Language.shipp_u_s ? props.Language.shipp_u_s :'Shipping updated successfully', 'success');
        clearResponse();
        getInvoiceFunction();
      } else if (responseStatus === 'successfully_localpickupapi') {
        setAlert(props.Language.localpkup_u_s ? props.Language.localpkup_u_s :'Local Pick up updated successfully', 'success');
        clearResponse();
        getInvoiceFunction();
      } else {
        if (!isAuthenticated || !isAdmin) {
        } else {
          setAlert(responseStatus, 'error');
          clearResponse();
        }
      }
    } else {
      clearResponse();
    }
  }, [responseStatus]);
  const options = {};
  useEffect(() => {
    if (props.match.params.id) {
      setLoading(true);
      getInvoiceFunction();
    } else {
    }
  }, [isAuthenticated]);
  const generatePDF = (toPdf) => {
    setLoading(true);
    $('#invoiceContainer').addClass('ContainerforPdf');
    $('.hiddenPDF').addClass('HiddenforPdf');
    $('#totalInvoice').removeClass('mw-824');
    $('.imghidepdf').hide();
    group_invoice_details.SlibuyPayments.map(
      (item, index) => {
        $(`#selpdf${index}`).append(`<p id="tempp${index}">${$(`#changebidshp${item.filb_ide_name}`).text()}</p>`)
        $(`.selpdf${index}`).hide();
      })
    //console.log(value)
    toPdf();
  };
  const completedPDF = () => {
    $('#invoiceContainer').removeClass('ContainerforPdf');
    $('.hiddenPDF').removeClass('HiddenforPdf');
    $('#totalInvoice').addClass('mw-824');
    $('.imghidepdf').hide();
    $('.imghidepdf').show();
    group_invoice_details.SlibuyPayments.map(
      (item, index) => {
        $(`#tempp${index}`).remove();
        $(`.selpdf${index}`).show();
      })
    setLoading(false);
  };

  useEffect(() => {
    console.log('group_invoice_details', group_invoice_details);
    setLoading(false);
    onChangeEndDate(new Date());
    let defaultVal = new Date();
    defaultVal = defaultVal.setHours('10');
    defaultVal = new Date(defaultVal).setMinutes('00');
    if (group_invoice_details) {
      if (group_invoice_details.SlibuyPayments) {
        if (group_invoice_details.SlibuyPayments.length > 0) {
          getInvoiceID = () => {
            return parseInt(group_invoice_details.in_number);
          };
          setpickUpTime({
            ...pickUpTime,
            val : defaultVal,
            enable: 0,
          });
          if (group_invoice_details.paid_status) {
          } else {
            group_invoice_details.SlibuyPayments.map((item, index) => {
              if (parseInt(item.local_pick) === 1) {
                setpickUpTime({
                  ...pickUpTime,
                  val:defaultVal,
                  enable: 0,
                });
              }
              // change this enable to 1 if need for local pick up
              return true;
            });
          }
        }
      }
    }
  }, [group_invoice_details]);

  const [open, setOpen] = useState(false);

  const errorTextMsg = (text) => {
    return `<ul class="parsley-errors-list filled"><li class="parsley-required">${text}</li></ul>`;
  };

  const changebidshp = async (val, thibtn, shipval) => {
    let thival = thibtn.target.value;
    if (thival === 'addnewship' || thival === 'addnewshipwhole') {
      return false;
    } else {
      let invoiceData = {
        buynow_id: val,
        ship_type: thival,
        ship_amt: shipval,
        buyer_id: user.id,
        inv_num: group_invoice_details.in_number,
        cinvoice: group_invoice_details.in_number,
      };
      const [res] = await Promise.all([
        apiCall('post', 'removeShippingcheckoutAdmin', invoiceData, ''),
      ]);
      if (res.data) {
        getInvoiceFunction();
      }
    }
  };
  const getInvoiceFunction = () => {
    if (!isAuthenticated || !isAdmin) {
      getInvoiceNew({ id: props.match.params.id });
    } else {
      getInvoiceNew({
        id: props.match.params.id,
        user_id: props.match.params.userid,
      });
    }
  };



  const customValidation = (pickupTime) => {
    return new Promise(async (resolve, reject) => {
      let error = 0;
      let selectedTime = parseFloat(pickupTime.time.replace(/:/g, '.')).toFixed(
        2
      );
      let selectedminTime = parseFloat(
        pickupTime.minTime.replace(/:/g, '.')
      ).toFixed(2);
      let selectedmaxTime = parseFloat(
        pickupTime.maxTime.replace(/:/g, '.')
      ).toFixed(2);
      let zeroTime = parseFloat('00.00').toFixed(2);
      console.log(selectedTime,'selectedTime')
      console.log(selectedminTime,'selectedminTime')
      console.log(selectedmaxTime,'selectedmaxTime')
      console.log(zeroTime,'zeroTime')
      console.log(parseFloat(selectedTime) >= parseFloat(selectedminTime))
      console.log(parseFloat(selectedTime) <= parseFloat(selectedmaxTime))

  
      if (selectedmaxTime !== zeroTime && selectedminTime !== zeroTime) {
        if (
          //string changed to int to reflect single digit numerics
          parseFloat(selectedTime) >= parseFloat(selectedminTime) &&  
          parseFloat(selectedTime) <= parseFloat(selectedmaxTime)
        ) {
        } else {

          $('#startdate_err').html(errorTextMsg('Time slot is not available'));
          error = 1;
        }
      } else {

        $('#startdate_err').html(errorTextMsg('Time slot is not available'));
        error = 1;
      }
      if (error === 1) {
        resolve(false);
      } else {
        resolve(true);
      }
    });
  };





  const openPayment = async () => {
    if (parseInt(pickUpTime.enable) === 1) {
      let [customValid] = await Promise.all([customValidation(pickUpTime)]);
      if (customValid) {
        setOpen(!open);
      }
    } else {
      setOpen(!open);
    }
  };
  const onSuccessFunction = async () => {
    let invoiceSuccessData = {
      mode: 'sandbox',
      transaction_id: '',
      trans_gateway: 'Card Payment',
      inv: group_invoice_details.in_number,
      persale: parseFloat(group_invoice_details.orgsalesPercen).toFixed(2),
      pickUpTime: pickUpTime.enable,
      sche_localpick: new Date(
        moment(pickUpTime.date).format('YYYY-MM-DD') +
          ' ' +
          pickUpTime.time +
          ':00'
      ),
      payamt: parseInt(group_invoice_details.nwrw_total),
      totalInvoice: group_invoice_details.final_final_total,
      salesAmt: group_invoice_details.salestax_total,
      shipAmt: group_invoice_details.total_shipping,
      shipOrg: group_invoice_details.shipcost_total,
      shipCmb: group_invoice_details.combined_shipp,
      buyerPremiumTotal: group_invoice_details.buypremium_total,
    };
    
    const [res] = await Promise.all([
      apiCall('post', 'successGroupInvoice', invoiceSuccessData, ''),
    ]);
    if (res.data) {
      if (res.data.success === 'yes') {
        setAlert(props.Language.invoice_s_u ? props.Language.invoice_s_u : 'Invoice successfully updated', 'success');
      } else {
        setAlert(res.data.success.reason, 'error');
      }
      getInvoiceFunction();
    }
  };

  useEffect(() => {
    setTimeout(()=>{
      setPdfexp(1)
    },4000)
    refreshbuyer((msg) => {
      let invoiceID = getInvoiceID();
      if (parseInt(msg.invoiceid) === invoiceID) {
        setAlert(props.Language.invoice_d_u ? props.Language.invoice_d_u : 'Invoice details updated', 'success');
        getInvoiceFunction();
        return false;
      } else {
        return false;
      }
      
    });
    return function () {
      refreshbuyerOff();
      counter= 0
    }
  }, []);
  
  // useEffect(()=>{
  //   console.log(counter,'ppppppppp',group_invoice_details)
  //   if(group_invoice_details && Object.keys(group_invoice_details).length && counter != 0){
  //     if(counter == 1){
  //       //setPdfexp(1)
  //     }
  //     counter = counter+1
  //   }else if(group_invoice_details && Object.keys(group_invoice_details).length && counter === 0){
  //     if(counter == 0){
  //       console.log('qqqqq')
  //       setPdfexp(1)
  //     }
  //     counter = counter+1
  //   }
  // },[group_invoice_details])
  

  
  return (
    <Layout>
      <Loading loading={loading} />
      {group_invoice_details.SlibuyPayments ? (
        <>
          <section className="mb-5 contact-us-wrapper">
            <div className="container mw-824" id="totalInvoice">
              <h2 className="main-head mb-4 clearfix mt-5">
                  {props.Language.inv_cap ? props.Language.inv_cap : "INVOICE"}
                {/* <Pdf
                  targetRef={ref}
                  options={options}
                  x={0}
                  y={2}
                  onComplete={completedPDF}
                  filename={`invoice-${group_invoice_details.in_number}.pdf`}
                >
                  {({ toPdf }) => (
                    <button
                      className="btn btn-primary ipdf-wrap"
                      onClick={() => generatePDF(toPdf)}
                    >
                      GENERATE PDF
                    </button>
                  )}
                </Pdf> */}
                
                {pdfexp ? <><PDFexport data = {group_invoice_details}/></> :  <button
                      className="btn btn-primary ipdf-wrap"
                    >
                      {props.Language.loaddoc ? props.Language.loaddoc : "Loading document"}...
                    </button>}
              </h2>
              <div id="invoiceContainer" ref={ref}>
                <div className="new-invoice-wrap np-pro-box">
                  <div className="invoice-head">{props.Language.inv_cap ? props.Language.inv_cap : "INVOICE"}</div>
                  <div className="row pb-3">
                    <div className="col-sm-4">
                      <div className="">
                        <b>{props.Language.shiptoo_caps ? props.Language.shiptoo_caps : "SHIP TO" }</b>
                      </div>
                      <div className="mb-2">
                        <b>
                          {group_invoice_details.userShippdetail.first_name}{' '}
                          {group_invoice_details.userShippdetail.last_name}
                        </b>

                        {!isAdmin ? (
                          <>
                            {!group_invoice_details.paid_status ? (
                              <Link to="/profile" className="sa-edit hiddenPDF">
                                {props.Language.edittt ? props.Language.edittt : "Edit"}
                              </Link>
                            ) : null}
                          </>
                        ) : null}
                      </div>
                      <div className="mb-2">
                        {group_invoice_details.userShippdetail.address},
                      </div>
                      {group_invoice_details.userShippdetail.address1 ? (
                        <>
                          <div className="mb-2">
                            {group_invoice_details.userShippdetail.address1},
                          </div>
                        </>
                      ) : null}
                      <div className="mb-2">
                        {group_invoice_details.userShippdetail.city},{' '}
                        {group_invoice_details.userShippdetail.state}
                      </div>
                      <div className="mb-2">
                        {group_invoice_details.userShippdetail.country},{' '}
                        {group_invoice_details.userShippdetail.zipcode}
                      </div>
                      <div className="mb-2">
                        {group_invoice_details.userShippdetail.phone}
                      </div>
                    </div>
                    <div className="col-sm-4 nv-tp-left">
                      <div className="mb-2">
                      <b>{props.Language.invoicein ? props.Language.invoicein : "Invoice"} #:</b> {group_invoice_details.in_number}
                      </div>
                      <div>
                      <b>{props.Language.statuss ? props.Language.statuss : "Status"}:</b>{' '}
                        {group_invoice_details.paid_status ||
                        group_invoice_details.newpaid_status
                          ? props.Language.paid ? props.Language.paid : 'Paid'
                          : props.Language.unpaid ? props.Language.unpaid :'Unpaid'}
                        ({group_invoice_details.biditem_cnt} {props.Language.items ? props.Language.items : "items"})
                      </div>
                    </div>
                    <div className="col-sm-4 text-right">
                      <img src="/images/new-logo.png" alt="" />
                    </div>
                  </div>
                  <div className="table-responsive mt-3">
                    <table
                      className="table table-hover invoice-table"
                      border="0"
                      cellpadding="0"
                      cellspacing="0"
                    >
                      <thead>
                        <tr>
                          <th>ID</th>
                        <th>{props.Language.date_Sm}</th>
                        <th className="imghidepdf">{props.Language.img}</th>
                          <th>{props.Language.itleeee}</th>
                          <th>{props.Language.locationn}</th>
                          <th className="text-center">{props.Language.amttt}</th>
                          <th>{props.Language.ret_opt}</th>
                          <th>{props.Language.buyerpr}</th>
                          <th>{props.Language.sales_taxx}</th>
                          <th>{props.Language.ttl}</th>
                        </tr>
                      </thead>
                      <tbody id="tbddy">
                        {group_invoice_details.SlibuyPayments.map(
                          (item, index) => {
                            return (
                              <tr>
                                <td className="account_p_lbl">{item.id}</td>
                                <td>{dateFormatFront(item.date_added)}</td>
                                <td className="account_p_lbl imghidepdf">
                                  <div className="ipro-img">
                                    <img
                                      src={`${global.image_url}${item.file_name}`}
                                      alt=""
                                      width="80"
                                    />
                                  </div>
                                </td>
                                <td>{item.title}</td>
                                <td>{item.state}</td>
                                <td
                                  className="account_p_lbl text-center"
                                  data-label="Amount"
                                >
                                  ${item.amount}
                                </td>
                                {isAdmin ? (
                                  <>
                                    <td>
                                      <select>
                                        <option selected="selected">
                                          {item.afpaidrtstatus}
                                        </option>
                                      </select>
                                    </td>
                                  </>
                                ) : (
                                  <>
                                    {group_invoice_details.paid_status ? (
                                      <>
                                        <td>
                                          <select
                                          id={`changebidshp${item.filb_ide_name}`}>
                                            <option selected="selected">
                                              {item.afpaidrtstatus}
                                            </option>
                                          </select>
                                        </td>
                                      </>
                                    ) : (
                                      <>
                                        <td id={`selpdf${index}`}>
                                          <select
                                            
                                            id={`changebidshp${item.filb_ide_name}`}
                                            className={`selpdf${index} checkPay`}
                                            value={
                                              parseInt(item.local_pick) === 1
                                                ? 'notavail'
                                                : item.b_id
                                            }
                                            onChange={(event, value) =>
                                              changebidshp(
                                                item.b_id,
                                                event,
                                                item.shippingcost
                                              )
                                            }
                                          >
                                            {parseInt(item.localpickup) ===
                                            1 ? (
                                              <option value="notavail">
                                                {props.Language.lk_pk}
                                              </option>
                                            ) : null}
                                            {parseInt(item.hasshipping) ===
                                            1 ? (
                                              <>
                                                <option value={item.b_id}>
                                                  $
                                                  {item.shippingcost === ''
                                                    ? 0
                                                    : item.comma_shipcost}
                                                  ({props.Language.fdxg}){' '}
                                                </option>
                                              </>
                                            ) : null}
                                          </select>
                                        </td>
                                      </>
                                    )}
                                  </>
                                )}
                                <td>${item.buypremium_amt}</td>
                                <td>${item.sales_tax}</td>
                                <td>${item.total_amt_rw}</td>
                              </tr>
                            );
                          }
                        )}
                      </tbody>
                    </table>
                  </div>
                  <div className="clearfix"></div>

                  <div className="row mt-2 mb-2">
                    {group_invoice_details.paid_status ? (
                      <>
                        <div className="col-sm-7">
                          <div className="row padboth">
                            {group_invoice_details.final_scheduledpickup ? (
                              <>
                                <div className="col-sm-12 form-group">
                                {props.Language.lk_pk}
                                  <br />
                                  {group_invoice_details.final_scheduledpickup ? (
                                    <>
                                      Scheduled at:{' '}
                                      {dateFormatFront(
                                        group_invoice_details.final_scheduledpickup
                                      )}
                                      <br />
                                    </>
                                  ) : null}
                                  Los Angeles Auction Depot
                                  <br />
                                  10940 Burbank Blvd
                                  <br />
                                  North Hollywood CA 91601
                                  <br />
                                  818-665-3863
                                </div>
                              </>
                            ) : null}

                            {/* {parseInt(invoice_details.local_pick) !== 1 &&
                            parseInt(invoice_details.shipping_amount) > 0 ? (
                              <>
                                <div className="col-sm-12 form-group">
                                  Shipping Status: <br />
                                  {invoice_details.shipping_status ===
                                  'awaiting_shipment' ? (
                                    <>
                                      {invoice_details.tracking_number !== '' &&
                                      invoice_details.tracking_number ? (
                                        <>
                                          Tracking Code :{' '}
                                          {invoice_details.tracking_number
                                            .split(',')
                                            .map((item, index) => {
                                              return (
                                                <>
                                                  <a
                                                    target="_blank"
                                                    rel="noreferrer noopener"
                                                    href={`https://www.fedex.com/Tracking?tracknumbers=${item}`}
                                                  >
                                                    {item}
                                                  </a>
                                                  <br />
                                                </>
                                              );
                                            })}
                                        </>
                                      ) : (
                                        <>Awaiting Shipment</>
                                      )}
                                    </>
                                  ) : (
                                    <>{invoice_details.shipping_status}</>
                                  )}
                                </div>
                              </>
                            ) : (
                              <>
                                {parseInt(invoice_details.local_pick) === 1 ? (
                                  <>
                                    <div className="col-sm-12 form-group">
                                      Local Pickup
                                      <br />
                                      {invoice_details.sche_localpick ? (
                                        <>
                                          Scheduled at:{' '}
                                          {dateFormatFront(
                                            invoice_details.sche_localpick
                                          )}
                                          <br />
                                        </>
                                      ) : null}
                                      Los Angeles Auction Depot
                                      <br />
                                      10940 Burbank Blvd
                                      <br />
                                      North Hollywood CA 91601
                                      <br />
                                      818-665-3863
                                    </div>
                                  </>
                                ) : null}
                              </>
                            )} */}
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="col-sm-7 ">
                          {isAdmin ? null : (
                            <>
                              <span className="hiddenPDF">
                                {pickUpTime.enable === 1 ? (
                                  <>
                                    {props.Language.shlopk}:
                                    <br />
                                    <MuiPickersUtilsProvider
                                      utils={MomentUtils}
                                    >
                                      <KeyboardDatePicker
                                        disableToolbar
                                        autoOk={true}
                                        variant="inline"
                                        margin="normal"
                                        className={classes.datepicker}
                                        disablePast
                                        label="Date"
                                        value={pickUpTime.date}
                                        onChange={onChangeEndDate}
                                        KeyboardButtonProps={{
                                          'aria-label': 'change date',
                                        }}
                                      />
                                    </MuiPickersUtilsProvider>
                                    {pickUpTime.minTime !== '00:00' &&
                                    pickUpTime.maxTime !== '00:00' ? (
                                      <>
                                      {/* <TextField
                                          label="Time"
                                          type="time"
                                          name="time"
                                          value={pickUpTime.time}
                                          onChange={(event, value) => {
                                            onChangeText(event);
                                            clearError('startdate_err');
                                          }}
                                          className={classes.textField}
                                          InputLabelProps={{
                                            shrink: true,
                                          }}
                                          inputProps={{
                                            min: pickUpTime.minTime,
                                            max: pickUpTime.maxTime,
                                            step: 300, // 5 min
                                          }}
                                        /> */}
                                        <KeyboardTimePicker
                                          ampm={true}
                                          label="Time"
                                          value={pickUpTime.value(pickUpTime.val)}
                                          onChange={(event, value) => {
                                            let isPm = false;
                                            if(/PM/.test(value)){
                                              isPm = true
                                            }
                                            value = value.replace(/AM|PM/gi,'')
                                            
                                            if(isPm){
                                              value = Number(value.split(':')[0]) == 12 ? 
                                                        Number(value.split(':')[0]) + ':' + value.split(':')[1]
                                                        : 
                                                        Number(value.split(':')[0]) + 12 + ':' + value.split(':')[1]
                                            }else{
                                              if(Number(value.split(':')[0]) == 12){
                                                value = value.replace('12','00')
                                              }
                                            }
                                            setpickUpTime({
                                              ...pickUpTime,
                                              time:value.trim(),
                                              val : event
                                            })
                                            //onChangeText(event);
                                            clearError('startdate_err');
                                          }}
                                        />
                                        <br />
                                        Available Time (
                                        {moment(pickUpTime.minTime, [
                                          'HH:mm ',
                                        ]).format('h:mm A')}{' '}
                                        to{' '}
                                        {moment(pickUpTime.maxTime, [
                                          'HH:mm ',
                                        ]).format('h:mm A')}
                                        )
                                      </>
                                    ) : (
                                      <>
                                        <br />
                                        {props.Language.srywauatd}
                                        {props.Language.selad}{' '}
                                      </>
                                    )}
                                    <span id="startdate_err"></span>
                                    <br />
                                    <br />
                                  </>
                                ) : null}
                              </span>
                            </>
                          )}
                        </div>
                      </>
                    )}

                    <div className="col-sm-5">
                      <div className="row mb-2">
                        <div className="col-sm-6">
                          <b>{props.Language.bid_amt}</b>
                        </div>
                        <div className="col-sm-6">
                          ${group_invoice_details.bidamount_total}
                        </div>
                      </div>
                      {parseInt(group_invoice_details.shipcostrow_total) > 0 ? (
                        <>
                          <div className="row mb-2">
                            <div className="col-sm-6">
                              <b> {props.Language.totalship}</b>
                            </div>
                            <div className="col-sm-6">
                              ${group_invoice_details.shipcostrow_total}
                            </div>
                          </div>
                        </>
                      ) : null}
                      <div className="row mb-2">
                        <div className="col-sm-6">
                          <b>{props.Language.buyerpr}</b>
                        </div>
                        <div className="col-sm-6">
                          ${group_invoice_details.buypremium_total_lowbox}
                        </div>
                      </div>
                      <div className="row mb-2">
                        <div className="col-sm-6">
                          <b>{props.Language.sales_taxx}</b>
                        </div>
                        <div className="col-sm-6">
                          ${group_invoice_details.salestax_total}
                        </div>
                      </div>
                      <div className="row mb-2">
                        <div className="col-sm-6">
                          <b> {props.Language.ttl} </b>
                        </div>
                        <div className="col-sm-6">
                          <b> ${group_invoice_details.nwrw_total}</b>
                        </div>
                      </div>
                      {group_invoice_details.paid_status ? (
                        <>
                          {/* <div className="row mb-2">
                            <div className="col-sm-6">
                              <b>Total Due</b>
                            </div>
                            <div className="col-sm-6">
                              <b> ${group_invoice_details.totalduee}</b>
                            </div>
                          </div> */}
                        </>
                      ) : (
                        <>
                          <div className="row mb-2">
                            <div className="col-sm-6">
                              <b> {props.Language.ttl_de}</b>
                            </div>
                            <div className="col-sm-6">
                              <b> ${group_invoice_details.totalduee}</b>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                  <div className="clearfix"></div>
                  {group_invoice_details.paid_status || isAdmin ? null : (
                    <>
                      <div className="row mt-2 mb-2">
                        <div className="col-sm-3 form-group"></div>
                        <div className="col-sm-6 form-group">
                          <button
                            type="submit"
                            className="btn btn-primary hiddenPDF"
                            onClick={() => openPayment()}
                          >
                            {props.Language.anow}
                          </button>
                        </div>
                      </div>
                      <MakePayment
                        open={open}
                        openPayment={openPayment}
                        amount={parseFloat(group_invoice_details.totalduee)}
                        onSuccess={onSuccessFunction}
                        payType="auction_pay"
                        sendID={group_invoice_details.in_number}
                      />
                    </>
                  )}
                  {/* <div className="row mt-2 mb-2">
                    <div className="col-sm-8"></div>
                    <div className="col-sm-4 btm-intxt-right">
                      <div className="row padboth">
                        <div className="col-sm-12 form-group">
                          <select className="form-control ftn16">
                            <option> Select payment</option>
                            <option> Paypal</option>
                          </select>
                        </div>
                        <div className="col-sm-6 form-group">
                          <button type="submit" className="btn btn-primary">
                            Pay Now
                          </button>
                        </div>
                        <div className="col-sm-6 form-group">
                          <button type="submit" className="btn btn-secondary">
                            Contact Us
                          </button>
                        </div>
                      </div>
                      <div className="col-md-12 padlr">
                        <b>Add 3% if paid for by Credit Card</b>
                      </div>
                    </div>
                    <div className="clearfix"></div>
                  </div> */}
                  <div className="clearfix"></div>
                </div>
              </div>
            </div>
          </section>{' '}
        </>
      ) : null}
    </Layout>
  );
};

const mapStateToProps = state => {
  return {
    Language : state.Language.languages
  }
}

export default connect(mapStateToProps)(Invoice);
