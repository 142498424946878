import React from 'react';

const Loading = ({ loading }) => {
  return (
    loading && (
      <div className="clearfix loader-wrap">
        <i className="fa fa-spinner fa-4x"></i>
      </div>
    )
  );
};

export default Loading;
