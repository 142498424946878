import axios from 'axios';

export const GetBase64FromURL = (url) => {
  return new Promise(async (resolve, reject) => {
    axios.get(url, { responseType: 'arraybuffer' }).then((response) => {
      let image = btoa(
        new Uint8Array(response.data).reduce(
          (data, byte) => data + String.fromCharCode(byte),
          ''
        )
      );
      resolve(
        `data:${response.headers['content-type'].toLowerCase()};base64,${image}`
      );
    });
  });
};

export const GetFileFromBase64 = (blob) => {
  var arr = blob.split(','),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  let filename = Math.random().toString(36).substring(7);
  console.log('mime type', mime);
  if (mime === 'image/jpeg') {
    filename = `${filename}.jpg`;
  } else if ('image/png') {
    filename = `${filename}.png`;
  }
  return new File([u8arr], filename, { type: mime });
};
