import React, { useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";
import { dateFormatFront } from "../../Common/Components";
import {connect} from 'react-redux';

const SiteAuctions = (props) => {
  let settings = {
    dots: true,
    infinite: props.auctions.results.length > 1,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    vertical: true,
    verticalSwiping: true,
    adaptiveHeight: false,
    initialSlide: 0,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [ 
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="site-auctions-wrapper siw">
      <div className="dbmhead" noWrap>
        <Link to="/marketplace/search">
  <span className="hbox">{props.Language.ste}</span>
          <span>{props.Language.auc}</span>
        </Link>
      </div>

      <Slider {...settings}>
        {props.auctions.results.map((item, index) => {
          return (
            <div className="list-item-wrapper">
              <h3 className="item-title">{item.title}</h3>
              <div className="media">
                <div className="media-left">
                  <img
                    src={`${global.image_url}${item.file_name}`}
                    alt={item.title}
                  />
                </div>
                <div className="media-body">
                  <div className="loc-name">{item.location}</div>
                  <div className="item-text item-descp">{item.description}</div>
                  <div className="details-link">{item.auctiontype}</div>
                  <div className="item-text small-txt">
          <b>{props.Language.bgns}</b> {dateFormatFront(item.date_added)}
                  </div>
                  <div className="item-text small-txt">
          <b>{props.Language.endss}</b> {dateFormatFront(item.date_closed)}
                  </div>
                  <div className="item-text small-txt">
                    <img
                      src="/images/placeholder.png"
                      className="loc-icon"
                      alt=""
                    />{" "}
                    <span>
                      {item.lotcity}, {item.lotstate}
                    </span>
                  </div>
                  <Link
                    to={`/search?lotid=${item.id}`}
                    className="btn btn-green overflowBtn"
                  >
                    {props.Language.cobid}
                  </Link>
                </div>
              </div>
            </div>
          );
        })}
      </Slider>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    Language : state.Language.languages
   }
 };


export default connect(mapStateToProps,undefined)(SiteAuctions);
