/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useContext, useEffect } from 'react';
import $ from 'jquery';
import './profile.css';
import { Redirect } from 'react-router-dom';
import { onChangeFunction } from '../../Common/Components';
import AlertContext from '../../context/alert/alertContext';
import UserContext from '../../context/user/userContext';
import {connect} from 'react-redux'

const ChangePwdForm = (props) => {
  const button = $('#formSubmitButton');
  const alertContext = useContext(AlertContext);
  const userContext = useContext(UserContext);

  const { setAlert } = alertContext;
  let { changepwd, responseStatus, clearResponse } = userContext;

  const [toHome, settoHome] = useState(false);
  const [pass, setPass] = useState({
    current_password: '',
    new_password: '',
    confirm_new_password: '',
  });

  const onChange = (e) => setPass(onChangeFunction(e, pass));

  useEffect(() => {
    if (responseStatus && responseStatus !== '') {
      button.attr('disabled', false);
      if (responseStatus === 'password_updated_successful') {
        setAlert(props.Language.pass_s_c ? props.Language.pass_s_c : 'Password Successfully changed', 'success');
        clearResponse();
        settoHome(true);
      } else {
        let language = localStorage.getItem('language') 
        if(language === 'Spanish'){
          if(responseStatus === 'Current password not match'){
            responseStatus = "La contrasena actual no coincide"
          }
        }
        setAlert(responseStatus, 'error');
        clearResponse();
      }
    } else {
      clearResponse();
    }
  }, [responseStatus]);

  const onSubmit = (e) => {
    e.preventDefault();
    if ($('#ChangePwdForm').parsley().validate()) {
      changepwd(pass);
    } else {
      return false;
    }
  };
  return (
    <div className="password-form-wrapper">
      {toHome ? <Redirect to="/" /> : null}
      {/* <EditUser /> */}
      <form id="ChangePwdForm" data-parsley-Validate onSubmit={onSubmit}>
        <div className="row">
          <div className="col-12 form-group">
            <label className="sf-label">{props.Language.current} {props.Language.p_s}</label>
            <div className="icon-input">
              <div className="icon-box">
                <img src="/images/pwd.png" alt="" />
              </div>
              <input
                name="current_password"
                type="password"
                onChange={onChange}
                value={pass.current_password}
                className="form-control"
                placeholder={`${props.Language.current} ${props.Language.p_s}`}
                data-parsley-required="true"
                data-parsley-error-message={props.Language.thisir ? props.Language.thisir : 'This value is required'}
                autoFocus={true}
              />
            </div>
          </div>
          <div className="col-12 form-group">
            <label className="sf-label">{props.Language.current} {props.Language.p_s}</label>
            <div className="icon-input">
              <div className="icon-box">
                <img src="/images/pwd.png" alt="" />
              </div>
              <input
                name="new_password"
                type="password"
                id="password"
                onChange={onChange}
                value={pass.new_password}
                className="form-control"
                placeholder={`${props.Language.new} ${props.Language.p_s}`}
                data-parsley-minlength="8"
                data-parsley-required="true"
                data-parsley-error-message={props.Language.thisir ? props.Language.thisir : 'This value is required'}
              />
            </div>
          </div>
          <div className="col-12 form-group">
            <label className="sf-label">{props.Language.confirm} {props.Language.new} {props.Language.p_s}</label>
            <div className="icon-input">
              <div className="icon-box">
                <img src="/images/pwd.png" alt="" />
              </div>
              <input
                type="password"
                name="confirm_new_password"
                onChange={onChange}
                value={pass.confirm_new_password}
                className="form-control"
                placeholder={`${props.Language.new} ${props.Language.p_s}`}
                data-parsley-required="true"
                data-parsley-error-message={props.Language.thisir ? props.Language.thisir : 'This value is required'}
                data-parsley-minlength="8"
                data-parsley-equalto="#password"
              />
            </div>
          </div>
        </div>
        <div className="pt-3 btns-wrap">
          <button className="btn btn-green" id="formSubmitButton">
            {props.Language.save}
          </button>
        </div>
      </form>
    </div>
  );
};

const mapToState = state =>{
  return {
    Language : state.Language.languages
  }
}

export default connect(mapToState)(ChangePwdForm);
