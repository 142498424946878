import React from 'react';

import './SocialMediaShare.css';

const SocialMediaShare = (props) =>{
    const fbs = () => {
        window.FB.init({
          appId: '604375720390652',
          cookie: true,
          xfbml: true,
          version: 'v5.0'
        });
        window.FB.ui({ method: 'share', href: window.location.href },(result)=>{
            console.log(result);
            console.log('after share')
        })
      }
    const lns = () => {
      window.location.href = `https://www.linkedin.com/sharing/share-offsite/?url=${window.location.href}`
    }
    return(
        <>
        <div
          className="row mt-2"
          
        >
          <div className="ml-4 mt-2 col-md-3" onClick={() => fbs()}>
            <button class="btn btn-fb" type="button">
              <i class="fa fa-share-alt"></i> Facebook
              </button>
          </div>
          <div className="mt-2 col-md-3" onClick={() => lns()}>
            <button class="btn btn-ln" type="button">
              <i class="fa fa-share-alt"></i> LinkedIn
              </button>
          </div>
        </div>
      </>
    )
}

export default React.memo(SocialMediaShare)