
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import {
  dateFormatFront,
  currencyFormat,
  maskEmailFront,
} from '../../Common/Components';
import { Map, Marker } from 'google-maps-react';
import {connect} from 'react-redux';

const TabPanel = ({ children, value, index, ...other }) => {
  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `product-view-${index}`,
    'aria-controls': `product-view-tabpanel-${index}`,
  };
}

const ProductTabs = ({ google, proddetail, biddetails, bidhistory, Language }) => {
  const [value, setValue] = React.useState(0);
  const [googleLat, setGoogleLat] = useState({
    lat: 34.171759,
    lng: -118.3694329,
  });
  const [address, setAddress] = useState('');
  // Using callback
  useEffect(() => {
    if (proddetail) {
      var geocoder = new google.maps.Geocoder();

      var address =
        proddetail.sell_location +
        ' ' +
        proddetail.city +
        ' ' +
        proddetail.state +
        ' ' +
        proddetail.location +
        ' ' +
        proddetail.content_head5;
      console.log('address', address);
      geocoder.geocode({ address: address }, function (results, status) {
        if (status === 'OK') {
          setAddress(address);
          console.log('lat', results[0].geometry.location.lat());
          console.log('lng', results[0].geometry.location.lng());
          setGoogleLat({
            lat: results[0].geometry.location.lat(),
            lng: results[0].geometry.location.lng(),
          });
        } else {
          console.log(
            'Geocode was not successful for the following reason: ' + status
          );
        }
      });
    }
  }, [proddetail]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const mapStyles = {
    width: '100%',
    height: '100%',
  };
  return (
    <>
      <div className="container tabs-wrapper">
        <Tabs value={value} onChange={handleChange} aria-label="product-view">
          <Tab label={Language.descriptionn} {...a11yProps(0)} />
          <Tab label={Language.bidhistoryy} {...a11yProps(1)} />
          <Tab label={Language.locationn} {...a11yProps(2)} />
          <Tab label={Language.dett} {...a11yProps(3)} />
          <Tab label={Language.termss} {...a11yProps(4)} />
          <Tab label={Language.shipp} {...a11yProps(5)} />
        </Tabs>
      </div>
      <div className="pvt-wrap">
        <div className="container">
          <TabPanel value={value} index={0}>
            <p>{proddetail.description}</p>
          </TabPanel>
          <TabPanel value={value} index={1}>
            {bidhistory.length ? (
              <div className="bid-hwrap bid-history-wrapper">
                <div className="bhis-box bhh-box">
                  <div className="row">
            <div className="col-6">{Language.usrnme}</div>
            <div className="col-2">{Language.bdamt}</div>
            <div className="col-2">{Language.biddatee}</div>
                  </div>
                </div>

                {bidhistory.map((item, index) => {
                  return (
                    <div className="bhis-box">
                      <div className="row">
                        <div className="col-6">
                          {maskEmailFront(item.email)}
                        </div>
                        <div className="col-2">${item.proposed_amount}</div>
                        <div className="col-2">
                          {dateFormatFront(item.created_at)}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            ) : (
              <div className="bhis-box">
                <div className="row">
            <div className="col-12 text-center"> {Language.no_bidsfound}!</div>
                </div>
              </div>
            )}
          </TabPanel>
          <TabPanel value={value} index={2}>
            <div className="row">
              <div className="col-4 ">
                <p>
                  {proddetail.sell_location}
                  <br />
                  {proddetail.city}
                  <br />
                  {proddetail.state}
                  <br />
                  {proddetail.location}
                  <br />
                  {proddetail.content_head5}
                </p>
              </div>
              <div className="col-8 text-center">
                <Map
                  google={google}
                  zoom={10}
                  style={mapStyles}
                  initialCenter={googleLat}
                >
                  <Marker position={googleLat} title={address} />
                </Map>
              </div>
            </div>
          </TabPanel>
          <TabPanel value={value} index={3}>
            <div className="bid-hwrap bid-history-wrapper">
              <div
                dangerouslySetInnerHTML={{
                  __html: proddetail.content_head2,
                }}
              ></div>
              {global.admin_bid_increment.length ? (
                <>
                  <div className="bhis-box bhh-box">
                    <div className="row">
              <div className="col-6">{Language.bidrange}</div>
              <div className="col-6">{Language.minincre}</div>
                    </div>
                  </div>
                  {global.admin_bid_increment.map((item, index) => {
                    return (
                      <>
                        <div className="bhis-box">
                          <div className="row">
                            <div className="col-6">
                              ${currencyFormat(item.from_amount)} - $
                              {currencyFormat(item.to_amount)}
                            </div>
                            <div className="col-6">
                              ${currencyFormat(item.increment)}
                            </div>
                          </div>
                        </div>
                      </>
                    );
                  })}
                </>
              ) : (
                <> </>
              )}
            </div>
          </TabPanel>
          <TabPanel value={value} index={4}>
            <div
              dangerouslySetInnerHTML={{
                __html: proddetail.content_head3,
              }}
            ></div>
          </TabPanel>
          <TabPanel value={value} index={5}>
            <div
              dangerouslySetInnerHTML={{
                __html: proddetail.content_head4,
              }}
            ></div>
          </TabPanel>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    Language : state.Language.languages
   }
 };

export default connect(mapStateToProps,undefined) (ProductTabs);
