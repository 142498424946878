/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react';
import Layout from '../../Components/Layout';
import SearchHeader from '../../Components/Search/SearchHeader';
import Filters from '../../Components/Search/Filters';
import SearchMarketList from '../../Components/Search/SearchMarketList';
import ProductContext from '../../context/product/productContext';
import { onChangeFunction } from '../../Common/Components';
import { Pagination } from '@material-ui/lab';
import queryString from 'query-string';
import $ from 'jquery';
import AuthContext from '../../context/auth/authContext';
import moment from 'moment';
import Loading from '../../Components/Loading';
import AlertContext from '../../context/alert/alertContext';
import { Redirect } from 'react-router-dom';
import LAauctionModal from '../../Components/Modal';
import {bid_extended,bid_extendedOff} from '../../Common/Socket';
import {connect} from 'react-redux';

let searchDataback = {
  sh_limit: 10,
  page: 1,
  orderby: '6',
  location: [],
  state: [],
  city: [],
  category: [],
  subcategory: [],
  storedate: [],
  enddate: null,
  fromprice: 0,
  toprice: 0,
  totalpage: 5,
  lotof: '',
  searchbar: '',
};
const SGrid = (props) => {
  const productContext = useContext(ProductContext);
  const alertContext = useContext(AlertContext);
  const authContext = useContext(AuthContext);
  const [toSearch, settoSearch] = useState(false);
  const [toSearchContent, settoSearchContent] = useState({
    NavigateLot: false,
    NavigateQ: false,
    id: '',
  });

  const { setAlert } = alertContext;
  const {
    isAuthenticated,
    responseStatus,
    clearResponse,
    saveSearch,
  } = authContext;

  const { search_allproducts, getAllSearchProducts } = productContext;
  const [loading, setLoading] = useState(true);
  const [ProcessingInital, setProcessingInital] = useState(false);
  let [viewProduct, setViewProduct] = useState([]);

  const [imgDefault, setImgDefault] = useState(false);

  useEffect(() => {
    setLoading(false);
    setImgDefault(true);
    setViewProduct(
      search_allproducts.results.length ? search_allproducts.results : []
    );
  }, [search_allproducts]);
  useEffect(() => {
    bid_extended(data => {
      if (viewProduct && viewProduct.length) {
        let index = viewProduct.findIndex(ele => Number(ele.id) === Number(data.product_id))
        var testDateUtc = moment.utc(data.date_extended);
        var localDate = moment(testDateUtc).local();
        if(viewProduct[index]){
          viewProduct[index].date_closed = localDate.toDate();
        }
        let viewnp = JSON.stringify(viewProduct)
        setViewProduct(JSON.parse(viewnp));
      }
    })
  }, [viewProduct])
  useEffect(() => {
    if (responseStatus && responseStatus !== '') {
      if (responseStatus === 'search_word_added') {
        setAlert(props.Language.svdsr_s_s ? props.Language.svdsr_s_s :'Saved Search Successfully', 'success');
        clearResponse();
      } else if (responseStatus === 'search_word_existing') {
        setAlert(props.Language.svdsr_a_e ? props.Language.svdsr_a_e : 'Search already Saved!', 'success');
        clearResponse();
      }
    } else {
      clearResponse();
    }
  }, [responseStatus]);

  const removeSublot = (id) => {
    let searchsubcat = [];
    $('input[datamasterid="' + id + '"]').each(function () {
      searchsubcat = search.subcategory.filter(
        (x) => parseInt(x) !== parseInt($(this).data('subid'))
      );
    });
    setSearch({ ...search, subcategory: searchsubcat });
  };

  useEffect(() => {
    setViewProduct([]);
    return () => {
      setViewProduct([]);
      bid_extendedOff();
    };
  }, []);

  const [search, setSearch] = useState({
    sh_limit: 10,
    page: 1,
    orderby: '6',
    location: [],
    state: [],
    city: [],
    category: [],
    subcategory: [],
    storedate: [],
    enddate: null,
    fromprice: 0,
    toprice: 0,
    totalpage: 5,
    lotof: '',
    searchbar: '',
  });

  const handleDateChange = (e) => {
    $('.MuiPopover-root').hide();
    $('body').css('overflow','auto');
    if (moment(e).isValid()) {
      setSearch({ ...search, enddate: moment(e).toDate() });
    }
  };

  const onChange = (e) => setSearch(onChangeFunction(e, search));
  const onChangeCheckBox = (e) => {
    let categorypush = search[e.target.name];
    if (e.target.value !== '') {
      $('input[name="' + [e.target.name] + '"]').each(function () {
        if (this.value === '') {
          this.checked = false;
        }
      });
      if (e.target.checked) {
        categorypush.push(e.target.value);
        setSearch({ ...search, [e.target.name]: categorypush });
      } else {
        const index = categorypush.indexOf(e.target.value);
        if (index > -1) {
          categorypush.splice(index, 1);
        }
        setSearch({ ...search, [e.target.name]: categorypush });
      }
    } else {
      setSearch({ ...search, [e.target.name]: [] });
      $('input[name="' + [e.target.name] + '"]').each(function () {
        if (this.value !== '') {
          this.checked = false;
        }
      });
    }
  };

  const onHandlePage = (event, value) => {
    setSearch({ ...search, page: value });
    window.scrollTo(0, 0);
  };

  const [listview, setlistview] = useState(false);

  useEffect(() => {
    if (ProcessingInital) {
      setLoading(true);
      setImgDefault(true);
      getAllSearchProducts(search);
      searchDataback = search;
    }
  }, [search]);

  useEffect(() => {
    if (props.location.search) {
      let value = queryString.parse(props.location.search);
      if (value.q) {
        setSearch({ ...search, searchbar: value.q });
        setProcessingInital(true);
      } else if (value.lotid) {
        setSearch({ ...search, lotof: value.lotid });
        setProcessingInital(true);
      } else {
        setProcessingInital(true);
      }
    } else {
      setSearch({ ...search, searchbar: '', lotof: '' });
      setProcessingInital(true);
    }
  }, [props.location.search]);

  useEffect(() => {
    if (props.match.params.type) {
      if (props.match.params.type === 'list') {
        setlistview(true);
      } else if (props.match.params.type === 'back') {
        setSearch(searchDataback);
        if (searchDataback.searchbar) {
          settoSearchContent({ NavigateQ: true, id: searchDataback.searchbar });
        } else if (searchDataback.lotof) {
          settoSearchContent({ NavigateLot: true, id: searchDataback.lotof });
        } else {
          settoSearch(true);
        }
      } else {
        setlistview(false);
      }
    } else {
      setlistview(false);
    }
  }, [props.match.params, props.match.params.type]);

  useEffect(() => {
    const loadImg = setTimeout(() => {
      setImgDefault(false);
    }, 500);
    return () => clearTimeout(loadImg);
  }, [search_allproducts]);

  const [saveSearchs, setSaveSearchs] = useState(false);

  const openSaveSearchs = () => {
    setSaveSearchs(!saveSearchs);
  };

  return (
    <Layout props={props}>
      {toSearch ? <Redirect to="/search" /> : null}
      {toSearchContent.NavigateLot ? (
        <Redirect to={`/search?lotid=${toSearchContent.id}`} />
      ) : null}
      {toSearchContent.NavigateQ ? (
        <Redirect to={`/search?q=${toSearchContent.id}`} />
      ) : null}
      <Loading loading={loading} />
      <SearchHeader
        fromAuction={0}
        handleDateChange={handleDateChange}
        search={search}
        title={props.Language.mrkt_place ? props.Language.mrkt_place:"MARKET PLACE"}
        subtitle={props.Language.auctions ? props.Language.auctions:"Auctions"}
        listLink={`/search/list${props.location.search}`}
        gridLink={`/search${props.location.search}`}
        listview={listview}
        onChange={onChange}
        search_allproducts={search_allproducts}
      />
      <div className="container mt-3">
        <div className="row">
          <div className="col-sm-4 col-md-3">
            <Filters
              fromAuction={0}
              search={search}
              removeSublot={removeSublot}
              onChange={onChange}
              locationItems={
                search_allproducts.city.length ? search_allproducts.city : []
              }
              categoriesCounts={
                search_allproducts.categoriesCounts.length
                  ? search_allproducts.categoriesCounts
                  : []
              }
              subcategoriesCounts={
                search_allproducts.subcategoriesCounts.length
                  ? search_allproducts.subcategoriesCounts
                  : []
              }
              upcomingAuction={
                search_allproducts.upcomingAuction.length
                  ? search_allproducts.upcomingAuction
                  : []
              }
              onChangeCheckBox={onChangeCheckBox}
            />
          </div>

          <div className="col-sm-8 col-md-9">
            {search.searchbar && isAuthenticated ? (
              <div className="svsh saveschhide" id="backtotopm">
                <div className="form-group savets" >
                  <span
                    onClick={() => saveSearch({ searchbar: search.searchbar })}
                  >
                    {props.Language.save_t_s ? props.Language.save_t_s : "Save This Search" }
                  </span>
                </div>
                <div className="popover right">
                  <div className="popover-content">
                    <div className="media">
                      <div className="sarrow media-left">
                        {' '}
                        <i className="fa fa-arrow-left"></i>
                      </div>
                      <div className="media-body">
                      {`${props.Language.save_t_s ? props.Language.save_t_s : "Save This Search"} ${props.Language.now_to_c_wmil ? props.Language.now_to_c_wmil : "Save this search now to see when more are listed!"}`}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}

            <SearchMarketList
              listview={listview}
              allsearchitems={viewProduct}
              imgLoad={imgDefault}
            />
            {search_allproducts.results.length ? (
              <div className="d-flex w-100 mb-3 pagination-wrapper">
                <div className="mr-auto mt-2">
                  {props.Language.owningsh ? props.Language.owningsh : "Showing"} {search_allproducts.set_disp} of{' '}
                  {search_allproducts.total_pagecnt}{' '}
                </div>
                <Pagination
                  count={Math.ceil(
                    search_allproducts.total_pagecnt / search.sh_limit
                  )}
                  page={search.page}
                  onChange={onHandlePage}
                  siblingCount={3}
                  showFirstButton
                  showLastButton
                  boundaryCount={2}
                />
              </div>
            ) : (
              <div className="np-pro-box"> No Records Found! </div>
            )}
          </div>

          <LAauctionModal
            open={saveSearchs}
            handleClose={openSaveSearchs}
            modaltitle="Save Searches"
          >
            <p>
              You can now save this search into an organized folder in Profile
              >> Saved Searches letting you manage your results more easily.
            </p>
            <form id="save-search">
              <div className="form-group">
                <label>Title of this saved search</label>
                <input
                  className="form-control"
                  name="title"
                  placeholder="Enter the Title"
                />
                <button className="btn btn-green" type="submit">
                  SAVE
                </button>
              </div>
            </form>
          </LAauctionModal>
        </div>
      </div>
    </Layout>
  );
};

const mapStateToProps = state =>{
  return {
    Language: state.Language.languages,
  }
}

export default connect(mapStateToProps)(SGrid);
