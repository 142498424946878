/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from 'react';
import MakePayment from '../../Components/Payment/makePayment';

import Container from '@material-ui/core/Container';
// import Grid from '@material-ui/core/Grid';
import Layout from '../../Components/Layout';

import ProductContext from '../../context/product/productContext';
import AlertContext from '../../context/alert/alertContext';

import DashboardHeader from '../../Components/DashboardHeader';
import AllBids from '../../Components/DashboardBuyer/AllBids';
import {connect} from 'react-redux';

const WatchList = (props) => {
  const productContext = useContext(ProductContext);
  const alertContext = useContext(AlertContext);

  const { responseStatus, updateBuyNowDynamic, clearResponse } = productContext;
  const { setAlert } = alertContext;

  const [bidDetails, setBidDetails] = useState({
    amount: 0,
    bid_id: 0,
    proj_id: 0,
  });
  const [open, setOpen] = useState(false);
  const openPayment = (cmgbid_id, cmgamount, cmgprojid) => {
    if (!open && cmgamount) {
      setBidDetails({
        ...bidDetails,
        amount: cmgamount,
        bid_id: cmgbid_id,
        proj_id: cmgprojid,
      });
    }
    setOpen(!open);
  };
  const onSuccessFunction = (payment) => {
    updateBuyNowDynamic({
      bid_id: bidDetails.bid_id,
      buynowdetails: {
        paid: '1',
        shipping_status: 'yet to process',
        sales_tax: payment.taxPercent,
        sales_tax_amount: payment.taxAmount,
        shipping_amount: payment.shippingAmount,
        total_invoice: payment.totalAmount,
      },
    });
  };

  useEffect(() => {
    if (responseStatus && responseStatus !== '') {
      if (responseStatus === 'buynow_updated_successfull') {
        // setAlert('Paid successfully', 'success');
        clearResponse();
      } else {
        setAlert(responseStatus, 'error');
        clearResponse();
      }
    } else {
      clearResponse();
    }
  }, [responseStatus]);

  return (
    <Layout props={props}>
      <DashboardHeader title={props.Language.my ? props.Language.my : "MY"} subtitle={props.Language.wat_cap ? props.Language.wat_cap : "WATCHLIST"} />
      <Container className="db-bids-wrapper pt-3">
        <AllBids page="watchlist" />
      </Container>

      <MakePayment
        open={open}
        openPayment={openPayment}
        amount={bidDetails.amount}
        onSuccess={onSuccessFunction}
        payType="auction_pay"
        sendID={bidDetails.proj_id}
      />
    </Layout>
  );
};

const mapStateToProps = state => {
  return {
    Language : state.Language.languages
  }
}

export default connect(mapStateToProps)(WatchList);
