/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react';
import Layout from '../../Components/Layout';
import UserContext from '../../context/user/userContext';
import Loading from '../../Components/Loading';

const Static = (props) => {
  const userContext = useContext(UserContext);
  const { static_page, getStaticPage } = userContext;
  const [loading, setLoading] = useState(true);
  const getFunction = (page_id) => {
    if (page_id !== 0) {
      setLoading(true);
      getStaticPage({ page_id: page_id });
    }
  };

  useEffect(() => {
    setLoading(false);
  }, [static_page]);

  useEffect(() => {
    let page_id = 0;
    if (props.location.pathname) {
      if (props.location.pathname === '/press') {
        page_id = 'press';
      } else if (props.location.pathname === '/trust') {
        page_id = 'trust';
      } else if (props.location.pathname === '/terms') {
        page_id = 'terms';
      } else if (props.location.pathname === '/privacy') {
        page_id = 'privacy';
      } else if (props.location.pathname === '/cookie-policy') {
        page_id = 'cookie-policy';
      } else if (props.location.pathname === '/about') {
        page_id = 'about';
      } else if (props.location.pathname === '/blog') {
        page_id = 'blog';
      } else if (props.location.pathname === '/laad') {
        page_id = 'laad';
      } else if (props.location.pathname === '/forgotpassword') {
        page_id = 'forgotpassword';
      }

      getFunction(page_id);
    }
  }, [props.location.pathname]);

  return (
    <Layout>
      <Loading loading={loading} />
      <div
        dangerouslySetInnerHTML={{
          __html: static_page.content,
        }}
      ></div>
    </Layout>
  );
};

export default Static;
