import React, { useContext, useState, useEffect } from 'react';
import Layout from '../../Components/Layout';
import TopCarousel from '../../Components/Home/TopCarousel';
import SiteAuctions from '../../Components/ItemCards/SiteAuctions';
import BidNowAuctions from '../../Components/ItemCards/BidNowAuctions';
import AuthLeft from '../../Components/Auth/AuthLeft';
import LoginForm from '../../Components/Auth/LoginForm';
// import Reviews from '../../Components/ItemCards/Reviews';
import AuthContext from '../../context/auth/authContext';
import { withRouter } from 'react-router';
import ProductContext from '../../context/product/productContext';
import { bindActionCreators } from 'redux';
import {compose} from 'redux';
import {connect} from 'react-redux';

const Home = (props) => {
  const authContext = useContext(AuthContext);
  const productContext = useContext(ProductContext);

  const { isAuthenticated, error } = authContext;

  const {
    search_allproducts,
    getAllSearchProducts,
    search_allauctions,
    getAllSearchAuctions,
  } = productContext;

  const [search] = useState({
    sh_limit: 6,
    page: 1,
    orderby: '2',
  });

  useEffect(() => {
    getAllSearchProducts(search);
    getAllSearchAuctions(search);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

 useEffect(()=>{
 console.log(props.Language,'asdasdasd')
 })
  

  return (
    <Layout props={props}>
      <div className="tcarousel-wrapper">
        <TopCarousel auctions={search_allauctions} />
        {/* <TopCarousel/> */}
      </div>
      <div className="container mt-3">
        {!isAuthenticated || error === 'Login successful' ? (
          <div className="row home-mwrap">
            <div className="col-sm-6 col-lg-4">
              <SiteAuctions auctions={search_allauctions} />
            </div>
            <div className="col-sm-6 col-lg-4 blr-wrap">
              <BidNowAuctions products={search_allproducts} />
            </div>
            <div className="col-sm-12 col-lg-4 pt-50">
              <div className="row home-auth-wrapper loginCnt">
                <div className="col-md-6 col-lg-12">
                  <LoginForm />
                </div>
                <div className="col-md-6 col-lg-12">
                  <div className="hal-wrap">
                    <AuthLeft
                      home
                      pageTitle={props.Language.regn_ow}
                      subTitle={props.Language.we_hp_ur}
                      primaryButton={props.Language.ter_now}
                      secondayTitle={props.Language.pr_er}
                      ssubTitle={props.Language.retr}
                      secondarySubTitle={props.Language.lob_pr}
                      threeSubTitle={props.Language.pm_hotline}
                      fourSubTitle={props.Language.sn_pick}
                      fiveSubTitle={props.Language.vip_htline}
                      secondaryButton={props.Language.pbrhe}
                      primaryHref="/register"
                      secondaryHref="/proregister"
                    />
                  </div>
                </div>
                <div className="mt-4 reviews-sm-wrapper col-md-12">
                  <div class="elfsight-app-d7869373-5393-459a-9744-2782df705b64"></div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="row home-mwrap">
            <div className="col-sm-6">
              <SiteAuctions auctions={search_allauctions} />
            </div>
            <div className="col-sm-6 blr-wrap">
              <BidNowAuctions products={search_allproducts} />
            </div>
          </div>
        )} 
      </div>
      
      <div className="home-bottom">
        <h3> {props.Language.wptgb}</h3>

        <ul className="list-inline">
          <li className="list-inline-item">
            <a
              target="_blank"
              rel="noreferrer noopener"
              href="https://www.woundedwarriorproject.org/"
            >
              <img src="/images/wwp.png" alt="" />
            </a>
          </li>
          <li className="list-inline-item">
            <a
              target="_blank"
              rel="noreferrer noopener"
              href="https://teencanceramerica.org/donate/"
            >
              <img src="/images/TCA.png" alt="" />
            </a>
          </li>
        </ul>
      </div>
    </Layout>
  );
};

// export default withRouter(Home);

const mapStateToProps = (state) => {
 return {
   Language : state.Language.languages
  }
};
 


export default compose(
  withRouter,
  connect(mapStateToProps, undefined)
)(Home);