import React from 'react';
import { Link } from 'react-router-dom';
import { dateFormatFront } from '../../Common/Components';
import {connect} from 'react-redux';

const SearchList = (props) => {
  return (
    <div className="search-right-wrapper">
      {props.listview ? (
        <div className="table-responsive">
          <table className="table table-stripped list-vew">
            <thead>
              <tr>
      <th>{props.Language.gemi}</th>
      <th>{props.Language.itleeee}</th>
      <th>{props.Language.deeees}</th>
                {/* <th>Location</th>
              <th>Auction Type</th> */}
                <th>{props.Language.ttiont}</th>
            <th>{props.Language.ctndss}</th>
              </tr>
            </thead>
            <tbody>
              {props.allsearchitems.map((item, index) => {
                return (
                  <tr key={`sl-${index}`}>
                    <td>
                      <div className="fpos-rel">
                        <img
                          src={`${global.image_url}${item.file_name}`}
                          alt={item.title}
                          className="sal-img"
                        />
                      </div>
                    </td>
                    <td>
                      <Link
                        to={`/search?lotid=${item.id}`}
                        className="details-link"
                      >
                        {item.title}
                      </Link>
                    </td>
                    <td>
                      <p className="sl-descp">{item.description}</p>
                    </td>
                    {/* <td>{item.location}</td>
                  <td>{item.auctiontype}</td> */}
                    <td>{dateFormatFront(item.date_added)}</td>
                    <td>{dateFormatFront(item.date_closed)}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      ) : (
        <div className="row">
          {props.allsearchitems.map((item, index) => {
            return (
              <div className="col-sm-6" key={`sl-${index}`}>
                <div className="list-item-wrapper">
                  <h3 className="item-title">{item.title}</h3>
                  <div className="media">
                    <div className="media-left fpos-rel">
                      <img
                        src={`${global.image_url}${item.file_name}`}
                        alt={item.title}
                      />
                    </div>
                    <div className="media-body">
                      <div className="loc-name">{item.location}</div>
                      <div className="item-text item-descp">
                        {item.description}
                      </div>
                      <div className="details-link">{item.auctiontype}</div>
                      <div className="item-text small-txt">
            <b>{props.Language.bgns}</b> {dateFormatFront(item.date_added)}
                      </div>
                      <div className="item-text small-txt">
            <b>{props.Language.endss}</b> {dateFormatFront(item.date_closed)}
                      </div>
                      <div className="item-text small-txt">
                        <img src="/images/placeholder.png" alt="" />{' '}
                        {item.lotcity}, {item.lotstate}
                      </div>
                      <Link
                        to={`/search?lotid=${item.id}`}
                        className="btn btn-green overflowBtn"
                      >
                        {props.Language.cloning}
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};


const mapStateToProps = (state) => {
  return {
    Language : state.Language.languages
   }
 };


export default connect( mapStateToProps, undefined ) (SearchList);
