import React from 'react';
import { Link } from 'react-router-dom';
import Timer from '../../Common/Timer';
import FavouriteButton from '../Favourite/FavouriteButton';
import {connect} from 'react-redux';

const GridCard = ({ item, index, isAuthenticated, imgLoaded ,Language}) => {
  return (
    <div className="col-sm-6 col-md-4 col-lg-3" key={`sg-${index}`}>
      <div className="card grid-card">
        <div className="fpos-rel">
          <Link to={`/product-view/${item.id}`}>
            <img
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = './images/no-image.png';
              }}
              src={
                imgLoaded
                  ? './images/imgDefault.gif'
                  : `${global.image_url}${item.file_name}`
              }
              alt={item.title}
            />
          </Link> 
        </div>
        <div className="card-body">
          <div className="price-txt">
            {item.auction === 1 ? `$ ${item.wprice}` : `$ ${item.bprice}`}
          </div>
          <Link to={`/product-view/${item.id}`} className="details-link">
            <div className="name-txt">{item.title}</div>
          </Link>
          <div className="timer-text">
            <Timer
              date_added={item.date_added}
              date_closed={item.date_closed}
              withText={0}
            ></Timer>
          </div>
          <div className="gv-btns-wrap mt-2">
            <Link to={`/product-view/${item.id}`} className="btn btn-green">{Language.dnoww}</Link>
            {isAuthenticated && <FavouriteButton watchlisted={item.watchlisted} project_id={item.id} />}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    Language : state.Language.languages
   }
 };

export default connect(mapStateToProps,undefined)(GridCard);
