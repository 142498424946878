/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import Input from '@material-ui/core/Input';
import moment from 'moment';
import Layout from '../../Components/Layout';
import { Redirect } from 'react-router-dom';
import '../../Components/Checkbox/Checkbox.css';
import Checkbox from '../../Components/Checkbox/Checkbox';
import { Checkbox as MCheckbox } from '@material-ui/core';
import DashboardHeader from '../../Components/DashboardHeader';
import { GetFileFromBase64 } from '../../Common/ImageHandler';
import apiCall from '../../Common/ApiCall';
import $ from 'jquery';
import ProductContext from '../../context/product/productContext';
import { RegionDropdown } from 'react-country-region-selector';
// CountryDropdown,
import AlertContext from '../../context/alert/alertContext';
import AuthContext from '../../context/auth/authContext';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Chip from '@material-ui/core/Chip';
import MomentUtils from '@date-io/moment';
import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';

import CKEditor from 'ckeditor4-react';
import {connect} from 'react-redux';

// import CKEditor from '@ckeditor/ckeditor5-react';
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import { onChangeFunction } from '../../Common/Components';
import Loading from '../../Components/Loading';

const TabPanel = ({ children, value, index, ...other }) => {
  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
};

let imgProcess = false;
const toReRenderImages = (product, setProduct, setAlert,props) => {
  return new Promise(async (resolve, reject) => {
    console.log('toReRenderImages', product.product_imagefile);
    let imageSelected = [];
    let arraytoPush = product.product_image;
    imageSelected = product.product_imagefile;
    let TotalLimit = 15;
    let currentCount =
      product.product_existingimage.length +
      product.product_image.length +
      imageSelected.length;
    if (imageSelected.length) {
      if (currentCount <= TotalLimit) {
        // eslint-disable-next-line array-callback-return
        Object.keys(imageSelected).map((key, index) => {
          var reader = new FileReader();
          reader.readAsDataURL(imageSelected[index]);
          reader.onloadend = function (e) {
            arraytoPush.push(reader.result);
            setProduct({
              ...product,
              product_image: arraytoPush,
            });
            console.log(
              'chcking heresecond ',
              imageSelected.length,
              parseInt(key) + 1
            );
            if (imageSelected.length === parseInt(key) + 1) {
              console.log('second last removing here');
              setProduct({
                ...product,
                product_imagefile: [],
              });
              document.getElementById('product_imagefile').value = '';
              $('#image_err').html('');
              imgProcess = false;
              resolve();
            }
          };
        });
      } else {
        setAlert( `${props.Language.cantupmt ? props.Language.cantupmt :  "Cannot upload more than"}` + TotalLimit + ` ${ props.Language.imgs ? props.Language.imgs : 'Images'}`, 'error');
        setProduct({
          ...product,
          product_imagefile: [],
        });
        document.getElementById('product_imagefile').value = '';
        $('#image_err').html('');
        imgProcess = false;
        resolve();
      }
    } else {
      document.getElementById('product_imagefile').value = '';
      imgProcess = false;
      $('#image_err').html('');
      resolve();
    }
  });
};

const errorTextMsg = (text) => {
  return `<ul class="parsley-errors-list filled"><li class="parsley-required">${text}</li></ul>`;
};

$(document).on('focus', '.replaceonfocus', function (event) {
  if ($(this).val() !== '') {
    if (parseInt($(this).val()) === 0) {
      $(this).val('');
    }
  }
});

$(document).on('focusout', '.replaceonfocus', function (event) {
  if ($(this).val() === '') {
    $(this).val(0);
  }
});

const customValidation = (product, setstepTwo, stepTwo, props) => {
  return new Promise(async (resolve, reject) => {
    let error = 0;
    if (
      !product.product_existingimage.length &&
      !product.product_image.length
    ) {
      $('#image_err').html(errorTextMsg(props.Language.thisir ? props.Language.thisir :'This value is required.'));
      error = 1;
    }
    if (!product.location) {
      $('#location_err').html(errorTextMsg(props.Language.thisir ? props.Language.thisir :'This value is required.'));
      error = 1;
    }
    if (!product.auction && !product.buynow) {
      $('#auction_error').html(errorTextMsg(props.Language.thisir ? props.Language.thisir :'This value is required.'));
      error = 1;
    }
    if (!product.state) {
      $('#state_err').html(errorTextMsg(props.Language.thisir ? props.Language.thisir :'This value is required.'));
      error = 1;
    }
    if (product.startdate >= product.enddate) {
      $('#startdate_err').html(
        errorTextMsg(props.Language.sslted ? props.Language.sslted : 'Start date should be lower than end date')
      );
      if (stepTwo) {
        setstepTwo(!stepTwo);
      }
      error = 1;
    }
    if (error === 1) {
      resolve(false);
    } else {
      resolve(true);
    }
  });
};

const PostProject = (props) => {
  const productContext = useContext(ProductContext);
  const alertContext = useContext(AlertContext);
  const { setAlert } = alertContext;
  const {
    postProduct,
    editProduct,
    edit_product,
    getActiveUsers,
    all_active_users,
    getEditProduct,
    responseStatus,
    clearResponse,
  } = productContext;
  const authContext = useContext(AuthContext);
  const { isAuthenticated, isAdmin } = authContext;
  const [toHome, settoHome] = useState(false);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    if (!isAuthenticated || !isAdmin) {
      settoHome(true);
    }
  }, [isAuthenticated]);
  const [toSellerDashboard, settoSellerDashboard] = useState(false);
  const [stepTwo, setstepTwo] = useState(false);
  const [stepThree, setstepThree] = useState(false);
  const [product, setProduct] = useState({
    product_id: '',
    auctionid: '',
    category_id: '',
    radcondition: '',
    subcategory: '',
    protitle: '',
    auction: 1,
    buynow: 0,
    sprice: 0,
    rprice: 0,
    enable_rprice: 1,
    private_auction: 0,
    selectedbuyers: [],
    prev_rprice: 0,
    bprice: 0,
    startdate: new Date(),
    description: '',
    qty: 1,
    tags: [],
    enddate: new Date(),
    state: 'California',
    location: 'United States',
    city: '',
    lotof: '',
    paymentoptionresult: '',
    contenthead1: 0,
    contenthead2: global.products_detail,
    contenthead3: global.products_terms,
    contenthead4: '',
    contenthead5: '',
    latestbid: 0,
    hasshipping: 0,
    sell_location: '',
    localpickup: 1,
    weight: 0,
    length: 0,
    width: 0,
    height: 0,
    product_deletedimage: [],
    product_image: [],
    product_existingimage: [],
    product_imagefile: [],
  });

  useEffect(() => {
    if (responseStatus && responseStatus !== '') {
      if (responseStatus === 'successfully_completed') {
        if (edit_product.product_details && props.match.params.id) {
          setAlert(props.Language.prdt_up_s ? props.Language.prdt_up_s : 'Product updated successfully', 'success');
        } else {
          setAlert(props.Language.prdt_po_s ? props.Language.prdt_po_s :'Product posted successfully', 'success');
        }
        clearResponse();
        setLoading(false);
        settoSellerDashboard(true);
      } else {
        setLoading(false);
        setAlert(responseStatus, 'error');
        clearResponse();
      }
    } else {
      clearResponse();
    }
  }, [responseStatus]);

  const onChangeContentHead2 = (data) =>
    setProduct({ ...product, contenthead2: data });
  const onChangeContentHead3 = (data) =>
    setProduct({ ...product, contenthead3: data });
  const onChangeContentHead4 = (data) =>
    setProduct({ ...product, contenthead4: data });

  useEffect(() => {
    async function fetchData() {
      if (product.product_imagefile.length && !imgProcess) {
        imgProcess = true;
        await Promise.all([toReRenderImages(product, setProduct, setAlert,props)]);
      }
    }
    console.log('second data initialised', imgProcess);
    fetchData();
  }, [product.product_imagefile]);

  useEffect(() => {
    if (props.match.params.id) {
      setLoading(true);
      getActiveUsers({});
      getEditProduct({ product_id: props.match.params.id });
    } else {
      getActiveUsers({});
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (edit_product.product_details && props.match.params.id) {
      let details = edit_product.product_details;
      // edit_product.attachments.forEach(async item => {
      //   let [value] = await Promise.all([
      //     GetBase64FromURL(`${imageURL}${item.src}`)
      //   ]);
      //   imagetoShow.push(value);
      // });

      setProduct({
        ...product,
        product_id: details.id ? details.id : '',
        auctionid: details.auctionid ? details.auctionid : '',
        category_id: details.category_id ? details.category_id : '',
        radcondition: details.conditionunique ? details.conditionunique : '',
        subcategory: details.subcategory ? details.subcategory : '',
        protitle: details.title ? details.title : '',
        latestbid: details.latestbid ? details.latestbid : '',
        location: details.location ? details.location : 'United States',
        state: details.state ? details.state : 'California',
        city: details.city ? details.city : '',
        tags: details.tags ? details.tags.split(',') : [],
        lotof: details.lotof ? details.lotof : '',
        auction: details.auction === 1 ? 1 : 0,
        buynow: details.buynow === 1 ? 1 : 0,
        sprice: details.sprice ? details.sprice : '',
        rprice: details.rprice ? details.rprice : '',
        enable_rprice: details.rprice && parseInt(details.rprice) > 0 ? 1 : 0,
        private_auction:
          details.private_auction && parseInt(details.private_auction) > 0
            ? 1
            : 0,
        selectedbuyers: details.selectedbuyers
          ? details.selectedbuyers.split(',').map((i) => parseInt(i))
          : [],
        prev_rprice: details.rprice ? details.rprice : '',
        bprice: details.bprice && details.buynow === 1 ? details.bprice : '',
        startdate: details.date_added ? details.date_added : new Date(),
        description: details.description ? details.description : '',
        qty: details.qty ? details.qty : 1,
        enddate: details.date_closed ? details.date_closed : new Date(),
        product_existingimage: edit_product.attachments.map((i) => i.src),
        paymentoptionresult: details.paymentoptionresult
          ? details.paymentoptionresult
          : '',
        market_status: details.market_status,
        contenthead1: details.content_head1 ? details.content_head1 : 0,
        contenthead2: details.content_head1
          ? details.content_head2
          : global.products_detail,
        contenthead3: details.content_head1
          ? details.content_head3
          : global.products_terms,
        hasshipping: details.hasshipping ? details.hasshipping : 0,
        sell_location: details.sell_location ? details.sell_location : 0,
        localpickup: details.localpickup ? parseInt(details.localpickup) : 0,
        weight: details.weight ? details.weight : 0,
        length: details.length ? details.length : 0,
        width: details.width ? details.width : 0,
        height: details.height ? details.height : 0,
        contenthead4: details.content_head4 ? details.content_head4 : '',
        contenthead5: details.content_head5 ? details.content_head5 : '',
      });
      setLoading(false);
    }
  }, [edit_product.product_details]);

  const onSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    let button = $('#formSubmitButton');
    button.attr('disabled', true);
    let [customValid] = await Promise.all([
      customValidation(product, setstepTwo, stepTwo,props),
    ]);
    if ($('#ProductForm').parsley().validate() && customValid) {
      const formDataSend = new FormData();
      for (var key in product) {
        if (key === 'product_image') {
          for (var inside in product[key]) {
            let [FileGenerated] = await Promise.all([
              GetFileFromBase64(product[key][inside]),
            ]);
            formDataSend.append(key, FileGenerated);
          }
        } else {
          if (typeof product[key] == 'object') {
            if (key === 'startdate') {
              formDataSend.append(key, product[key]);
            } else if (key === 'enddate') {
              formDataSend.append(key, product[key]);
            } else {
              for (let insideout in product[key]) {
                formDataSend.append(key, product[key][insideout]);
              }
            }
          } else {
            formDataSend.append(key, product[key]);
          }
        }
        if (key === 'product_imagefile') {
          if (product.hasshipping === 1) {
            let dataRate = {
              mode: 'sandbox',
              fromzipcode: '91601',
              zipcode: '91601',
              weight: parseInt(product.weight),
              length: parseInt(product.length),
              width: parseInt(product.width),
              height: parseInt(product.width),
            };

            if (props.match.params.id) {
              const [res] = await Promise.all([
                apiCall('post', 'getshippingrates', dataRate, '', 'auctionpay'),
              ]);
              if (res.data.success) {
                if (res.data.data.status[0].shipmentCost) {
                  editProduct(formDataSend);
                } else {
                  setAlert(props.Language.invalid_ship_d ? props.Language.invalid_ship_d : 'Invalid Shipping Data', 'error');
                }
              } else {
                setAlert(props.Language.invalid_ship_d ? props.Language.invalid_ship_d :'Invalid Shipping Data', 'error');
              }
            } else {
              const [res] = await Promise.all([
                apiCall('post', 'getshippingrates', dataRate, '', 'auctionpay'),
              ]);
              if (res.data.success) {
                if (res.data.data.status[0].shipmentCost) {
                  postProduct(formDataSend);
                } else {
                  setAlert(props.Language.invalid_ship_d ? props.Language.invalid_ship_d :'Invalid Shipping Data', 'error');
                }
              } else {
                setAlert(props.Language.invalid_ship_d ? props.Language.invalid_ship_d :'Invalid Shipping Data', 'error');
              }
            }
          } else {
            if (props.match.params.id) {
              editProduct(formDataSend);
            } else {
              postProduct(formDataSend);
            }
          }
          return false;
        }
      }
    } else {
      setLoading(false);
      if (!$('#ProductForm').parsley().validate('stepOne')) {
        if (stepTwo) {
          setstepTwo(!stepTwo);
        }
      } else {
      }
      button.attr('disabled', false);
      return false;
    }
  };

  const onChange = (e) => setProduct(onChangeFunction(e, product));

  // const clearError = (name) => {
  //   $('#' + name + '').html('');
  // };

  const changeReservePrice = (event) => {
    if (event.target.checked) {
      setProduct({
        ...product,
        rprice: product.prev_rprice,
        enable_rprice: 1,
      });
    } else {
      setProduct({
        ...product,
        rprice: 0,
        enable_rprice: 0,
        prev_rprice: product.rprice,
      });
    }
  };

  const onChangeStartDate = (e) => {
    $('#startdate_err').html('');
    setProduct({ ...product, startdate: moment(e).toDate() });
  };

  const onChangeTag = (e) => {
    setProduct({ ...product, tags: e });
  };

  const onChangeEndDate = (e) => {
    $('#startdate_err').html('');
    setProduct({ ...product, enddate: moment(e).toDate() });
  };

  const changeFirstNext = () => {
    setstepTwo(!stepTwo);
    window.scrollTo(0, 0);
  };

  const changeThirdNext = () => {
    setstepThree(!stepThree);
    window.scrollTo(0, 0);
  };

  const onChangeRegion = (e) => {
    $('#location_err').html('');
    $('#state_err').html('');
    setProduct({ ...product, state: e });
  };

  const handleRemoveExistingItem = (id) => {
    let initialProduct = product.product_existingimage;
    const existingDelete = product.product_deletedimage;
    if (initialProduct[parseInt(id)]) {
      existingDelete.push(initialProduct[parseInt(id)]);
      initialProduct.splice(parseInt(id), 1);
    }
    setProduct({
      ...product,
      product_existingimage: initialProduct,
      product_deletedimage: existingDelete,
    });
  };
  const handleRemoveItem = (id) => {
    let initialProduct = product.product_image;
    if (initialProduct[parseInt(id)]) {
      initialProduct.splice(parseInt(id), 1);
    }
    setProduct({ ...product, product_image: initialProduct });
    if (!imgProcess) {
      imgProcess = true;
      toReRenderImages(product, setProduct, setAlert);
    }
  };

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `product-view-${index}`,
      'aria-controls': `product-view-tabpanel-${index}`,
    };
  }

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangePrivate = (event) => {
    console.log('event.target.value', event.target.value);
    setProduct({ ...product, selectedbuyers: event.target.value });
  };

  return (
    <Layout props={props}>
      <Loading loading={loading} />
      {toSellerDashboard ? <Redirect to="/sellerdashboard" /> : null}
      {toHome ? <Redirect to="/" /> : null}
      <DashboardHeader title={props.Language.post_cap} subtitle={props.Language.mpauc} />
      <form
        id="ProductForm"
        data-parsley-validate
        className="pp-form"
        onSubmit={onSubmit}
      >
        <div className={stepTwo ? 'hidden' : ''}>
          <div className="row">
            <div className="col-12 form-group">
  <label className="pp-label">{props.Language.pr_title}</label>
              <input
                name="protitle"
                onChange={onChange}
                value={product.protitle}
                className="form-control"
                placeholder={props.Language.pr_titleuse}
                data-parsley-required="true"
                data-parsley-error-message={props.Language.thisir ? props.Language.thisir : 'This value is required'}
                data-parsley-group="stepOne"
              />
            </div>
          </div>
          <div className="row">
            <div className="col-12 form-group">
  <label className="pp-label">{props.Language.desccr}</label>
              <textarea
                rows="4"
                name="description"
                onChange={onChange}
                value={product.description}
                data-parsley-required="true"
                data-parsley-error-message={props.Language.thisir ? props.Language.thisir : 'This value is required'}
                data-parsley-group="stepOne"
                className="form-control"
                placeholder={props.Language.entrdescrp}
              ></textarea>
            </div>
          </div>

          <div className="row">
            <div className="col-sm-6 form-group">
  <label className="pp-label">{props.Language.categ}</label>
              <div className="clearfix cselec-wrap">
                <select
                  name="category_id"
                  onChange={onChange}
                  value={product.category_id}
                  className="custom-select"
                  data-parsley-required="true"
                  data-parsley-error-message={props.Language.thisir ? props.Language.thisir : 'This value is required'}
                  data-parsley-group="stepOne"
                >
                  <option value="">{props.Language.categ}</option>
                  {global.categories.map((category, index) => (
                    <option value={category.id}>{category.name}</option>
                  ))}
                </select>
              </div>
            </div>
            <div className="col-sm-6 form-group">
                  <label className="pp-label">{props.Language.sub_cate}</label>
              <div className="clearfix cselec-wrap">
                <select
                  name="subcategory"
                  onChange={onChange}
                  value={product.subcategory}
                  className="custom-select"
                  data-parsley-group="stepOne"
                  data-parsley-required={
                    parseInt(product.category_id) < 9 ? 'true' : 'false'
                  }
                >
                  <option value="">{props.Language.sub_cate}</option>
                  {global.subcategories
                    .filter((x) => x.master_id === product.category_id)
                    .map((subcat, index) => (
                      <option data-master={subcat.master_id} value={subcat.id}>
                        {subcat.name}
                      </option>
                    ))}
                </select>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-6 form-group">
                    <label className="pp-label">{props.Language.conditionnn}</label>
              <div className="clearfix cselec-wrap">
                <select
                  name="radcondition"
                  onChange={onChange}
                  value={product.radcondition}
                  className="custom-select"
                  data-parsley-required="true"
                  data-parsley-error-message={props.Language.thisir ? props.Language.thisir : 'This value is required'}
                  data-parsley-group="stepOne"
                >
                  <option value="">{props.Language.conditionnn}</option>
                  {global.conditions.map((condition, index) => (
                    <option value={condition.id}>{condition.name}</option>
                  ))}
                </select>
              </div>
            </div>
          </div>

          {/* <div className="form-group check-group">
            <Checkbox
              name="auction"
              onChange={(event, value) => {
                onChange(event);
                clearError('auction_error');
              }}
              value="Auction"
              checked={product.auction === 1 ? true : false}
            />
            <Checkbox
              name="buynow"
              onChange={(event, value) => {
                onChange(event);
                clearError('auction_error');
              }}
              value="Buy Now"
              checked={product.buynow === 1 ? true : false}
            />
            <span id="auction_error"></span>
          </div> */}

          <hr className="pp-divider" />
          <div className="form-group check-group">
            <Checkbox
              name="enable_rprice"
              onChange={(event, value) => {
                changeReservePrice(event);
              }}
              value={props.Language.en_res_pr}
              checked={product.enable_rprice === 1 ? true : false}
            />
          </div>
          <div id="auction">
            <div className="row">
              {product.auction ? (
                <>
                  <div className="col-sm-6 form-group">
                    <label className="pp-label">
                      {props.Language.st_price}
                      <span className="info-wrap">
                        <i className="fa fa-info-circle"></i>
                      </span>
                    </label>
                    <input
                      name="sprice"
                      type="number"
                      min="0"
                      onChange={onChange}
                      value={product.sprice}
                      className="form-control replaceonfocus"
                      id="start_price"
                      placeholder={props.Language.st_price}
                      data-refername="Start Price"
                      data-parsley-min="1"
                      data-parsley-required="true"
                      data-parsley-error-message={props.Language.thisir ? props.Language.thisir : 'This value is required'}
                      data-parsley-group="stepOne"
                    />
                  </div>
                  {product.enable_rprice ? (
                    <div className="col-sm-6 form-group">
                      <label className="pp-label">
                        {props.Language.res_pricee}
                        <span className="info-wrap">
                          <i className="fa fa-info-circle"></i>
                        </span>
                      </label>
                      <input
                        name="rprice"
                        type="number"
                        min="0"
                        onChange={onChange}
                        value={product.rprice}
                        className="form-control replaceonfocus"
                        placeholder={props.Language.res_pricee}
                        data-refername="Reserve Price"
                        data-parsley-required="true"
                        data-parsley-error-message={props.Language.thisir ? props.Language.thisir : 'This value is required'}
                        data-parsley-group="stepOne"
                        data-parsley-check-greater="Start Price"
                        data-parsley-min="1"
                        data-parsley-errors-container="#rprice_error"
                      />
                      <span id="rprice_error"></span>
                    </div>
                  ) : null}
                </>
              ) : null}
              {product.buynow ? (
                <>
                  <div className="col-sm-6 form-group">
                    <label className="pp-label">
                      Buynow Price
                      <span className="info-wrap">
                        <i className="fa fa-info-circle"></i>
                      </span>
                    </label>
                    <input
                      name="bprice"
                      type="number"
                      min="0"
                      onChange={onChange}
                      value={product.bprice}
                      className="form-control"
                      placeholder="Buynow Price"
                      data-parsley-required="true"
                      data-parsley-error-message={props.Language.thisir ? props.Language.thisir : 'This value is required'}
                      data-parsley-group="stepOne"
                      data-parsley-min="1"
                      data-parsley-check-greater="Reserve Price"
                      data-parsley-errors-container="#bprice_error"
                    />
                    <span id="bprice_error"></span>
                  </div>
                </>
              ) : null}
              <div className="col-sm-6 form-group">
              <label className="pp-label">{props.Language.quantityy}</label>
                <input
                  name="qty"
                  type="number"
                  min="0"
                  onChange={onChange}
                  value={product.qty}
                  className="form-control"
                  placeholder={props.Language.quantityy}
                  data-parsley-required="true"
                  data-parsley-error-message={props.Language.thisir ? props.Language.thisir : 'This value is required'}
                  data-parsley-group="stepOne"
                  data-parsley-min="1"
                />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-sm-6 form-group">
              <label className="pp-label">{props.Language.stdte}</label>
              <div className="cal-input">
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <DateTimePicker
                    variant="inline"
                    name="startdate"
                    id="startdate"
                    disablePast
                    value={product.startdate}
                    onChange={onChangeStartDate}
                    placeholder={props.Language.stdte}
                  />
                </MuiPickersUtilsProvider>
                <span id="startdate_err"></span>
              </div>
            </div>
            <div className="col-sm-6 form-group">
              <label className="pp-label">{props.Language.endate}</label>
              <div className="cal-input">
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <DateTimePicker
                    variant="inline"
                    name="enddate"
                    id="enddate"
                    disablePast
                    value={product.enddate}
                    onChange={onChangeEndDate}
                    placeholder={props.Language.endate}
                  />
                </MuiPickersUtilsProvider>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-6 form-group">
              <div className="form-group check-group">
                <Checkbox
                  name="private_auction"
                  onChange={(event, value) => {
                    onChange(event);
                  }}
                  value={props.Language.pvt_act}
                  checked={product.private_auction === 1 ? true : false}
                />
              </div>
            </div>
            <div className="col-sm-6 form-group">
              {product.private_auction ? (
                <Select
                  multiple
                  className="w-100"
                  value={product.selectedbuyers}
                  onChange={handleChangePrivate}
                  input={<Input />}
                  renderValue={(selected) =>
                    all_active_users.map((lot) =>
                      product.selectedbuyers.indexOf(parseInt(lot.id)) > -1
                        ? lot.email + ' , '
                        : null
                    )
                  }
                  MenuProps={{
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'left',
                    },
                    transformOrigin: {
                      vertical: 'top',
                      horizontal: 'left',
                    },
                    getContentAnchorEl: null,
                  }}
                >
                  {all_active_users.map((lot) => (
                    <MenuItem key={lot.id} value={lot.id}>
                      <MCheckbox
                        checked={product.selectedbuyers.indexOf(lot.id) > -1}
                      />
                      <ListItemText primary={lot.email} />
                    </MenuItem>
                  ))}
                </Select>
              ) : null}
            </div>
          </div>

          <div className="pp-btns-wrap">
            {/* <button type="button" className="btn btn-primary">
              CANCEL
            </button> */}
            <button
              onClick={changeFirstNext}
              type="button"
              className="btn btn-primary"
            >
              {props.Language.nextaps}
            </button>
          </div>
        </div>
        <div className={stepThree ? '' : 'hidden'}>
          <div className="container tabs-wrapper">
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="product-view"
            >
              <Tab label={props.Language.detcaps} {...a11yProps(0)} />
              <Tab label={props.Language.termss} {...a11yProps(1)} />
              <Tab label={props.Language.shipp} {...a11yProps(2)} />
            </Tabs>
          </div>
          <div className="pvt-wrap">
            <div className="container">
              <TabPanel value={value} index={0}>
                <CKEditor
                  data={product.contenthead2}
                  config={{
                    allowedContent: true,
                  }}
                  onChange={(event, editor) => {
                    onChangeContentHead2(event.editor.getData());
                  }}
                />
              </TabPanel>
              <TabPanel value={value} index={1}>
                <CKEditor
                  data={product.contenthead3}
                  config={{
                    allowedContent: true,
                  }}
                  onChange={(event, editor) => {
                    onChangeContentHead3(event.editor.getData());
                  }}
                />
              </TabPanel>
              <TabPanel value={value} index={2}>
                <CKEditor
                  data={product.contenthead4}
                  config={{
                    allowedContent: true,
                  }}
                  onChange={(event, editor) => {
                    onChangeContentHead4(event.editor.getData());
                  }}
                />
              </TabPanel>
            </div>
          </div>
          <div className="pp-btns-wrap">
            <button
              type="button"
              onClick={changeThirdNext}
              className="btn btn-primary"
            >
              {props.Language.prevcaps}
            </button>
          </div>
        </div>
        <div className={stepThree ? 'hidden' : stepTwo ? '' : 'hidden'}>
          <div className="row">
            <div className="col-sm-12 form-group">
                <label className="pp-label">{props.Language.addrss}</label>
              <div className="clearfix cselec-wrap">
                <input
                  name="sell_location"
                  onChange={onChange}
                  value={product.sell_location}
                  className="form-control"
                  placeholder={props.Language.addrss}
                  data-parsley-required="true"
                  data-parsley-error-message={props.Language.thisir ? props.Language.thisir : 'This value is required'}
                />
              </div>
            </div>
          </div>
          <div className="row">
            {/* <div className="col-sm-6 form-group">
              <label className="pp-label">Country</label>
              <div className="clearfix cselec-wrap">
                <CountryDropdown
                  name="location"
                  onChange={onChangeCountry}
                  value={product.location}
                  className="custom-select"
                />
                <span id="location_err"></span>
              </div>
            </div> */}

            <div className="col-sm-6 form-group">
          <label className="pp-label">{props.Language.statee}</label>
              <div className="clearfix cselec-wrap">
                <RegionDropdown
                  name="state"
                  onChange={onChangeRegion}
                  country={product.location}
                  value={product.state}
                  className="custom-select"
                />
                <span id="state_err"></span>
              </div>
            </div>
            <div className="col-sm-6 form-group">
          <label className="pp-label">{props.Language.cityy}</label>
              <div className="clearfix cselec-wrap">
                {product.state === 'California' ? (
                  <>
                    <select
                      name="city"
                      onChange={onChange}
                      value={product.city}
                      className="custom-select"
                      data-parsley-required="true"
                      data-parsley-error-message={props.Language.thisir ? props.Language.thisir : 'This value is required'}
                    >
                      <option value="">{props.Language.cityy}</option>
                      {global.californiacity.map((city, index) => (
                        <option value={city.city}>{city.city}</option>
                      ))}
                    </select>
                  </>
                ) : (
                  <>
                    <input
                      name="city"
                      onChange={onChange}
                      value={product.city}
                      className="form-control"
                      placeholder={props.Language.entcity}
                      data-parsley-required="true"
                      data-parsley-error-message={props.Language.thisir ? props.Language.thisir : 'This value is required'}
                    />
                  </>
                )}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-6 form-group">
                <label className="pp-label">{props.Language.zip_sm}</label>
              <div className="clearfix cselec-wrap">
                <input
                  name="contenthead5"
                  onChange={onChange}
                  value={product.contenthead5}
                  className="form-control"
                  placeholder={props.Language.entzpcd}
                  data-parsley-required="true"
                  data-parsley-error-message={props.Language.thisir ? props.Language.thisir : 'This value is required'}
                />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-12 form-group check-group">
              <Checkbox
                name="contenthead1"
                onChange={(event, value) => {
                  onChange(event);
                }}
                value={props.Language.bpu}
                checked={product.contenthead1 === 1 ? true : false}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-12 form-group check-group">
              <Checkbox
                name="hasshipping"
                onChange={(event, value) => {
                  onChange(event);
                }}
                value={props.Language.shipp_sm}
                checked={product.hasshipping === 1 ? true : false}
              />
              <label className="custom-checkbox pure-material-checkbox">
               {props.Language.pickupp}
                <input
                  type="checkbox"
                  name="localpickup"
                  value={null}
                  onChange={(event, value) => {
                    onChange(event);
                  }}
                  checked={parseInt(product.localpickup) === 0 ? false : true}
                />
                <span></span>
              </label>
              <span id="auction_error"></span>
            </div>
          </div>
          {product.hasshipping ? (
            <>
              <div className="row">
                <div className="col-sm-6 form-group">
          <label className="pp-label">{props.Language.weightt}</label>
                  <input
                    name="weight"
                    type="number"
                    min="0"
                    onChange={onChange}
                    value={product.weight}
                    className="form-control replaceonfocus"
                    placeholder={props.Language.weightt}
                    data-parsley-required="true"
                    data-parsley-error-message={props.Language.thisir ? props.Language.thisir : 'This value is required'}
                    data-parsley-group="stepOne"
                    data-parsley-min="1"
                    data-parsley-errors-container="#weight_error"
                  />
                  <span id="weight_error"></span>
                </div>

                <div className="col-sm-6 form-group">
          <label className="pp-label">{props.Language.lengthh}</label>
                  <input
                    name="length"
                    type="number"
                    min="0"
                    onChange={onChange}
                    value={product.length}
                    className="form-control replaceonfocus"
                    placeholder={props.Language.lengthh}
                    data-parsley-required="true"
                    data-parsley-error-message={props.Language.thisir ? props.Language.thisir : 'This value is required'}
                    data-parsley-group="stepOne"
                    data-parsley-min="1"
                    data-parsley-errors-container="#length_error"
                  />
                  <span id="length_error"></span>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-6 form-group">
          <label className="pp-label">{props.Language.widthh}</label>
                  <input
                    name="width"
                    type="number"
                    min="0"
                    onChange={onChange}
                    value={product.width}
                    className="form-control replaceonfocus"
                    placeholder={props.Language.widthh}
                    data-parsley-required="true"
                    data-parsley-error-message={props.Language.thisir ? props.Language.thisir : 'This value is required'}
                    data-parsley-group="stepOne"
                    data-parsley-min="1"
                    data-parsley-errors-container="#width_error"
                  />
                  <span id="width_error"></span>
                </div>

                <div className="col-sm-6 form-group">
          <label className="pp-label">{props.Language.hightt}</label>
                  <input
                    name="height"
                    type="number"
                    min="0"
                    onChange={onChange}
                    value={product.height}
                    className="form-control replaceonfocus"
                    placeholder={props.Language.hightt}
                    data-parsley-required="true"
                    data-parsley-error-message={props.Language.thisir ? props.Language.thisir : 'This value is required'}
                    data-parsley-group="stepOne"
                    data-parsley-min="1"
                    data-parsley-errors-container="#height_error"
                  />
                  <span id="height_error"></span>
                </div>
              </div>
            </>
          ) : null}
          <div className="row">
            <div className="col-sm-12 form-group">
              <label className="pp-label">{props.Language.keywordss}</label>
              <div className="clearfix cselec-wrap">
                <Autocomplete
                  multiple
                  id="tags-filled"
                  limitTags={2}
                  options={[]}
                  value={product.tags.length ? product.tags : []}
                  onChange={(event, value) => onChangeTag(value)}
                  freeSolo
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip
                        variant="outlined"
                        label={option}
                        {...getTagProps({ index })}
                      />
                    ))
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      className="form-control"
                      variant="filled"
                    />
                  )}
                />
              </div>
            </div>
          </div>
          <div className="row">
            {/* <div className="col-12 form-group">
              <label className="pp-label">Other</label>
              <textarea
                rows="4"
                name="contenthead1"
                value={product.contenthead1}
                onChange={onChange}
                className="form-control"
                placeholder="SOPs – Standard Operating Procedures for Puchase"
              ></textarea>
            </div> */}
            <div className="col-12 form-group">
              <label className="pp-label">
                {props.Language.uplimg}
              </label>
              <div className="upload-document">
                <input
                  type="file"
                  id="product_imagefile"
                  accept="image/x-png, image/jpeg, image/png"
                  className="form-control"
                  name="product_imagefile"
                  onChange={onChange}
                  data-parsley-errors-container="#image_err"
                  multiple
                />
                <div>{props.Language.dr_img}</div>
                <button type="button" className="btn btn-primary">
                  {props.Language.uplimage}
                </button>
                {/* <div className="btxt">SIZE (500x500)</div> */}
              </div>
            </div>
          </div>
          <span id="image_err"></span>
          {product.product_existingimage.map((item, index) => {
            return (
              <div className="pp-uimg" key={index}>
                <span
                  onClick={() => handleRemoveExistingItem(index)}
                  className="img-del"
                >
                  <i className="fa fa-times"></i>
                </span>
                {/* {item} */}
                <img
                  className="pro-img"
                  alt="ProdImage"
                  src={`${global.image_url}${item}`}
                />
              </div>
            );
          })}
          {product.product_image.map((item, index) => {
            return (
              <div className="pp-uimg" key={index}>
                <span
                  onClick={() => handleRemoveItem(index)}
                  className="img-del"
                >
                  <i className="fa fa-times"></i>
                </span>
                {/* {item} */}
                <img className="pro-img" alt="ProdImage" src={item} />
              </div>
            );
          })}
          {/* <div className="text-center">
            <button className="sdp-link" type="button">
              Save as draft
            </button>
            <button className="sdp-link" type="button">
              Preview
            </button>
          </div> */}
          <div className="pp-btns-wrap">
            <button
              type="button"
              onClick={changeFirstNext}
              className="btn btn-primary"
            >
              {props.Language.prevcaps}
            </button>

            {/* <button type="button" className="btn btn-primary">
              CANCEL
            </button> */}
            {(props.match.params.id &&
              parseInt(product.auction) === 1 &&
              parseInt(product.latestbid) > 0) ||
            (props.match.params.id &&
              product.market_status !== 'open') ? null : (
              <button
                id="formSubmitButton"
                type="submit"
                className="btn btn-green"
              >
                {props.Language.stim}
              </button>
            )}
            {/* 
            {(props.match.params.id && product.market_status !== 'draft') ||
            !props.match.params.id ? (
              <button className="btn btn-green">SAVE AS DRAFT</button>
            ) : null} */}
            <button
              onClick={changeThirdNext}
              type="button"
              className="btn btn-primary"
            >
              {props.Language.staticcaps}
            </button>
          </div>
        </div>
      </form>
    </Layout>
  );
};

const mapStateToProps = (state) => {
  return {
    Language : state.Language.languages
   }
 };

export default connect(mapStateToProps,undefined) (PostProject);
