/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useContext, useEffect } from 'react';

import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Layout from '../../Components/Layout';
import { Link, Redirect } from 'react-router-dom';
import $ from 'jquery';
import { onChangeFunction } from '../../Common/Components';
import AlertContext from '../../context/alert/alertContext';
import UserContext from '../../context/user/userContext';
import queryString from 'query-string';
import {connect} from 'react-redux';

const ChangePassword = (props) => {
  useEffect(()=>{
    
  },[])
  useEffect(() => {
    if (props.location.search) {
      let value = queryString.parse(props.location.search);
      if (value.id) {
        setPass({ ...pass, user_token: value.id });
      } else {
        settoHome(true);
      }
    } else {
      settoHome(true);
    }
  }, [props.location.search]);

  const button = $('#resetSubmitButton');
  const alertContext = useContext(AlertContext);
  const userContext = useContext(UserContext);

  const { setAlert } = alertContext;
  const { changeForgotPassword, responseStatus, clearResponse } = userContext;

  const [toHome, settoHome] = useState(false);
  const [pass, setPass] = useState({
    user_token: '',
    confirm_password: '',
    password: '',
  });

  const onChange = (e) => setPass(onChangeFunction(e, pass));

  useEffect(() => {
    if (responseStatus && responseStatus !== '') {
      $('#resetSubmitButton').attr('disabled', false);
      if (responseStatus === 'password_changed_successful') {
        setAlert(props.Language.pass_s_c ? props.Language.pass_s_c : 'Password Successfully changed', 'success');
        clearResponse();
        settoHome(true);
      } else {
        setAlert(responseStatus, 'error');
        clearResponse();
      }
    } else {
      clearResponse();
    }
  }, [responseStatus]);

  const onSubmit = (e) => {
    e.preventDefault();
    $('#resetSubmitButton').attr('disabled', true);
    if ($('#ChangePwdForm').parsley().validate()) {
      changeForgotPassword(pass);
    } else {
      $('#resetSubmitButton').attr('disabled', false);
      return false;
    }
  };

  return (
    <Layout props={props}>
      {toHome ? <Redirect to="/" /> : null}

      <div className="container">
        <Grid className="formBox">
          <Grid className="lFormHead">
            <Link to="/">
              <img src="/images/auth-logo.png" alt="" />
            </Link>
          </Grid>
          <Grid className="lFormBody">
            <h4 className="main-head">
  <span className="hbox">{props.Language.ange}</span>
  <span>{props.Language.pass_caps}</span>
            </h4>
  <p class="auth-note">{props.Language.your_passhr}</p>
            <form id="ChangePwdForm" data-parsley-validate onSubmit={onSubmit}>
              <Grid container>
                <Grid item xs={12} className="mb-3">
                  <TextField
                    autoFocus={true}
                    name="password"
                    id="password"
                    type="password"
                    onChange={onChange}
                    value={pass.password}
                    className="form-control fpwd-input"
                    placeholder={props.Language.epasshrn}
                    inputProps={{
                      'data-parsley-minlength': '8',
                      'data-parsley-required': 'true',
                      "data-parsley-error-message":props.Language.thisir ? props.Language.thisir : 'This value is required'
                    }}
                  />
                </Grid>
                <Grid item xs={12} className="mb-2">
                  <TextField
                    name="confirm_password"
                    type="password"
                    onChange={onChange}
                    value={pass.confirm_password}
                    className="form-control fpwd-input"
                    placeholder={props.Language.confirm_password}
                    inputProps={{
                      'data-parsley-minlength': '8',
                      'data-parsley-required': 'true',
                      'data-parsley-equalto': '#password',
                      'data-parsley-equalto-message' : 'teste',
                      "data-parsley-error-message":props.Language.thisir ? props.Language.thisir : 'This value is required'
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <button className="btn btn-green" id="resetSubmitButton">
                    {props.Language.stim}
                  </button>
                </Grid>
              </Grid>
            </form>
          </Grid>
        </Grid>
      </div>
    </Layout>
  );
};

const mapStateToProps = (state) => {
  return {
    Language : state.Language.languages
   }
 };

export default connect(mapStateToProps,undefined) (ChangePassword);
