/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useContext, useEffect } from 'react';
import $ from 'jquery';
import { Redirect } from 'react-router-dom';
import Layout from '../../Components/Layout';
import AlertContext from '../../context/alert/alertContext';
import { GetFileFromBase64 } from '../../Common/ImageHandler';
import UserContext from '../../context/user/userContext';
import { onChangeFunction } from '../../Common/Components';
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Loading from '../../Components/Loading';
import {connect} from 'react-redux'
function toReRenderImages(auction, setAuction) {
  return new Promise(async (resolve, reject) => {
    let imageSelected = [];
    let arraytoPush = [];
    imageSelected = auction.product_imagefile;
    if (imageSelected.length) {
      var reader = new FileReader();
      reader.readAsDataURL(imageSelected[0]);
      reader.onloadend = function (e) {
        arraytoPush.push(reader.result);
        setAuction({
          ...auction,
          banner_image: arraytoPush,
          product_imagefile: [],
        });
        document.getElementById('product_imagefile').value = '';
        $('#image_err').html('');
        resolve();
      };
    } else {
      document.getElementById('product_imagefile').value = '';
      $('#image_err').html('');
      resolve();
    }
  });
}

const errorTextMsg = (text) => {
  return `<ul class="parsley-errors-list filled"><li class="parsley-required">${text}</li></ul>`;
};

const scrollFunction = (id) => {
  $('html, body').animate(
    {
      scrollTop: $(`#${id}`).offset().top,
    },
    2000
  );
};

const BlogPost = (props) => {
  const button = $('#BlogForm');
  const userContext = useContext(UserContext);
  const alertContext = useContext(AlertContext);
  const { addNewBlogPost, responseStatus, clearResponse } = userContext;

  const [toHome, settoHome] = useState(false);
  const { setAlert } = alertContext;
  const [loading, setLoading] = useState(false);
  const [blogPost, setBlogPost] = useState({
    title: '',
    description: '',
    content: '',
    banner_image: [],
    publish: 0,
    product_imagefile: [],
  });

  useEffect(() => {
    if (responseStatus && responseStatus !== '') {
      if (responseStatus === 'blog_successfullysend') {
        setAlert(props.Language.blog_a_s ? props.Language.blog_a_s : 'Blog added successfully', 'success');
        clearResponse();
        setLoading(false);
        settoHome(true);
      } else if (responseStatus === 'blog_notsend') {
        setLoading(false);
        setAlert(props.Language.blog_na_tra ? props.Language.blog_a_s :'Blog not added! Try again', 'error');
        clearResponse();
      } else {
        setAlert(responseStatus, 'error');
        clearResponse();
      }
    } else {
      clearResponse();
    }
  }, [responseStatus]);

  const onChange = (e) => setBlogPost(onChangeFunction(e, blogPost));
  const onChangeContent = (data) => setBlogPost({ ...blogPost, content: data });
  useEffect(() => {}, []);

  const customValidation = (product) => {
    return new Promise(async (resolve, reject) => {
      let error = 0;
      if (!product.banner_image.length) {
        $('#image_err').html(errorTextMsg('This value is required.'));
        if (error === 0) {
          scrollFunction('image_top');
        }
        error = 1;
      }
      if (error === 1) {
        resolve(false);
      } else {
        resolve(true);
      }
    });
  };
  const onSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    let button = $('#formSubmitButton');
    button.attr('disabled', true);
    let [customValid] = await Promise.all([customValidation(blogPost)]);
    if ($('#BlogForm').parsley().validate() && customValid) {
      const formDataSend = new FormData();
      for (var key in blogPost) {
        if (key === 'banner_image') {
          for (var inside in blogPost[key]) {
            let [FileGenerated] = await Promise.all([
              GetFileFromBase64(blogPost[key][inside]),
            ]);
            formDataSend.append(key, FileGenerated);
          }
        } else {
          if (typeof blogPost[key] == 'object') {
            for (let insideout in blogPost[key]) {
              formDataSend.append(key, blogPost[key][insideout]);
            }
          } else {
            formDataSend.append(key, blogPost[key]);
          }
        }
        if (key === 'product_imagefile') {
          addNewBlogPost(formDataSend);
          return false;
        }
      }
    } else {
      setLoading(false);
      button.attr('disabled', false);
      return false;
    }
  };
  // for image rendering
  useEffect(() => {
    async function fetchData() {
      if (blogPost.product_imagefile.length) {
        await Promise.all([toReRenderImages(blogPost, setBlogPost)]);
      }
    }
    fetchData();
  }, [blogPost.product_imagefile]);

  return (
    <Layout>
      <Loading loading={loading} />
      {toHome ? <Redirect to="/pro-buyer-blog" /> : null}
      <section className="mt-5 mb-5 contact-us-wrapper">
        <div className="blog-wrap pl-3 pr-3">
          <h2 className="clearfix main-head mb-3 pblog-head">
            <span className="hbox">Create</span>
            <span>Blog Post</span>
          </h2>
          <form id="BlogForm" onSubmit={onSubmit}>
            <div className="form-group">
              <label className="sf-label">Title</label>
              <input
                className="form-control"
                name="title"
                value={blogPost.title}
                onChange={onChange}
                data-parsley-required="true"
                placeholder="Enter title of the post"
              />
            </div>
            <div className="form-group">
              <label className="sf-label">Description</label>
              <textarea
                className="form-control"
                name="description"
                value={blogPost.description}
                onChange={onChange}
                data-parsley-required="true"
                rows="4"
              ></textarea>
            </div>
            <div className="form-group">
              <label className="sf-label">Content</label>
              <CKEditor
                editor={ClassicEditor}
                data="Blog Post Content Here"
                onChange={(event, editor) => {
                  onChangeContent(editor.getData());
                }}
              />
            </div>

            <div className="row">
              <div className="col-12 form-group">
                <span id="image_top"> </span>
                <label className="pp-label">
                  Upload thumbnail image ( JPEG, PNG )
                </label>
                <div className="upload-document">
                  <input
                    type="file"
                    id="product_imagefile"
                    className="form-control"
                    name="product_imagefile"
                    data-parsley-errors-container="#image_err"
                    onChange={onChange}
                  />
                  <div>DRAG IMAGES</div>
                  <button type="button" className="btn btn-primary">
                    UPLOAD IMAGES
                  </button>
                  {/* <div className="btxt">SIZE (500x500)</div> */}
                </div>
              </div>
            </div>
            <span id="image_err"></span>
            {blogPost.banner_image.map((item, index) => {
              return (
                <span key={index}>
                  <img className="pro-img" alt="ProdImage" src={item} />
                </span>
              );
            })}
            <div className="pt-3 mb-4">
              <button
                id="formSubmitButton"
                type="submit"
                className="btn btn-green m-auto"
              >
                SUBMIT
              </button>
            </div>
          </form>
        </div>
      </section>
    </Layout>
  );
};

const mapStateToProps = state => {
  return {
    Language : state.Language.languages
  }
}

export default connect(mapStateToProps)(BlogPost);
