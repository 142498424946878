/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Layout from '../../Components/Layout';
import UserContext from '../../context/user/userContext';
import AuthContext from '../../context/auth/authContext';
import { Pagination } from '@material-ui/lab';
import {connect} from 'react-redux';

const BlogGrid = ({Language, title, description, image, dateCreated, createdBy }) => {
  const authContext = useContext(AuthContext);
  const { user } = authContext;
  const userContext = useContext(UserContext);
  const { getBlogDetails, blogposted } = userContext;

  const [search, setSearch] = useState({
    sh_limit: 10,
    page: 1,
  });

  const onHandlePage = (event, value) => {
    setSearch({ ...search, page: value });
  };

  useEffect(() => {
    getBlogDetails(search);
  }, [search]);

  return (
    <Layout>
      <div className="container search-content-wrap mb-5">
        <h2 className="main-head clearfix text-left mt-5 mb-3 pblog-head">
  <span className="hbox">{Language.pbyyy}</span>
  <span>{Language.ogpst}</span>
        </h2>
        {user.role === '3' ? (
          <>
            <Link to={`/create-blog`} className="blog-title">
        <button className="btn btn-green">{Language.stogg}</button>
            </Link>
          </>
        ) : null}
        <div className="clearfix"></div>

        {blogposted.results.length ? (
          <div className="row blog-lwrap">
            {blogposted.results.map((item, index) => {
              return (
                <div
                  className="col-sm-6 col-md-4 col-lg-3 form-group"
                  key={index}
                >
                  <div className="card grid-card">
                    <Link
                      to={`/pro-blog-details/${item.id}`}
                      className="blog-title"
                    >
                      <img
                        src={`${global.profile_image_url}banner/${item.images}`}
                        alt={item.title}
                        className="bgc-img"
                      />
                    </Link>
                    <div className="card-body">
                      {/* <div className="clearfix">
                        <div className="float-left">{item.createdBy}</div>
                        <div className="float-right">{item.dateCreated}</div>
                      </div> */}

                      {item.title}

                      <div className="blog-descp">{item.description}</div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        ) : (
          <div className="np-pro-box"> {Language.no_ogs_std} </div>
        )}

        {blogposted.results.length ? (
          <>
            <div className="d-flex mt-3">
              <div className="ml-auto mt-2">
                Showing {search.sh_limit * (search.page - 1) + 1} -{' '}
                {search.sh_limit * search.page > blogposted.total_pagecnt
                  ? blogposted.total_pagecnt
                  : search.sh_limit * search.page}{' '}
                of {blogposted.total_pagecnt}
              </div>
              <Pagination
                count={Math.ceil(blogposted.total_pagecnt / search.sh_limit)}
                page={search.page}
                onChange={onHandlePage}
              />
            </div>
          </>
        ) : null}
      </div>
    </Layout>
  );
};

const mapStateToProps = (state) => {
  return {
    Language : state.Language.languages
   }
 };

export default connect(mapStateToProps,undefined) (BlogGrid);
