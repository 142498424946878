import {
  RESPONSE_STATUS,
  ADD_USER_CARD,
  PAYMENT_WITH_CARD,
  CLEAR_RESPONSE,
  GET_USER_CARD
} from './payTypes';

export default (state, action) => {
  switch (action.type) {
    case PAYMENT_WITH_CARD:
      return {
        ...state,
        responseStatus: action.payload
      };
    case GET_USER_CARD:
      return {
        ...state,
        usercards: action.payload
      };
    case ADD_USER_CARD:
      return {
        ...state,
        responseStatus: action.payload
      };
    case RESPONSE_STATUS:
      return {
        ...state,
        responseStatus: action.payload
      };
    case CLEAR_RESPONSE:
      return {
        ...state,
        responseStatus: ''
      };
    default:
      return state;
  }
};
