/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useContext, useEffect } from 'react';

import $ from 'jquery';
import PayContext from '../../context/payment/payContext';
import AlertContext from '../../context/alert/alertContext';
import AuthContext from '../../context/auth/authContext';
import LAauctionModal from '../Modal';
import AddCard from './addCard';
import {connect} from 'react-redux';

const MakePayment = ({
  openPayment,
  open,
  shippingCost,
  amount,
  onSuccess,
  payType,
  sendID,
  invoiceID,
  ...props
}) => {
  const authContext = useContext(AuthContext);
  const payContext = useContext(PayContext);
  const alertContext = useContext(AlertContext);
  const { setAlert } = alertContext;
  const { user } = authContext;

  const [openAdd, setOpenAdd] = useState(false);
  const paymentSuccess = () => {
    if (openAdd) {
      openPayment();
      setOpenAdd(!openAdd);
    } else {
      setOpenAdd(!openAdd);
    }
  };
  const {
    usercards,
    responseStatus,
    paymentWithCardAuthorize,
    getAllUserCardsAuthorize,
    clearResponse,
  } = payContext;

  let totalAmount = amount;
  let buyerPremiumAmount = 0;
  let buyerPremiumPercent = 0;
  let taxPercent = 0;
  let taxAmount = 0;
  let shippingAmount = shippingCost ? shippingCost : global.ship_cost;
  if (payType === 'buynow_pay') {
    if (user.country === 'United States' && global.salestax_settings) {
      let salesData = global.salestax_settings.filter((obj) => {
        return obj.state === user.state;
      });
      if (salesData.length) {
        taxPercent = parseFloat(salesData[0].percentage);
      }
    }
    if (user.role !== '3') {
      buyerPremiumPercent = parseInt(global.buyer_premium_basic);
    } else {
      buyerPremiumPercent = parseInt(global.buyer_premium_pro);
    }
    taxAmount = parseFloat(parseFloat(amount) * (taxPercent / 100)).toFixed(2);
    buyerPremiumAmount = parseFloat(
      parseFloat(amount) * (buyerPremiumPercent / 100)
    ).toFixed(2);
    totalAmount = parseFloat(
      parseFloat(amount) +
        parseFloat(taxAmount) +
        parseFloat(buyerPremiumAmount) +
        parseFloat(shippingAmount)
    ).toFixed(2);
  }

  const [payment] = useState({
    invoiceno: invoiceID ? invoiceID : '',
    payAmt: totalAmount,
    proId: '',
    payId: '',
  });

  useEffect(() => {
    if (user.card_profileid) {
      getAllUserCardsAuthorize();
    }
  }, [user]);

  useEffect(() => {
    if (responseStatus && responseStatus !== '') {
      if (responseStatus === 'cardadded') {
        getAllUserCardsAuthorize();
        openPayment();
      } else if (responseStatus === 'paymentsuccessful') {
        $('#formPaymentButton').attr('disabled', false);
        setAlert(props.Language.pay_s ? props.Language.pay_s : 'Payment Successful', 'success');
        let paymentdata = {
          payamt: totalAmount,
          totalAmount: totalAmount,
          shippingAmount: shippingAmount,
          taxPercent: taxPercent,
          taxAmount: taxAmount,
          buyerPremiumAmount: buyerPremiumAmount,
          buyerPremiumPercent: buyerPremiumPercent,
        };
        onSuccess(paymentdata);
        openPayment();
      } else {
        setAlert(responseStatus, 'error');
        clearResponse();
        $('#formPaymentButton').attr('disabled', false);
      }
    } else {
      clearResponse();
    }
  }, [responseStatus]);

  const onSubmit = (e) => {
    e.preventDefault();
    $('#formPaymentButton').attr('disabled', true);
    let selectedId = $('#selected_id option:selected').val();
    var selectedCard = usercards.filter((obj) => {
      if (parseInt(obj.customerPaymentProfileId) === parseInt(selectedId)) {
        return true;
      } else {
        return false;
      }
    });

    payment.invoiceno = sendID ? sendID : 'USER_' + user.id;
    payment.payAmt = totalAmount;
    payment.pay_type = payType ? payType : '';
    payment.proId = parseInt(selectedCard[0].custproi);
    payment.payId = parseInt(selectedCard[0].customerPaymentProfileId);
    paymentWithCardAuthorize(payment);
  };
  // onChangeFunction(e, card) ?  : null;

  return (
    <div className="card-form-wrapper">
      <LAauctionModal
        handleClose={openPayment}
        modaltitle={props.Language.upip?props.Language.upip:"MAKE PAYMENT"}
        open={open}
      >
        {usercards.length ? (
          <>
            <form id="CardForm" data-parsley-validate onSubmit={onSubmit}>
              {payType === 'buynow_pay' ? (
                <>
                  <div className="row">
                    <div className="col-12 form-group">
                      <label className="sf-label">{props.Language.amtttAmount ? props.Language.amtttAmount : 'Amount'} : ${amount}</label>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 form-group">
                      <label className="sf-label">
                      {props.Language.premium ? props.Language.premium : 'Premium'} : ${buyerPremiumAmount} ({buyerPremiumPercent}{' '}
                        %)
                      </label>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 form-group">
                      <label className="sf-label">
                       {props.Language.sales_taxx ? props.Language.sales_taxx : 'Sales Tax'}  : ${taxAmount} ({taxPercent} %)
                      </label>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 form-group">
                      <label className="sf-label">
                      {props.Language.shipping_sm ? props.Language.shipping_sm : 'Shipping'}   : ${shippingAmount}
                      </label>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 form-group">
                      <label className="sf-label">
                      {props.Language.totalAmount ? props.Language.totalAmount : 'Total Amount'} : ${totalAmount}
                      </label>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className="row">
                    <div className="col-12 form-group">
                      <label className="sf-label">
                      {props.Language.totalAmount ? props.Language.totalAmount : 'Total Amount'} : ${totalAmount}
                      </label>
                    </div>
                  </div>{' '}
                </>
              )}
              <br />
              <select id="selected_id" className="custom-select">
                {usercards.map((item, index) => {
                  return (
                    <>
                      <option value={item.customerPaymentProfileId}>
                        {item.cardNumber}
                      </option>
                    </>
                  );
                })}
              </select>
              <div className="pt-3 btns-wrap">
                <button
                  className="btn btn-green"
                  id="formPaymentButton"
                  type="submit"
                >
                  {props.Language.upip ? props.Language.upip : 'MAKE PAYMENT'}
                </button>
              </div>
            </form>
          </>
        ) : (
          <>
            {/* <NavLink to="/payments">ADD CARD</NavLink> */}
            <span>
              {props.Language.yanckactcp ? props.Language.yanckactcp : "You have no card added. Kindly add card to continue payment."}
            </span>
            <br />
            <br />
            <button className="btn btn-green" onClick={paymentSuccess}>
              {props.Language.add_crd ? props.Language.add_crd : "ADD CARD"}
            </button>
          </>
        )}
      </LAauctionModal>

      <AddCard
        open={openAdd}
        card_profileid=""
        paymentSuccess={paymentSuccess}
      />
    </div>
  );
};

const mapStateToProps = state => {
  return {
    Language : state.Language.languages
  }
}

export default connect(mapStateToProps)(MakePayment);
