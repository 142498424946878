import React from 'react';
import Box from '@material-ui/core/Box';
import { NavLink } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import {connect} from 'react-redux';

const ProfileLinks = ({ profilelink, preflink, cpwdlink, sslink, ...props }) => {
  return (
    <Box className="db-menu-links">
      <NavLink to={profilelink} activeClassName="active">
       {props.Language.gen_inf ? props.Language.gen_inf : "General Info"} 
      </NavLink>
      <NavLink to={cpwdlink} activeClassName="active">
      {props.Language.chg_pass ? props.Language.chg_pass : "Change password"}
      </NavLink>
      <NavLink to={sslink} activeClassName="active">
      {props.Language.svdsrch ? props.Language.svdsrch : "Saved Search"}
      </NavLink> 
      <NavLink to={preflink} activeClassName="active">
      {props.Language.pref ? props.Language.pref : "Preference"}
      </NavLink>
    </Box>
  );
};

const mapStateToProps = state => {
  return {
    Language : state.Language.languages
  }
}

export default withRouter(connect(mapStateToProps)(ProfileLinks));
