import React from 'react';
import Container from '@material-ui/core/Container';
import Layout from '../../Components/Layout';

import DashboardHeader from '../../Components/DashboardHeader';
import CardForm from '../../Components/Payment/CardForm';
import {connect} from 'react-redux';

const Payment = (props) => {
  return (
    <Layout props={props}>
      <DashboardHeader title={props.Language.paymentcaps} subtitle={props.Language.carddetails_caps} />
      <Container className="dashboard-wrapper">
        <CardForm />
      </Container>
    </Layout>
  );
};

const mapStateToProps = (state) => {
  return {
    Language : state.Language.languages
   }
 };

export default connect(mapStateToProps,undefined) (Payment);
