/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from 'react';
import moment from 'moment';
import Layout from '../../Components/Layout';
import { Redirect } from 'react-router-dom';
import DashboardHeader from '../../Components/DashboardHeader';
import { GetFileFromBase64 } from '../../Common/ImageHandler';
import $ from 'jquery';
import ProductContext from '../../context/product/productContext';
import AlertContext from '../../context/alert/alertContext';
import AuthContext from '../../context/auth/authContext';
import MomentUtils from '@date-io/moment';
import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Checkbox from '@material-ui/core/Checkbox';
import CheckboxCustom from '../../Components/Checkbox/Checkbox';
import ListItemText from '@material-ui/core/ListItemText';
import Input from '@material-ui/core/Input';
import { onChangeFunction } from '../../Common/Components';
import Loading from '../../Components/Loading';
import {connect} from 'react-redux';

function toReRenderImages(auction, setAuction) {
  return new Promise(async (resolve, reject) => {
    let imageSelected = [];
    let arraytoPush = [];
    imageSelected = auction.product_imagefile;
    if (imageSelected.length) {
      var reader = new FileReader();
      reader.readAsDataURL(imageSelected[0]);
      reader.onloadend = function (e) {
        arraytoPush.push(reader.result);
        setAuction({
          ...auction,
          product_image: arraytoPush,
          product_existingimage: [],
          product_imagefile: [],
        });
        document.getElementById('product_imagefile').value = '';
        $('#image_err').html('');
        resolve();
      };
    } else {
      document.getElementById('product_imagefile').value = '';
      $('#image_err').html('');
      resolve();
    }
  });
}

const errorTextMsg = (text) => {
  return `<ul class="parsley-errors-list filled"><li class="parsley-required">${text}</li></ul>`;
};

const scrollFunction = (id) => {
  $('html, body').animate(
    {
      scrollTop: $(`#${id}`).offset().top,
    },
    2000
  );
};

const customValidation = (product,props) => {
  return new Promise(async (resolve, reject) => {
    let error = 0;
    if (
      !product.product_existingimage.length &&
      !product.product_image.length
    ) {
      $('#image_err').html(errorTextMsg(`${props.Language.thisir}.`));
      if (error === 0) {
        scrollFunction('image_top');
      }
      error = 1;
    }
    if (product.lot_id.length <= 0) {
      $('#lotid_err').html(errorTextMsg(`${props.Language.thisir}.`));
      if (error === 0) {
        scrollFunction('lot_top');
      }
      error = 1;
    }
    if (product.la_seller_id === '') {
      $('#lasell_id_err').html(errorTextMsg(`${props.Language.thisir}.`));
      if (error === 0) {
        scrollFunction('lot_top');
      }
      error = 1;
    }
    if (product.start_date >= product.end_date) {
      $('#startdate_err').html(
        errorTextMsg(`${props.Language.sdslted}.`)
      );
      if (error === 0) {
        scrollFunction('date_top');
      }
      error = 1;
    }
    if (error === 1) {
      resolve(false);
    } else {
      resolve(true);
    }
  });
};

const PostAuction = (props) => {
  const productContext = useContext(ProductContext);
  const alertContext = useContext(AlertContext);
  const { setAlert } = alertContext;
  const {
    postAuction,
    edit_auction,
    auction_lots,
    getNewAuction,
    getActiveUsers,
    all_active_users,
    getEditAuction,
    responseStatus,
    clearResponse,
    getla_sellers,
    la_sellers,
  } = productContext;
  const authContext = useContext(AuthContext);
  const { isAuthenticated, isAdmin } = authContext;
  const [toHome, settoHome] = useState(false);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    getla_sellers();
  }, []);
  useEffect(() => {
    if (!isAuthenticated || !isAdmin) {
      settoHome(true);
    }
  }, [isAuthenticated]);
  const [toSellerDashboard, settoSellerDashboard] = useState(false);
  const [auction, setAuction] = useState({
    title: '',
    description: '',
    start_date: new Date(),
    end_date: new Date(),
    lot_id: [],
    status: 'open',
    auctionid: 0,
    private_auction: 0,
    selectedbuyers: [],
    grouping: 1,
    ending_enable: 0,
    ending_items: 0,
    ending_mints: 0,
    la_seller_id: '',
    product_image: [],
    product_existingimage: [],
    product_imagefile: [],
  });

  useEffect(() => {
    if (responseStatus && responseStatus !== '') {
      if (responseStatus === 'successfully_completed') {
        if (props.match.params.id) {
          setAlert(props.Language.auct_u_s ? props.Language.auct_u_s :'Auction updated successfully', 'success');
        } else {
          setAlert(props.Language.auct_p_s ? props.Language.auct_p_s :'Auction posted successfully', 'success');
        }
        clearResponse();
        setLoading(false);
        settoSellerDashboard(true);
      } else {
        setLoading(false);
        setAlert(responseStatus, 'error');
        clearResponse();
      }
    } else {
      clearResponse();
    }
  }, [responseStatus]);

  // const MenuProps = {
  //   PaperProps: {
  //     style: {
  //       width: 250,
  //     },
  //   },
  // };

  useEffect(() => {
    if (props.match.params.id) {
      setLoading(true);
      getActiveUsers({});
      getEditAuction({ auctionid: props.match.params.id });
    } else {
      getNewAuction({});
      getActiveUsers({});
      setLoading(false);
    }
  }, []);

  // for image rendering
  useEffect(() => {
    async function fetchData() {
      if (auction.product_imagefile.length) {
        await Promise.all([toReRenderImages(auction, setAuction)]);
      }
    }
    fetchData();
  }, [auction.product_imagefile]);

  useEffect(() => {
    if (edit_auction && props.match.params.id) {
      setAuction({
        ...auction,
        title: edit_auction.title,
        description: edit_auction.description,
        start_date: edit_auction.date_added
          ? edit_auction.date_added
          : new Date(),
        end_date: edit_auction.date_closed
          ? edit_auction.date_closed
          : new Date(),
        lot_id: auction_lots
          .filter((i) => (i.lotof === props.match.params.id ? true : false))
          .map((dat) => dat.id),
        private_auction:
          edit_auction.private_auction &&
          parseInt(edit_auction.private_auction) > 0
            ? 1
            : 0,
        selectedbuyers: edit_auction.selectedbuyers
          ? edit_auction.selectedbuyers.split(',').map((i) => parseInt(i))
          : [],
        status: 'open',
        ending_enable: edit_auction.ending_enable,
        ending_items: edit_auction.ending_items,
        ending_mints: edit_auction.ending_mints,
        auctionid: props.match.params.id,
        la_seller_id: edit_auction.la_seller_id,
        product_existingimage: [edit_auction.avatar],
        product_imagefile: [],
      });
      setLoading(false);
    }
  }, [edit_auction]);

  const onSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    let button = $('#formSubmitButton');
    button.attr('disabled', true);
    let [customValid] = await Promise.all([customValidation(auction,props)]);
    if ($('#AuctionForm').parsley().validate() && customValid) {
      const formDataSend = new FormData();
      for (var key in auction) {
        if (key === 'product_image') {
          for (var inside in auction[key]) {
            let [FileGenerated] = await Promise.all([
              GetFileFromBase64(auction[key][inside]),
            ]);
            formDataSend.append(key, FileGenerated);
          }
        } else {
          if (typeof auction[key] == 'object') {
            if (key === 'start_date') {
              formDataSend.append(key, auction[key]);
            } else if (key === 'end_date') {
              formDataSend.append(key, auction[key]);
            } else {
              for (let insideout in auction[key]) {
                formDataSend.append(key, auction[key][insideout]);
              }
            }
          } else {
            formDataSend.append(key, auction[key]);
          }
        }
        if (key === 'product_imagefile') {
          // if (props.match.params.id) {
          // } else {
          // }
          postAuction(formDataSend);
          return false;
        }
      }
    } else {
      setLoading(false);
      button.attr('disabled', false);
      return false;
    }
  };

  const onChange = (e) => setAuction(onChangeFunction(e, auction));

  const handleChange = (event) => {
    $('#lotid_err').html('');
    setAuction({ ...auction, lot_id: event.target.value });
  };
  const handleChange_laseller = (event) => {
    $('#lasell_id_err').html('');
    setAuction({ ...auction, la_seller_id: event.target.value });
  };

  const handleChangePrivate = (event) => {
    console.log('event.target.value', event.target.value);
    setAuction({ ...auction, selectedbuyers: event.target.value });
  };

  const onChangeStartDate = (e) => {
    $('#startdate_err').html('');
    setAuction({ ...auction, start_date: moment(e).toDate() });
  };

  const onChangeEndDate = (e) => {
    $('#startdate_err').html('');
    setAuction({ ...auction, end_date: moment(e).toDate() });
  };

  return (
    <Layout props={props}>
      <Loading loading={loading} />
      {toSellerDashboard ? <Redirect to="/sellerdashboard" /> : null}
      {toHome ? <Redirect to="/" /> : null}
      <DashboardHeader title={props.Language.post_cap} subtitle={props.Language.steauctionn} />
      <form
        id="AuctionForm"
        data-parsley-validate
        className="pp-form"
        onSubmit={onSubmit}
      >
        <div>
          <div className="row">
            <div className="col-12 form-group">
  <label className="pp-label">{props.Language.actitile}</label>
              <input
                name="title"
                onChange={onChange}
                value={auction.title}
                className="form-control"
                data-parsley-required="true"
                data-parsley-error-message={props.Language.thisir ? props.Language.thisir : 'This value is required'}
                placeholder={props.Language.actitile}
              />
            </div>
          </div>
          <hr className="pp-divider" />
          <div className="row">
            <div className="col-12 form-group">
  <label className="pp-label">{props.Language.deeees}</label>
              <textarea
                rows="4"
                name="description"
                onChange={onChange}
                value={auction.description}
                className="form-control"
                data-parsley-required="true"
                data-parsley-error-message={props.Language.thisir ? props.Language.thisir : 'This value is required'}
                placeholder={props.Language.entrprasmuch}
              ></textarea>
            </div>
          </div>
          <div className="row">
            <div className="col-12 form-group">
              <span id="lot_top"> </span>
  <label className="pp-label">{props.Language.otts}</label>
              <div className="clearfix cselec-wrap">
                <Select
                  multiple
                  value={auction.lot_id ? auction.lot_id : ''}
                  onChange={handleChange}
                  input={<Input />}
                  renderValue={(selected) =>
                    auction_lots.map((lot) =>
                      auction.lot_id.indexOf(lot.id) > -1
                        ? lot.title + ' , '
                        : null
                    )
                  }
                  MenuProps={{
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'left',
                    },
                    transformOrigin: {
                      vertical: 'top',
                      horizontal: 'left',
                    },
                    getContentAnchorEl: null,
                  }}
                >
                  {auction_lots.map((lot) => (
                    <MenuItem key={lot.id} value={lot.id}>
                      <Checkbox checked={auction.lot_id.indexOf(lot.id) > -1} />
                      <ListItemText primary={lot.title} />
                    </MenuItem>
                  ))}
                </Select>
                <span id="lotid_err"></span>
                {/* <select
                  multiple
                  name="lot_id"
                  onChange={onChange}
                  value={auction.lot_id}
                  className="custom-select"
                  data-parsley-required="true"
                >
                  {auction_lots.map((lot, index) => (
                    <option value={lot.id}>{lot.title}</option>
                  ))}
                </select> */}
              </div>
            </div>
          </div>
          {la_sellers && la_sellers.length ? (
            <div className="row">
              <div className="col-12 form-group">
                <span id="lot_top"> </span>
          <label className="pp-label">{props.Language.sellrs}</label>
                <div className="clearfix cselec-wrap">
                  <Select
                    value={auction.la_seller_id ? auction.la_seller_id : ''}
                    onChange={handleChange_laseller}
                    MenuProps={{
                      anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'left',
                      },
                      transformOrigin: {
                        vertical: 'top',
                        horizontal: 'left',
                      },
                      getContentAnchorEl: null,
                    }}
                  >
                    {la_sellers &&
                      la_sellers.length &&
                      la_sellers.map((seller) => (
                        <MenuItem key={seller.id} value={seller.id}>
                          {seller.seller_name}
                        </MenuItem>
                      ))}
                  </Select>
                  <span id="lasell_id_err"></span>
                </div>
              </div>
            </div>
          ) : null}
          <div className="row">
            <span id="date_top"> </span>
            <div className="col-sm-6 form-group">
                      <label className="pp-label">{props.Language.stdte}</label>
              <div className="cal-input">
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <DateTimePicker
                    variant="inline"
                    name="start_date"
                    disablePast
                    value={auction.start_date}
                    onChange={onChangeStartDate}
                    placeholder={props.Language.stdte}
                  />
                </MuiPickersUtilsProvider>
                <span id="startdate_err"></span>
              </div>
            </div>
            <div className="col-sm-6 form-group">
                      <label className="pp-label">{props.Language.endate}</label>
              <div className="cal-input">
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <DateTimePicker
                    variant="inline"
                    name="end_date"
                    disablePast
                    value={auction.end_date}
                    onChange={onChangeEndDate}
                    placeholder={props.Language.endate}
                  />
                </MuiPickersUtilsProvider>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 form-group">
              <span id="image_top"> </span>
              <label className="pp-label">
                {props.Language.uplauctimg}
              </label>
              <div className="upload-document">
                <input
                  type="file"
                  id="product_imagefile"
                  className="form-control"
                  name="product_imagefile"
                  data-parsley-errors-container="#image_err"
                  onChange={onChange}
                />
                <div>{props.Language.dr_img}</div>
                <button type="button" className="btn btn-primary">
                  {props.Language.uplauctimg}
                </button>
                {/* <div className="btxt">SIZE (500x500)</div> */}
              </div>
            </div>
          </div>
          <span id="image_err"></span>
          {auction.product_existingimage.map((item, index) => {
            return (
              <span key={index}>
                <img
                  className="pro-img"
                  alt="ProdImage"
                  src={`${global.image_url}${item}`}
                />
              </span>
            );
          })}

          {auction.product_image.map((item, index) => {
            return (
              <span key={index}>
                <img className="pro-img" alt="ProdImage" src={item} />
              </span>
            );
          })}
          <div className="clearfix">
            <div className="row">
              <div className="col-sm-6 form-group">
                <div className="form-group check-group">
                  <CheckboxCustom
                    name="private_auction"
                    onChange={(event, value) => {
                      onChange(event);
                    }}
                    value={props.Language.pvt_act}
                    checked={auction.private_auction === 1 ? true : false}
                  />
                </div>
              </div>
              <div className="col-sm-6 form-group">
                {auction.private_auction ? (
                  <Select
                    multiple
                    value={auction.selectedbuyers}
                    onChange={handleChangePrivate}
                    input={<Input />}
                    renderValue={(selected) =>
                      all_active_users.map((lot) =>
                        auction.selectedbuyers.indexOf(parseInt(lot.id)) > -1
                          ? lot.email + ' , '
                          : null
                      )
                    }
                    MenuProps={{
                      anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'left',
                      },
                      transformOrigin: {
                        vertical: 'top',
                        horizontal: 'left',
                      },
                      getContentAnchorEl: null,
                    }}
                  >
                    {all_active_users.map((lot) => (
                      <MenuItem key={lot.id} value={lot.id}>
                        <Checkbox
                          checked={auction.selectedbuyers.indexOf(lot.id) > -1}
                        />
                        <ListItemText primary={lot.email} />
                      </MenuItem>
                    ))}
                  </Select>
                ) : null}
              </div>
            </div>

            <div className="clearfix"></div>

            <div className="form-group check-group">
              <CheckboxCustom
                name="ending_enable"
                onChange={(event, value) => {
                  onChange(event);
                }}
                value={props.Language.enb_stag_bid}
                checked={auction.ending_enable === 1 ? true : false}
              />
            </div>
          </div>
          {auction.ending_enable ? (
            <>
              <div className="row">
                <div className="col-sm-6 form-group">
                  <label className="pp-label">
                    {props.Language.itemss}
                    <span className="info-wrap">
                      <i className="fa fa-info-circle"></i>
                    </span>
                  </label>
                  <input
                    name="ending_items"
                    type="number"
                    min="0"
                    data-parsley-max={auction.lot_id ? auction.lot_id.length : 1000}
                    onChange={onChange}
                    value={auction.ending_items}
                    className="form-control"
                    placeholder="Items"
                    data-parsley-required="true"
                    data-parsley-error-message={props.Language.thisir ? props.Language.thisir : 'This value is required'}
                    data-parsley-group="stepOne"
                    data-parsley-min="1"
                    data-parsley-errors-container="#stagitems_error"
                  />
                  <span id="stagitems_error"></span>
                </div>

                <div className="col-sm-6 form-group">
                  <label className="pp-label">
                    {props.Language.minu}
                    <span className="info-wrap">
                      <i className="fa fa-info-circle"></i>
                    </span>
                  </label>
                  <input
                    name="ending_mints"
                    type="number"
                    min="0"
                    onChange={onChange}
                    value={auction.ending_mints}
                    className="form-control"
                    placeholder={props.Language.minu}
                    data-parsley-required="true"
                    data-parsley-error-message={props.Language.thisir ? props.Language.thisir : 'This value is required'}
                    data-parsley-group="stepOne"
                    data-parsley-min="1"
                    data-parsley-errors-container="#stagmins_error"
                  />
                  <span id="stagmins_error"></span>
                </div>
              </div>
            </>
          ) : null}
          <div className="pp-btns-wrap">
            {/* <button type="button" className="btn btn-primary">
              CANCEL
            </button> */}
            <button
              id="formSubmitButton"
              type="submit"
              className="btn btn-green"
            >
              {props.Language.stim}
            </button>
          </div>
        </div>
      </form>
    </Layout>
  );
};

const mapStateToProps = (state) => {
  return {
    Language : state.Language.languages
   }
 };

export default connect(mapStateToProps,undefined) (PostAuction);
