// for registration
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAIL = 'REGISTER_FAIL';
export const USER_LOADED = 'USER_LOADED';
export const USER_LOADED_INITAL = 'USER_LOADED_INITAL';
export const AUTH_ERROR = 'AUTH_ERROR';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGOUT = 'LOGOUT';
export const CLEAR_ERROR = 'CLEAR_ERROR';
//for alert
export const SET_ALERT = 'SET_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';
export const CLEAR_INITIAL = 'CLEAR_INITIAL';

export const RECENT_SEARCH = 'RECENT_SEARCH';
export const ADD_RECENT_SEARCH = 'ADD_RECENT_SEARCH';

export const RESPONSE_STATUS = 'RESPONSE_STATUS';
export const CLEAR_RESPONSE = 'CLEAR_RESPONSE';
export const ONLY_ONCE = 'ONLY_ONCE';
