/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useContext, useEffect } from 'react';
import $ from 'jquery';
import Layout from '../../Components/Layout';
import { Redirect } from 'react-router-dom';
import AlertContext from '../../context/alert/alertContext';
import UserContext from '../../context/user/userContext';
import { onChangeFunction } from '../../Common/Components';
import {connect} from 'react-redux'

const Career = (props) => {
  const userContext = useContext(UserContext);
  const alertContext = useContext(AlertContext);
  const [toHome, settoHome] = useState(false);

  const { setAlert } = alertContext;
  const { careerForm, responseStatus, clearResponse } = userContext;

  const [career, setCareer] = useState({
    careercv: '',
    toemail: global.adminemail_foremail,
    first_name: '',
    last_name: '',
    email: '',
    phone_number: '',
    position: '',
    cover_letter: '',
    start: '',
  });

  const onChange = (e) => setCareer(onChangeFunction(e, career));

  useEffect(() => {
    if (responseStatus) {
      if (responseStatus === 'Career successful') {
        $('#formContactButton').attr('disabled', false);
        setAlert(props.Language.req_s_s ? props.Language.req_s_s : 'Request sent successfully', 'success');
        clearResponse();
        settoHome(true);
      } else {
        $('#formContactButton').attr('disabled', false);
        setAlert(responseStatus, 'error');
        clearResponse();
      }
    }
  }, [responseStatus]);

  const customValidation = (data) => {
    return new Promise(async (resolve, reject) => {
      let error = 0;
      if (error === 1) {
        resolve(false);
      } else {
        resolve(true);
      }
    });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    $('#formContactButton').attr('disabled', true);
    let [customValid] = await Promise.all([customValidation(career)]);
    if ($('#CareerForm').parsley().validate() && customValid) {
      const formDataSend = new FormData();
      for (var key in career) {
        if (key === 'careercv') {
          for (var inside in career[key]) {
            formDataSend.append(key, career[key][inside]);
          }
        } else {
          formDataSend.append(key, career[key]);
        }
        if (key === 'start') {
          careerForm(formDataSend);
          return false;
        }
      }
    } else {
      $('#formContactButton').attr('disabled', false);
      return false;
    }
  };

  return (
    <Layout>
      {toHome ? <Redirect to="/" /> : null}
      <div className="container mt-5 mb-5 faq-wrapper">
        <h1 className="mb-4 main-head">CAREERS</h1>

        <div className="row">
          <div className="col-md-6">
            <h2 className="mb-3 atHead">Why The LA Auction Depot?</h2>

            <p>
              This could be that moment: that moment you know you found not just
              another job, but just the right job. Join a company that respects
              and understands you. Where you can get the hours you need, the
              training you want, and teammates who have your back.
            </p>

            <ul>
              <li>Flexible scheduling</li>
              <li>Free, in-depth training and learning</li>
              <li>Promotion opportunities and paths to grow</li>
              <li>Medical, dental, and vision</li>
              <li>Paid time off</li>
              <li>Team Party Nights (TPN)</li>
              <li>Unlimited smiles</li>
            </ul>
          </div>
          <div className="col-md-6">
            <img className="w-100" alt="" src="./images/career.png" />
          </div>
        </div>
        <div className="chead-wrap mt-4">
          <h5 className="af-pos">Apply for a Position</h5>
          <p>Please complete the form below to apply for a position with us.</p>
        </div>
        <div className="row">
          <div className="col-sm-6">
            <form id="CareerForm" data-parsley-validate onSubmit={onSubmit}>
              <div className="row">
                <div className="col-sm-6 form-group">
                  <label className="sf-label">First Name</label>

                  <input
                    className="form-control"
                    name="first_name"
                    value={career.first_name}
                    onChange={onChange}
                    data-parsley-required="true"
                    placeholder="Enter First Name"
                  />
                </div>
                <div className="col-sm-6 form-group">
                  <label className="sf-label">Last Name</label>
                  <input
                    className="form-control"
                    name="last_name"
                    value={career.last_name}
                    onChange={onChange}
                    data-parsley-required="true"
                    placeholder="Enter Last Name"
                  />
                </div>
              </div>
              <div className="form-group">
                <label className="sf-label">E-mail</label>
                <input
                  className="form-control"
                  name="email"
                  type="email"
                  value={career.email}
                  onChange={onChange}
                  data-parsley-required="true"
                  placeholder="Enter Email"
                />
              </div>
              <div className="form-group">
                <label className="sf-label">Phone Number</label>
                <input
                  className="form-control numberonly"
                  name="phone_number"
                  type="text"
                  maxLength="15"
                  value={career.phone_number}
                  onChange={onChange}
                  data-parsley-required="true"
                  placeholder="Enter Phone Number"
                />
              </div>
              <div className="form-group">
                <label className="sf-label">Applying for Position</label>
                <input
                  className="form-control"
                  name="position"
                  value={career.position}
                  onChange={onChange}
                  data-parsley-required="true"
                  placeholder="Enter Position"
                />
              </div>
              <div className="form-group">
                <label className="sf-label">When can you start?</label>
                <input
                  className="form-control"
                  name="start"
                  value={career.start}
                  onChange={onChange}
                  data-parsley-required="true"
                  placeholder="Enter Start date"
                />
              </div>
              <div className="form-group">
                <label className="sf-label">Cover Letter</label>
                <textarea
                  className="form-control"
                  name="cover_letter"
                  value={career.cover_letter}
                  onChange={onChange}
                  data-parsley-required="true"
                  rows="4"
                ></textarea>
              </div>
              <div className="form-group">
                <label className="sf-label">Upload Resume</label>
                <input
                  type="file"
                  id="careercv"
                  className="form-control upload-resum"
                  name="careercv"
                  data-parsley-required="true"
                  data-parsley-errors-container="#image_err"
                  onChange={onChange}
                />
              </div>
              <div className="pt-3 mb-4">
                <button
                  id="formCareerButton"
                  type="submit"
                  className="btn btn-green"
                >
                  SUBMIT
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Layout>
  );
};

const mapStateToProps = state => {
  return {
    Language : state.Language.languages
  }
}

export default connect(mapStateToProps)(Career);
