/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { Redirect } from 'react-router-dom';
import Container from '@material-ui/core/Container';
// import Grid from '@material-ui/core/Grid';
import Layout from '../../Components/Layout';

import DashboardHeader from '../../Components/DashboardHeader';
import AllBids from '../../Components/DashboardSeller/AllBids';
import Auctions from '../../Components/DashboardSeller/Auctions';
import BiddersReport from '../../Containers/BiddersReport/BiddersReport'
import AuthContext from '../../context/auth/authContext';
// import ActiveBids from '../../Components/DashboardSeller/ActiveBids';
// import Sold from '../../Components/DashboardSeller/Sold';
// import Draft from '../../Components/DashboardSeller/Draft';
import {connect} from 'react-redux';

const TabPanel = ({ children, value, index, ...other }) => {
  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `buyer-dashboard-${index}`,
    'aria-controls': `buyer-dashboard-tabpanel-${index}`,
  };
}

const SellerDashboard = (props) => {
  const authContext = useContext(AuthContext);
  const { isAuthenticated, isAdmin } = authContext;
  const [toHome, settoHome] = useState(false);
  useEffect(() => {
    if (!isAuthenticated || !isAdmin) {
      settoHome(true);
    }
  }, [isAuthenticated]);

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Layout props={props}>
      {toHome ? <Redirect to="/" /> : null}
      <DashboardHeader title={`${props.Language.auction ? props.Language.auction : "AUCTION"}`} subtitle={`${props.Language.itemss ? props.Language.itemss : "ITEMS"}`} seller />
      <Container className="db-bids-wrapper">
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="buyer-dashboard"
        >
          <Tab label={props.Language.act} {...a11yProps(0)} />
          <Tab label={props.Language.sld_cap} {...a11yProps(1)} />
          <Tab label={props.Language.ntslddd} {...a11yProps(2)} />
          <Tab label={props.Language.steauctionn} {...a11yProps(3)} />
          <Tab label={props.Language.biddre} {...a11yProps(4)} />
          {/* <Tab label="ALL" {...a11yProps(4)} /> */}
          {/* <Tab label="DRAFT" {...a11yProps(4)} /> */}
        </Tabs>
        <TabPanel value={value} index={0}>
          <AllBids page="open" />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <AllBids page="sold" />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <AllBids page="closed" />
        </TabPanel>
        <TabPanel value={value} index={3}>
          <Auctions />
        </TabPanel>
        <TabPanel value={value} index={4}>
          <BiddersReport />
        </TabPanel>
        {/* <TabPanel value={value} index={4}>
          <AllBids page="" />
        </TabPanel> */}
        {/* <TabPanel value={value} index={4}>
          <Draft />
        </TabPanel> */}
      </Container>
    </Layout>
  );
};

const mapStateToProps = (state) => {
  return {
    Language : state.Language.languages
   }
 };


export default connect(mapStateToProps,undefined) (SellerDashboard);
