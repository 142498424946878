/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useContext, useEffect } from 'react';
import $ from 'jquery';
import { Redirect } from 'react-router-dom';
import Layout from '../../Components/Layout';
import AlertContext from '../../context/alert/alertContext';
import UserContext from '../../context/user/userContext';
import { onChangeFunction } from '../../Common/Components';
import {connect} from 'react-redux';

const ContactUs = (props) => {
  const userContext = useContext(UserContext);
  const alertContext = useContext(AlertContext);
  const [toHome, settoHome] = useState(false);

  const { setAlert } = alertContext;
  const {
    contactUs,
    getStaticPage,
    static_page,
    responseStatus,
    clearResponse,
  } = userContext;

  useEffect(() => {
    getStaticPage({ page_id: 'contactus' });
  }, []);

  const [contact, setContact] = useState({
    name: '',
    from_email: '',
    phone_number: '',
    subject: '',
    messages: '',
    to_email: '',
    from_id: 0,
    to_id: 0,
  });

  const onChange = (e) => setContact(onChangeFunction(e, contact));
  useEffect(() => {
    if (responseStatus) {
      if (responseStatus === 'Contact successful') {
        $('#formContactButton').attr('disabled', false);
        setAlert(props.Language.conta_msg_s_s ? props.Language.conta_msg_s_s : 'Contact message sent successfully', 'success');
        clearResponse();
        settoHome(true);
      } else {
        $('#formContactButton').attr('disabled', false);
        setAlert(responseStatus, 'error');
        clearResponse();
      }
    }
  }, [responseStatus]);

  const customValidation = (data) => {
    return new Promise(async (resolve, reject) => {
      let error = 0;
      if (error === 1) {
        resolve(false);
      } else {
        resolve(true);
      }
    });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    $('#formContactButton').attr('disabled', true);
    let [customValid] = await Promise.all([customValidation(contact)]);
    if ($('#ContactForm').parsley().validate() && customValid) {
      contactUs(contact);
    } else {
      $('#formContactButton').attr('disabled', false);
      return false;
    }
  };

  return (
    <Layout>
      {toHome ? <Redirect to="/" /> : null}
      <section className="mt-5 mb-5 contact-us-wrapper">
        <div className="container">
          <h2 className="clearfix main-head mb-3">
  <span className="hbox">{props.Language.con}</span>
  <span>{props.Language.uss}</span>
          </h2>
          <div
            dangerouslySetInnerHTML={{
              __html: static_page.content,
            }}
          ></div>
          <div className="chead-wrap">
          <h5>{props.Language.ests}</h5>
            <p>{props.Language.gtch}.</p>
          </div>
          <div className="row">
            <div className="col-sm-8">
              <form id="ContactForm" data-parsley-validate onSubmit={onSubmit}>
                <div className="row">
                  <div className="col-sm-6 form-group">
          <label className="sf-label">{props.Language.meme}</label>
                    <input
                      className="form-control"
                      name="name"
                      value={contact.name}
                      onChange={onChange}
                      data-parsley-required="true"
                      placeholder={props.Language.meme}
                    />
                  </div>
                  <div className="col-sm-6 form-group">
                    <label className="sf-label">{props.Language.em_l} Id</label>
                    <input
                      className="form-control"
                      name="from_email"
                      type="email"
                      value={contact.from_email}
                      onChange={onChange}
                      data-parsley-required="true"
                      placeholder={props.Language.em_l} 
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-6 form-group">
          <label className="sf-label">{props.Language.ph_no}</label>
                    <input
                      className="form-control numberonly"
                      name="phone_number"
                      type="text"
                      maxLength="15"
                      value={contact.phone_number}
                      onChange={onChange}
                      data-parsley-required="true"
                      placeholder={props.Language.ph_no}
                    />
                  </div>

                  <div className="col-sm-6 form-group">
          <label className="sf-label">{props.Language.sject}</label>
                    <input
                      className="form-control"
                      name="subject"
                      value={contact.subject}
                      onChange={onChange}
                      data-parsley-required="true"
                      placeholder={props.Language.sject}
                    />
                  </div>
                </div>
                <div className="form-group">
          <label className="sf-label">{props.Language.meggs}</label>
                  <textarea
                    className="form-control"
                    name="messages"
                    value={contact.messages}
                    onChange={onChange}
                    data-parsley-required="true"
                    rows="4"
                  ></textarea>
                </div>
                {/* 
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={subscribe}
                        value="subscribe"
                        color="default"
                      />
                    }
                    label="I would like to receive email communications"
                  />
                </FormGroup> */}
                <div className="pt-3 mb-4">
                  <button
                    id="formContactButton"
                    type="submit"
                    className="btn btn-green"
                  >
                    {props.Language.stim}
                  </button>
                </div>
              </form>
            </div>
            <div className="col-sm-4">
              <div className="chead-wrap">
              <h5>{props.Language.taess}</h5>
              </div>
              <address>
                {props.Language.laad} <br />
                10940 {props.Language.brblvd} <br />
                {props.Language.nhca} 91601 <br />
                818-665-3863
              </address>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

const mapStateToProps = (state) => {
  return {
    Language : state.Language.languages
   }
 };

export default connect(mapStateToProps,undefined) (ContactUs);
