import React from 'react';
import { fade, makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import MenuItem from '@material-ui/core/MenuItem';
import { NavLink, withRouter } from 'react-router-dom';
import '../DashboardHeader/dashboard.css';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import $ from 'jquery';
import {connect} from 'react-redux';
import {compose} from 'redux';


const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  dashboardheader: {
    background: '#f5f5f5',
    boxShadow: 'none',
  },
  toolbar: {
    background: '#f5f5f5',
    maxWidth: '1280px',
    width: '100%',
    margin: '0 auto',
  },
  title: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 7),
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: 200,
    },
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
}));
function opencal(){
  $('.MuiPopover-root').show();
}
const SearchHeader = ({
  title,
  subtitle,
  listLink,
  gridLink,
  listview,
  fromAuction,
  handleDateChange,
  search,
  onChange,
  search_allproducts,
  Language
}) => {
  const classes = useStyles();
  // const [anchorEl, setAnchorEl] = React.useState(null);

  return (
    <div className={classes.grow}>
      <AppBar position="static" className={classes.dashboardheader}>
        <Toolbar className={classes.toolbar}>
          <div className="dbmhead db-thead-xs db-head-xs" noWrap>
            <span className="hbox">{title}</span>
            <span>{subtitle}</span>
          </div>
          <div className={classes.grow} />
          {fromAuction === 0 ? (
            <div className="cb-date">
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <KeyboardDatePicker
                  onOpen = {opencal}
                  disableToolbar
                  disablePast
                  variant="inline"
                  format="MM/DD/YYYY"
                  margin="normal"
                  value={search.enddate}
                  id="date-picker-inline"
                  label={Language.cl_bf_date ? Language.cl_bf_date : "Close Before Date"}
                  onChange={handleDateChange}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                />
              </MuiPickersUtilsProvider>
            </div>
          ) : null}
          <div className="ml-auto show-txt">
            {' '}
            {search_allproducts.results.length ? (
              <>
                {Language.owningsh} {search_allproducts.set_disp} of{' '}
                {search_allproducts.total_pagecnt}{' '}
              </>
            ) : null}
          </div>
          <div className={classes.scards}>
            <select
              name="orderby"
              value={search ? (search.orderby ? search.orderby : '') : ''}
              onChange={onChange}
              className="custom-select"
            >
              <option value="">{Language.sortbyy}</option>
            <option value="3">{Language.owghh}</option>
            <option value="4">{Language.ritypo}</option>
            <option value="2">{Language.estfir}</option>
            </select>
          </div>
          <div className="sdb-wrap">
            <MenuItem className="dbmlinks">
              <NavLink
                exact
                to={gridLink}
                className="searchlinks"
                activeClassName="active"
              >
                <img src="/images/list.png" alt="" />
              </NavLink>
            </MenuItem>
            <MenuItem className="dbmlinks">
              <NavLink
                exact
                to={listLink}
                className="searchlinks"
                activeClassName="active"
              >
                <img src="/images/grid.png" alt="" />
              </NavLink>
            </MenuItem>
          </div>
        </Toolbar>
      </AppBar>
    </div>
  );
};


const mapStateToProps = (state) => {
  return {
    Language : state.Language.languages
   }
 };

export default compose(
  withRouter,
  connect(mapStateToProps, undefined)
)(SearchHeader);