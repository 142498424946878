import React, { useEffect, useContext, useState } from 'react';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import ProductContext from '../../context/product/productContext';
import { Pagination } from '@material-ui/lab';
import {connect} from 'react-redux';
const BiddersReport = (props) => {
    const productContext = useContext(ProductContext);
    let {allProductsViewed,getProduct_viewed_all} = productContext;
    const [search, setSearch] = useState({
        limit: 10,
        page: 1,
    });
    const onHandlePage = (event, value) => {
        setSearch({ ...search, page: value });
    };
    useEffect(() => {
        getProduct_viewed_all(search);
    }, [search]);
    return (
        <>
            <Container>
                    <div className="row" style={{marginLeft:"220px"}}>
                                    <div className="col-sm-4" style={{paddingLeft:"38px"}}>
                                        ID
                                    </div>
                                    <div className="col-sm-4" style={{paddingLeft:"33px"}}>
        `                               {props.Language.itleeee ? props.Language.itleeee : 'Title'}
                                    </div>
                                    <div className="col-sm-4">
                                    {props.Language.itleeee ? props.Language.vrs_cnt : 'Viewers Count'}
                                    </div>
                    </div>
                    {allProductsViewed && allProductsViewed.data && allProductsViewed.data.length ? allProductsViewed.data.map(ele=>{
                        return (<>
                        <Box className="db-pro-box">
                        <div className="row" style={{marginLeft:"220px"}}>
                            <div className="col-sm-4">
                                {ele.id}
                            </div>
                            <div className="col-sm-4">
                                {ele.title}
                            </div>
                            <div className="col-sm-4" style={{paddingLeft:"66px"}}>
                                {ele.c}
                            </div>
                        </div>
                        </Box>
                        </>)
                    }) : <>
                    <Box className="db-pro-box">
                      <div className="row">
                <div className="col-sm-12 text-center"> {props.Language.brd_rep_nt_av ? props.Language.brd_rep_nt_av : "Bidders report not available"} </div>
                      </div>
                    </Box>
                  </>}
                    {allProductsViewed && allProductsViewed.length ?<div className="d-flex mt-3">
                        <div className="ml-auto mt-2">
                        Showing {search.limit * (search.page - 1) + 1} -{' '}
                        {search.limit * search.page > allProductsViewed.length
                            ? allProductsViewed.length
                            : search.limit * search.page}{' '}
                        of {allProductsViewed.length}
                        </div>
                        <Pagination
                        count={Math.ceil(allProductsViewed.length / search.limit)}
                        page={search.page}
                        onChange={onHandlePage}
                        /> 
                    </div> : null}
            </Container>
        </>
        )
}

const mapStateToProps = state =>{
    return {
        Language : state.Language.languages
    }
}

export default connect(mapStateToProps)(BiddersReport);