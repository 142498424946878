import React, { useState, useContext, useEffect } from 'react';
import moment from 'moment';
import $ from 'jquery';
import { Page, Document, Image, StyleSheet, View, Text, PDFDownloadLink } from '@react-pdf/renderer';
import {connect} from "react-redux";
const styles = StyleSheet.create({
    page: {
        fontFamily: 'Helvetica',
        fontSize: 11,
        paddingTop: 30,
        paddingLeft:10,
        paddingRight:10,
        lineHeight: 1.5,
        flexDirection: 'column',
    }, 
    logo: {
        width: 74,
        height: 66,
        marginTop:-20,
        // marginLeft: 'auto',
        // marginRight: 'auto'
    },
    titleContainer:{
        flexDirection: 'row',
        marginTop: 24,
    },
    reportTitle:{
        // color: '#61dafb',
        marginLeft : -150,
        letterSpacing: 4,
        // fontSize: 25,
        textAlign: 'center',
        textTransform: 'uppercase',
        fontSize: 32,
        color: "#df621e"
    },
    invoiceNoContainer: {
        flexDirection: 'row',
        marginTop: 0,
        justifyContent: 'flex-end'
    },
    invoiceNoContainer1: {
        flexDirection: 'row',
        justifyContent: 'flex-end'
    },
    invoiceDateContainer: {
        flexDirection: 'row',
        justifyContent: 'flex-end'
    },
    invoiceDate: {
            fontSize: 12,
            fontStyle: 'bold',
    },
    label: {
        width: 60
    },
    headerContainer: {
        marginTop: 0
    },
    billTo: {
        marginTop: 0,
        paddingBottom: 3,
        fontFamily: 'Helvetica-Oblique'
    },
    tableContainer: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginTop: 24,
        borderWidth: 0,
        borderColor: '#df621e'
    },
    container: {
        flexDirection: 'row',
        borderBottomColor: '#df621e',
        backgroundColor: '#df621e',
        borderBottomWidth: 1,
        alignItems: 'center',
        height: 50,
        textAlign: 'center',
        fontStyle: 'bold',
        flexGrow: 1,
    },
    

   id: {
        width: '5%',
    },
    date: {
        width: '10%'
    },
    title: {
        width: '20%'
    },
    location: {
        width: '10%'
    },
    amount2: {
          width: '10%'
      },
    retop: {
        width: '20%',
    },
    buyerprem: {
        width: '5%'
    },
    salestax: {
        width: '5%',
      paddingLeft : 10
    },
    total: {
        width: '10%',
      paddingLeft : 10
    },
  id1: {
        width: '5%',
        textAlign: 'center'
    },
    date1: {
        width: '10%',
        textAlign: 'center',
      	paddingLeft:10
    },
    title1: {
        width: '20%',
        textAlign: 'center'
    },
    location1: {
        width: '10%',
        textAlign: 'center'
    },
    amount1: {
        width: '10%',
      	textAlign: 'center'
      },
    retop1: {
        width: '20%',
        textAlign: 'center'
    },
    buyerprem1: {
        width: '5%',
        textAlign: 'center'
    },
    salestax1: {
        width: '5%',
        textAlign: 'center',
      paddingLeft : 10
    },
    total1: {
        width: '10%',
        textAlign: 'center',
      paddingLeft : 15
    },
    row: {
        flexDirection: 'row',
        borderBottomColor: '#df621e',
        borderBottomWidth: 1,
        alignItems: 'center',
        height: 55,
        textAlign: 'center',
        fontStyle: 'bold',
        flexGrow: 1,
    }
  });
  const sringsplit = (value, index) => value.substring(0, index) + "\n" + value.substring(index);
  const reccstrsplit = (str)=>{
      return str.match(/.{1,10}/g).join('\n');
  }
  
  
const InvoiceDoc = (props) => {
    let chunkedArr = [];
    let chunk = [];  
    let orgArray = props.data.SlibuyPayments;
    orgArray.map((ele,index)=>{
        if((chunk.length-1) === 8 ){
            chunkedArr.push(chunk)
            chunk = [];
            chunk.push(ele)
        }else if((orgArray.length -1) === index ){
            chunk.push(ele);
            chunkedArr.push(chunk);
            chunk = []
        }else{
            chunk.push(ele)
        }
    })
    return (
        <Document>
            {chunkedArr.map((ele,index) => {
               return <Page size="A4" style={styles.page}>
                {index == 0 ? <>
                  <View style={styles.titleContainer}>
                        <Image
                        style={styles.logo}
                        src="/images/new-logo.png"
                        />
                      <Text style={styles.reportTitle}>Invoice</Text>
                  </View>
                  <View style={styles.invoiceNoContainer}>
                          <Text style={styles.label}>Invoice No:</Text>
                          <Text style={styles.invoiceDate}>{props.data.in_number}</Text>
                      </View >
                      <View style={styles.invoiceDateContainer}>
                          <Text style={styles.label}>Status: </Text>
                          <Text >{props.data.paid_status ||
                                props.data.newpaid_status
                                  ? 'Paid'
                                  : 'Unpaid'}
                                ({props.data.biditem_cnt} items)</Text>
                      </View ></>:null}
                      {index === 0 ? <View style={styles.headerContainer}>
                          <Text style={styles.billTo}>Ship To:</Text>
                          <Text>{props.data.userShippdetail.first_name}{' '}
                                  {props.data.userShippdetail.last_name}</Text>
                          <Text>{props.data.userShippdetail.address}</Text>
                          <Text>{props.data.userShippdetail.address1 ? 
                                props.data.userShippdetail.address1
                               : null},</Text>
                          <Text>{props.data.userShippdetail.city},{' '}
                                {props.data.userShippdetail.state}</Text>
                          <Text>{props.data.userShippdetail.country},{' '}
                                {props.data.userShippdetail.zipcode}</Text>
                          <Text>{props.data.userShippdetail.phone}</Text>
                      </View> : null}
                      <View style={styles.tableContainer}>
                        <View style={styles.container}>
                            <Text style={styles.id}>ID</Text>
                            <Text style={styles.date}>Date</Text>
                            <Text style={styles.title}>Title</Text>
                            <Text style={styles.location}>Location</Text>
                            <Text style={styles.amount2}>Amount</Text>
                            <Text style={styles.retop}>{"Retrieval\nOptions"}</Text>
                            <Text style={styles.buyerprem}>{"Buyer\nPremium"}</Text>
                            <Text style={styles.salestax}>{"Sales\nTax"}</Text>
                            <Text style={styles.total}>Total</Text>
                        </View>
                        {ele.map((ele1,ind1)=>{  
                            return (
                                    <>
                                        <View style={styles.row} >
                                            <Text style={styles.id1}>{ele1.id}</Text>
                                            <Text style={styles.date1}>{moment(new Date(ele1.date_added)).format('MM/DD/YYYY hh:mmA').toString().split(' ').join('\n')}</Text>
                                            <Text style={styles.title1}>{reccstrsplit(ele1.title)}</Text>
                                            <Text style={styles.location1}>{reccstrsplit(ele1.state)}</Text>
                                            <Text style={styles.amount1}>{`$${ele1.amount}`}</Text>
                                            <Text style={styles.retop1}>{$(`#changebidshp${ele1.filb_ide_name}`).text()}</Text>
                                            <Text style={styles.buyerprem1}>{`$${ele1.buypremium_amt}`}</Text>
                                            <Text style={styles.salestax1}>{`$${ele1.sales_tax}`}</Text>
                                            <Text style={styles.total1}>{`$${ele1.total_amt_rw}`}</Text>
                                        </View>
                                    </>
                                )
                        })}
                          {/* <InvoiceTableBlankSpace rowsCount={ tableRowsCount - invoice.items.length} />
                          <InvoiceTableFooter items={invoice.items} /> */}
                      </View>
                      {chunkedArr.length-1 === index ? 
                            <>
                                <View style={styles.invoiceNoContainer}>
                                    <Text >Bid Amount : </Text>
                                    <Text style={styles.invoiceDate}>${props.data.bidamount_total}</Text>
                                </View >
                                {parseInt(props.data.shipcostrow_total) > 0 ?
                                <View style={styles.invoiceNoContainer1}>
                                    <Text >Total Shipping : </Text>
                                    <Text >${props.data.shipcostrow_total}</Text>
                                </View > : null}
                                <View style={styles.invoiceNoContainer1}>
                                    <Text >Buyer Premium : </Text>
                                    <Text >${props.data.buypremium_total_lowbox}</Text>
                                </View >
                                <View style={styles.invoiceNoContainer1}>
                                    <Text >Sales Tax : </Text>
                                    <Text >${props.data.salestax_total}</Text>
                                </View >
                                <View style={styles.invoiceNoContainer1}>
                                    <Text >Total :</Text>
                                    <Text >${props.data.nwrw_total}</Text>
                                </View >
                            </>
                        :
                            null
                    }
                </Page>
            })}
        
      </Document>
  )}

const pdfexport = function(props){
    return (
        <>
            <PDFDownloadLink document={<InvoiceDoc data={props.data} />} fileName={`invoice-${props.data.in_number}.pdf`}>
            {({ blob, url, loading, error }) => (loading ? 
                    <button
                      className="btn btn-primary ipdf-wrap"
                    >
                      {props.Language.loaddoc ?  props.Language.loaddoc : "Loading document"}...
                    </button> : 
                    <button
                    className="btn btn-primary ipdf-wrap"
                  >
                   {props.Language.genpdf ?  props.Language.genpdf :  "Generate PDF"}
                  </button>)}
            </PDFDownloadLink>
        </>
    )
}
const mapStateToProps = state => {
  return {
    Language : state.Language.languages
  }
}
export default connect(mapStateToProps)(pdfexport);
