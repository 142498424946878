import React from 'react';
import AuthLeft from '../../Components/Auth/AuthLeft';
import LoginForm from '../../Components/Auth/LoginForm';
import {connect} from 'react-redux';
import { withRouter } from 'react-router-dom';
import {compose} from 'redux';


import Grid from '@material-ui/core/Grid';

const LoginPage = (props) => {
  return (
    <Grid container className="auth-wrapper">
      <Grid item sm={6} className="auth-left-wrapper auth-xs-left">
        <AuthLeft
          pageTitle={props.Language.rtionrr}
          subTitle={props.Language.we_hp_ur}
          primaryButton={props.Language.regn_ow}
          secondayTitle={props.Language.onhree}
          secondarySubTitle={props.Language.lob_pr}
          threeSubTitle={props.Language.pm_hotline}
          fourSubTitle={props.Language.sn_pick}
          fiveSubTitle={props.Language.vip_htline}
          secondaryButton={props.Language.pbrhe}
          primaryHref="/register"
          secondaryHref="/proregister"
        />
      </Grid>
      <Grid item sm={6} className="Login-form">
        <LoginForm withoutHeader={1} />
      </Grid>
    </Grid>
  );
};


const mapStateToProps = (state) => {
  return {
    Language : state.Language.languages
   }
 };


export default compose(
  withRouter,
  connect(mapStateToProps, undefined)
)(LoginPage);