import React, { useContext } from 'react'; 
import ListCard from './ListCard';
import GridCard from './GridCard'; 
import AuthContext from '../../context/auth/authContext';
import {connect} from 'react-redux';

const SearchMarketList = ({Language, imgLoad, ...props }) => {
  const authContext = useContext(AuthContext);
  const { isAuthenticated } = authContext;
  return (
    <div className="search-right-wrapper">
      {props.listview ? (
        <div className="table-responsive">
          <table className="table table-stripped list-vew">
            <thead>
              <tr>
      <th>{Language.imgeee}</th>
      <th>{Language.nme}</th>
      <th>{Language.pr_e}</th>
      <th>{Language.merer}</th>
      <th width="150">{Language.ctionn}</th>
              </tr>
            </thead>
            {props.allsearchitems.map((item, index) => {
              return (
                <ListCard
                  item={item}
                  index={index}
                  isAuthenticated={isAuthenticated}
                  imgLoaded={imgLoad}
                />
              );
            })}
          </table>
        </div>
      ) : (
        <div className="row">
          {props.allsearchitems.map((item, index) => {
            return (
              <GridCard
                item={item}
                index={index}
                isAuthenticated={isAuthenticated}
                imgLoaded={imgLoad}
              />
            );
          })}
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    Language : state.Language.languages
   }
 };

export default connect(mapStateToProps,undefined)(SearchMarketList) ;
