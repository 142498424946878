/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from 'react';
import { fade, makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
// import Menu from '@material-ui/core/Menu';
import AuthContext from '../../context/auth/authContext';
import { Link, NavLink, useHistory, withRouter } from 'react-router-dom';
import './dashboard.css';
import {connect} from 'react-redux';

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  dashboardheader: {
    background: '#f5f5f5',
    boxShadow: 'none',
  },
  toolbar: {
    background: '#f5f5f5',
    maxWidth: '1280px',
    width: '100%',
    margin: '0 auto',
  },
  title: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 7),
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: 200,
    },
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
}));

const DashboardHeader = ({ title, subtitle, seller,...props }) => {
  const history = useHistory();
  const classes = useStyles();
  const [dbmenu, setDbmenu] = useState(false);
  const authContext = useContext(AuthContext);
  const { isAuthenticated, isAdmin } = authContext;
  const ShowMenu = () => {
    setDbmenu(!dbmenu);
  };

  useEffect(() => {
    return history.listen(() => setDbmenu(false));
  }, []);

  return (
    <div className={classes.grow}>
      {isAuthenticated ? (
        <>
          <AppBar position="static" className={classes.dashboardheader}>
            <Toolbar className={classes.toolbar}>
              <div className="dbmhead db-thead-xs db-head-xs" noWrap>
                <span className="hbox">{title}</span>
                <span>{subtitle}</span>
              </div>
              <div
                className={`ml-auto db-sh-menu ${
                  dbmenu ? 'db-show-menu' : 'db-hide-menu'
                }`}
              >
                <>
                  {seller && (
                    <>
                      {' '}
                      <Link
                        to="/post-project"
                        className="btn btn-green pp-btn mr-3"
                      >
                        {`${props.Language.post_cap} ${props.Language.mrkt_place} ${props.Language.auc}`} 
                      </Link>{' '}
                      <Link to="/post-auction" className="btn btn-green pp-btn">
                      {`${props.Language.post_cap} ${props.Language.ste} ${props.Language.auc}`}  
                      </Link>{' '}
                    </>
                  )}
                </>
                <div className={classes.grow} />
                <ul className="profileNav">
                  {seller && (
                    <MenuItem className="dbmlinks">
                      <NavLink to="/sellerdashboard" activeClassName="active">
                      {props.Language.Products ? props.Language.Products : "PRODUCTS"} 
                      </NavLink>
                    </MenuItem>
                  )}
                  {!seller && !isAdmin && (
                    <MenuItem className="dbmlinks">
                      <NavLink to="/buyerdashboard" activeClassName="active">
                      {props.Language.bds_cap ? props.Language.bds_cap : "BIDS"} 
                      </NavLink>
                    </MenuItem>
                  )}

                  <MenuItem className="dbmlinks">
                    <NavLink activeClassName="active" to="/profile">
                     {props.Language.prf_cap ? props.Language.prf_cap : "PROFILE"} 
                    </NavLink>
                  </MenuItem>
                  <MenuItem className="dbmlinks">
                    <NavLink to="/messages" activeClassName="active">
                    {props.Language.inb_cap ? props.Language.inb_cap : "INBOX"} 
                    </NavLink>
                  </MenuItem>
                  {!isAdmin ? (
                    <>
                      {' '}
                      <MenuItem className="dbmlinks">
                        <NavLink to="/payments" activeClassName="active">
                        {props.Language.paymentcaps ? props.Language.paymentcaps : "PAYMENT"}
                        </NavLink>
                      </MenuItem>
                      <MenuItem className="dbmlinks">
                        <NavLink to="/watchlist" activeClassName="active">
                        {props.Language.wat_cap ? props.Language.wat_cap : "WATCHLIST"}
                        </NavLink>
                      </MenuItem>
                      <MenuItem className="dbmlinks">
                        <NavLink
                          to="/transactions"
                          className={classes.dbmlinks}
                          activeClassName="active"
                        >
                          {props.Language.tra_cap ? props.Language.tra_cap : "TRANSACTIONS"}
                        </NavLink>
                      </MenuItem>
                      <MenuItem className="dbmlinks">
                        <NavLink
                          to="/membership"
                          className={classes.dbmlinks}
                          activeClassName="active"
                        >
                          {props.Language.mem_cap ? props.Language.mem_cap : "MEMBERSHIP"}
                        </NavLink>
                      </MenuItem>
                    </>
                  ) : null}
                </ul>
              </div>
              <div className={classes.sectionMobile}>
                <Button
                  aria-label="show menu"
                  aria-haspopup="true"
                  onClick={ShowMenu}
                  className="mm-toggle profileToggleBtn"
                >
                  <i className="fa fa-bars"></i>
                </Button>
              </div>
            </Toolbar>
          </AppBar>
        </>
      ) : null}
    </div>
  );
};

const mapStateToProps = state =>{
  return {
    Language : state.Language.languages
   }
}

export default connect(mapStateToProps)(withRouter(DashboardHeader));
