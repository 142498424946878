import React, { useState, useEffect } from 'react';
import { dateFormatFront, checkProductOpen } from '../../Common/Components';
import Timer from '../../Common/Timer';
import { Link } from 'react-router-dom';
import { Tooltip } from '@material-ui/core';
import FavouriteButton from '../../Components/Favourite/FavouriteButton';
import SocialMediaShare from '../../Components/SocialMediaShare/SocialMediaShare';
import {connect} from 'react-redux';

const ProductTop = ({
  product,
  historyBack,
  productSocket,
  bidForm,
  proddate_closed,
  onChange,
  onSubmit,
  user,
  openPayment,
  openSellerContact,
  isAuthenticated,
  Language
}) => {
  const proddetail = product.product_details;
  const [sliderIndex, setImage] = useState(0);
  let mainImageIndex = sliderIndex;

  const [imgLoaded, setImgLoaded] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);

  useEffect(() => {
    setDataLoaded(true);
    setImgLoaded(true);

    const loadImg = setTimeout(() => {
      setImgLoaded(false);
    }, 500);

    const loadData = setTimeout(() => {
      setDataLoaded(false);
    }, 700);

    return () => {
      clearTimeout(loadData);
      clearTimeout(loadImg);
    };
  }, [product.product_details.id]);

  function changeImage(currentImageIndex) {
    setImage((mainImageIndex = currentImageIndex));
  }

  return (
    <div className="container pv-mt-25">
      <button
        onClick={historyBack}
        style={{ marginBottom: '15px', display: 'inline-block' }}
        className="btn "
      >
        {Language.bcktosrch}
      </button>
      {productSocket.top_message !== '' && (
        <div className={`lb-breadcrumb ${productSocket.bid_class}`}>
          {isAuthenticated && (
            <>
              <span>{productSocket.top_message}</span>
              <span className="abid-info">
                <i className="fa fa-info-circle"></i>
                <div className="tooltip bs-tooltip-top">
                  <div className="tooltip-inner">
                   {Language.aconfima}
                  </div>
                </div>
              </span>
            </>
          )}
        </div>
      )}

      <div className="media pv-media">
        <div className="media-left">
          {product.product_existingimage.length > 0 ? (
            <>
              {product.product_existingimage.length === 1 ? (
                <div className="d-flex">
                  <div className="lg-main w-100 position-relative">
                    <img
                      className="w-100"
                      onError={(e) => {
                        e.target.onerror = null;
                        e.target.src = './images/no-image.png';
                      }}
                      src={
                        imgLoaded
                          ? '/images/imgDefault.gif'
                          : `${global.image_url}${product.product_existingimage[0]}`
                      }
                      alt=""
                    />
                  </div>
                </div>
              ) : (
                <div className="d-flex">
                  <div className="lg-main position-relative">
                    <img
                      onError={(e) => {
                        e.target.onerror = null;
                        e.target.src = './images/no-image.png';
                      }}
                      src={
                        imgLoaded
                          ? '/images/imgDefault.gif'
                          : `${global.image_url}${product.product_existingimage[mainImageIndex]}`
                      }
                      alt=""
                    />
                  </div>
                  <div className="pro-thumb-wrap">
                    {product.product_existingimage.map((item, index) => {
                      return (
                        <div className="pro-thumb">
                          <img
                            onError={(e) => {
                              e.target.onerror = null;
                              e.target.src = './images/no-image.png';
                            }}
                            src={
                              imgLoaded
                                ? '/images/imgDefault.gif'
                                : `${global.image_url}${item}`
                            }
                            onClick={() => changeImage(index)}
                            alt=""
                          />
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
            </>
          ) : null}

          {/* <div className="lg-main">
              <img
                src={`${global.image_url}${product.product_existingimage[0]}`}
                alt=""
              />
            </div>
            <div className="pro-thumb-wrap">
              {product.product_existingimage.map((item, index) => {
                return index > 0 ? (
                  <div className="pro-thumb">
                    <img src={`${global.image_url}${item}`} alt="" />
                  </div>
                ) : null;
              })}
            </div> */}
        </div>
        <div className="media-body">
          {dataLoaded ? (
            <>
              <img
                className="w-100"
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = './images/no-image.png';
                }}
                src={'/images/imgDefault.gif'}
                alt=""
              />
            </>
          ) : (
            <>
              <h3 className="pv-title">{proddetail.title}</h3>

              <div className="pv-timer">
                {proddate_closed ? (
                  <Timer
                    date_added={proddetail.date_added}
                    date_closed={proddate_closed}
                    withText={0}
                  ></Timer>
                ) : null}
              </div>
              <div className="pv-txt">{Language.pridd}: {proddetail.id}</div>
              {parseInt(proddetail.qty) > 0 ? (
                <div className="pv-txt">
                  {Language.quantttt} : {parseInt(proddetail.qty)}
                </div>
              ) : null}

              {proddetail.auction === 1 ? (
                <>
                  <div className="pv-txt">
                    {Language.stbidd} : ${parseInt(proddetail.sprice)}
                  </div>
                  <div className="pv-otxt">
                    {Language.ttbidd} :{' '}
                    <span className="pp-txt">[{productSocket.bid_count}]</span>
                  </div>
                </>
              ) : null}
              {proddetail.buynow === 1 ? (
                <>
                  <div className="pv-txt">
                    {Language.bnpr} : ${proddetail.bprice}
                  </div>
                </>
              ) : null}
              {proddetail.conditionunique ? (
                <>
                  <div className="pv-txt">
                    {Language.connn} :{' '}
                    {global.conditions
                      .filter((x) => x.id === proddetail.conditionunique)
                      .map((subcat, index) => {
                        return (
                          <>
                            <Tooltip
                              title={subcat.content}
                              placement="top-start"
                            >
                              <span style={{ cursor: 'pointer' }}>
                                {subcat.name}
                              </span>
                            </Tooltip>
                          </>
                        );
                      })}
                  </div>
                </>
              ) : null}
              {proddetail.auction === 1 ? (
                <>
                  {parseInt(proddetail.content_head1) > 0 ? (
                    <>
                      <div className="pv-txt">
                        {Language.curr_bid ? Language.curr_bid : "Current Bid" } : ${productSocket.current_price}{' '}
                        <span>
                          ($
                          {parseFloat(
                            parseInt(productSocket.current_price)
                          ).toFixed(2)}{' '}
                          * {proddetail.qty} = $
                          {parseFloat(
                            parseInt(productSocket.current_price) *
                              parseInt(proddetail.qty)
                          ).toFixed(2)}{' '}
                          for the lot)
                        </span>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="pv-txt">
                      {Language.curr_bid ? Language.curr_bid : "Current Bid" } : ${productSocket.current_price}
                      </div>
                    </>
                  )}
                </>
              ) : null}
              <div className="pv-txt">
                  {Language.clos_date ? Language.clos_date : "Closing Date" } : {dateFormatFront(proddetail.date_closed)}
              </div>

              {user.id !== proddetail.user_id &&
              checkProductOpen(proddetail.date_added, user.role) &&
              proddetail.market_status === 'open' ? (
                <>
                  <div className="row sb-wrap">
                    {proddetail.auction === 1 ? (
                      <div className="col-12">
                        {isAuthenticated && user.card_profileid? (
                          <>
                            <form
                              id="BidForm"
                              data-parsley-validate
                              onSubmit={onSubmit}
                            >
                              {parseInt(proddetail.content_head1) > 0 ? (
                                <> {Language.bidding_perunt ? Language.bidding_perunt : "Bidding is per unit" }: </>
                              ) : null}
                              <div className="bids-input  d-flex justify-content-start align-items-center pvActBtn">
                                <input
                                  className="form-control numberonly"
                                  type="text"
                                  name="wsprice"
                                  value={bidForm.wsprice}
                                  maxLength="10"
                                  onChange={onChange}
                                  data-parsley-required="true"
                                  data-parsley-min={productSocket.next_bid}
                                  placeholder={`${Language.enter} ${Language.amttt} $ ${productSocket.next_bid}`}
                                  data-parsley-errors-container="#rprice_error"
                                  data-parsley-error-message={`${Language.thisvaltheqto} ${productSocket.next_bid}`}
                                />
                                <button
                                  id="bidSubmitButton"
                                  className="btn btn-green"
                                  type="submit"
                                >
                                  {Language.submit_bid ? Language.submit_bid : "SUBMIT BID"}
                                </button>
                              </div>
                              <span id="rprice_error"></span>
                            </form>
                          </>
                        ) : (
                          <>
                            <div className="bids-input  d-flex justify-content-start align-items-center pvActBtn">
                              <input
                                className="form-control numberonly"
                                type="text"
                                name="wsprice"
                                value={bidForm.wsprice}
                                maxLength="10"
                                onChange={onChange}
                                data-parsley-required="true"
                                data-parsley-min={productSocket.next_bid}
                                placeholder={`${Language.enter} ${Language.amttt} $ ${productSocket.next_bid}`}
                              />
                              {isAuthenticated  ? (
                                <>
                                  <Link to={`/payments`}>
                                    <button className="btn btn-green">
                                      {Language.addcrdbid}
                                    </button>
                                  </Link>
                                </>
                              ) : (
                                <>
                                  <Link to={`/login`}>
                                    <button className="btn btn-green">
                                      {Language.lgbd}
                                    </button>
                                  </Link>
                                </>
                              )}
                            </div>
                          </>
                        )}

                        <div className="em-bids">
                          Enter US ${productSocket.next_bid} or more
                        </div>
                        {parseInt(productSocket.current_price) >=
                        parseInt(proddetail.rprice) ? (
                          <>
                            <span>{Language.res_prmet}</span>
                          </>
                        ) : (
                          <>
                            <span>{Language.resprinm}</span>
                          </>
                        )}
                      </div>
                    ) : null}

                    {proddetail.auction === 1 && proddetail.buynow === 1 ? (
                      <>
                        {parseInt(productSocket.current_price) <=
                        parseInt(proddetail.bprice) ? (
                          <>
                            <div className="col-12 mt-3">
                              <button
                                className="btn btn-green"
                                type="button"
                                onClick={() => openPayment()}
                              >
                                {Language.bynww}
                              </button>
                            </div>
                            <br />
                            <br />
                          </>
                        ) : null}
                      </>
                    ) : proddetail.buynow === 1 ? (
                      <>
                        <div className="col-12 mt-3">
                          <button
                            className="btn btn-green"
                            type="button"
                            onClick={() => openPayment()}
                          >
                            {Language.bynww}
                          </button>
                        </div>
                      </>
                    ) : null}
                    <div className="col-12 mt-3">
                      <button
                        className="btn btn-green"
                        type="button"
                        onClick={() => openSellerContact()}
                      >
                        {Language.conttt}
                      </button>
                    </div>
                  </div>
                </>
              ) : null}
              <div className="mt-2">
                {isAuthenticated && (
                  <FavouriteButton
                    watchlisted={proddetail.wlistpop}
                    project_id={proddetail.id}
                  />
                )}
              </div>
              <div className="mt-2">
                {isAuthenticated && (
                  <SocialMediaShare
                  project_id={proddetail.id}
                  />
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    Language : state.Language.languages
   }
 };


export default connect(mapStateToProps,undefined)(ProductTop);
