import React, { useReducer } from 'react';
import BuyerContext from './buyerContext';
import BuyerReducer from './buyerReducer';
import apiCall from '../../Common/ApiCall';

import {
  GET_VIEW_PRODUCT,
  BID_PRODUCT,
  GET_ALL_BUYER,
  GET_ALL_TRANSACTION,
  GET_BID_HISTORY,
  RESPONSE_STATUS,
  CLEAR_RESPONSE,
} from './buyerTypes';

const BuyerState = (props) => {
  const initialState = {
    activeInterval: [],
    buyer_alltransaction: {
      results: [],
      total_pagecnt: 0,
      total_only: 0,
    },
    product_view: {},
    product_bidhistory: {},
    buyer_allproducts: {
      results: [],
      total_pagecnt: 0,
      total_only: 0,
    },
    responseStatus: null,
  };

  const [state, dispatch] = useReducer(BuyerReducer, initialState);
  // Post Product
  const bidConfirm = async (formData) => {
    try {
      const [res] = await Promise.all([
        apiCall('post', 'mobileconfirmForward', formData),
      ]);
      if (res.data.status === 'true') {
        dispatch({
          type: BID_PRODUCT,
          payload: 'successfully_completed',
        });
      } else {
        dispatch({
          type: RESPONSE_STATUS,
          payload: res.data.failedreason,
        });
      }
    } catch (err) {
      dispatch({
        type: RESPONSE_STATUS,
        payload: 'Something went wrong!',
      });
    }
  };

  // Get view Product
  const getViewProduct = async (formData) => {
    try {
      const [res] = await Promise.all([
        apiCall('post', 'getprodetails', formData),
      ]);
      if (res.data.success === 'yes') {
        dispatch({
          type: GET_VIEW_PRODUCT,
          payload: res.data,
        });
      } else {
        dispatch({
          type: RESPONSE_STATUS,
          payload: res.data.failedreason,
        });
      }
    } catch (err) {
      console.log('err', err);
      dispatch({
        type: RESPONSE_STATUS,
        payload: 'Something went wrong!',
      });
    }
  };

  const getBidHistoryProduct = async (formData) => {
    try {
      const [res] = await Promise.all([
        apiCall('post', 'getbiddetails', formData),
      ]);
      if (res.data.success === 'yes') {
        dispatch({
          type: GET_BID_HISTORY,
          payload: res.data.results,
        });
      } else {
        dispatch({
          type: RESPONSE_STATUS,
          payload: res.data.failedreason,
        });
      }
    } catch (err) {
      console.log('err', err);
      dispatch({
        type: RESPONSE_STATUS,
        payload: 'Something went wrong!',
      });
    }
  };

  const getAllBuyerProducts = async (formData) => {
    try {
      const [res] = await Promise.all([
        apiCall('post', 'buyerDashboard', formData),
      ]);
      if (res.data.success === 'yes') {
        dispatch({
          type: GET_ALL_BUYER,
          payload: res.data,
        });
      } else {
        dispatch({
          type: GET_ALL_BUYER,
          payload: {
            result: [],
            length: 0,
          },
        });
      }
    } catch (err) {
      dispatch({
        type: RESPONSE_STATUS,
        payload: 'Something went wrong!',
      });
    }
  };

  const getAllTransaction = async (formData) => {
    try {
      const [res] = await Promise.all([
        apiCall('post', 'buyerTransaction', formData),
      ]);
      if (res.data.success === 'yes') {
        dispatch({
          type: GET_ALL_TRANSACTION,
          payload: res.data,
        });
      } else {
        dispatch({
          type: GET_ALL_TRANSACTION,
          payload: {
            result: [],
            length: 0,
          },
        });
      }
    } catch (err) {
      dispatch({
        type: RESPONSE_STATUS,
        payload: 'Something went wrong!',
      });
    }
  };

  const clearResponse = () =>
    dispatch({
      type: CLEAR_RESPONSE,
    });
  return (
    <BuyerContext.Provider
      value={{
        product_view: state.product_view,
        activeInterval: state.activeInterval,
        product_bidhistory: state.product_bidhistory,
        buyer_allproducts: state.buyer_allproducts,
        responseStatus: state.responseStatus,
        buyer_alltransaction: state.buyer_alltransaction,
        clearResponse,
        getAllBuyerProducts,
        getAllTransaction,
        getViewProduct,
        getBidHistoryProduct,
        bidConfirm,
      }}
    >
      {props.children}
    </BuyerContext.Provider>
  );
};

export default BuyerState;
