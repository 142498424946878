/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState, useEffect } from 'react';
import Layout from '../../Components/Layout';
import UserContext from '../../context/user/userContext';
import { dateFormatFront } from '../../Common/Components';
import Loading from '../../Components/Loading';

const BlogDetail = (props) => {
  const userContext = useContext(UserContext);
  const [loading, setLoading] = useState(true);
  const { getOneBlog, blogcontent } = userContext;

  useEffect(() => {
    if (props.match.params.id) {
      setLoading(true);
      getOneBlog({ blog_id: props.match.params.id });
    }
  }, []);

  useEffect(() => {
    setLoading(false);
  }, [blogcontent]);

  return (
    <Layout>
      <Loading loading={loading} />
      <div className="container search-content-wrap mt-5 mb-5">
        <div className="row">
          <div className="col-md-12 col-lg-12">
            <div className="blog-details-wrapper">
              <div className="media mb-3">
                {blogcontent.useravatar ? (
                  <>
                    <div className="media-left">
                      <img
                        src={`${global.profile_image_url}${blogcontent.useravatar}`}
                        alt=""
                        className="posted-user"
                      />
                    </div>
                  </>
                ) : null}

                <div className="meda-body">
                  <h4>{blogcontent.title}</h4>
                  <div className="clearfix">
                    <div className="float-left">
                      {`${blogcontent.userfirst_name ? blogcontent.userfirst_name : ''} ${blogcontent.userlast_name ? blogcontent.userlast_name : ''} `}
                    </div>
                    {blogcontent.date_added ? <>
                      <div className="float-right">
                        {' '}
                        - {dateFormatFront(blogcontent.date_added)}
                      </div>
                      </> : null}
                  </div>
                </div>
              </div>
              {/* <div className="bimg-wrap">
                <img src="/images/image1.jpg" alt="" className="bimg-wrap" />
              </div> */}
              <div
                dangerouslySetInnerHTML={{
                  __html: blogcontent.content ? blogcontent.content : '',
                }}
              ></div>
            </div>
          </div>
          {/* <div className="col-md-5 col-lg-4">
            <h2 className="main-head clearfix text-left mt-5 mb-3 pblog-head">
              <span className="hbox">Latest</span>
              <span>Blog Posts</span>
            </h2>
            <div className="pro-lp-wrap">
              {blogposted.map((item, index) => {
                return (
                  <div className="media" key={index}>
                    <img src={item.image} alt={title} className="posted-user" />
                    <div className="media-body">
                      <Link to="/blog" className="blog-title">
                        {item.title}
                      </Link>
                      <div className="clearfix">
                        <div className="float-left">{item.createdBy}</div>
                        <div className="float-right">{item.dateCreated}</div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div> */}
        </div>

        {/* <h2 className="main-head clearfix text-left mt-5 mb-3 pblog-head">
          <span className="hbox">Similar</span>
          <span>Blog Posts</span>
        </h2>

        <div className="clearfix"></div>

        <div className="row blog-lwrap">
          {blogposted.map((item, index) => {
            return (
              <div
                className="col-sm-6 col-md-4 col-lg-3 form-group"
                key={index}
              >
                <div className="card grid-card">
                  <img src={item.image} alt={title} className="bgc-img" />
                  <div className="card-body">
                    <div className="clearfix">
                      <div className="float-left">{item.createdBy}</div>
                      <div className="float-right">{item.dateCreated}</div>
                    </div>

                    <Link to="/blog" className="blog-title">
                      {item.title}
                    </Link>
                    <div className="blog-descp">{item.description}</div>
                  </div>
                </div>
              </div>
            );
          })}
        </div> */}
      </div>
    </Layout>
  );
};

export default BlogDetail;
