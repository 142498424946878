import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import GridItem from "./GridItem";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

const BidNowAuctions = (props) => {
  let settings = {
    dots: true,
    infinite: props.products.results.length > 1,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    vertical: true,
    verticalSwiping: true,
    adaptiveHeight: false,
    initialSlide: 0,
    autoplay: true,
    pauseOnHover: false,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="site-auctions-wrapper mbn-wrap bidAuc">
      <div className="dbmhead bn-head" noWrap>
        <Link to="/search">
          <span className="hbox">{props.Language.mrkt_place}</span>
          <span>{props.Language.bd_nw}</span>
          <span>{props.Language.auc}</span>
        </Link>
      </div>

      <Slider {...settings}>
        {props.products.results.map((item, index) => {
          return (
            <GridItem
              image={`${global.image_url}${item.file_name}`}
              name={item.title}
              price={item.wprice}
              details={`/product-view/${item.id}`}
              timer={item.date_closed}
              addedtimer={item.date_added}
              key={`item-${index}`}
            />
          );
        })}
      </Slider>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    Language: state.Language.languages,
  };
};

export default connect(mapStateToProps, undefined)(BidNowAuctions);
