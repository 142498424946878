/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useContext, useEffect } from 'react';
import ProductContext from '../../context/product/productContext';
import AlertContext from '../../context/alert/alertContext';
import {connect} from 'react-redux';
const FavouriteButton = ({ watchlisted, project_id , ...props}) => {
  const productContext = useContext(ProductContext);
  const alertContext = useContext(AlertContext);
  const { setAlert } = alertContext;
  const [projectChanged, setProjectChanged] = useState('');
  const [checked, setChecked] = useState(
    parseInt(watchlisted) > 0 ? true : false
  );

  const {
    addWatchlist,
    removeWatchlist,
    responseStatus,
    clearResponse,
  } = productContext;
  useEffect(() => {
    setChecked(parseInt(watchlisted) > 0 ? true : false);
  }, [project_id, watchlisted]);
  useEffect(() => {
    if (
      responseStatus &&
      responseStatus !== '' &&
      project_id === projectChanged
    ) {
      if (responseStatus === 'watchlistadded_successfully') {
        setAlert(props.Language.walist_a_s ? props.Language.walist_a_s : 'Watchlist added successfully', 'success');
        clearResponse();
        setChecked(!checked);
      } else if (responseStatus === 'watchlistremoved_successfully') {
        setAlert(props.Language.walist_r_s ? props.Language.walist_r_s :'Watchlist removed successfully', 'success');
        clearResponse();
        setChecked(!checked);
      } else {
        setAlert(responseStatus, 'error');
        clearResponse();
      }
      setProjectChanged('');
    } else {
      clearResponse();
    }
  }, [responseStatus]);

  const toggleFavourite = () => {
    if (checked) {
      setProjectChanged(project_id);
      removeWatchlist({ product_id: project_id });
    } else {
      setProjectChanged(project_id);
      addWatchlist({ product_id: project_id, watchlistemail: '1' });
    }
  };

  return (
    <>
      <div
        className={`wl-cbox fav-button ${
          checked ? 'btn btn-primary' : 'btn btn-green'
        } `}
        onClick={() => toggleFavourite()}
      >
        <input type="checkbox" checked={checked} />
        {checked ? props.Language.rem_to_wishl ? props.Language.rem_to_wishl : 'REMOVE FROM WATCHLIST' : props.Language.Add_to_wishl ? props.Language.Add_to_wishl : 'ADD TO WATCHLIST'}
      </div>
    </>
  );
};

const mapToStateProps = state =>{
  return {
    Language : state.Language.languages
   }
}

export default connect(mapToStateProps)(React.memo(FavouriteButton));
