import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './home.css';
import {connect} from 'react-redux';

const TopCarousel = (props) => {
  let settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  let sliderData = [
    {
      sliderTitle: props.Language.store_closeou,
      sliderImg: './images/storeCloseout.png',
    },
    {
      sliderTitle: props.Language.bank_liquid,
      sliderImg: './images/bankruptcy.png',
    },
    {
      sliderTitle: props.Language.jorest,
      sliderImg: './images/majorEstate.png',
    },
    {
      sliderTitle: props.Language.evprty,
      sliderImg: './images/eviction1.png',
    },
    {
      sliderTitle: props.Language.res_equ,
      sliderImg: './images/5.png',
    },
    {
      sliderTitle: props.Language.oreouts,
      sliderImg: './images/storeCloseout2.png',
    },
    {
      sliderTitle: props.Language.bank_liquid,
      sliderImg: './images/bankruptcy2.png',
    },
    {
      sliderTitle: props.Language.jorest,
      sliderImg: './images/majorEstate2.png',
    },
    {
      sliderTitle: props.Language.evprty,
      sliderImg: './images/eviction2.png',
    },
    {
      sliderTitle: props.Language.res_equip,
      sliderImg: './images/resturant.png',
    },
    {
      sliderTitle: props.Language.oreouts,
      sliderImg: './images/storeCloseout3.png',
    },
    {
      sliderTitle: props.Language.bank_liquid,
      sliderImg: './images/bankruptcy3.png',
    },
    {
      sliderTitle: props.Language.jorest,
      sliderImg: './images/majorEstate3.png',
    },
    {
      sliderTitle: props.Language.evprty,
      sliderImg: './images/eviction3.png',
    },
    {
      sliderTitle: props.Language.res_equip,
      sliderImg: './images/Restaurant_pic2.png',
    },
    {
      sliderTitle: props.Language.oreouts,
      sliderImg: './images/storeCloseout4.png',
    },
    {
      sliderTitle: props.Language.bank_liquid,
      sliderImg: './images/bankruptcy4.png',
    },
    {
      sliderTitle: props.Language.jorest,
      sliderImg: './images/majorEstate4.png',
    },
    {
      sliderTitle: props.Language.evprty,
      sliderImg: './images/eviction4.png',
    },
    {
      sliderTitle: props.Language.res_equip,
      sliderImg: './images/Restaurant_pic_1b.png',
    },
  ];

  return (
    <div className="container">
      <Slider {...settings}>
        {sliderData.map((item, index) => {
          return (
            <div className="top-pro-item" key={`item-${index}`}>
              <h1>{item.sliderTitle}</h1>
              <img src={item.sliderImg} alt={item.title} className="item-img" />
            </div>
          );
        })}
      </Slider>

      {/* <Slider {...settings}>
        {props.auctions.results.map((item, index) => {
          return (
            <div className="top-pro-item" key={`item-${index}`}>
              <h1>{item.title}</h1>
              <img
                src={`${global.image_url}${item.file_name}`}
                alt={item.title}
                className="item-img htc-img"
              />
            </div>
          );
        })}
      </Slider> */}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    Language : state.Language.languages
   }
 };

export default connect(mapStateToProps,undefined) (TopCarousel);
