import React from 'react';
import { Link } from 'react-router-dom';
import './cards.css';
import Timer from '../../Common/Timer';

const GridItem = ({ name, image, details, price, timer, addedtimer }) => {
  return (
    <div className="card grid-card">
      <div className="fpos-rel">
        <Link to={details}>
          <img src={image} alt={name} />
        </Link>
      </div>
      <div className="card-body">
        <div className="price-txt">${price}</div>
        <Link to={details} className="details-link">
          <div className="name-txt">{name}</div>
        </Link>
        <div className="timer-text">
          <Timer
            date_added={addedtimer}
            date_closed={timer}
            withText={0}
          ></Timer>
        </div>
      </div>
    </div>
  );
};

export default GridItem;
