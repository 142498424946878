/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { fade, makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
// import TextField from '@material-ui/core/TextField';
// import Autocomplete from '@material-ui/lab/Autocomplete';
import { Redirect, Link } from 'react-router-dom';
import AuthContext from '../../context/auth/authContext';
import Alerts from '../Alert/alerts';
import queryString from 'query-string';
import { onChangeFunction } from '../../Common/Components';
import {connect} from 'react-redux';

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  header: {
    background: '#fff',
    boxShadow: '0px 3px 26px rgba(116, 116, 116, 0.7)',
    paddingBottom: '10px',
    [theme.breakpoints.up('md')]: {
      boxShadow: 'none',
      marginBottom: '5px',
    },
  },
  logo: {
    height: '105px',
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    width: theme.spacing(7),
    height: '54px',
    position: 'absolute',
    pointerEvents: 'fill',
    display: 'flex',
    cursor: 'pointer',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#fff',
    right: '0',
    top: '0px;',
    background: '#39A767',
    backgroundclip: 'padding-box',
    borderLeft: '0',
    border: '0px solid #ced4da',
    borderRadius: '0 5px 5px 0',
    [theme.breakpoints.down('sm')]: {
      width: '46px',
      height: '46px',
      right: '5px',
      top: '5px;',
    },
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 7),
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: 200,
    },
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
}));

const Header = (props) => {
  let history = useHistory();
  const classes = useStyles();
  const authContext = useContext(AuthContext);
  const { user, membership, initialLogin, clearInitial } = authContext;
  const [searchMenu, setSearchMenu] = useState(false);
  const handleMobileSearch = () => {
    setSearchMenu(!searchMenu);
  };

  // function handleProfileMenuOpen(event) {
  //   setAnchorEl(event.currentTarget);
  // }

  useEffect(() => {
    return history.listen(() => setSearchMenu(false));
  }, []);

  useEffect(() => {
    if (Object.keys(user).length !== 0 && props.props) {
      if (user.avatar) {
        if (parseInt(user.role) === 1) {
        } else if (parseInt(user.role) === 2) {
          if (
            props.props.location.pathname === '/membership' ||
            props.props.location.pathname === '/payments'
          ) {
          } else {
            settoRedirect(true);
          }
        } else if (parseInt(user.role) === 3) {
          if (membership.status !== 'active') {
            if (
              props.props.location.pathname === '/membership' ||
              props.props.location.pathname === '/payments'
            ) {
            } else {
              settoRedirect(true);
            }
          }
        }
      } else {
        if (props.props.location.pathname === '/profile') {
        } else {
          settoProfile(true);
        }
      }
    }
  }, [user, props.props]);

  useEffect(() => {
    if (initialLogin) {
      if (Object.keys(user).length !== 0) {
        clearInitial();
      }
    }
  }, [initialLogin, user]);

  const [toSearch, settoSearch] = useState(false);
  const [search, setSearch] = useState({
    search_bar: '',
  });
  const onEnterFunction = (e) => {
    if (e.key === 'Enter') {
      settoSearch(true);
    } else {
      settoSearch(false);
    }
  };

  // const onChangeValue = (e) => {
  //   setSearch({
  //     ...search,
  //     search_bar: e,
  //   });
  //   settoSearch(true);
  // };

  const onClickSearch = (e) => {
    if (search.search_bar) {
      settoSearch(true);
    } else {
      settoFirstSearch(true);
    }
  };

  // const onInputValue = (e) => {
  //   settoSearch(false);
  //   setSearch({
  //     ...search,
  //     search_bar: e,
  //   });
  // };
  // const handleKeyDown = (e) => {
  //   if (e.key === 'Enter') {
  //     settoSearch(true);
  //   }
  // };
  useEffect(() => {
    if (props.props) {
      if (props.props.location.search) {
        let value = queryString.parse(props.props.location.search);
        if (value.q) {
          setSearch({ ...search, search_bar: value.q });
        }
      } else {
      }
    }
  }, []);

  const onChange = (e) => setSearch(onChangeFunction(e, search));

  const [toRedirect, settoRedirect] = useState(false);
  const [toProfile, settoProfile] = useState(false);
  const [toFirstSearch, settoFirstSearch] = useState(false);
  return (
    <div className={classes.grow}>
      {toRedirect ? <Redirect to="/membership" /> : null}
      {toProfile ? <Redirect to="/profile" /> : null}
      {toFirstSearch ? <Redirect to="/search" /> : null}
      <Alerts />
      {toSearch ? (
        <Redirect
          to={`/search${search.search_bar ? '?q=' + search.search_bar : ''}`}
        />
      ) : null}
      <AppBar position="static" className={classes.header}>
        <Toolbar>
          <div className={classes.title} noWrap>
            <Link to="/" className="logo-link">
              <img src="/images/new-logo.png" className={classes.logo} alt="" />
            </Link>
          </div>
          <div className="phone-number">
            <img src="/images/call.png" alt="" /> 818-665-3863
          </div>
          <div
            className={`search-box ${
              searchMenu ? 'show-search' : 'hide-search'
            }`}
          >
            {/* onChange={onChange} onKeyDown={onEnterFunction} */}
            {/* <Autocomplete
              freeSolo
              disableClearable={true}
              value={search.search_bar}
              onChange={(event, value) => onChangeValue(value)}
              onInputChange={(event, value) => {
                onInputValue(value);
              }}
              onKeyDown={handleKeyDown}
              options={
                recent_search.length
                  ? recent_search.map((option) => option.searchtext)
                  : []
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  className="form-control"
                  label="Search Keyword, Description, or Title"
                  margin="normal"
                  name="search_bar"
                  variant="outlined"
                  id="search_bar"
                  InputProps={{ ...params.InputProps, type: 'search' }}
                />
              )}
            /> */}
            <input
              name="search_bar"
              value={search.search_bar}
              onChange={onChange}
              onKeyDown={onEnterFunction}
              placeholder={props.Language.sktd}
              className="form-control"
            />
            <button className={classes.searchIcon} onClick={onClickSearch}>
              <i className="fa fa-search"></i>
            </button>
            {/* <input
              name="search_bar"
              onChange={onChange}
              onKeyDown={onEnterFunction}
              placeholder="Search Keyword, Category, or Auction"
              className="form-control"
            />
            <button className={classes.searchIcon}>
              <i className="fa fa-search"></i>
            </button> */}
          </div>
          <div className={classes.grow}>
            {/* <div className={classes.sectionDesktop}>
              <span className="language-link">
                <img src="/images/united-states.png" alt="" /> English
              </span>
              <span className="language-link">
                <img src="/images/spain.png" alt="" /> Spanish
              </span>
            </div> */}
          </div>
          <div className={classes.sectionMobile}>
            <span onClick={handleMobileSearch} className="search-icon">
              <i className="fa fa-search"></i>
            </span>
          </div>
          <div className="social-links ml-auto">
            <a
              target="_blank"
              rel="noreferrer noopener"
              href="https://www.pinterest.com/laauctiondepot/pins/"
            >
              <i className="fab fa-pinterest"></i>
            </a>
            <a
              target="_blank"
              rel="noreferrer noopener"
              href="https://www.instagram.com/laauctiondepot/"
            >
              <i className="fab fa-instagram"></i>
            </a>
            <a
              target="_blank"
              rel="noreferrer noopener"
              href="https://www.linkedin.com/in/nina-tang-53b0771a1/?locale=zh_CN"
            >
              <i className="fab fa-linkedin"></i>
            </a>
            <a
              target="_blank"
              rel="noreferrer noopener"
              href="https://www.facebook.com/LAAuctionDepot"
            >
              <i className="fab fa-facebook"></i>
            </a>
            <a
              target="_blank"
              rel="noreferrer noopener"
              href="https://twitter.com/depot_la"
            >
              <i className="fab fa-twitter"></i>
            </a>
          </div>
        </Toolbar>
      </AppBar>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    Language : state.Language.languages
   }
 };


export default connect(mapStateToProps,undefined)(Header);
