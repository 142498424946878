import React from 'react';
import './Checkbox.css';

const Checkbox = (props) => {
  return (
    <label className="custom-checkbox pure-material-checkbox">
      {props.value}
      <input
        type="checkbox"
        name={props.name ? props.name : null}
        value={props.checkvalue ? props.checkvalue : null}
        onChange={props.onChange ? props.onChange : null}
        checked={props.checked ? props.checked : null}
      />
      <span>{props.link}</span>
    </label>
  );
};

export default Checkbox;
